import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from "../../../other/LoadingBar";
import {
    brTagActivator,
    getCurrentMenuInfo,
    brTagRemover,
    getTodayDate,
    calculateScore, FailScore, isMobile, isIOS
} from "../../../../asset/js/myFunctions";
import Footer_MDA from "../../Footer_MDA";
import Score from '../../Score';
import { Element ,  scroller } from 'react-scroll'
import $ from "jquery";

class MemorizeA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            numOfPair:0,   // 총 대화가 몇 쌍인지.
            // thisMenuNumber: 72,
            isStart:false,
            playingIndex: 0,
            // isPlaying:false,
            result:[{
                testResult:null,
            }],
            currentIndex:0,
            isTesting:false,
            isEnd:false,
            isReadyTest:false,
            currentPhase:{
                index:-1,
                pass:false,
                count:0,
            },
            letStartRecognition:false,
            err:null,
            errCount:0,
            tryCount:0,
            totalScore:0,
            isComplete:false,
            waiting:false,
        };
        //
        // this._renderDialogueA = this._renderDialogueA.bind(this);
        // this._setResult = this._setResult.bind(this);
        this.mTimeout = null;
    }

    componentDidMount(){
        this.props.myJS();
    }

    componentWillUnmount() {
        clearTimeout(this.mTimeout);
        this.mTimeout = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null && prevState.numOfPair === 0){ //처음 세팅시에만 걸림
            let results = [];
            for(let i =0 ; i < nextProps.contents.length; i ++){ //results 초기화.
                results[i] = {testResult:null}
            }
            ////console.log("result 초기화 완료. results: ",results);

            return({
                testingIndex:0,
                numOfPair:Math.ceil(nextProps.contents.length / 2),
                result:results,
            })
        }

        // if(nextProps.playingIndex !== prevState.playingIndex){ //playingIndex변경시 걸림.
        //     scroller.scrollTo(`dialogue${nextProps.playingIndex}`,{
        //         duration:800,
        //         delay:0,
        //         smooth:true,
        //         containerId: 'dialogueContainer',
        //         offset:isMobile && $(window).width() < 1200 ? -150 : -300,
        //     });
        //     return {playingIndex: nextProps.playingIndex};
        // }
        //
        // if(prevState.isTesting === true && nextProps.isPlaying === true){
        //     return ({
        //        isPlaying: nextProps.isPlaying,
        //     })
        // }

        return null;
    }


    componentDidUpdate(prevProps, prevState){

        if(prevState.currentPhase.index !== this.state.currentPhase.index){ //페이즈 변경됐을 경우

            scroller.scrollTo(`dialogue${this.state.currentPhase.index * 2 + 1}`,{ //스크롤 변경하고
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:-300,
            });
            // this._playRange(this.state.currentPhase.index * 2, this.state.currentPhase.index*2 + 1);
        } else if(prevState.playingIndex !== this.state.playingIndex){//스크롤 변경
            scroller.scrollTo(`dialogue${this.state.playingIndex}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:-300,
            });
        }

        if(prevState.isTesting === true && this.state.isTesting === false){ //테스트 종료 하고 나면 화면을 스크롤 시킴 제일 하단으로
            scroller.scrollTo(`dialogue${this.state.currentPhase.index*2 + 1}`,{
                duration:0,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });
        }

    }

    render() {
        if(this.props.contents === null || this.props.menuInfo === null){
            return (<LoadingBar />);
        }
        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }
        return (
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    {/*<h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>*/}
                    <h2 className="intro-title">{brTagRemover(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <span className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</span>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return(
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <Element className="content-wrap align-start" id={"dialogueContainer"}>
                        <ul className="talk-list">
                            <li className="datetime"><span>{getTodayDate()}</span></li>
                            {this._renderDialogueA(this.state.currentPhase.index)}
                        </ul>
                    </Element>

                    <div className="lecture-step">
                        <span className="current">{this.state.currentPhase.index + 1}</span>/<span className="total">{this.state.numOfPair}</span>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        A의 대사를 외웁니다.<br/>
                                        "삐" 소리 후 <strong>A의 대사를 영어로</strong> 말하세요.
                                        <div className="divider" />
                                        <div className="tip">
                                            발음연습 버튼을 눌러<br />
                                            <strong>입으로 충분히 연습</strong>하세요.
                                        </div>

                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_MDA
                    {...this.props}
                    audioPlayRange={this.audioPlayRange}
                    isReadyTest = {this.state.isReadyTest}
                    isEnd = {this.state.isEnd}
                    isTesting={this.state.isTesting}
                    toggleReadyTest = {this._toggleReadyTest}
                    letStartRecognition={this.state.letStartRecognition}
                    practicePronunciation = {this._practicePronunciation}
                    currentIndex ={this.state.currentIndex}
                    testingIndex={this.state.testingIndex}
                    setResult={this._setResult}
                    memorizeATest = {this._memorizeATest}
                    handleError = {this._handleError}
                    numOfPair={this.state.numOfPair}
                    currentPhase={this.state.currentPhase}
                    nextPhase={this._nextPhase}
                    resetResult={this._resetResult}
                    err={this.state.err}
                    isComplete={this.state.isComplete}
                    waiting={this.state.waiting}
                    showContinue={this.state.showContinue}
                    resetAudioContext={this.resetAudioContext}
                    //isPlaying={this.state.isPlaying}
                />
            </>
        )
    };

    _renderDialogueA = (index) => { //index = 0에서 시작.
        let dialogues = [];
        for(let i = 0 ; i < (index+1) * 2; i ++) { //짝수개씩 끊음. 2,4,6,8,10 등
            dialogues.push(
                <li
                    key={(this.props.contents[i].contents_idx)}
                    className={
                        this.state.playingIndex === i ? //재생중인 대화는 clicked 처리. 홀수는 왼쪽, 짝수는 오른쪽 대화.
                            (i % 2 === 0 ? "block left-block clicked" : "block right-block clicked")
                            :
                            (i % 2 === 0 ? "block left-block" : "block right-block")}

                >
                    <img
                        className="profile"
                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[i].mobile_url}`}
                        alt={"다이어로그 캐릭터 프로필"}/>
                    <Element
                        name={`dialogue${i}`}
                        className={this.props.isPlaying ? "chat-bubble" : "chat-bubble CursorPointer"}
                        onClick={() => {
                            this._selectBubble(i);
                        }}
                    >
                        <h2>{this.state.isTesting && i % 2 === 0 ? "" : brTagActivator(this.props.contents[i].eng_content)}</h2>
                        <p>
                            {brTagActivator(this.props.contents[i].kor_content)}
                        </p>
                        {
                            i % 2 === 0 && this.state.isTesting === false
                                ?
                                <button
                                    type="button"
                                    className="btn-practice"
                                    onClick={(event) => {
                                        this._practicePronunciation(i, event)
                                    }}
                                >발음연습
                                </button>
                                :
                                ""
                        }
                        {
                            this.state.result[i].testResult !== null
                                ? //해당 대화문에 결과가 있으면 결과를 출력함.
                                <>
                                    <div className="balloon">
                                        {
                                            isIOS ?
                                                "A"
                                                :
                                                <Score
                                                    scoreType={1} //무조건 abc로만 나오게
                                                    result={this.state.result[i].testResult}
                                                    original={this.props.contents[i].eng_content}
                                                />
                                        }

                                    </div>
                                </>
                                :
                                ""
                        }
                    </Element>
                </li>
            );
        }

        return(
            dialogues
        )
    };


    _start = () => {
        this.setState({
            isStart: true,
            currentPhase:{ //시작 시 음원 재생
                index:0,
                pass:false,
                count:0,
            },

        })
    };

    _selectBubble = (key) => {
        //console.log(key + " is clicked");
        if(this.props.isPlaying || this.state.isTesting){return;} //음원재생중일때 || 테스트 중일때는, 셀렉트 안됨.
        this.setState({
            playingIndex:key,
        });

        this.props.setHowler(
            `${this.props.contents[key].step_code}/${this.props.contents[key].lesson_no}/${this.props.contents[key].eng_url}`
        );
    };



    _toggleReadyTest = () => {
        this.setState({
            isReadyTest: !this.state.isReadyTest
        })
    }

    _setResult = (result, index) => { //전체 대화문의 index와 result를 받아와서 해당 결과를 업데이트 함.
        if(this.state.isTesting){//테스트 중일때와
            //테스트 중일 때 결과를 받으면
            if(index === this.state.currentPhase.index*2) { //마지막 대화문의 테스트 결과를 받은 경우
                let currentResult = this.state.result;
                currentResult[index] = {testResult:result};
                let pass = (this.state.totalScore+calculateScore(result, this.props.contents[index].eng_content))/(this.state.currentPhase.index + 1) >= FailScore;

                if(pass){
                    this.setState({
                        result:currentResult,
                        totalScore:0,
                        currentPhase:{
                            index: this.state.currentPhase.index,
                            pass:pass,
                            count: this.state.currentPhase.count + 1,
                        },
                        err:null,
                        waiting:true,
                        letStartRecognition:false,
                    });

                }else if(!pass && this.state.currentPhase.count < 2){
                    this.setState({
                        result:currentResult,
                        totalScore: 0,
                        currentPhase:{
                            index: this.state.currentPhase.index,
                            pass: pass,
                            count: this.state.currentPhase.count + 1,
                        },
                        err: ["대화를 다시 확인 하시고", <br />,"다시 ", <strong>테스트</strong>, "하세요."],
                        waiting:true,
                        letStartRecognition:false,
                    });
                    //this.props.openTip(); //팁창 출력

                }else if(!pass && this.state.currentPhase.count >=2){
                    this.setState({
                        result:currentResult,
                        totalScore: 0,
                        currentPhase:{
                            index: this.state.currentPhase.index,
                            pass: true, //3번이상이면 pass=true,
                            count: this.state.currentPhase.count + 1,
                        },
                        err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                        waiting:true,
                        letStartRecognition:false,
                    });
                }

                //0.8초후에 다음 대화를 재생시킴.
                setTimeout(() => {
                    this._memorizeATest(index + 1);
                }, 800)

            } else { //아직 테스트할게 남았으면 isTesting을 true 로 유지한채 다음 음원 재생시킴, testingIndex + 1,
                let currentResult = this.state.result;
                currentResult[index] = {testResult:result};
                this.setState({
                    result:currentResult,
                    totalScore: this.state.totalScore + calculateScore(result, this.props.contents[index].eng_content),
                    err:null,
                    waiting:true,
                    letStartRecognition:false,
                });
                // setTimeout(() => {
                //     this.props.audioPlayWithCb("eng", index + 1, () => {
                //         this.setState({isComplete: true, waiting: false,})
                //     })
                // }, 800); //0.7초후에 다음 대화를 재생시킴.
                setTimeout(() => {
                    this._memorizeATest(index + 1);
                }, 800)
            }

        } else { //발음 연습하고 있을 때를 구분함.
            let currentResult = this.state.result;
            currentResult[this.state.currentIndex] = {testResult:result};
            this.setState({
                result:currentResult,
                letStartRecognition:false,
            })
        }

    };

    _practicePronunciation = (index, event) => {
        if(this.props.isPlaying || this.props.isTesting){ //테스트 중이거나 음원재생중일때는 발음연습 불가
            return;
        }

        //console.log("발음 연습 시작. index: ",index);
        //this.props.audioFakePlay(index); //버블 선택.
        event.stopPropagation();

        this.setState({
            currentIndex:index,
            letStartRecognition:true,
            playingIndex:index
        })
    };

    // _playTill = () => { //출력되어 있는 대화문까지만 재생시키기 위한 함수.
    //     //console.log("play all");
    //     // this.props.audioPlayTill((this.state.currentPhase.index + 1) * 2,0); //어디까지 플레이 해야 하는지 마지막 index 를 전송함.
    //     this.props.audioPlayRange(0, (this.state.currentPhase.index * 2 + 1), 0)
    //     //this.props.audioPlay("eng", this.state.index)
    // };
    //
    // _playRange= (start, end) => { //범위 지정해서 대화문 재생
    //     //console.log("play all");
    //     this.props.audioPlayRange(start, end, 0); //재생 범위 입력
    //     //this.props.audioPlay("eng", this.state.index)
    // };
    //
    audioPlayRange = (current, start, end) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }

        //Recursive
        if(current === start){
            this.props.closeTip();

            this.setState({
                isAllPlayingComplete: false,
                playingIndex:current,
                isPlaying:true,
            });

            scroller.scrollTo(`dialogue${current}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });

        } else if(current > end) {
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.setState({
                playingIndex:current
            });
            scroller.scrollTo(`dialogue${current}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });
        }

        let src = `${this.props.contents[current].step_code}/${this.props.contents[current].lesson_no}/${this.props.contents[current].eng_url}`;
        this.props.setHowlerError(
            src,
            () => {
                this.audioPlayRange(current + 1, start, end);
            },
            () => {
                this.props.setSrc(null);
                this.audioPlayRange(current + 1, start, end)
            }
        );
    }




    // _memorizeATest = () => {
    //     //this._selectBubble(0); //먼저 0번부터 시작.
    //     this.props.closeTip();
    //     this.props.audioFakePlay(0); //0번 버블 선택.
    //     this.setState({
    //         isTesting:true, //테스트 모드 돌입
    //         testingIndex:0,
    //         isComplete:false,
    //         waiting:true,
    //     });
    // };

    _memorizeATest = (idx) => {
        if(idx > (this.state.currentPhase.index * 2 + 1)) { //끝까지 간 경우 테스트 종료
            this.setState({
                isTesting:false,
                waiting:false,
            });
            return;
        }

        if(idx === 0) { //테스트 첫 시작
            let results = [];
            for(let i =0 ; i < this.props.contents.length; i ++){ //results 초기화.
                results[i] = {testResult:null}
            }
            this.setState({
                isTesting:true, //테스트 모드 돌입
                result:results,
                currentIndex: idx,
                playingIndex: idx,
            });
            this.props.closeTip();
        }

        if(idx % 2 === 0) { //A대화의 경우 Fake and Recognition start
            this.setState({
                letStartRecognition: true,
                currentIndex: idx,
                playingIndex: idx,
            });
            // this.props.setSrc(`${this.props.contents[idx+1].step_code}/${this.props.contents[idx+1].lesson_no}/${this.props.contents[idx+1].eng_url}`)

        } else { //B대화의 경우 음원 플레이
            this.setState({
                currentIndex: idx,
                playingIndex: idx,
            });

            // this.props.setSrc(null);
            // this.setState({
            //     err:["소리파일 로드를 실패하였습니다. 이어서 테스트 하세요."],
            //     waiting:false,
            // });
            // this.props.openTip();
            // this.showContinueButton();
            //

            this.props.setHowlerError(
                `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].eng_url}`,
                () => {
                    this._memorizeATest(idx + 1);
                },
                () => {
                    this.props.setSrc(null);
                    this.setState({
                        err:["소리파일 로드를 실패하였습니다. 이어서 테스트 하세요."],
                        waiting:false,
                    });
                    this.props.openTip();
                    this.showContinueButton();
                }
            )

            // this.props.setHowler(
            //     `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].eng_url}`,
            //     //`BigSample${idx}.mp3`,
            //     () => {
            //         this._memorizeATest(idx + 1);
            //     },
            // );
        }
    };

    _memorizeATestRetry = (idx) => {
        if(idx > (this.state.currentPhase.index * 2 + 1)) { //끝까지 간 경우 테스트 종료
            this.setState({
                isTesting:false,
                waiting:false,
            });
            return;
        }

        if(idx === 0) { //테스트 첫 시작
            let results = [];
            for(let i =0 ; i < this.props.contents.length; i ++){ //results 초기화.
                results[i] = {testResult:null}
            }
            this.setState({
                isTesting:true, //테스트 모드 돌입
                result:results,
                currentIndex: idx,
                playingIndex: idx,
            });
            this.props.closeTip();
        }

        if(idx % 2 === 0) { //A대화의 경우 Fake and Recognition start

            this.setState({
                letStartRecognition: true,
                currentIndex: idx,
                playingIndex: idx,
            });
            // this.props.setSrc(`${this.props.contents[idx+1].step_code}/${this.props.contents[idx+1].lesson_no}/${this.props.contents[idx+1].eng_url}`)

        } else { //B대화의 경우 음원 플레이
            this.setState({
                currentIndex: idx,
                playingIndex: idx,
            });

            this.props.setHowlerError(
                `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].eng_url}`,
                () => {
                    this._memorizeATest(idx + 1);
                },
                () => {
                    this.props.setSrc(null);
                    this._memorizeATest(idx + 1);
                }
            )
        }
    };


    _updateTestingIndex = (testingIndex) => {
        this.setState({
            testingIndex: testingIndex
        })
    }

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    resetAudioContext = () => {
        let context = new AudioContext();
        context.resume().then(() => {
            console.log('Playback resumed successfully');
            this.setState({
                showContinue:false,
            })
            //this._memorizeATestRetry(this.state.currentIndex);
            this._memorizeATestRetry(this.state.playingIndex);
        });
    }

    showContinueButton = () => {
        this.setState({
            showContinue:true,
        })
    }

    _handleError = (err) => {
        //console.log("음성인식 실패.");
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){ //err===null 이면 return
            this.setState({
                err:err,
                waiting:false,
            });

            return;
        }

        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.currentIndex); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            waiting:false,
        });

    };

    _nextPhase = () => {

        this.audioPlayRange(
            (this.state.currentPhase.index + 1) * 2,
            (this.state.currentPhase.index + 1) * 2,
            (this.state.currentPhase.index + 1) * 2 + 1
        );


        this.setState({ //index 증가 및 초기화.
            currentPhase:{
                index:this.state.currentPhase.index+1,
                pass:false,
                count:0,
            },
            err:null,
            errCount:0,
            totalScore:0,
            waiting:false,
        })
    };

    _resetResult = () => {
        let results = [];
        for(let i =0 ; i < this.props.contents.length; i ++){ //results 초기화.
            results[i] = {testResult:null}
        }
        ////console.log("result 초기화 완료. results: ",results);

        this.setState({
            result:results,
        });
    }


}




//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(MemorizeA);

