import React, {Component} from 'react';

import withRecognizerPR from "./withRecognizerPR";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";

class Footer_PR1 extends Component {


    render() {
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        //console.log(`this.props.isPlaying : ${this.props.isPlaying}, this.props.listening : ${this.props.listening}`);

        return (

        <section className="view-footer">
            {this.props.isPlaying && !this.props.isTesting?
                <button type="button" className="f-btn f-center btn-listen" />
                :
                <>

                    {
                        this.props.currentPage > 1 ?
                            this.props.isTesting ?
                                <button type="button" className="f-btn">Empty</button>
                                :
                                <button type="button" className="f-btn btn-prev" onClick={this.props.prev}>이전</button>
                            :
                            <button type="button" className="f-btn">Empty</button>
                    }
                    {
                        this.props.isTesting ?
                            <MicVisualizer/>
                            :
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    if (this.props.listening) {
                                        //console.log("already Listening!");
                                        return null;
                                    }

                                    this._testClick();

                                }}>
                                테스트
                            </button>

                    }

                    {
                        this.props.pass && this.props.recognizerReady ?

                            this.props.maxPage === this.props.currentPage ?
                                <button type="button" className="f-btn btn-next-page" onClick={() => {
                                    this.props.goNextMenu()
                                }}>다음메뉴</button>
                                :
                                <button type="button" className="f-btn btn-next" onClick={() => {
                                    this.props.next()
                                }}>다음</button>
                            :
                            <button type="button" className="f-btn">Empty</button>
                    }
                </>
            }


        </section>

        );
    }

    _testClick = () => {
        ////console.log(this.props.audioPlay);
        this.props.setResult(null); //결과값 초기화 후 테스트 시작
       // this.props.audioPlay("eng", this.props.index);


        let src = `${this.props.contents[this.props.index].step_code}/${this.props.contents[this.props.index].lesson_no}/${this.props.contents[this.props.index].eng_url}`;
        //console.log("B대사 종료");
        this.props.setHowler(
            src,
            () => { //음원 종료 후 음성인식 종료
                setTimeout(() => {
                    this.props.endRecognition()

                }, 1000);
            }
        )
        //
        // this.props.audioPlayWithCb("eng",this.props.index, () => {
        //     //음원 종료 후 음성인식 종료
        //     setTimeout(() => {
        //         this.props.endRecognition()
        //
        //     }, 1000);
        //
        // });

        this.props.startRecognition();
        this.props.handleError(null);
        this.props.closeTip();
        this.props.startTest(); //set isTest True
    }

}

export default withRecognizerPR()(Footer_PR1);
    
    