import React from 'react';
import Loader from 'react-loader-spinner';

const Uploading = (props) => {

    return (
        <div className="UploadingProgressBar">
            <Loader
                type="TailSpin"
                color="#006681"
                height={60}
                width={60}
            />
            <br/>
            Uploading...
        </div>
    )


}

export default Uploading;