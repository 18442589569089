import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    isMobile,
    uploadAnswerAl, trimStrForUpload2, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_CVP_L3 from "../../Footer_CVP_L3";

class ConversationPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            isStart: false,
            result: {},
            wordInfo: {
                num: 1,  //0은 패턴
                getResult: false,
            },
            contents: null,
            err: null,
            errCount: 0,
            pass: false,
            waiting: null,
            count: 0,
            isTesting: false,
            letStartRecognition: false,
            showResult: false,
            showImage:false,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // //console.log(this.props);

        if (nextProps.contents && prevState.contents === null) {

            return {
                contents: nextProps.contents,

            }
        }

        if (nextProps.currentPage - 1 !== prevState.index) { //페이지 변경시
            return {
                index: nextProps.currentPage - 1,
                result: {},
                err: null,
                errCount: 0,
            }
        }
        return null;
    }



    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if (this.state.showResult) {
            return (
                this._renderResult()
            )
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    {
                        this.state.isTesting ?
                            <>

                                <div className="content-wrap">
                                    <div className="top-tip">다음 그림을 잘 보고 테스트를 눌러 질문에 대답하세요.</div>
                                    <figure className="figure l3cp-figure">
                                        <img
                                            src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                            alt="일러스트 이미지"
                                        />
                                    </figure>
                                    <div className="l3-conversation">
                                        <h2 className="question"><span className="q">Q.</span>
                                            {this.props.contents[this.state.wordInfo.num].eng_content}
                                        </h2>
                                    </div>
                                </div>

                                <div className="lecture-step">
                                    <span className="current">{this.state.wordInfo.num+1}</span>/<span className="total">{this.props.contents.length}</span>
                                </div>


                                <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                                        onClick={this.props.initTipToggle}>팁 토글
                                </button>
                                <div className={`tip-container tip-bg-${this.props.step123}`}>
                                    <div className="message-box">
                                        {
                                            this.state.err === null ?
                                                <>
                                                    다음 그림을 잘 보고 테스트를 눌러<br/>
                                                    질문에 대답하세요.
                                                </>
                                                :
                                                this.state.err.map((textOrHtml, index) => <span
                                                    key={index}>{textOrHtml}</span>)
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>

                                <div className="content-wrap">
                                    <figure className="figure l3cp-figure">
                                        <img
                                            src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                            alt="일러스트 이미지"
                                        />
                                    </figure>
                                </div>

                                <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                                        onClick={this.props.initTipToggle}>팁 토글
                                </button>
                                <div className={`tip-container tip-bg-${this.props.step123}`}>
                                    <div className="message-box">
                                        {
                                            this.state.err === null ?
                                                <>
                                                    다음 <strong>그림</strong>을 잘 보고 <strong>테스트</strong>를 눌러<br/>
                                                    <strong>질문에 대답</strong>하세요.
                                                </>
                                                :
                                                this.state.err.map((textOrHtml, index) => <span
                                                    key={index}>{textOrHtml}</span>)
                                        }
                                    </div>
                                </div>
                            </>
                    }

                </section>
                <Footer_CVP_L3
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    letStartRecognition={this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                    pass={this.state.pass}
                />
            </>
        );
    };

    _renderResult = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={isMobile||$(window).width() < 1200 ? "content-wrap justify-start":"content-wrap"}>
                        <div className="l3-conversation">
                            <div className="answer-header">
                                <button type="button" className="btn btn-picture" onClick={() => {this._showImage()}}>그림 보기
                                </button>
                            </div>
                            <div className="answer-wrap">
                                <div className="answer my-answer">
                                    {this._renderMyAnswer()}
                                </div>
                                <div className="answer correct-answer">
                                    {this._renderAnswer()}
                                </div>
                            </div>
                            <div className="common-popup">
                                <div className="mask" onClick={() => {this._showImage()}}/>
                                <div className="popup-wrap">
                                    <div className="pop-header">
                                        <h2 className="pop-title">이미지 보기</h2>
                                        <button
                                            type="button"
                                            className="btn-pop-close"
                                            onClick={() => {this._showImage()}}
                                        >팝업 닫기
                                        </button>
                                    </div>
                                    <div className="pop-body">
                                        <figure>
                                            <img src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`} alt="일러스트 이미지" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            잘 하셨습니다.<br/>
                            <strong>나의 대답</strong>과 <strong>모범 대답</strong>을 비교해 보세요.
                            <hr className="divider"/>
                            <div className="tip">
                                대답에는 여러가지 정답이 있을 수 있습니다. <br/>
                                <strong>나의 대답</strong>은 학습현황에 저장됩니다.
                            </div>
                        </div>
                    </div>
                </section>

                <Footer_CVP_L3
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    letStartRecognition={this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                />
            </>
        )

    };

    // _audioPlay = (lang, idx) => {
    //     //console.log("play");
    //     if (this.props.isPlaying) { //동시 여러번 음원재생 방지
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    // }

    // audioPlay = (index) => {
    //     if(!this.props.contents) {
    //         console.log("not loaded contents yet");
    //         return;
    //     }
    //     let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
    //
    //     this.props.setHowler(
    //         src
    //     );
    // }


    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        this.setState({
            result: {
                ...this.state.result,
                [index]: result
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 갯수만큼 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.props.contents.length) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);
            setTimeout(() => {
                this.setState({
                    waiting: false,
                });
                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                    this._continueRecognition(this.state.wordInfo.num);
                }
            }, 1000);
        }

        //contents.length만큼 결과를 받으면 show Result.
        if (this.state.count >= this.props.contents.length) {
            setTimeout(() => {
                this._showResult();
                this._decidePass(true); //무조건 통과

                let answers = '';
                if(!isIOS) {
                    for (let i = 0; i < this.props.contents.length; i++){
                        answers = answers + this.state.result[i];
                        if(i !== this.props.contents.length - 1){
                            answers = answers + '|'
                        }
                    }
                }

                //uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1);//점수 서버에 업로드
                uploadAnswerAl(this.props.apiUrl, trimStrForUpload2(answers), this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,'T',this.props.serverData.auth_type);

            }, 1000);

            // if(this.state.totalScore / this.props.contents.length < FailScore) {
            //     this._decidePass(false);
            // } else {
            //     this._decidePass(true);
            // }
        }
    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },

        });
        //
        // this.props.audioPlayWithCb("eng",num,() => {
        //     this.setState({
        //         selectedBubble:1
        //     });
        //     this._handleLetStartRecognition(true);
        // })

    };

    _continueRecognition = (nextNum) => {
        this.setState({
            selectedBubble: 0
        });

        this.props.setHowler(
            `${this.props.contents[nextNum].step_code}/${this.props.contents[nextNum].lesson_no}/${this.props.contents[nextNum].eng_url}`,
            () => {
                this.setState({
                    selectedBubble: 1
                });
                this._handleLetStartRecognition(true);
            }
        )

        // this.props.audioPlayWithCb("eng", nextNum, () => {
        //     this.setState({
        //         selectedBubble: 1
        //     });
        //     this._handleLetStartRecognition(true);
        // })
    }


    _startTest = () => {
        //console.log("컨티뉴리코그나이져");
        this.props.closeTip();
        this.setState({
            isTesting: true,
            selectedBubble: 0,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            showResult: false,
            err: null,
            errCount: 0,
            waiting: false,
            count: 0,
        });

        this.props.setHowler(
            `${this.props.contents[0].step_code}/${this.props.contents[0].lesson_no}/${this.props.contents[0].eng_url}`,
            () => {
                this._handleLetStartRecognition(true);
            }
        )
        //
        // this.props.audioPlayWithCb("eng", 0, () => {
        //
        //     this._handleLetStartRecognition(true);
        // })

    };

    _handleLetStartRecognition = (bool) => {
        this.setState({
            letStartRecognition: bool,
        })
    };

    _showResult = () => {
        this.setState({
            showResult: true,
            isTesting: false,
        })
    };

    _renderMyAnswer = () => {
        let answers = [];

        for (let i = 0; i < this.props.contents.length; i++) {
            answers.push(
                <li>
                    <div className="q">{this.props.contents[i].eng_content}</div>
                    <div className="a">{isIOS ? this.props.contents[i].t_answer : this.state.result[i]}</div>
                </li>
            )
        }

        return (
            <ul>
                {answers}
            </ul>
        )
    };

    _renderAnswer = () => {
        let answers = [];

        for (let i = 0; i < this.props.contents.length; i++) {
            answers.push(
                <li>
                    <div className="q">{this.props.contents[i].eng_content}</div>
                    <div className="a">{this.props.contents[i].t_answer}</div>
                </li>
            )
        }

        return (
            <ul>
                {answers}
            </ul>
        )
    }

    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["다시 한번 ", <strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,
                    <div className="tip">점수가 잘 나오지 않는다면 <br/>더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass: isPass,
                err: null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }



        if (this.state.errCount >= 1) {

            // this.setState({
            //     result: '##ERROR',
            //     err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
            //         <div className="tip">테스트 버튼을 눌러 다시 한번 발음해 보세요.</div>],
            //     pass: true,
            //     waiting: false,
            // });

            //에러가 한번 이상 발생하면 그냥 에러 결과를 입력하고, 다음 문제로 넘어감

            this._setResult("잘 인식되지 않았습니다.", this.state.wordInfo.num);

            return;
        }

        this.props.openTip();
        this.setState({
            err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
                <div className="tip">{this.props.contents[this.state.wordInfo.num].eng_content} <br/>{this.props.contents[this.state.wordInfo.num].kor_content}</div>],
            errCount: this.state.errCount + 1,
            waiting: false,
        })
    }

    _showImage = () => {
        $('.common-popup').fadeToggle('fast');
    }
}


export default withContents()(ConversationPractice);
    
    