import React from 'react';


const Footer_RES_L7 = (props) => {
    return (

        <section className="view-footer">

            <button
                type="button"
                className="f-btn btn-next-page"
                onClick={() => {
                    props.goNextMenu()
                }}>
                다음 메뉴
            </button>


        </section>


    )
}


export default Footer_RES_L7;
    
    