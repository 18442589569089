import React, {Component} from "react";
//
// const propTypes = {
//     // Props injected by SpeechRecognition
//     transcript: PropTypes.string,
//     resetTranscript: PropTypes.func,
//     browserSupportsSpeechRecognition: PropTypes.bool
// };

// const TestPage = ({
//                       transcript,
//                       resetTranscript,
//                       browserSupportsSpeechRecognition
//                   }) => {
//     if (!browserSupportsSpeechRecognition) {
//         return null;
//     }
//
//     return (
//         <div>
//             <button onClick={resetTranscript}>Reset</button>
//             <span>{transcript}</span>
//         </div>
//     );
// };
//
// TestPage.propTypes = propTypes;
//
// export default SpeechRecognition(TestPage);

class TestPage extends Component {

    constructor(props){
        super(props)
        this.state = {
            text:null,
            isRecognizeSuccess:false,
            listening: false,
            recognizerReady:false,
        }
    }

    initialize() {
        // alert("start recognition");
        const Recognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!Recognition) {
            alert(
                '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
            );
            return;
        }

        this.recognition = new Recognition();
        this.recognition.lang = 'en-US';
        this.recognition.continuous = false;
        this.recognition.interimResults = false;
        this.recognition.maxAlternatives = 1;

        this.recognition.onresult = event => {
            const text = event.results[0][0].transcript;
            //console.log('transcript', text);
            this.setState({
                text,
                isRecognizeSuccess: true,
            });
            // this.props.setResult(text, this.props.wordInfo.num);
        };

        this.recognition.onspeechend = () => {
            //console.log('stopped');

            this.setState({
                show: true,
            });
        };

        this.recognition.onnomatch = event => {
            //console.log('no match');
            this.setState({ text: "Sorry, can't hear" });
        };

        this.recognition.onstart = () => {
            //console.log('Recognizer start');


            this.setState({
                text:null,
                listening: true,
                recognizerReady:false,
                isRecognizeSuccess: false,
            });
        };

        this.recognition.onend = () => {
            //console.log('Recognizer end');


            if(this.state.text === null && this.state.isRecognizeSuccess === false && this.props.handleError){
                this.props.handleError(["음성인식에 실패하였습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"문제가 계속된다면 ", <strong>마이크를 점검</strong>,"하세요."]);
            }
            this.setState({
                listening: false,
                recognizerReady:true,
            })



            this.endRecognition();


        };

        this.recognition.onerror = event => {
            //console.log('error', event);

            this.setState({
                listening: false,
                text: event.error,
                isRecognizeSuccess: false,
            });
        };
    }

    componentDidMount(){
        this.initialize(); //Initialize
        if(this.state.recognizerReady === false){
            //console.log("recognizer is ready");
            this.setState({
                recognizerReady:true
            })
        }
    }

    componentWillUnmount() {
        this.recognition.stop();
    }

    startRecognition(){
        if(this.state.listening === true){
            //console.log("already listening");
            return;
        }
        this.recognition.stop();
        this.recognition.start();
        //console.log("start recognition");
    }

    endRecognition(){
        this.recognition.stop();
    }


    render(){
        return (
            <div>
                 <button onClick={()=>{this.startRecognition()}}>START</button>
                 <span>{this.state.text}</span>
            </div>
        );
    }
}

export default TestPage;