import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_2MS from '../../Footer_2MS';
import {brTagActivator, getCurrentMenuInfo, isMobile,isIOS} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";


class TwoMinutesStorytelling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            isStart: false,
            isTesting: false,
            hasResult: false,
            result: {},
            isPlaying: false,
            audioURL: null,
            doUpload: false,
            uploadComplete: false,
            letUpload: false,
            errCount: 0,
            err: null,
        };

    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.hasResult && this.state.hasResult) {
            //녹음파일을 받으면 팁창 오픈
            this.props.openTip();
        }
    }

    _getAudioURL = (url) => {
        if (this.state.audioURL === null) {
            this.setState({
                audioURL: url,
            })
        }
    };

    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ", this.state.audioURL);
        //this.props.upload();
        this.setState({
            letUpload: true,
            uploadComplete: true,
        });

    };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };


    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        );

    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={() => {
                                        this._playRecordedFile()
                                    }}>녹음듣기
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={() => {
                                        this._uploadRecordedFile()
                                    }}>녹음제출
                                    </button>
                                </li>
                            </ul>
                            :
                            <figure className="figure small-figure">
                                <img
                                    alt={"cover"}
                                    src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}/>
                            </figure>
                        }
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            오늘 학습한 내용을 2분 동안<br/>
                                            <strong>영어로 발표</strong>합니다.<br/>
                                            TEST버튼을 누른 후 발표를 시작하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>
                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_2MS
                    {...this.props}
                    c_code={this.props.c_code}
                    isPlaying={this.state.isPlaying}
                    playRecordedFile={this._playRecordedFile}
                    stopRecordedFile={this._stopRecordedFile}
                    hasResult={this.state.hasResult}
                    checkResult={this._checkResult}
                    getAudioURL={this._getAudioURL}
                    totalTime={120}
                    letUpload={this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                />
            </>

        );
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult: bool,
        })
    };

    _showSubmitPage = () => {
        //녹음파일 제출할 수 있는 페이지로 이동함.
        this.setState({
            ...this.state,
            isTesting: false,
            hasResult: true,
        });

    };
    _start = () => {
        this.setState({
            isStart: true,
            hasResult: false,
        })
        //this.props.setControlPanel(true);
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }


        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };


}


export default withContents()(TwoMinutesStorytelling);

