import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo, isMobile} from "../../../../asset/js/myFunctions";
import Footer_LNU from "../../Footer_LNU";
import {scroller} from "react-scroll";
import $ from "jquery";

class ListenAndUnderstand extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 89,
            isStart: false,
        }

    }


    static getDerivedStateFromProps(nextProps, prevState){
        //메뉴 변경시(같은메뉴)
        if(nextProps.currentMenu !== prevState.thisMenuNumber){
            return({
                index: 0,
                thisMenuNumber: nextProps.currentMenu,
                isStart: false,
            })
        }

        return null;

    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }


        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap HideScrollBar">

                        <figure className="figure figure-lnu">
                            <img
                                src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                alt={"cover"}
                            />
                        </figure>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            오늘 배울 이야기를 잘 들어주세요.<br />
                            리스닝이 끝나면 다음 메뉴로 넘어갈 수 있습니다.
                            <hr className="divider" />
                            <div className="tip">
                                잘 들리지 않았다면 <strong>여러번 듣고</strong> 넘어가세요.
                            </div>
                        </div>
                    </div>

                </section>
                <Footer_LNU
                    {...this.props}
                    c_code={this.props.c_code}
                    playAll={this._playAll}
                    isAllPlayingComplete={this.state.isAllPlayingComplete}
                />




            </>
        )

    }

    // _playAll = () => {
    //     //console.log("play all");
    //     this.props.audioPlayAll("eng",0);
    //     //this.props.audioPlay("eng", this.state.index)
    // }
    _playAll = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }

        //Recursive
        if(index === 0){
            this.props.closeTip();

            this.setState({
                isAllPlayingComplete: false,
                playingIndex:0,
                isPlaying:true,
            });

            // scroller.scrollTo(`dialogue${index}`,{
            //     duration:800,
            //     delay:0,
            //     smooth:true,
            //     containerId: 'dialogueContainer',
            //     offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            // });

        } else if(index > this.props.contents.length - 1) {
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.setState({
                playingIndex:index
            });
            // scroller.scrollTo(`dialogue${index}`,{
            //     duration:800,
            //     delay:0,
            //     smooth:true,
            //     containerId: 'dialogueContainer',
            //     offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            // });
        }


        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        this.props.setHowler(
            src,
            () => {
                this._playAll(index + 1);
            },
        );

    }


}

export default withContents()(ListenAndUnderstand);