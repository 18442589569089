import React from 'react';


const Footer_WritePhrase = (props) => {
    return (

        <section className="view-footer">
            {
                props.pass ?
                    props.totalPage <= props.currentPage ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                props.goNextMenu()
                            }}>
                            다음 메뉴
                        </button>
                        :
                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={() => {
                                props.next();
                            }}>
                            다음 페이지
                        </button>
                    :
                    <button
                        type="button"
                        className="f-btn"
                    >
                        Empty
                    </button>
            }
        </section>


    )
}


export default Footer_WritePhrase;
    
    