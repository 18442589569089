import React from 'react';


const Footer_WritePhrase = (props) => {
    return (

        <section className="view-footer">
            {
                props.isResultPage ?
                    <>
                        <button
                            type="button"
                            className="f-btn"
                        >
                            EMpty
                        </button>

                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                props.retest()
                            }}>
                            다음 메뉴
                        </button>
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                props.goNextMenu()
                            }}>
                            다음 메뉴
                        </button>
                    </>
                    :
                    props.isFillComplete ?
                        <button
                            type="button"
                            className="f-btn btn-check-correct"
                            onClick={() => {
                                props.goResultPage()
                            }}>
                            결과화면
                        </button>
                        :
                        <button
                            type="button"
                            className="f-btn"
                        >
                            EMpty
                        </button>

            }
        </section>


    )
}


export default Footer_WritePhrase;
    
    