import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {
    brTagActivator,
    calculateScore,
    FailScore,
    getCurrentMenuInfo,
    isMobile,
    isIOS
} from "../../../../asset/js/myFunctions";
import Footer_Test33_A from "../../Footer_Test33_A";
import Footer_Test33_B from "../../Footer_Test33_B";
import Footer_Test33_C from "../../Footer_Test33_C";
import Score from '../../Score';
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";


class Test33 extends Component {

    constructor(props){
        super(props);
        this.state = {
            index: 0,
            isInitialized:false,
            wordsContents:null,
            testContents:null,
            thisMenuNumber: 102,
            isStart: false,
            maxPage: 0,
            currentPage:0,
            phase:0,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            isTesting:false,
            isTesting2:false,
            isPlaying:false,
            letUpload:false,
            // audioURL: null,
            isUploadComplete: false,
            err:null,
            errCount:0,
            pass: false,
            totalScore: 0, //총 점수
            currentPhase:0,  //0부터 contents.length * 2 - 1 까지 늘어남
            isTestingComplete: false, //녹음 종료 신호 보냄
            activatedWords: null,
            count: 0,
            waiting: true,
            hasResult: false,
        }

        this.timeout = null;
    }

    componentDidUpdate(prevProps, prevState){

        if(!prevState.hasResult && this.state.hasResult){
            //녹음파일을 받으면 팁창 오픈
            this.props.openTip();
            //점수 출력시 나오는 애니메이션
            $('.word-list li').each(function (index) {
                let $score = $('.score', $(this)),
                    animationTime = 0.6,    // 애니메이션 전체 시간
                    delay = (index - 1) * 0.3;  // 각 스코어별 delay

                TweenLite.to($score, animationTime, {
                    y: 0,
                    delay: delay,
                    yPercent: 0,
                    opacity: 1
                })
            });
        }

        //c_code 변경 시 초기화
        if(prevProps.c_code !== this.props.c_code) {
            this.setState({
                index: 0,
                isInitialized:false,
                wordsContents:null,
                testContents:null,
                thisMenuNumber: 102,
                isStart: false,
                maxPage: 0,
                currentPage:0,
                phase:0,
                result: {},
                wordInfo: {
                    num: 0,
                    getResult: false,
                },
                isTesting:false,
                isTesting2:false,
                isPlaying:false,
                letUpload:false,
                // audioURL: null,
                isUploadComplete: false,
                err:null,
                errCount:0,
                pass: false,
                totalScore: 0, //총 점수
                currentPhase:0,  //0부터 contents.length * 2 - 1 까지 늘어남
                isTestingComplete: false, //녹음 종료 신호 보냄
                activatedWords: null,
                count: 0,
                waiting: true,
                hasResult: false,
            })
        }
    }



    static getDerivedStateFromProps(nextProps, prevState) {

        if (!prevState.isInitialized && nextProps.contents) {
            let wordsContents = [];
            let testContents = [];
            for (let i = 0; i < nextProps.contents.length; i++) {

                if (nextProps.contents[i].c_code_no === "1") {
                    wordsContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "2") {
                    testContents.push(nextProps.contents[i]);
                }
            }
            return ({
                wordsContents: wordsContents,
                testContents: testContents,
                isInitialized: true,
            })
        }
        return null;
    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        //직독직해
        if(this.state.phase === 1){
            return(
                this._renderDTMain()
            )
        }

        if (this.state.isTesting) {
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest());
        }



        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {
        //console.log("render main is called again");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">


                        {
                            this.state.hasResult ?
                                this._renderWordsWithResult()
                                :
                                this._renderWords()
                        }
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            오늘의 표현들을 뜻과 함께 외워주세요.<br/>다 외우셨다면 테스트 합니다.
                            <hr className="divider"/>
                            <div className="tip">원어민 발음을 듣고 넘어가시면<br/>이어지는 스토리 듣기에 도움이 될거에요.</div>
                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_Test33_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest={this._startTest}
                    hasResult={this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                    //speaking={this.state.speaking}

                />
            </>

        );
    };


    _renderDTMain = () => {

        //console.log("Current isTesting :",this.state.isTesting);
        return (
            <>
                <section className="view-body tip-opened">

                    <div className="content-wrap">

                        <div
                            className={this.state.hasResult ? "story-practice-header opened" : "story-practice-header"}>
                            <button type="button" className="btn btn-meaning" onClick={() => this._showStory()}>스토리 보기
                            </button>
                        </div>


                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={()=>{this._playRecordedFile()}}>녹음듣기</button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={()=>{this._uploadRecordedFile()}}>녹음제출</button>
                                </li>
                            </ul>
                            :
                            !this.state.isTesting2 ?
                                ""
                                :
                                this.state.currentPhase % 2 === 0 ?
                                    <p className="direct-translation type1">
                                        {this.state.testContents[Math.floor(this.state.currentPhase / 2)].eng_content}
                                    </p>
                                    :
                                    <p className="direct-translation type2">한국어로 뜻을 말하세요.</p>

                        }

                    </div>


                    {!this.state.hasResult && this.state.isTesting2 ?
                        <div className="lecture-step">
                            <span className="current">{Math.floor(this.state.currentPhase/2)+1}</span> /
                            <span className="total">{this.state.testContents.length}</span>
                        </div>
                        :
                        ""
                    }
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            직독직해를 시작합니다.<br/>
                                            TEST를 누른 후 출력되는 <strong>영어</strong>를 먼저 읽고<br/>
                                            <strong>한국어</strong>로 뜻을 말하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>

                    {this._renderStory()}


                </section>
                <Footer_Test33_B
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest2}
                    isPlaying = {this.state.isPlaying}
                    playRecordedFile = {this._playRecordedFile}
                    stopRecordedFile = {this._stopRecordedFile}
                    hasResult = {this.state.hasResult}
                    checkResult = {this._checkResult}
                    // getAudioURL={this._getAudioURL}
                    audioUploadComplete = {this._audioUploadComplete}
                    letUpload = {this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    audioStop={this.props.audioStop}
                    isTestingComplete = {this.state.isTestingComplete}
                    currentPhase={this.state.currentPhase}
                    clearTimeout = {this._clearTimeout}
                />

            </>
        )

    };


    _renderTest = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.state.wordsContents[this.state.wordInfo.num].kor_content}</h2>
                        </div>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br/>뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_Test33_C
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    goWordsList={this._goWordsList}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    //speaking={this.state.speaking}
                />
            </>
        )

    };

    _goWordsList = () => {
        this.setState({
            isTesting:false,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            err:null,
            errCount:0,
        })
    }

    _renderWords = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }} className={this.state.activatedWords === i ? "active" : ""}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                </li>)
        }

        return (
            <ul className="word-list word-list-grid-12">
                {wordsList}
            </ul>
        );
    };

    _renderWordsWithResult = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li className={"CursorPointer"} key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                    <div className="score score-blue">
                        {
                            isIOS ?
                                "A"
                                :
                                <Score scoreType={1}
                                       result={this.state.result[i]}
                                       original={this.state.wordsContents[i].eng_content}/>
                        }

                    </div>

                </li>)
        }

        return (
            <ul className="word-list word-list-grid">
                {wordsList}
            </ul>
        );
    };



    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // //console.log("M3w Component's err: "+this.state.err);
        this.setState({
            result: {
                ...this.state.result,
                [index]: result,
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            totalScore: this.state.totalScore + calculateScore(result, this.state.wordsContents[index].eng_content),
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.state.wordsContents.length) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);

            setTimeout(() => {

                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                }
                this.setState({
                    waiting: false,
                });
            }, 1000);
        }

        //결과를 다 받았다면,
        if (this.state.count >= this.state.wordsContents.length) {
            this._showResult();
            this._decidePass(this.state.totalScore / this.state.wordsContents.length >= FailScore || isIOS);
        }

    };

    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["정답을 확인 후 다시 한번", <br/>, <strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };


    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count: 0,
            isTesting: false,
            hasResult: true,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
        });

        //console.log("the recorded results are:", this.state.result);
    };


    _audioUploadComplete = () => {
        this.setState({
            isUploadComplete:true,
        })
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ");
        //this.props.upload();
        this.setState({
            letUpload:true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this._endLesson();
    };


    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult:bool,
        })
    };

   _startTest = () => {
       this.props.closeTip();
       this.setState({
           isTesting:true,
       });

       // if(this.state.phase === 1){
       //     this._controlPhase();
       // }
   };

    _startTest2 = () => {
        this.setState({
            isTesting2:true,
            currentPhase: 0,
        });

        this._controlPhase();

    }


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }


        if (this.state.errCount >= 1) {
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
            waiting:false,
        })
    };


   //글자 갯수에 따라 콘텐츠를 넘김
   _controlPhase = () => {


       let duration = this.state.testContents[Math.floor(this.state.currentPhase/2)].eng_content.length * 120;
       //console.log("duration :",duration);
       if((this.state.currentPhase + 1) >= (this.state.testContents.length*2 - 1) ){ //currentPhase가 증가된 값이 마지막 이라면 duration 후에 녹음 종료 및 초기화
           this.timeout = setTimeout(() => {
               this.setState({
                   currentPhase: 0,
                   isTestingComplete: true, //녹음 종료 신호 보냄
               });
           }, duration)

       } else { //다음 페이즈가 남아 있으면 페이즈 1 증가 후, 다시 controlPhase 호출
           this.timeout = setTimeout(() => {
               this.setState((prevState) => ({
                   currentPhase:prevState.currentPhase + 1,
               }), this._controlPhase());
           }, duration);
       }

   }

   _clearTimeout = () => {
       if(this.timeout != null) {
           clearTimeout(this.timeout);
       }

   }


   //페이즈 넘김
    _handlePhase = (phase) => {
        this.setState({
            phase: phase,
            hasResult: false,
        })
    }

    _ttsPlay = (text) => {
        if (window.speechSynthesis.speaking) { //재생중일땐 Return;
            return;
        }
        let tts = new SpeechSynthesisUtterance(text);
        tts.lang = 'en-US';
        tts.rate = 1;
        tts.pitch = 1;
        window.speechSynthesis.speak(tts);
        this.setState({
            speaking: true,
        });
        tts.onend = () => {
            this.setState({
                speaking: false,
            })
        }

    }

    _onWordsClick = (key) => {
        //console.log(key);
        // if (this.state.speaking) { //음원 재생중 중복 실행 방지
        //     return;
        // }
        if(this.state.activatedWords === key && this.props.isPlaying){
            return;
        }
        //this.props.audioPlay("eng", key);
        // this.props.audioPlayWithURL(this.state.wordsContents[key].eng_url);

        let src = `${this.state.wordsContents[key].step_code}/${this.state.wordsContents[key].lesson_no}/${this.state.wordsContents[key].eng_url}`;
        this.props.setHowler(
            src
        );

       // this._ttsPlay(this.state.wordsContents[key].eng_content);
        this.setState({
            activatedWords: key,
        })
    };

    _showStory = () => {
        // $('.common-popup').fadeToggle('fast');
        $('.speak-poplayer').wPopLayer();
    }


    _renderStory = () => {
        // //console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 1 ; i < this.state.testContents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p className={"l5-comprehension"}>
                        {this.state.testContents[i].kor_content.replace(/[<>]/g,"")}<br />
                        <span>{this.state.testContents[i].eng_content.replace(/[<>]/g,"")}</span>
                    </p>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>해석 보기</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    };



}

export default withContents()(Test33);