import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


class Footer_ListenAndSolve_A extends Component {

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
        //console.log(this.props.currentPage);

        return (


            <section className="view-footer">
                {
                    this.props.currentPage === 1 ?
                        <button type="button" className="f-btn">Empty</button>
                        :
                        <button type="button" className="f-btn btn-prev" onClick={() => {
                            this.props.prev()
                        }}>이전</button>
                }
                {
                    this.props.isPlaying ?
                        <section className="view-footer">
                            <button
                                type="button"
                                className="f-btn f-center btn-listen"
                            >
                                듣는 중
                            </button>
                        </section>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => this.props.startTest()}>
                            테스트
                        </button>
                }

                {
                    !this.props.pass ?
                        <button type="button" className="f-btn">Empty</button>
                        :
                        <button type="button" className="f-btn btn-next" onClick={() => {
                            this.props.handlePhase(1)
                        }}>다음</button>
                }
            </section>

        );
    }

}

export default withRecognizer()(Footer_ListenAndSolve_A);
    
    