import React, {Component} from 'react';
// import withRecognizerForMemorizeB from "./withRecognizerForMemorizeB";
import withRecognizerDialogue from "./withRecognizerDialogue";
import MicVisualizer from "../other/MicVisualizer";
import {scroller} from "react-scroll";
import {isMobile} from "../../asset/js/myFunctions";
import $ from "jquery";


class Footer_RDA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
            recording: false,
            audioURL: null,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevProps.letStartRecognition && this.props.letStartRecognition) {
            this.props.startRecognition();
            if (this.props.serverData.user_kind === "K") {
                // console.log(this.props.contents[this.props.testingIndex].eng_content.length * 80);
                setTimeout(() => {
                    this.props.endRecognition();
                }, this.props.contents[this.props.currentIndex].eng_content.length * 200)
            } else if(this.props.serverData.step_code === "L3"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, 1 + this.props.contents[this.props.currentIndex].eng_content.length * 110)
            }
        }
    }

    render() {
        if(this.props.showContinue){
            return(
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.resetAudioContext();
                            this.props.closeTip();
                        }}>파이널 테스트
                    </button>
                </section>
            )
        }
        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>
                {
                    this.props.isPlaying ?
                        <button
                            type="button"
                            className="f-btn f-center btn-listen"
                        > 듣는중</button>
                        :
                        this.props.err === null ?
                            this.props.listening ?
                                <MicVisualizer/>
                                :
                                this.props.waiting ?
                                    ""
                                    :
                                    <button
                                        type="button"
                                        className="f-btn f-center btn-test"
                                        onClick={() => {
                                            //this.props.goNextMenu()
                                            // this._memorizeAFinalTest();
                                            this.props.memorizeAFinalTest(0)
                                        }}>파이널 테스트 재시작
                                    </button>
                            :
                            this.props.isTesting ?
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        //this.props.goNextMenu()
                                        this.props.startRecognition();
                                        if (this.props.serverData.user_kind === "K") {
                                            // console.log(this.props.contents[this.props.testingIndex].eng_content.length * 80);
                                            setTimeout(() => {
                                                //console.log("timeout");
                                                this.props.endRecognition();
                                            }, this.props.contents[this.props.currentIndex].eng_content.length * 200)
                                        } else if(this.props.serverData.step_code === "L3"){
                                            setTimeout(() => {
                                                this.props.endRecognition();
                                            }, 1 + this.props.contents[this.props.currentIndex].eng_content.length * 110)
                                        }
                                        this.props.handleError(null);
                                        this.props.closeTip();
                                    }}>재시작
                                </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        //this.props.goNextMenu()
                                        // this._memorizeAFinalTest();
                                        this.props.memorizeAFinalTest(0);
                                        this.props.closeTip();
                                    }}>파이널 테스트
                                </button>
                }
                {
                    this.props.isComplete && this.props.pass ?
                        <button type="button" className="f-btn btn-next-page" onClick={() => this.props.goNextMenu()}>
                            다음메뉴
                        </button> : <button type="button" className="f-btn">Empty</button>
                }

            </section>

        );
    }

}

export default withRecognizerDialogue()(Footer_RDA);

    
    