import React, { Component } from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import {isMobile} from "../../asset/js/myFunctions";

const withRecognizerRDT2 = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            isError:false,
            listening: false,
            text:null,
            recognizerReady:false,
            sumText:"",
        };


        initialize() {
            // alert("start recognition");
            const Recognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!Recognition) {
                alert(
                    '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
                );
                return;
            }

            this.recognition = new Recognition();
            this.recognition.lang = process.env.REACT_APP_LANGUAGE || 'en-US';
            this.recognition.continuous = !isMobile;
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = event => {
                let sumText = "";
                for(let i = 0; i < event.results.length; i++){
                    sumText = this.state.sumText + ". " + event.results[i][0].transcript;
                   // //console.log(sumText);
                }

                this.setState({
                    sumText,
                    isRecognizeSuccess: true,
                });
                this.setState({
                    text:null,
                })
            };

            this.recognition.onspeechend = () => {
                //console.log('stopped');

            };

            this.recognition.onnomatch = event => {
                //console.log('no match');
                // this.setState({ text: "Sorry, can't hear" });
                if(this.props.handleError){
                    this.props.handleError(["죄송합니다. 알아 듣지 못했습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"가급적 조용한 환경에서 수업해 주세요."]);
                }
                this.setState({
                    isError:true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                });
            };

            this.recognition.onstart = () => {
                //console.log('Recognizer start');

                if(!isMobile) {
                    let startAudio = new Audio(StartSound);
                    startAudio.play();
                }
                // let startAudio = new Audio(StartSound);
                // startAudio.play();
                this.setState({
                    text:null,
                    sumText:"",
                    listening:true,
                    recognizerReady:false,
                })

                // if(this.state.firstStartedAt === null){
                //     if(!isMobile) {
                //         let startAudio = new Audio(StartSound);
                //         startAudio.play();
                //     }
                //     let time = new Date().getTime();
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //         firstStartedAt:time,
                //         lastStartedAt:time,
                //         sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                //     });
                // } else {
                //     let time = new Date().getTime();
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //         lastStartedAt:time,
                //     });
                //
                // }
            };

            this.recognition.onend = () => {
                this.setState({listening: false}); //어쩄든 종료되면 listening:false,
                //let currentTime = new Date().getTime();
                if(!isMobile){
                    let endSound = new Audio(EndSound);
                    endSound.play();
                }
                // let endSound = new Audio(EndSound);
                // endSound.play();
                if(!this.state.isError) {
                    this._setResult();
                }
                this.setState({
                    sumText:"",
                    isError: false,
                    listening: false,
                    recognizerReady: true,
                })

            };

            this.recognition.onerror = event => {
                //console.log('error', event);
                //alert("마이크 응답이 없습니다. 마이크 연결과 전원을 확인하세요.");
                if(this.state.sumText === ""){
                    if(this.props.handleError){
                        this.props.handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                            <strong>원격 지원</strong>,"을 받아주세요."]);
                    }
                } else {
                    ////console.log("#######onError 결과 입력");
                    this._setResult();
                }

                this.setState({
                    isError:true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                });
            };
        }

        componentWillUnmount() {
            this.recognition.stop();
        }


        componentDidMount(){
            this.initialize(); //Initialize
            if(this.state.recognizerReady === false){
                //console.log("recognizer is ready");
                this.setState({
                    recognizerReady:true
                })
            }
        }


        startRecognition(){
            if(this.state.listening === true){
                //console.log("already listening");
                return;
            }
            console.log("start recognition");
            console.trace();
            this.recognition.start();
           // //console.log("start recognition");
        }

        endRecognition(){
            this.recognition.stop();
        }

        _forceStop = () => {
            //console.log("유저가 녹음 종료");
            this.setState({
                isUserStopped: true
            });
            this.recognition.stop();

        };

        _setResult = () => {
            this.props.setResult(this.state.sumText, this.props.wordInfo.num);
            this.setState({
                sumText:"",
            })

        };

        //
        // _setResultForMobile = () => {
        //     this.setState({listening: false}); //어쩄든 종료되면 listening:false,
        //
        //     if(!this.state.isError) {
        //         this._setResult();
        //     }
        //
        //     this.setState({
        //         sumText:"",
        //         isError: false,
        //         listening: false,
        //         recognizerReady: true,
        //     })
        // }


        fakeRecognitionStart(){
            let startAudio = new Audio(StartSound);
            startAudio.play();

            // console.log("페이크 시작");
            this.setState({
                text:null,
                listening: true,
                recognizerReady:false,
                isRecognizeSuccess: false,
            });
        }

        fakeRecognitionEnd(){
            let endSound = new Audio(EndSound);
            endSound.play();
            // console.log("페이크 끝.");
            this.props.setResult("iOSResult", this.props.wordInfo.num);
            this.setState({
                text:null,
                isRecognizeSuccess: true,
                listening: false,
                recognizerReady:true,
            });
        }



        render(){
            return (
                <WrappedComponent {...this.props}
                                  recognizerReady={this.state.recognizerReady}
                                  text={this.state.text}
                                  listening={this.state.listening}
                                  startRecognition={this.startRecognition.bind(this)}
                                  endRecognition={this.endRecognition.bind(this)}
                                  forceStop={this._forceStop}
                                  fakeRecognitionStart={this.fakeRecognitionStart.bind(this)}
                                  fakeRecognitionEnd={this.fakeRecognitionEnd.bind(this)}
                />
            );
        }
    }

};

export default withRecognizerRDT2;