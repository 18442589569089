import React, {Component} from 'react';
import withContents from "../../../withContents";
import LoadingBar from "../../../../other/LoadingBar";
import $ from "jquery";
import axios from "axios/index";
import {getCurrentMenuInfo, brTagActivator} from "../../../../../asset/js/myFunctions";
import Footer_TPWP from '../../../Footer_TPWP';
import {apiKey} from "../../../../../secret/keys";
import DiffText from "../../../../other/DiffText";
axios.defaults.headers.common['Authorization'] = apiKey;

class WordsPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contentsIdx:0,
            currentPage:1,
            isStart: false,
            err: null,
            errCount: 0,
            pass: false,
            userInput:"",
            isLoaded:false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents && !prevState.isLoaded) { //콘텐츠 로딩 완료
           return({
               isLoaded:true,
           })
        }
        if(prevState.currentPage !== nextProps.currentPage) {//페이지 변경시
            return({
                currentPage:nextProps.currentPage,
                contentsIdx:prevState.contentsIdx + 1,
                userInput:"",
            })
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("update");
        // this.props.myJS();
        if(prevState.contentsIdx !== this.state.contentsIdx){
            //페이지 변경시
            // this._audioPlay("eng",this.state.contentsIdx);
            this.audioPlay(this.state.contentsIdx);
        }

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        });

        // this._audioPlay("eng",0);
        this.audioPlay(0);
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={"view-body"}>
                    <div className="content-wrap typing-game-01">
                        <div className="top-tip">다음 단어를 작성해 보세요.</div>

                        <div className={"typing-sentence"}>
                            <DiffText
                                original={this._trim(this.props.contents[this.state.contentsIdx].eng_content)}
                                userInput={this.state.userInput}
                                audioPlay={this.audioPlay}
                                contentsIdx={this.state.contentsIdx}
                            />
                            <div className={"H30"} />
                            <h3 className={"korean"}>{this.props.contents[this.state.contentsIdx].kor_content}</h3>
                        </div>

                        <div className="input-wrap">
                            <input
                                id="typingInput"
                                onChange={event => this._handleInputChange(event)}
                                onKeyDown={event => this._onKeyDown(event)}
                                onKeyUp={event => this._onKeyUp(event)}
                                value={this.state.userInput}
                                autoComplete={"off"}
                                spellCheck={"off"}
                                autoFocus={true}
                                onPaste={(event) => {event.preventDefault()}}
                                onDrop={(event) => {event.preventDefault()}}
                            />
                            {/*<label className="input-visual" for="typingInput" />*/}

                            {/*<span className={"fake-text input-visual"}>*/}
                               {/*{*/}

                                   {/*this._diffCheck(this._trim(this.props.contents[this.state.contentsIdx].eng_content), this.state.userInput)*/}
                                       {/*.map((textOrHtml, index) =>*/}
                                           {/*<React.Fragment key={index}>*/}
                                               {/*{textOrHtml}*/}
                                           {/*</React.Fragment>*/}
                                       {/*)*/}
                               {/*}*/}
                            {/*</span>*/}
                        </div>

                        <div className={"keyboard"}>
                            {this._renderKeyboard()}
                        </div>


                        {/*<ul className="word-list voice-box">*/}
                            {/*<li>*/}
                                {/*<div className="word">{this.props.contents[this.state.contentsIdx].eng_content}</div>*/}
                                {/*<div className="txt">{this.props.contents[this.state.contentsIdx].kor_content}</div>*/}
                                {/*<span className="btn-wrap">*/}
                                {/*<button className="btn-play-sound CursorPointer"*/}
                                        {/*onClick={*/}
                                            {/*() => this._audioPlay("eng",this.state.contentsIdx)*/}
                                        {/*}>*/}
                                    {/*발음듣기*/}
                                {/*</button>*/}
                            {/*</span>*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                        {/*<ul className={"sentence-practice"}>*/}
                            {/*<li className={"group"}>*/}
                                {/*<div className={"input-group"}>*/}
                                    {/*<input*/}
                                        {/*className="input"*/}
                                        {/**/}
                                    {/*/>*/}

                                {/*</div>*/}
                            {/*</li>*/}

                        {/*</ul>*/}
                        {/*<span className={"fake-text"}>*/}
                               {/*{*/}

                                   {/*this._diffCheck(this._trim(this.props.contents[this.state.contentsIdx].eng_content), this.state.userInput)*/}
                                       {/*.map((textOrHtml, index) =>*/}
                                           {/*<span key={`fake_text_${index}`}>*/}
                                               {/*{textOrHtml}*/}
                                           {/*</span>*/}
                                       {/*)*/}
                               {/*}*/}
                        {/*</span>*/}



                        {/*input의 color 는 transparent 하게 바꾸고, caret-color를 따로 설정한 후, span 은 transform translate로 위로 올림 */}



                    </div>

                </section>
                <Footer_TPWP
                    {...this.props}
                    pass={this.state.pass}
                    checkCorrect={this._checkCorrect}
                />

            </>

        );
    };

    // _diffCheck = (original, userResult) => {
    //     if(userResult == null){
    //         return [];
    //     }
    //
    //     if(original === userResult) {
    //         //맞은 경우 엔터에 pressed 표시함
    //         $(`#key_13`).addClass('pressed');
    //     } else {
    //         $(`#key_13`).removeClass('pressed');
    //     }
    //
    //     let arrOriginal = original.split('');
    //     let arrUserResult = userResult.split('');
    //
    //     let diffResult = [];
    //     for(let i = 0 ; i < arrUserResult.length; i ++){
    //         if(arrOriginal[i] === arrUserResult[i]) {
    //             if(arrUserResult[i] === " ") {
    //
    //                 diffResult[i] = <span className={"white-space"}/>
    //
    //             } else {
    //                 diffResult[i] = <span>{arrUserResult[i]}</span>
    //             }
    //         } else {
    //             if(arrUserResult[i] === " ") {
    //                 diffResult[i] = <span className={"wrong-space"}/>
    //             } else {
    //                 diffResult[i] = <strong>{arrUserResult[i]}</strong>;
    //             }
    //         }
    //     }
    //
    //     return diffResult;
    //
    // }

    _diffCheck = (original, userResult) => {
        if(userResult == null){
            return [];
        }

        if(original === userResult) {
            //맞은 경우 엔터에 pressed 표시함
            $(`#key_13`).addClass('pressed');
        } else {
            $(`#key_13`).removeClass('pressed');
        }

        let arrOriginal = original.split('');
        let arrUserResult = userResult.split('');

        let diffOriginal = [];
        for(let i = 0 ; i < arrOriginal.length; i ++){
            if(arrUserResult[i] == null) {
                diffOriginal[i] = <>{arrOriginal[i]}</>
            } else if(arrOriginal[i] === arrUserResult[i]) {
                // if(arrUserResult[i] === " ") {
                //
                //     diffResult[i] = <span className={"white-space sentence"}/>
                //     //diffResult[i] = `${&nbsp}`;
                //
                // } else {
                //     diffResult[i] = <>{arrUserResult[i]}</>
                // }
                diffOriginal[i] = <span className={"tp01-s-correct"}>{arrOriginal[i]}</span>
            } else {
                // if(arrUserResult[i] === " ") {
                //     diffResult[i] = <span className={"wrong-space sentence"}/>
                //     //diffResult[i] = `&nbsp`;
                // } else {
                //     diffResult[i] = <strong>{arrUserResult[i]}</strong>;
                // }
                diffOriginal[i] = <span className={"tp01-s-wrong"}>{arrOriginal[i]}</span>
            }
        }
        return diffOriginal;
    }


    _checkCorrect = () => {
        if (this.state.userInput == null || this.state.userInput==="") {
            //유저 입력값이 없으면 리턴
            return;
        }

        if(this.state.userInput === this._trim(this.props.contents[this.state.contentsIdx].eng_content)) {
            if(this.state.contentsIdx+1 >= this.props.contents.length){
                //다음 메뉴로 이동
                this.props.goNextMenu();
                return;
            }
            //통과
            this.setState({
                userInput:"",
                contentsIdx:this.state.contentsIdx+1,
            })
        }
    };


    _handleInputChange = (e) => {
        //console.log(e.target.value);
        if(this._trim(this.props.contents[this.state.contentsIdx].eng_content)  === e.target.value.toString()) {
            //맞은 경우 엔터에 pressed 표시함
            $(`#key_13`).addClass('pressed');
        } else {
            $(`#key_13`).removeClass('pressed');
        }

        this.setState({
            userInput: e.target.value.toString(),
        });

    }

    audioPlay = (index) => {
        console.log(index);
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }


    // _audioPlay = (lang, idx) => {
    //     if (this.props.playingIndex === idx && this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     } else {
    //         if (this.props.isPlaying) {
    //             this.props.audioStop();
    //         }
    //         this.props.audioPlay(lang, idx);
    //     }
    // };


    _onKeyDown = (event) => {
        $(`#key_${event.keyCode}`).addClass('pressed');

        if (event.keyCode === 13) { //enter를 누른 경우
            this._checkCorrect();
        }

        if (event.keyCode === 8) {
        }

    };

    _onKeyUp = (event) => {
        $(`#key_${event.keyCode}`).removeClass('pressed');
    };

    _renderKeyboard = () => {
        let buttons = [];

        let keys =  [
            {key:'Q', keyCode:81,rows:1},
            {key:'W', keyCode:87,rows:1},
            {key:'E', keyCode:69,rows:1},
            {key:'R', keyCode:82,rows:1},
            {key:'T', keyCode:84,rows:1},
            {key:'Y', keyCode:89,rows:1},
            {key:'U', keyCode:85,rows:1},
            {key:'I', keyCode:73,rows:1},
            {key:'O', keyCode:79,rows:1},
            {key:'P', keyCode:80,rows:1},
            {key:'A', keyCode:65,rows:2},
            {key:'S', keyCode:83,rows:2},
            {key:'D', keyCode:68,rows:2},
            {key:'F', keyCode:70,rows:2},
            {key:'G', keyCode:71,rows:2},
            {key:'H', keyCode:72,rows:2},
            {key:'J', keyCode:74,rows:2},
            {key:'K', keyCode:75,rows:2},
            {key:'L', keyCode:76,rows:2},
            {key:'Z', keyCode:90,rows:3},
            {key:'X', keyCode:88,rows:3},
            {key:'C', keyCode:67,rows:3},
            {key:'V', keyCode:86,rows:3},
            {key:'B', keyCode:66,rows:3},
            {key:'N', keyCode:78,rows:3},
            {key:'M', keyCode:77,rows:3},
            {key:'Tab',keyCode:9,rows:1},
            {key:'Enter', keyCode:13,rows:1},
            {key:'CapsLock', keyCode:20,rows:2},
            {key:'LShift', keyCode:16,rows:3},
            {key:'RShift', keyCode:16, right:true,rows:3},
            {key:'LCtrl', keyCode:17,rows:4},
            {key:'LAlt', keyCode:18,rows:4},
            {key:'RCtrl', keyCode:17, right:true,rows:4},
            {key:'RAlt', keyCode:18, right:true,rows:4},
            {key:'Space', keyCode:32,rows:4},
            {key:'[', keyCode:219,rows:1},
            {key:']', keyCode:221,rows:1},
            {key:';', keyCode:59,rows:2},
            {key:'"', keyCode:222,rows:2},
            {key:'<', keyCode:188,rows:3},
            {key:'>', keyCode:190,rows:3},
            {key:'/', keyCode:191,rows:3},
        ]

        for(let i = 0; i < keys.length; i ++){
            buttons.push(
                <button
                    type={"button"}
                    className={keys[i].right ? `rows-${keys[i].rows} right`:`rows-${keys[i].rows}`}
                    key={`key_${i}`}
                    data-keycode = {keys[i].keyCode}
                    id={`key_${keys[i].keyCode}`}
                >
                    {keys[i].key}
                </button>
            )

        }

        return(
            <>
                {buttons}
            </>
        )
    }


    _trim = (text) => {
        let trimmed;
        trimmed = text.replace(/[＂”]/gi,'"');
        trimmed = trimmed.replace(/[´’＇]/gi,'\'');
        return trimmed.trim();
    }
}


export default withContents()(WordsPractice);
    
    