import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    trimStrForUpload2, uploadAnswerAl,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_SolveProblems from "../../Footer_SolveProblems";

class SolveProblems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            pass: false,
            hasResult: false,
            isComplete: false,
            userAnswers: {},
            answerResult: {},

        };
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }
        //
        // if(this.state.hasResult){
        //
        //     return(
        //         this._renderResult()
        //     )
        // }

        return (
            this._renderMain()
        )


    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">

                        <div className="top-tip">읽은 스토리를 잘 생각하여, 다음 질문에 답해보세요.</div>

                        <div
                            className={this.state.hasResult ? "story-practice-header opened" : "story-practice-header"}>
                            <button type="button" className="btn btn-story" onClick={() => this._showStory()}>스토리 보기</button>
                        </div>

                        <div className="story-practice">
                            {this._renderProblems()}
                        </div>

                        <div className="common-popup">
                            <div className="mask" onClick={() => {this._showStory();this.props.audioStop()}}/>
                            <div className="popup-wrap popup-wrap-story">
                                <div className="pop-header">
                                    <h2 className="pop-title">스토리</h2>
                                    <button
                                        type="button"
                                        className="btn-pop-close"
                                        onClick={
                                            () => {this._showStory(); this.props.audioStop()}
                                        }>
                                        팝업 닫기
                                    </button>
                                </div>
                                <div className="pop-body l4sp">
                                    <span className="btn-wrap">
                                        <button className="btn-play-sound CursorPointer story-play"
                                                onClick={
                                                    () => this.audioPlay(0)
                                                }>
                                            발음듣기
                                        </button>
                                    </span>
                                    <p className="story-read">
                                        {this.props.contents[0].eng_content}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        <div className="tip">
                                            스토리 버튼을 눌러 다시 한번 확인해 보세요.
                                        </div>
                                    </>
                                    :
                                    <>
                                        앞에서 읽은 스토리에 맞는 정답을 고르세요.
                                    </>

                            }

                        </div>
                    </div>
                </section>
                <Footer_SolveProblems
                    {...this.props}
                    pass={this.state.pass}
                    hasResult={this.state.hasResult}
                    checkCorrect={this._checkCorrect}
                />

            </>

        );
    };

    _checkCorrect = () => {


        let answerResult = {};
        let answers = '';

        for (let i = 1; i < this.props.contents.length; i++) {
            if (this.state.userAnswers[i] === undefined || this.state.userAnswers[i] == null) {
                //console.log("정답을 다 체크 안함");
                return;
            }
            answers = answers+this.state.userAnswers[i].toString();
            if(i !== this.props.contents.length -1){
                answers = answers + '|';
            }

            answerResult[i] = this.state.userAnswers[i].toString() === this.props.contents[i].t_answer.toString();

        }

        //유저 정답 업로드
        uploadAnswerAl(this.props.apiUrl, trimStrForUpload2(answers), this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,'T',this.props.serverData.auth_type);

        this.props.openTip();
        this.setState({
            hasResult: true,
            answerResult: answerResult
        })
    };

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };
    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }




    _renderProblems = () => {
        let problems = [];
        for (let i = 1; i < this.props.contents.length; i++) {
            let options = [];
            if (this.props.contents[i].tpk_content === "O") { //OX타입
                //console.log(this.props.contents[i].t_answer);

                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 1 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 1)}
                            checked={
                                this.state.hasResult ?
                                    this.props.contents[i].t_answer === "1"
                                    :
                                    this.state.userAnswers[i] === 1
                            }/>
                        <span>O</span>
                    </label>
                );
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 2 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 2)}
                            checked={
                                this.state.hasResult ?
                                    this.props.contents[i].t_answer === "2"
                                    :
                                    this.state.userAnswers[i] === 2
                            }/>
                        <span>X</span>
                    </label>
                );

            } else { //3지 선다
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 1 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 1)}
                            checked={
                                this.state.hasResult ?
                                    this.props.contents[i].t_answer === "1"
                                    :
                                    this.state.userAnswers[i] === 1
                            }/>
                        <span>{this.props.contents[i].t_ex1}</span>
                    </label>
                )
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 2 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 2)}
                            checked={
                                this.state.hasResult ?
                                    this.props.contents[i].t_answer === "2"
                                    :
                                    this.state.userAnswers[i] === 2
                            }/>
                        <span>{this.props.contents[i].t_ex2}</span>
                    </label>
                )
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 3 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 3)}
                            checked={
                                this.state.hasResult ?
                                    this.props.contents[i].t_answer === "3"
                                    :
                                    this.state.userAnswers[i] === 3
                            }/>
                        <span>{this.props.contents[i].t_ex3}</span>
                    </label>
                )
            }
            problems.push(
                <li
                    className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                "correct"
                                :
                                "wrong"
                            :
                            ""
                    }>
                    <h2 className="question">{this.props.contents[i].t_question}</h2>
                    <div className={this.props.contents[i].tpk_content === "O" ? "options" : "options long-option"}>
                        {options}
                    </div>
                </li>
            )


        }

        return (
            <ol className="question-list">
                {problems}
            </ol>

        )
    }

    _selectAnswer = (problemNum, optionsNum) => {
        let userAnswers = this.state.userAnswers;
        userAnswers[problemNum] = optionsNum;
        this.setState({
            userAnswers: userAnswers,
        })
    }

    _showStory = () => {
        $('.common-popup').fadeToggle('fast');
    }

}


export default withContents()(SolveProblems);
    
    