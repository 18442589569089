import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_RES_L2 from "../../Footer_RES_L2";

class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            err: null,
            errCount: 0,
            pass: false,
            wrongPopShow:false,
            correctPopShow:false,
            hint:{
                eng:null,
                kor:null,
            },
            selectedIdx:null,
            options:null, ///옵션을 랜덤으로 출력시키기 위한건데. 흠 더 좋은 디자인 있는지 생각해볼것.


        };

        this.mTimeout = null;

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="top-tip">다음 문장에 해당하는 말은 무엇일까요?</div>

                        <div className="l2-rest">
                            <h2 className="question">{this.props.contents[0].t_question}</h2>


                                {/*<li><label className="option"><input type="radio" value="Y" name="l2_rest" /><span /></label>*/}
                                {/*</li>*/}
                                {/*<li><label className="option"><input type="radio" value="N" name="l2_rest" /><span /></label>*/}
                                {/*</li>*/}
                                {/*<li><label className="option"><input type="radio" value="N" name="l2_rest" /><span /></label>*/}
                                {/*</li>*/}
                            {this._renderOptions()}

                        </div>

                        <div className={this.state.correctPopShow ? "alert-correct display" : "alert-correct"}>
                            <div className="mask" onClick={() => {this._handleCorrectPop(false)}}/>
                            <div className="alert-wrap">
                                <h2 className="tit">정답입니다.</h2>
                                <h4 className="tit1">{this.state.hint.eng}</h4>
                                <p className="tit2">{this.state.hint.kor}</p>
                            </div>
                        </div>

                        <div className={this.state.wrongPopShow ? "alert-wrong display" : "alert-wrong"}>
                            <div className="mask" onClick={() => {this._handleWrongPop(false)}}/>
                            <div className="alert-wrap">
                                <h2 className="tit">다시 한번 생각해 보세요</h2>
                                <h4 className="tit1">{this.state.hint.eng}</h4>
                                <p className="tit2">{this.state.hint.kor}</p>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        스피커 버튼을 눌러 <strong>소리를 잘 듣고</strong><br />
                                        한글 문장에 해당하는 소리를 찾아주세요.
                                        <div className="divider"/>
                                        <div className="tip">
                                            정답에 해당하는 스피커 버튼을 선택 후 <br/>
                                            정답확인 버튼을 눌러주세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_RES_L2
                    {...this.props}
                    pass={this.state.pass}
                    handleCorrectPop={this._handleCorrectPop}
                    handleWrongPop = {this._handleWrongPop}
                    checkCorrect={this._checkCorrect}
                    selectedIdx={this.state.selectedIdx}
                />

            </>

        );
    };



    _handleWrongPop = (isShow) => {
        if (isShow) {
            this.setState({
                wrongPopShow: isShow
            });
            $('.alert-wrong').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.wrongPopShow) {
                    $('.alert-wrong').fadeOut('fast');
                    this.setState({
                        wrongPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 4000)
        } else {
            if (this.state.wrongPopShow) {
                $('.alert-wrong').fadeOut('fast');
                this.setState({
                    wrongPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    }

    _handleCorrectPop = (isShow) => {
        if (isShow) {
            this.setState({
                correctPopShow: isShow
            });
            $('.alert-correct').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.correctPopShow) {
                    $('.alert-correct').fadeOut('fast');
                    this.setState({
                        correctPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 4000)
        } else {
            if (this.state.correctPopShow) {
                $('.alert-correct').fadeOut('fast');
                this.setState({
                    correctPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    }

    _checkCorrect = () => {
        if(this.props.contents[this.state.selectedIdx + 1].tpk_content === "1"){
            //console.log("정답");
            this._handleCorrectPop(true);
            this.setState({
                pass:true,
            })
        } else {
            //console.log("오답");
            this._handleWrongPop(true);
        }

    }

    _renderOptions = () => {
        let options =[];
        for(let i = 0 ; i < 3; i ++){
            options.push(
                <li key={i} onClick={() => {
                    // this._audioPlay("eng",i+1);
                    this.audioPlay(i+1);
                    this._selectOption(i);
                }}>
                    <label className="option">
                        <input type="radio" value="Y" name="l2_rest" /><span />
                    </label>
                </li>
            )
        }


        return (
            <ul className="option-wrap">
                {this.state.options === null ? this._shuffle(options) : this.state.options }
            </ul>
        )

    }

    // _audioPlay = (lang, idx) => {
    //     if(this.props.playingIndex === idx){
    //         //this.props.audioStop();
    //         return;
    //     } else {
    //         if(this.props.isPlaying) {
    //             this.props.audioStop();
    //         }
    //         this.props.audioPlay(lang, idx);
    //     }
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }


    _selectOption = (idx) => {
        this.setState({
            selectedIdx: idx,
            hint:{
                kor:this.props.contents[idx+1].kor_content,
                eng:this.props.contents[idx+1].eng_content,
            }
        })
    }

    _shuffle = (array) => {
        if(this.state.options !== null){
            return;
        }

        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        this.setState({
            options:array,
        });
        return array;
    }


}


export default withContents()(TakeBreak);
    
    