import React from 'react';
import endImage from '../../../../asset/images/lecture/pic-complete.png';
import endImage_m from '../../../../asset/images-m/lecture/pic-complete-m.png';
import $ from 'jquery'




const EndLesson = (props) => {

    props.myJS();

    return (
        <section className="view-body" style={{height:window.innerHeight}}>

            <div className="content-wrap">

                <div className="complete">
                    <figure className="complete-figure">
                        <img src={$(window).width() < 900 ? endImage_m : endImage} alt={"complete"}/>
                    </figure>
                    <h2 className="complete-title">축하합니다.</h2>
                    <h5 className="complete-subtitle">모든 수강을 마쳤습니다.</h5>
                    <p className="complete-desc">아래 버튼을 눌러 수강완료를 하세요.</p>
                    <button
                        type="button"
                        className="btn-lecture-end"
                        onClick={() => props.endLesson()}
                    >수강완료</button>
                </div>

            </div>
        </section>
    )

};

export default EndLesson;