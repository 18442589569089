import React, {Component} from 'react';
import withContents from "../../../withContents";
import LoadingBar from "../../../../other/LoadingBar";
import axios from "axios/index";
import {getCurrentMenuInfo, brTagActivator} from "../../../../../asset/js/myFunctions";
import DiffMatchPatch from 'diff-match-patch';
import Footer_TPWG from '../../../Footer_TPWG';
import WGInput from '../../../../other/WGInput';
import {apiKey} from "../../../../../secret/keys";

axios.defaults.headers.common['Authorization'] = apiKey;

class WordsGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            characterRootURL: 'https://study.itcenglish.com/contents/common',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            contentsIdx: 0,
            currentPage: 1,
            isStart: false,
            err: null,
            errCount: 0,
            pass: false,
            userInput: '',
            isLoaded: false,
            score: 0,
            totalTime:60,
            leftTime:60,
            myRank: [],
            topRanks: [],
            checkedList: [],
            popShow:false,
            activeTab:0,
            hasResult:false,
            progress:0,
            topSentenceRanks: [],
            mySentenceRank: [],
            minusPoint:0,
        };

        this.mCounter = null;

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state.minusPoint !== nextState.minusPoint){
            return false;
        }

        return true;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.contents && !prevState.isLoaded) { //콘텐츠 로딩 완료
            return ({
                isLoaded: true,
            })
        }


        if (prevState.currentPage !== nextProps.currentPage) {//페이지 변경시


            return ({
                currentPage: nextProps.currentPage,
                contentsIdx: prevState.contentsIdx + 1,
                userInput: '',
            })
        }

        return null;

    }

    componentWillUnmount(){
       this._stopCounter();

    }
    //
    // componentDidUpdate(prevProps, prevState) {
    //     console.log("update");
    //     this.props.myJS();
    // }




    _prettyDiff = (diffs) => {
        if (diffs == null) {
            return [];
        }

        const DIFF_DELETE = -1;
        const DIFF_INSERT = 1;
        const DIFF_EQUAL = 0;

        let html = [];
        let pattern_amp = /&/g;
        let pattern_lt = /</g;
        let pattern_gt = />/g;
        let pattern_para = /\n/g;
        for (let x = 0; x < diffs.length; x++) {
            let op = diffs[x][0];    // Operation (insert, delete, equal)
            let data = diffs[x][1];  // Text of change.
            let text = data.replace(pattern_amp, '&amp;').replace(pattern_lt, '&lt;')
                .replace(pattern_gt, '&gt;').replace(pattern_para, '&para;<br>');
            switch (op) {
                case DIFF_INSERT:
                    html[x] = <strong>{text}</strong>;
                    break;
                case DIFF_DELETE:
                    //html[x] = '<del style="background:#ffe6e6;">' + text + '</del>';
                    //html[x] = '';
                    break;
                case DIFF_EQUAL:
                    html[x] = text;
                    break;
                default:
                    break;
            }
        }
        return html;
        //return html;
    };

    _diff(original, userResult) {
        ////console.log(userResult);
        if (original == null || userResult == null) {
            return null;
        }
        const dmp = new DiffMatchPatch();
        const diff = dmp.diff_main(original.toLowerCase(), userResult.toLowerCase());

        return diff;
        //return {__html:dmp.diff_prettyHtml(diff)};
    }


    _checkCorrect = (value) => {
        // if (this.state.userInput == null) {
        //     //유저 입력값이 없으면 리턴
        //     return;
        // }

        let isWrong = true;

        for(let i = 0; i < this.props.contents.length; i ++){
            if(this._trim(this.props.contents[i].eng_content) === value) { //맞는게 있다면,
                let checkedList = this.state.checkedList;
                checkedList.push(i);
                isWrong = false;
                this.setState({
                    checkedList,
                    //userInput:'',
                });
                break;
            }
        }

        if(isWrong){ //틀렸다면, 5점 감점
            this.setState({
                minusPoint:this.state.minusPoint + 5,
            })
        }

        if(this.state.checkedList.length === this.props.contents.length){

            this._calculateScore();
            this._stopCounter();
        }



        // let isWrong = true;
        //
        // for(let i = 0; i < this.props.contents.length; i ++){
        //     if(this._trim(this.props.contents[i].eng_content) === this.state.userInput) { //맞는게 있다면,
        //         let checkedList = this.state.checkedList;
        //         checkedList.push(i);
        //         isWrong = false;
        //         this.setState({
        //             checkedList,
        //             userInput:'',
        //         });
        //         break;
        //     }
        // }
        //
        // if(isWrong){ //틀렸다면, 5점 감점
        //     this.setState({
        //         minusPoint:this.state.minusPoint + 5,
        //     })
        // }
        //
        // if(this.state.checkedList.length === this.props.contents.length){
        //
        //     this._calculateScore();
        //     this._stopCounter();
        // }



    };


    _handleInputChange = (e) => {

        if (e.target.value === " " && this.state.userInput === '') { //첫 띄어쓰기 막음
            return;
        }

        //첫 시작일 때 카운터 시작
        if(this.mCounter == null && this.state.userInput === '' && this.state.checkedList.length === 0 ) {
            this._startCounter();
            this.props.closeTip();
        }

        this.setState({
            userInput: e.target.value.toString(),
        });
    };

    //
    // _audioPlay = (lang, idx) => {
    //     if (this.props.playingIndex === idx) {
    //         //this.props.audioStop();
    //         return;
    //     } else {
    //         if (this.props.isPlaying) {
    //             this.props.audioStop();
    //         }
    //         this.props.audioPlay(lang, idx);
    //     }
    // };


    _keyListener = (event) => {
        if (event.keyCode === 13) { //enter를 누른 경우

            this._checkCorrect();
        }

        if (event.keyCode === 8) {
            //지우기 사용하면 감점 1점
            this.setState({
                minusPoint: this.state.minusPoint+1,
            })

        }

    };

    _getUserRank = () => {

        //무료학습과 관리자 모드일 시,
        if(this.props.serverData.auth_type === "A" || this.props.serverData.auth_type === "F") {
            return;
        }

        Promise.all([axios({
                method: 'get',
                url: `${this.props.apiUrl}/class/typing/rank/my/${this.props.serverData.mem_idx}/${this.props.lessonInfo.lesson_idx}/${this.props.currentMenu}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //request success
            this.setState({
                myRank: res.data,
            })
        }).catch(err => {
            //에러 발견

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

    _uploadUserScore = (score) => {

        //무료학습과 관리자 모드일 시, 점수 업로드 안함.
        if(this.props.serverData.auth_type === "A" || this.props.serverData.auth_type === "F") {
            return;
        }


        Promise.all([axios({
                method: 'put',
                url: `${this.props.apiUrl}/class/lesson/typing/score/g/${this.props.attend_idx}/${this.props.currentMenu}/${this.props.lessonInfo.lesson_idx}/${score}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res3]) => {
            //request success
            this.setState({
                uploadSuccess: true,
            })
        }).catch(err => {
            //에러 발견
            //console.log(err);

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

    _getTopRanks = () => {
        Promise.all([axios({
                method: 'get',
                url: `${this.props.apiUrl}/class/typing/rank/top/${this.props.currentMenu}/${this.props.lessonInfo.lesson_idx}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //request success
            this.setState({
                topRanks: res.data,
            })
        }).catch(err => {
            //에러 발견

        }).then(() => {
            //에러 발견시, 콜백
        });
    }


    _startCounter = () => {

        //첫 시작일 때 카운터 시작
        if(this.mCounter != null){
            return;
        }
        if(this.state.checkedList.length !== 0){
            return;
        }

        this.props.closeTip();

        this.mCounter = setInterval(() => {
                if(this.state.leftTime === 1){
                    //60초가 다 지났다면, 카운터 종료하고 점수 계산함
                    this.setState({
                        leftTime: 0,
                        progress: 100,
                    });
                    this._stopCounter();
                    this._calculateScore();
                } else {
                    //console.log(this.state.leftTime);
                    this.setState({
                        leftTime: this.state.leftTime - 1,
                        progress: 100-Math.ceil(((this.state.leftTime - 1) / this.state.totalTime) * 100)
                    });
                }
            }, 1000
        );
    };

    _stopCounter = () => {
        if(this.mCounter === null){
            return;
        }

        clearInterval(this.mCounter);
        this.mCounter = null;
    }

    _calculateScore = () => {
        let score;

        if(this.state.leftTime === 60) {
            //정상적인 입력이 아님
            score = this.state.checkedList.length * 30 * 49;
            this._uploadUserScore(score);

            this.setState({
                score,
                hasResult:true
            });

            return;
        }

        if(this.state.leftTime < 1){
            score = this.state.checkedList.length * 49;
        } else {
            score = this.state.checkedList.length * this.state.leftTime * 49;
        }

        score -= this.state.minusPoint;

        if(score < 0) {
            score = 0;
        }

        this._uploadUserScore(score);

        this.setState({
            score,
            hasResult:true
        })

    }

    _renderWords = () => {
        let words = [];

        for (let i = 0; i < this.props.contents.length; i++) {
            words.push(
                <li className={this.state.checkedList.includes(i) ? "checked" : ""}>
                    <span className={"word"}>
                        {this.props.contents[i].eng_content}
                    </span>
                </li>
            )
        }
        return (
            words
        )
    }

    _toggleRankPopup = () => {
        if(!this.state.popShow) { //열때, 랭킹 갱신함.
            this._getTopRanks();
            this._getUserRank();

        }
        this.setState({
            popShow:!this.state.popShow

        })

    }

    _renderRankers = () => {
        let rankers = [];

        if(this.state.activeTab === 0 ){
            for(let i = 0 ;i < this.state.topRanks.length; i++) {
                rankers.push(
                    <li className={"ranking-list"}>
                    <span className={this.state.topRanks[i].rank < 4 ? "rank top-rank":"rank"}>
                        {this.state.topRanks[i].rank}
                    </span>
                        <img className={"thumb"} src={this.state.topRanks[i].skin_alphabet == null ? `${this.state.characterRootURL}/character/character_a.png` : `${this.state.characterRootURL}/character/character_${this.state.topRanks[i].skin_alphabet.replace(/\s/gi,"")}.png`} alt = "프로필사진" />
                        <span className={"name"}>
                        {this.state.topRanks[i].Mem_Name}
                    </span>
                        <span className={"t-score"}>
                        <strong>{this.state.topRanks[i].score}</strong>점
                    </span>

                    </li>
                )
            }
        } else {
            for(let i = 0 ;i < this.state.topSentenceRanks.length; i++) {
                rankers.push(
                    <li className={"ranking-list"}>
                    <span className={this.state.topSentenceRanks[i].rank < 4 ? "rank top-rank":"rank"}>
                        {this.state.topSentenceRanks[i].rank}
                    </span>
                        <img className={"thumb"} src={this.state.topSentenceRanks[i].skin_alphabet == null ? `${this.state.characterRootURL}/character/character_a.png` : `${this.state.characterRootURL}/character/character_${this.state.topSentenceRanks[i].skin_alphabet.replace(/\s/gi,"")}.png`} alt = "프로필사진" />
                        <span className={"name"}>
                        {this.state.topSentenceRanks[i].Mem_Name}
                    </span>
                        <span className={"t-score"}>
                        <strong>{this.state.topSentenceRanks[i].score}</strong>점
                    </span>

                    </li>
                )
            }
        }




        return(
            rankers
        )

    }

    _retry = () => {
        this.setState({
            hasResult:false,
            userInput:'',
            leftTime:60,
            checkedList:[],
            progress:0,
            myRank: [],
            topRanks: [],
            minusPoint:0,
        })
    }

    _toggleTab = (idx) => {

        //무료학습과 관리자 모드일 시,
        if(this.props.serverData.auth_type === "A" || this.props.serverData.auth_type === "F") {

            this.setState({
                activeTab:idx,
            })

            return;
        }

        if(idx === 1 && this.state.topSentenceRanks.length === 0){
            //탑 랭커 점수 로딩
            Promise.all([
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/class/typing/rank/top/4/${this.props.lessonInfo.lesson_idx}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }),
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/class/typing/rank/my/${this.props.serverData.mem_idx}/${this.props.lessonInfo.lesson_idx}/4`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }),

            ]).then(([res1, res2]) => {
                //request success
                this.setState({
                    topSentenceRanks: res1.data,
                    mySentenceRank: res2.data,
                })
            }).catch(err => {
                //에러 발견

            }).then(() => {
                //에러 발견시, 콜백
            });
        }

        this.setState({
            activeTab:idx,
        })

    }

    _trim = (text) => {
        let trimmed;
        trimmed = text.replace(/[＂”]/gi,'"');
        trimmed = trimmed.replace(/[´’＇]/gi,'\'');
        //trimmed = trimmed.replace(/\s+$/, '');



        return trimmed.trim();

    }

    _minusPoint = (point) => {
        this.setState({
            minusPoint: this.state.minusPoint+1,
        })
    }


    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={this.state.hasResult ? "content-wrap typing-game-02" : "content-wrap typing-game-02 align-start"}>
                        {
                            this.state.hasResult ?
                                <div className={"score-wrap"}>
                                    <strong className="t-score">{this.state.score}</strong>
                                    <span className="text">YOUR SCORE</span>
                                    <button
                                        type="button"
                                        className="btn-ranking"
                                        onClick={() => {
                                            this._toggleRankPopup()
                                        }}>랭킹확인
                                    </button>
                                </div>
                                :
                                <div>
                                    <ul className="game-word-list">
                                        {this._renderWords()}
                                    </ul>
                                    <WGInput
                                        minusPoint={this._minusPoint}
                                        startCounter={this._startCounter}
                                        checkCorrect={this._checkCorrect}
                                    />

                                    {/*<input className="typing-input"*/}
                                           {/*onChange={this._handleInputChange}*/}
                                           {/*onKeyDown={this._keyListener}*/}
                                           {/*value={this.state.userInput}*/}
                                           {/*autoComplete={"off"}*/}
                                           {/*spellCheck={"off"}*/}
                                           {/*autoFocus={true}*/}
                                           {/*onPaste={(event) => {event.preventDefault()}}*/}
                                           {/*/>*/}
                                </div>

                        }

                        {
                            this.state.popShow ?
                                <div className="ranking-popup">
                                    <div className="ranking-popup-wrap">
                                        <div className="ranking-header">
                                            <h4 className="title">랭킹보기</h4>
                                            <button type="button" className="btn-close"
                                                    onClick={() => this._toggleRankPopup()}>랭킹보기 닫기
                                            </button>
                                        </div>
                                        <div className="ranking-body">

                                            <ul className="ranking-tab">
                                                <li className={this.state.activeTab === 0 ? "active":""}>
                                                    <a onClick={() => {this._toggleTab(0)}}>단어게임</a>
                                                </li>
                                                <li className={this.state.activeTab === 1 ? "active":""}>
                                                    <a onClick={() => {this._toggleTab(1)}}>문장게임</a>
                                                </li>
                                            </ul>
                                            <div className="ranking-score">
                                                {
                                                    this.props.userInfo.skin_alphabet == null ?
                                                        <img className={"thumb"} src={`${this.state.characterRootURL}/character/character_a.png`} alt="프로필캐릭터"/>
                                                        :
                                                        <img className={"thumb"} src={`${this.state.characterRootURL}/character/character_${this.props.userInfo.skin_alphabet.replace(/\s/gi,"")}.png`} alt="프로필캐릭터"/>
                                                }
                                                <div className="user-info">
                                                    <span className="name">{this.props.serverData.mem_name}</span>
                                                    <p className="t-score">
                                                        <strong>
                                                            {
                                                                this.state.activeTab ===  0 ?
                                                                    this.state.score :
                                                                    this.state.mySentenceRank.length !== 0 ?
                                                                        this.state.mySentenceRank[0].score
                                                                        : ""
                                                            }
                                                        </strong>점
                                                        <strong className="color">(
                                                            {
                                                                this.state.activeTab === 0 ?

                                                                    this.state.myRank.length !== 0 ? this.state.myRank[0].rank : ""
                                                                    :
                                                                    this.state.mySentenceRank.length !== 0 ? this.state.mySentenceRank[0].rank : ""
                                                            }
                                                            등)</strong>
                                                    </p>
                                                    {/*<p className="t-score">*/}
                                                        {/*<strong>{this.state.score}</strong>점*/}
                                                        {/*<strong className="color">>({this.state.myRank.length !== 0 ? this.state.myRank[0].rank : ""}등)</strong>*/}
                                                    {/*</p>*/}
                                                </div>
                                            </div>

                                            <ul className="ranking-list">
                                                {this._renderRankers()}
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                :
                                ""
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        잘 하셨어요.<br/>
                                        다른 친구들과의 점수를 비교해 보세요!<br />
                                        더 높은 점수를 원하시면 다시 테스트 하세요.
                                    </>
                                    :
                                    <>
                                        <strong>위 단어를 모두</strong> 입력하세요.<br/>
                                        <div className={"divider"} />
                                        <div className={"tip"}>
                                            빠르게 입력 할수록 더 높은 점수를 받을 수 있어요.
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </section>
                <Footer_TPWG
                    {...this.props}
                    checkCorrect={this._checkCorrect}
                    leftTime={this.state.leftTime}
                    hasResult={this.state.hasResult}
                    progress={this.state.progress}
                    retry={this._retry}
                />

            </>

        );
    };


}


export default withContents()(WordsGame);
    
    