import React from 'react';


const Footer_TPWG = (props) => {
    return (

        <section className="view-footer">

            <div className="typing-game-02-footer">
                {
                    props.hasResult ?
                        <div className="footer-progress">
                            <button
                                type="button"
                                className="f-btn"
                                >
                                empty
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    props.retry()
                                }}>
                                다시 테스트
                            </button>
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {
                                    props.goNextMenu()
                                }}>
                                다음 메뉴
                            </button>
                        </div>
                        :
                        <div className="footer-progress">
                            <i className="icon-progress-clock"/>
                            <div className="typing02-progress">
                                <div
                                    className="progress-inner"
                                    style={{width: `${props.progress}%`}}/>
                            </div>
                            <span className="remain-time">남은 시간 : {props.leftTime}초</span>
                        </div>
                }


            </div>
            {/*{*/}
                {/*props.isPlaying ?*/}
                    {/*<button*/}
                        {/*type="button"*/}
                        {/*className="f-btn f-center btn-listen"*/}
                    {/*>*/}
                        {/*재생중*/}
                    {/*</button>*/}
                    {/*:*/}
                    {/*props.pass ?*/}

                        {/*<button*/}
                            {/*type="button"*/}
                            {/*className="f-btn btn-next-page"*/}
                            {/*onClick={() => {*/}
                                {/*props.goNextMenu()*/}
                            {/*}}>*/}
                            {/*다음 메뉴*/}
                        {/*</button>*/}
                        {/*:*/}
                        {/*<button*/}
                            {/*type="button"*/}
                            {/*className="f-btn f-center btn-check-correct"*/}
                            {/*onClick={() => {*/}
                                {/*props.checkCorrect();*/}
                            {/*}}*/}
                        {/*>*/}
                            {/*정답확인*/}
                        {/*</button>*/}
            {/*}*/}
        </section>


    )
}

// class IOPanel_6 extends Component {
//
//     constructor(props) {
//         super(props);
//     }
//
//     _test3Words = () => {
//         this.props.startRecognition();
//
//     }
//
//     render() {
//         //console.log(this.props.currentPage + " and "+ this.props.maxPage);
//         //console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
//
//         return (
//
//         <section className="view-footer">
//             <button type="button" className="f-btn btn-prev-page" onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu - 1)}}>이전</button>
//             {this.props.hasResult ?
//                 <>
//                 <button
//                     type="button"
//                     className="f-btn f-center btn-retest"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 <button
//                     type="button"
//                     className="f-btn btn-next-page"
//                     onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu+1)}}>
//                     다음메뉴</button>
//                 </>
//                 :
//                 (<>
//                     <button
//                     type="button"
//                     className="f-btn f-center btn-test"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 </>)
//             }
//         </section>
//
//         );
//     }
//
// }

export default Footer_TPWG;
    
    