import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_TKE1 from '../../Footer_TKE1';
import {brTagActivator, calculateScore, FailScore, getCurrentMenuInfo, isIOS} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import Score from '../../Score';
import HtmlParser from "react-html-parser";


class TranslationKoreanEnglish1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            index: 0,
            maxPage: 0,
            thisMenuNumber: 74,
            result:null,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            pass:false,
            err:null,
            errCount:0,
            tryCount:0,
        };
    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null && prevState.maxPage === 0){

            nextProps.setMaxPage(nextProps.contents.length);

            return {
                index: nextProps.currentPage -1,
                maxPage : nextProps.contents.length,
            }
        }

        if(nextProps.currentPage - 1 !== prevState.index){
            //페이지 변경 시
            nextProps.closeTip();
            return {
                index : nextProps.currentPage - 1,
                result:null, //결과도 초기화
                err:null,
                pass:false,
                errCount:0,
                tryCount:0,
            }
        }
        return null;
    }

    render() {
        if(this.props.contents === null || this.props.menuInfo === null){
            //if(true){
            // return(<div>Loading contents...</div>);
            return (<LoadingBar type={0}/>);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        );
    }
    _renderMain = () => {
        //console.log("현재 result:",this.state.result);
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h4>{HtmlParser(this.props.contents[this.state.index].kor_content)}</h4>
                        </div>

                        {this.state.result === null ?
                            ""
                            :
                            <div className="answer-result-box tke1">
                                {
                                    isIOS ?
                                        <div className={"answer-result"}>
                                            {this.props.contents[this.state.index].eng_content}
                                        </div>
                                        :
                                        <div className={"answer-score"}>
                                            <Score scoreType={1}
                                                   result={this.state.result}
                                                   original={this.props.contents[this.state.index].eng_content}/>

                                        </div>

                                }
                            </div>
                        }
                        {/*<p className="Err">*/}
                            {/*{this.state.err}*/}
                        {/*</p>*/}

                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.index + 1}</span>/<span
                        className="total">{this.state.maxPage}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        다음 한글 문장을 영어로 전환하세요.<br />
                                        <hr className="divider" />
                                        <div className="tip">회화에서 배운 문장을 잘 생각해보세요.<br /></div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_TKE1
                    {...this.props}
                    c_code={this.props.c_code}
                    setResult={this._setResult}
                    wordInfo={this.state.wordInfo}
                    result={this.state.result}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    index={this.state.index}
                />
            </>
        )
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu))}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({isStart: true})
    };


    _setResult = (result) => {
        this.setState({
            result: result,
            err:null,
        })

        let pass = calculateScore(result,this.props.contents[this.state.index].eng_content) > FailScore || isIOS;
        this._decidePass(pass);

    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
            });
            return;
        }

        if(!isPass) { // 점수가 낮은경우 fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["정답을 다시 한번 생각해 보신 후", <br />,<strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
            })
        }
    };



    _renderKoreans = (index) => {
        return (
            <h3>{this.props.contents[index].kor_content}</h3>
        );
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }

        this.props.openTip();

        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
            });

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    }


}




//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(TranslationKoreanEnglish1);

