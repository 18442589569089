import React, {Component} from 'react';
import PracticePronunciation from "./menu/common/PracticePronunciation";
import Memorize3Words from "./menu/common/Memorize3Words";
import Memorize6Words from "./menu/common/Memorize6Words";
import Memorize12Words from "./menu/common/Memorize12Words";
import WordsFinalTest from "./menu/common/WordsFinalTest";
import NewWordsTest from "./menu/common/NewWordsTest";
import NewExpressionTest from "./menu/common/NewExpressionTest";
import ListenToTheDialogue from "./menu/l1/ListenToTheDialogue";
import MemorizeB from "./menu/l1/MemorizeB";
import RoleplayB from "./menu/l1/RoleplayB";
import MemorizeA from "./menu/l1/MemorizeA";
import RoleplayA from "./menu/l1/RoleplayA";
import TranslationKoreanEnglish1 from "./menu/l1/TranslationKoreanEnglish1";
import TranslationKoreanEnglish2 from "./menu/l1/TranslationKoreanEnglish2";
import PatternPracticeJr from "./menu/l1/PatternPracticeJr";
import PracticeForReading1 from './menu/l4/PracticeForReading1';
import PracticeForReading2 from './menu/l4/PracticeForReading2';
import ReadingTest from './menu/l4/ReadingTest';
import OneMinuteSpeech from './menu/l4/OneMinuteSpeech';
import ReadAndUnderstand from './menu/l6/ReadAndUnderstand';
import TranslationPractice from './menu/l6/TranslationPractice';
// import TranslationTest1 from './menu/l5/TranslationTest1';
import TranslationTest from './menu/l6/TranslationTest';
import TranslationListening from './menu/l6/TranslationListening';
import AppliedStorytelling from './menu/l6/AppliedStorytelling';
import CreativeStorytelling from './menu/l6/CreativeStorytelling';
import ListenAndUnderstand from './menu/l7/ListenAndUnderstand';
import SpeakingPractice1 from './menu/l7/SpeakingPractice1';
import SpeakingPractice2 from './menu/l7/SpeakingPractice2';
import SpeakingTest from './menu/l7/SpeakingTest';
import ReadingTestInL6 from './menu/l7/ReadingTestInL6';
import Reading from './menu/l8/Reading';
import DirectTranslation from './menu/l8/DirectTranslation';
import Comprehension from './menu/l8/Comprehension';
import PracticeForStorytelling from './menu/l8/PracticeForStorytelling';
import TwoMinutesStorytelling from './menu/l8/TwoMinutesStorytelling';
import Reading_L8 from './menu/l9/Reading';
import DirectTranslation_L8 from './menu/l9/DirectTranslation';
import Comprehension_L8 from './menu/l9/Comprehension';
import PracticeForStorytelling_L8 from './menu/l9/PracticeForStorytelling';
import TwoMinutesStorytelling_L8 from './menu/l9/TwoMinutesStorytelling';
import MemorizeExpressions from './menu/pt/MemorizeExpressions';
import PreparationForPresentation from './menu/pt/PreparationForPresentation';
import CIPresentation from './menu/pt/CIPresentation';
import SIPresentation from './menu/pt/SIPresentation';
import NativePresentation from './menu/pt/NativePresentation';



//추가학습 부분 Import
import ReviewWords from './menu/common/ReviewWords';
import ReviewExpressions from './menu/common/ReviewExpressions';
import PatternPracticeL1 from './menu/l1/PatternPractice';
import PatternPracticeL3 from './menu/l3/PatternPractice';
import MakeQuestions from './menu/l1/MakeQuestions';
import ConversationPracticeL1 from './menu/l1/ConversationPractice';
import ConversationPracticeL2 from './menu/l2/ConversationPractice';
import ConversationPracticeL3 from './menu/l3/ConversationPractice';
import TakeBreakL2 from './menu/l2/TakeBreak';
import TakeBreakL3 from './menu/l3/TakeBreak';
import TakeBreakL4 from './menu/l4/TakeBreak';
import TakeBreakL5 from './menu/l6/TakeBreak';
import TakeBreakL6 from './menu/l7/TakeBreak'
import TakeBreakL7 from './menu/l8/TakeBreak'
import Blinking from './menu/l4/Blinking';
import Spelling from './menu/l4/Spelling';
import ReadingStoryL4 from './menu/l4/ReadingStory';
import ReadingStoryL5 from './menu/l5/ReadingStory';
import SolveProblemsL4 from './menu/l4/SolveProblems';
import WritePhrase from './menu/l6/WritePhrase';
import KoreanEnglish from './menu/l6/KoreanEnglish';
import Accumulate from './menu/l6/Accumulate';
import WriteSentence from './menu/l7/WriteSentence'
import ListenAndSolve from './menu/l7/ListenAndSolve'
import ListenAndSpeak from './menu/l7/ListenAndSpeak'
import Theory from './menu/l8/Theory'
import Practice from './menu/l8/Practice'
import Test from './menu/l8/Test'
import Test33 from './menu/l8/Test33';
import Speech from './menu/l8/Speech';




//타이핑 부분 import
import T_WordsPractice from './menu/common/typing/WordsPractice';
import T_WordsGame from './menu/common/typing/WordsGame';
import T_SentencePractice from './menu/common/typing/SentencePractice';
import T_SentenceGame from './menu/common/typing/SentenceGame';



import EndLesson from './menu/common/EndLesson';
import {getCurrentMenuInfo} from "../../asset/js/myFunctions";
import MemorizeSpelling from "./menu/l4/MemorizeSpelling";
import ReviewSpelling from "./menu/common/ReviewSpelling";


class MainPanel extends Component {
    constructor(props){
        super(props);
        this._renderMenu.bind(this);
        this.props.myJS();
    }

    render() {

        if(this.props.menuInfo === null){
            return(<div>Loading...</div>);
        }

        if(this.props.lessonType === 'typing') {
            return(
                this._renderTypingMenu(this.props.currentMenu)
            )
        }

        return (
            this._renderMenu(this.props.currentMenu)
        )
    }

    componentDidMount() {
        if(this.props.currentMenu < this.props.initMenuNo) {
            this.props.handleCurrentMenu(this.props.initMenuNo);
        }
    }

    _renderMenu = (menuNumber) => {
        ////console.log("render ",menuNumber);
        const props = this.props;
        let c_code = null;
        let group_code = null;
        //let c_code_no = null;
        ////console.log("render menu:",menuNumber);

        let currentMenuInfo = getCurrentMenuInfo(this.props.menuInfo, menuNumber);
        if(menuNumber === 999) {
            c_code = null;
            return (
                <EndLesson
                    {...props}
                />
            )
        }else if(currentMenuInfo === null){
            //console.warn(this.props.currentMenu +" is not valid menu idx.");
            menuNumber = 998;
        } else {
            ////console.log("currentMenuInfo:",currentMenuInfo);
            c_code = currentMenuInfo.c_code;
            //c_code_no = currentMenuInfo.c_code_no;
            group_code = currentMenuInfo.group_code;

        }





        if(props === null){ return ("")}

        if(menuNumber < 10) { //공통 메뉴인 경우는 STEP_CODE를 체크 하지 않고 렌더링 함.
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 1:
                    return (<PracticePronunciation
                        {...props}
                        c_code={c_code}
                    />);
                case 2:
                    return (<Memorize3Words
                        {...props}
                        c_code={c_code}
                    />);
                case 3:
                    return (<Memorize6Words
                        {...props}
                        c_code={c_code}
                    />);
                case 4:
                    return (<Memorize12Words
                        {...props}
                        c_code={c_code}
                    />);
                case 5:
                    return (<WordsFinalTest
                        {...props}
                        c_code={c_code}
                    />);
                case 6:
                    return (<NewWordsTest
                        {...props}
                        c_code={c_code}
                    />);
                case 7:
                    return (<NewExpressionTest
                        {...props}
                        c_code={c_code}
                    />);
                case 8:
                    return (<MemorizeSpelling
                        {...props}
                        c_code={c_code}
                    />);
                // case 8:
                //     return (<MemorizeSpelling
                //         {...props}
                //         c_code={c_code}
                //     />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }
        }


        if(window.serverData.step_code === 'L1' ||window.serverData.step_code === 'L2'||window.serverData.step_code === 'L3') { //1단계 초급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 11:
                    return (<ListenToTheDialogue
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return (<MemorizeB
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return (<RoleplayB
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return (<MemorizeA
                        {...props}
                        c_code={c_code}
                    />);
                case 15:
                    return (<RoleplayA
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return (<TranslationKoreanEnglish1
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return (<TranslationKoreanEnglish2
                        {...props}
                        c_code={c_code}
                    />);

                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewExpressions
                        {...props}
                        c_code={c_code}
                    />);

                case 41:
                    if(window.serverData.step_code === "L1" || window.serverData.step_code === "L2"){
                        return (<PatternPracticeL1
                            {...props}
                            c_code={c_code}
                        />);
                    } else if (window.serverData.step_code === "L3"){
                        return (<PatternPracticeL3
                            {...props}
                            c_code={c_code}
                        />);
                    } else {
                        break;
                    }

                // case 42:
                //     return(
                //         <MakeQuestions
                //             {...props}
                //             c_code={c_code}
                //         />
                //     );
                case 43:
                    if(window.serverData.step_code === "L2") {
                        return (
                            <TakeBreakL2
                                {...props}
                                c_code={c_code}
                            />
                        )
                    } else {
                        return (
                            <TakeBreakL3
                                {...props}
                                c_code={c_code}
                            />
                        )
                    }
                case 44:
                    if(window.serverData.step_code === "L1"){
                        return(
                            <ConversationPracticeL1
                                {...props}
                                c_code={c_code}
                            />
                        );

                    } else if (window.serverData.step_code === "L2"){
                        return(
                            <ConversationPracticeL2
                                {...props}
                                c_code={c_code}
                            />
                        );

                    } else if (window.serverData.step_code === "L3"){
                        return(
                            <ConversationPracticeL3
                                {...props}
                                c_code={c_code}
                            />
                        );
                    } else {
                        break;
                    }
                // case 23:
                //     return (<PatternPractice
                //         {...props}
                //         c_code={c_code}
                //     />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'J1' ||window.serverData.step_code === 'J2'||window.serverData.step_code === 'J3'){
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 11:
                    return (<ListenToTheDialogue
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return (<MemorizeB
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return (<RoleplayB
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return (<MemorizeA
                        {...props}
                        c_code={c_code}
                    />);
                case 15:
                    return (<RoleplayA
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return (<TranslationKoreanEnglish1
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return (<TranslationKoreanEnglish2
                        {...props}
                        c_code={c_code}
                    />);
                case 23:
                    return (<PatternPracticeJr
                        {...props}
                        c_code={c_code}
                    />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }
        } else if (window.serverData.step_code === 'L4'){ //1단계 중급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 11:
                    return(<PracticeForReading1
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return(<PracticeForReading2
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<ReadingTest
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<OneMinuteSpeech
                        {...props}
                        c_code={c_code}
                    />);
                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewSpelling
                        {...props}
                        c_code={c_code}
                    />);
                case 41:
                    return(<Blinking
                        {...props}
                        c_code={c_code}
                    />);
                // case 42:
                //     return(<Spelling
                //         {...props}
                //         c_code={c_code}
                //     />);
                // case 43:
                //     return(<TakeBreakL4
                //         {...props}
                //         c_code={c_code}
                //     />);
                case 44:
                    return(<ReadingStoryL4
                        {...props}
                        c_code={c_code}
                    />);
                // case 45:
                //     return(<SolveProblemsL4
                //         {...props}
                //         c_code={c_code}
                //     />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L5'){ //1단계 중급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 11:
                    return(<PracticeForReading1
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return(<PracticeForReading2
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<ReadingTest
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<OneMinuteSpeech
                        {...props}
                        c_code={c_code}
                    />);
                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewExpressions
                        {...props}
                        c_code={c_code}
                    />);
                case 40:
                    return(<MakeQuestions
                        {...props}
                        c_code={c_code}
                    />);
                case 41:
                    return(<Blinking
                        {...props}
                        c_code={c_code}
                    />);
                case 42:
                    return(<Spelling
                        {...props}
                        c_code={c_code}
                    />);
                case 43:
                    return(<TakeBreakL4
                        {...props}
                        c_code={c_code}
                    />);
                case 44:
                    return(<ReadingStoryL5
                        {...props}
                        c_code={c_code}
                    />);
                case 45:
                    return(<SolveProblemsL4
                        {...props}
                        c_code={c_code}
                    />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L6'){ //1단계 고급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                //temp, 0: Practice Pronunciation, 1: Memorize 3 words
                case 11:
                    return(<ReadAndUnderstand
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return(<TranslationPractice
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<TranslationTest
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return(<TranslationListening
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<AppliedStorytelling
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return(<CreativeStorytelling
                        {...props}
                        c_code={c_code}
                    />);
                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewExpressions
                        {...props}
                        c_code={c_code}
                    />);
                case 40:
                    return(<MakeQuestions
                        {...props}
                        c_code={c_code}
                    />);
                case 41:
                    return(
                        <WritePhrase
                            {...props}
                            c_code={c_code}
                    />);
                case 42:
                    return(
                        <KoreanEnglish
                            {...props}
                            c_code={c_code}
                        />);
                case 43:
                    return(
                        <TakeBreakL5
                            {...props}
                            c_code={c_code}
                        />);
                case 44:
                    return(
                        <Accumulate
                            {...props}
                            c_code={c_code}
                        />);

                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L7'){ //2단계 초급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                case 11:
                    return(<ListenAndUnderstand
                        {...props}
                        c_code={c_code}
                    />);
                case 12:
                    return(<SpeakingPractice1
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<SpeakingPractice2
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return(<SpeakingTest
                        {...props}
                        c_code={c_code}
                    />);
                case 15:
                    return(<ReadingTestInL6
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<ListenAndUnderstand
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return(<SpeakingPractice1
                        {...props}
                        c_code={c_code}
                    />);
                case 23:
                    return(<SpeakingPractice2
                        {...props}
                        c_code={c_code}
                    />);
                case 24:
                    return(<SpeakingTest
                        {...props}
                        c_code={c_code}
                    />);
                case 25:
                    return(<ReadingTestInL6
                        {...props}
                        c_code={c_code}
                    />);
                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewExpressions
                        {...props}
                        c_code={c_code}
                    />);
                case 41:
                    return(
                        <WriteSentence
                            {...props}
                            c_code={c_code}
                        />);
                case 42:
                    return(
                        <ListenAndSolve
                            {...props}
                            c_code={c_code}
                        />);
                case 43:
                    return(
                        <ListenAndSpeak
                            {...props}
                            c_code={c_code}
                        />);
                case 44:
                    return(
                        <TakeBreakL6
                            {...props}
                            c_code={c_code}
                        />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L8') { //2단계 중급인 경우
            switch(menuNumber){ //menuNumber === menu_idx

                case 11:
                    return(<Reading
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<DirectTranslation
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return(<Comprehension
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<PracticeForStorytelling
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return(<TwoMinutesStorytelling
                        {...props}
                        c_code={c_code}
                    />);
                case 31:
                    return (<ReviewWords
                        {...props}
                        c_code={c_code}
                    />);
                case 32:
                    return (<ReviewExpressions
                        {...props}
                        c_code={c_code}
                    />);
                case 41:
                    return(
                        <Theory
                            {...props}
                            c_code={c_code}
                        />);
                case 42:
                    return(
                        <Practice
                            {...props}
                            c_code={c_code}
                        />);
                case 43:
                    return(
                        <Test
                            {...props}
                            c_code={c_code}
                        />);
                case 44:
                    return(
                        <TakeBreakL7
                            {...props}
                            c_code={c_code}
                        />);
                case 51:
                    return(
                        <Test33
                            {...props}
                            c_code={c_code}
                        />);
                case 52:
                    return(
                        <Speech
                            {...props}
                            c_code={c_code}
                        />);
                case 53:
                    return(
                        <Test33
                            {...props}
                            c_code={c_code}
                        />);
                case 54:
                    return(
                        <Speech
                            {...props}
                            c_code={c_code}
                        />);
                case 55:
                    return(
                        <TakeBreakL7
                            {...props}
                            c_code={c_code}
                        />);
                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L9') {
            switch(menuNumber){ //menuNumber === menu_idx

                case 11:
                    return(<Reading_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<DirectTranslation_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return(<Comprehension_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<PracticeForStorytelling_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return(<TwoMinutesStorytelling_L8
                        {...props}
                        c_code={c_code}
                    />);

                //L8 PT
                case 31:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 32:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);



                //PT에서 menu_idx 가 114 에서 127로 JUMP함.
                case 33:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 34:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 35:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 36:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 37:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 38:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 39:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 40:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 41:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 42:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);

                case 51:
                    return(<CIPresentation
                        {...props}
                        c_code={c_code}
                    />);
                case 52:
                    return(<NativePresentation
                        {...props}
                        c_code={c_code}
                    />);

                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }

        } else if (window.serverData.step_code === 'L10'||window.serverData.step_code === 'L11'||window.serverData.step_code === 'L12') {
            switch(menuNumber){ //menuNumber === menu_idx

                case 11:
                    return(<Reading_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 13:
                    return(<DirectTranslation_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 14:
                    return(<Comprehension_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 21:
                    return(<PracticeForStorytelling_L8
                        {...props}
                        c_code={c_code}
                    />);
                case 22:
                    return(<TwoMinutesStorytelling_L8
                        {...props}
                        c_code={c_code}
                    />);

                case 31:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 32:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);

                case 51:
                    return(<SIPresentation
                        {...props}
                        c_code={c_code}
                    />);
                case 52:
                    return(<NativePresentation
                        {...props}
                        c_code={c_code}
                    />);

                //PT
                case 33:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 34:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 35:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 36:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 37:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 38:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 39:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 40:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);
                case 41:
                    return(<MemorizeExpressions
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                    />);
                case 42:
                    return(<PreparationForPresentation
                        {...props}
                        c_code={c_code}
                        c_code_no={group_code.slice(-1)}
                        content_seq={parseInt(group_code.slice(-1))}
                    />);

                default:
                    return (<div>Not Defined Menu Number.{menuNumber}</div>);
            }
        }
    }

    _renderTypingMenu = (menuNumber) => {
        ////console.log("render ",menuNumber);
        const props = this.props;
        let c_code = null;

        let currentMenuInfo = getCurrentMenuInfo(this.props.menuInfo, menuNumber);

        if(menuNumber === 999) {
            c_code = null;
            return (
                <EndLesson
                    {...props}
                />
            )
        }else if(currentMenuInfo === null){
            console.warn(this.props.currentMenu +" is not valid menu idx.");
            menuNumber = 998;
        } else {
            c_code = currentMenuInfo.c_code;
        }

        if(props === null){ return ("")}


        switch(menuNumber){ //menuNumber === menu_idx

            case 1:
                return (<T_WordsPractice
                    {...props}
                    c_code={c_code}
                />);
            case 2:
                return (<T_WordsGame
                    {...props}
                    c_code={c_code}
                />);
            case 3:
                return (<T_SentencePractice
                    {...props}
                    c_code={c_code}
                />);
            case 4:
                return (<T_SentenceGame
                    {...props}
                    c_code={c_code}
                />);
            default:
                return (<div>Not Defined Menu Number.{menuNumber}</div>);
        }


    }
}


export default MainPanel;