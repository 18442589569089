import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    shuffleArr,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_RES_L5 from "../../Footer_RES_L5";

class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            err: null,
            errCount: 0,
            pass: false,
            wrongPopShow: false,
            correctPopShow:false,
            selectedIdx: null,
            selectedAnswerIdx: null,
            userAnswer: null,
            // options:[],
            randomIdx:[],
            isInitialized:false,

        };

        this.mTimeout = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if(nextProps.contents && !prevState.isInitialized) {

            return({
                isInitialized:true,
                randomIdx:shuffleArr([0,1,2,3]),
            })
        }
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }


        return (
            this._renderMain()
        )


    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <ul className="word-list voice-box proverb">
                            <li>
                                <h4 className="tit">오늘의 속담 - 무슨뜻일까요?</h4>
                                <div className="word">{this.props.contents[0].eng_content}</div>
                                <span className="btn-wrap MB0">
                                     <button
                                         type="button"
                                         className="btn-play-sound"
                                         onClick={() => this.audioPlay(0)}
                                     >
                                         발음듣기
                                     </button>
                                </span>
                            </li>
                        </ul>
                        {this._renderOptions()}
                        <div className="alert-wrong">
                            <div className="mask" onClick={() => {this._handleWrongPop(false)}}/>
                            <div className="alert-wrap">
                                <h2 className="tit">틀렸습니다.</h2>
                                <h4 className="tit1">다시 한번 생각해 보세요</h4>
                            </div>
                        </div>

                        <div className="alert-correct">
                            <div className="mask" onClick={() => {this._handleCorrectPop(false)}}/>
                            <div className="alert-wrap">
                                <h2 className="tit1">정답입니다.</h2>
                                <div className="H10" />
                                <p>
                                    {this.props.contents[0].tpk_content}
                                </p>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            영어 속담에 해당하는 한글 뜻(속담)을 골라주세요.
                            <div className="divider"/>
                            <div className="tip">
                                직역하기 보다는 어떤 상황에서<br/>
                                어울리는 말인지 생각해 보세요.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_RES_L5
                    {...this.props}
                    pass={this.state.pass}
                    isComplete={this.state.isComplete}
                    handleIsComplete={this._handleIsComplete}
                />

            </>

        );
    };

    _handleIsComplete = (bool) => {
        this.setState({
            isComplete: bool,
        })
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }



    _handleWrongPop = (isShow) => {
        if (isShow) {
            this.setState({
                wrongPopShow: isShow
            });
            $('.alert-wrong').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
                if (this.state.wrongPopShow) {
                    $('.alert-wrong').fadeOut('fast');
                    this.setState({
                        wrongPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 2000)
        } else {
            if (this.state.wrongPopShow) {
                $('.alert-wrong').fadeOut('fast');
                this.setState({
                    wrongPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    }

    _handleCorrectPop = (isShow) => {

        if (isShow) {
            this.setState({
                correctPopShow: isShow,
                pass:true, //통과
            });
            $('.alert-correct').fadeIn('fast');

                //Correct 창은 자동 종료 타임아웃 설정 안함.

            // this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
            //     if (this.state.correctPopShow) {
            //         $('.alert-correct').fadeOut('fast');
            //         this.setState({
            //             correctPopShow: false,
            //             // hint:{ //힌트 초기화
            //             //     kor:null,
            //             //     eng:null,
            //             // }
            //         })
            //     }
            // }, 4000)
        } else {
            if (this.state.correctPopShow) {
                $('.alert-correct').fadeOut('fast');
                this.setState({
                    correctPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                //clearTimeout(this.mTimeout);
            }
        }

    };

    _renderOptions = () => {
        let options = [];

        for(let i = 0; i < this.state.randomIdx.length; i ++){
            if(this.state.randomIdx[i] === 0){
                options.push(
                    <button
                        type="button"
                        className={this.state.selectedIdx === this.state.randomIdx[i] ? "o-card correct":"o-card"}
                        onClick={()=>this._selectOption(this.state.randomIdx[i])}
                    >
                        {this.props.contents[0].kor_content}
                    </button>
                )
            } else {
                options.push(
                    <button
                        type="button"
                        className={this.state.selectedIdx === this.state.randomIdx[i] ? "o-card wrong":"o-card"}
                        onClick={()=>this._selectOption(this.state.randomIdx[i])}
                    >
                        {this.props.contents[0][`t_ex${this.state.randomIdx[i]}`]}
                    </button>
                )
            }

        }

        return(
            <div className="card-select">
                {options}
            </div>
        )

    }


    _selectOption = (idx) => {
        this.props.closeTip();
        if(idx === 0){
            this._handleCorrectPop(true);

        } else{
            this._handleWrongPop(true);
        }
        this.setState({
            selectedIdx: idx,
        })
    }




    _handleOverlapPop = (isShow) => {

        if (isShow) {
            this.setState({
                overlapPopShow: isShow,
            });
            $('.alert-overlap').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
                if (this.state.overlapPopShow) {
                    $('.alert-overlap').fadeOut('fast');
                    this.setState({
                        overlapPopShow: false,

                    })
                }
            }, 2000)

        } else {
            if (this.state.overlapPopShow) {
                $('.alert-overlap').fadeOut('fast');
                this.setState({
                    overlapPopShow: false,

                });
                clearTimeout(this.mTimeout);

            }
        }

    };

}


export default withContents()(TakeBreak);
    
    