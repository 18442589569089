import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
// import {isIOS} from "../../asset/js/myFunctions";


class Footer_ISM_B extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
        };
        this._testStart = this._testStart.bind(this);

    }

    _testStart = () => {
        this.props.handleIsTesting(true);
        this.props.closeTip();
        this.props.setHowler(
            `${this.props.contents[this.props.wordInfo.num + 1].step_code}/${this.props.contents[this.props.wordInfo.num + 1].lesson_no}/${this.props.contents[this.props.wordInfo.num + 1].tpk_url}`,
            () => {
                this.props.startRecognition();
            },
        );
        // this.props.audioPlayWithCb("tpk", this.props.wordInfo.num + 1, () => {
        //     // if(isIOS){
        //     //     this.props.fakeRecognitionStart();
        //     //     this.props.isListening(true);
        //     //     this.props.audioPlayMute("eng",this.props.wordInfo.num + 1,
        //     //         () => setTimeout(() => {this.props.fakeRecognitionEnd();this.props.isListening(false);},1000)
        //     //     );
        //     // } else {
        //     //     this.props.startRecognition();
        //     // }
        //     this.props.startRecognition();
        // });
    };

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index && nextProps.wordInfo.num !== 0) {
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 테스트를 실행하고 index를 num에 맞춤.
            //console.log("received wordInfo is: " + nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();


            // nextProps.audioPlayWithCb("tpk", nextProps.wordInfo.num+1, () => {
            //
            //     if(isIOS){
            //         nextProps.fakeRecognitionStart();
            //         nextProps.isListening(true);
            //         nextProps.audioPlayMute("eng",nextProps.wordInfo.num+1,
            //             () => setTimeout(() => {nextProps.fakeRecognitionEnd(); nextProps.isListening(false);},1000)
            //         );
            //     } else {
            //         nextProps.startRecognition();
            //     }
            // });

            nextProps.setHowler(
                `${nextProps.contents[nextProps.wordInfo.num + 1].step_code}/${nextProps.contents[nextProps.wordInfo.num + 1].lesson_no}/${nextProps.contents[nextProps.wordInfo.num + 1].tpk_url}`,
                () => {
                    nextProps.startRecognition();
                },
            );

            return {
                index: nextProps.wordInfo.num,
            }
        }
        return null;
    }


    render() {
        ////console.log("is Listening?:", this.props.listening);
        if (!this.props.isTesting) {
            return (
                <section className="view-footer">
                    <button type="button" className="f-btn btn-prev" onClick={() => {this.props.handlePhase(1)}}>prev</button>
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this._testStart()
                        }}>
                    </button>
                    {
                        this.props.pass ?
                            <button
                                type="button"
                                className="f-btn f-center btn-next-page"
                                onClick={() => {
                                    this.props.goNextMenu();
                                }}>
                            </button>
                            :
                            <button type="button" className="f-btn">Empty</button>

                    }
                </section>
            )
        }

        return (

            <section className="view-footer">

                {
                    this.props.listening ?
                        <MicVisualizer/>
                        :
                        this.props.isPlaying ?
                            <button
                                type="button"
                                className="f-btn f-center btn-listen">듣는 중
                            </button>

                            :
                            this.props.waiting ?
                                <button type="button" className="f-btn">Empty</button>
                                :

                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this.props.closeTip();
                                        // if(isIOS){
                                        //     this.props.fakeRecognitionStart();
                                        //     this.props.isListening(true);
                                        //     this.props.audioPlayMute("eng",this.props.wordInfo.num+1,
                                        //         () => setTimeout(() => {this.props.fakeRecognitionEnd(); this.props.isListening(false);},1000)
                                        //     );
                                        // } else {
                                        //     this.props.startRecognition();
                                        // }
                                        this.props.startRecognition();
                                    }}>
                                </button>

                }

            </section>

        );
    }


}

export default withRecognizer()(Footer_ISM_B);
    
    