import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo, isMobile,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import Footer_RES_L7 from "../../Footer_RES_L7";

class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            toggleAnswer:false,
        };

        this.mTimeout = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if (nextProps.contents && !prevState.isInitialized) {
            let wordsContents = [];
            let answerContents = [];

            for (let i = 0; i < nextProps.contents.length; i++) {
                if (nextProps.contents[i].c_code_no === "1") { // c_code_no === 1 >> 단어 콘텐츠
                    wordsContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "2") { ///c_code_no === 2 >>정답 콘텐츠
                    answerContents.push(nextProps.contents[i]);
                }
            }

            return ({
                isInitialized: true,
                wordsContents: wordsContents,
                answerContents: answerContents,

            })
        }

        return null;


    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.phase === 1){
            return(
                this._renderResult()
            );
        }


        return (
            this._renderMain()
        )


    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={isMobile ? "content-wrap justify-start" :"content-wrap"}>
                        {isMobile ? <div className={"H60"}/> : "" }
                        <div className="alert-quiz relative display">
                            <div className={
                                isMobile ?
                                    this.state.toggleAnswer ? "alert-wrap opened MT40" : "alert-wrap MT-40"
                                    :
                                    this.state.toggleAnswer ? "alert-wrap opened" : "alert-wrap"
                            }>
                                <h2 className="title">오늘의 수수께끼</h2>
                                <p className="quiz-content">
                                    {this.props.contents[0].eng_content}
                                </p>
                                <p className={"quiz-hint"}>
                                    *{this.props.contents[0].t_question}
                                </p>
                                <div className="quiz-answer">
                                    <h4>정답</h4>
                                    <h2>{this.props.contents[0].kor_content}</h2>
                                    <p>
                                        {this.props.contents[0].tpk_content}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="btn-view-correct"
                                    onClick={() => {this._toggleAnswer()}}>정답보기
                                </button>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            다음 수수께끼를 풀어보세요!
                        </div>
                    </div>
                </section>
                <Footer_RES_L7
                    {...this.props}
                />

            </>

        );
    };

    _toggleAnswer = () => {
        this.setState({
            toggleAnswer:!this.state.toggleAnswer,
        })
    }

}


export default withContents()(TakeBreak);
    
    