import React from "react";

const _diffCheck = (original, userResult) => {

    if(userResult == null){
        return [];
    }
    let arrOriginal = original.split('');
    let arrUserResult = userResult.split('');

    let diffOriginal = [];
    for(let i = 0 ; i < arrOriginal.length; i ++){
        if(arrUserResult[i] == null) {
            diffOriginal[i] = <>{arrOriginal[i]}</>
        } else if(arrOriginal[i] === arrUserResult[i]) {
            diffOriginal[i] = <span className={"tp01-s-correct"}>{arrOriginal[i]}</span>
        } else {
            diffOriginal[i] = <span className={"tp01-s-wrong"}>{arrOriginal[i]}</span>
        }
    }

    return [diffOriginal];
}


function DiffText(props) {
    return (
        <h3 className={"english"}>
            {
                _diffCheck(props.original, props.userInput)
                    .map((textOrHtml, index) =>
                        <React.Fragment key={'typing-wp'+index}>
                            {textOrHtml}
                        </React.Fragment>
                    )
            }
            {/*{this.props.contents[this.state.contentsIdx].eng_content}*/}
            <button
                type={"button"}
                className={"btn-play-sound dark"}
                //onClick={() => props.audioPlay("eng", props.contentsIdx)}
                onClick={() => props.audioPlay(props.contentsIdx)}
            >
                소리 재생
            </button>
        </h3>
    );
}

export default DiffText