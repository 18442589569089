import React, {Component} from 'react';
import withContents from "../../../withContents";
import LoadingBar from "../../../../other/LoadingBar";
import axios from "axios/index";
import {getCurrentMenuInfo, brTagActivator} from "../../../../../asset/js/myFunctions";
import Footer_TPSG from '../../../Footer_TPSG';
import DiffInput from '../../../../other/DiffInput';
import {apiKey} from "../../../../../secret/keys";


axios.defaults.headers.common['Authorization'] = apiKey;

class SentenceGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            characterRootURL: 'https://study.itcenglish.com/contents/common',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            contentsIdx: 0,
            currentPage: 1,
            isStart: false,
            isLoaded: false,
            accuracy: 0,
            accuracyList: [],
            isFinished:false,
            updateAccuracy:0,
            popShow:false,
            score:0,
            uploadSuccess:false,
            letReset:false,
            myRank: [],
            topRanks: [],
            topWordsRanks:[],
            myWordsRank: [],
            activeTab:1,
            contentsLength:0,
            startCounter:false,
        };

    }



    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.contents && !prevState.isLoaded) { //콘텐츠 로딩 완료

            let lists = [];
            let sContents = [];
            let contentsLength = 0;
            for(let i = 0; i < nextProps.contents.length; i ++){
                let temp = nextProps.contents[i].eng_content.trim().replace(/[´’＇]/gi,'\'').replace(/[＂”]/gi,'"').replace(/<br>/gi,' ').replace(/\."/gi, `"`).replace(/Mr./gi,'Mr').replace(/Mrs./gi, 'Mrs').replace(/Mt./gi, 'Mt').replace(/C:/gi,'').replace(/B:/gi,'').split('.');
                for(let j = 0; j < temp.length; j++){
                    if(temp[j].trim() !== "") {
                        let types = ["?","!",",","\""];
                        if(types.includes(temp[j].slice(-1))) {
                            sContents.push(temp[j]);
                            lists.push('');
                            contentsLength += temp[j].length; //알파벳 갯수
                        } else {
                            sContents.push(temp[j] + ".");
                            lists.push('');
                            contentsLength += temp[j].length+1; //알파벳 갯수
                        }
                    }
                }


            }

            return ({
                isLoaded: true,
                accuracyList:lists,
                contentsLength,
                sContents,
            })
        }


        if (prevState.currentPage !== nextProps.currentPage) {//페이지 변경시


            return ({
                currentPage: nextProps.currentPage,
                contentsIdx: prevState.contentsIdx + 1,
                userInput: null,
            })
        }

        return null;

    }


    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!this.state.isLoaded && nextState.isLoaded){
            return true;
        }

        if(this.state.userInput !== nextState.userInput)
        {
            return false;
        }

        return !(this.state.startCounter && this.state.updateAccuracy !== nextState.updateAccuracy);


    }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log("Update");
    //     this.props.myJS();
    // }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        //console.log("렌더링");


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={this.state.isFinished ? "content-wrap typing-game-02":"content-wrap typing-game-04"}>
                        {
                            this.state.isFinished ?
                                <div className={"score-wrap"}>
                                    <strong className="t-score">{this.state.score}</strong>
                                    <span className="text">YOUR SCORE</span>
                                    <button
                                        type="button"
                                        className="btn-ranking"
                                        onClick={() => {
                                            this._toggleRankPopup()
                                        }}>랭킹확인
                                    </button>
                                </div>
                                :
                                <ul className="sentence-list">

                                    {this._renderSentence()}

                                </ul>
                        }

                        {
                            this.state.isFinished && this.state.popShow ?
                                <div className="ranking-popup">
                                    <div className="ranking-popup-wrap">
                                        <div className="ranking-header">
                                            <h4 className="title">랭킹보기</h4>
                                            <button type="button" className="btn-close"
                                                    onClick={() => this._toggleRankPopup()}>랭킹보기 닫기
                                            </button>
                                        </div>
                                        <div className="ranking-body">

                                            <ul className="ranking-tab">
                                                <li className={this.state.activeTab === 0 ? "active":""}>
                                                    <a onClick={() => {this._toggleTab(0)}}>단어게임</a>
                                                </li>
                                                <li className={this.state.activeTab === 1 ? "active":""}>
                                                    <a onClick={() => {this._toggleTab(1)}}>문장게임</a>
                                                </li>
                                            </ul>

                                            <div className="ranking-score">
                                                {
                                                    this.props.userInfo.skin_alphabet == null ?
                                                        <img className={"thumb"} src={`${this.state.characterRootURL}/character/character_a.png`} alt="프로필캐릭터"/>
                                                        :
                                                        <img className={"thumb"} src={`${this.state.characterRootURL}/character/character_${this.props.userInfo.skin_alphabet.replace(/\s/gi,"")}.png`} alt="프로필캐릭터"/>
                                                }
                                                <div className="user-info">
                                                    <span className="name">{this.props.serverData.mem_name}</span>
                                                    <p className="t-score">
                                                        <strong>
                                                            {
                                                                this.state.activeTab ===  1 ?
                                                                    this.state.score :
                                                                    this.state.myWordsRank.length !== 0 ?
                                                                        this.state.myWordsRank[0].score
                                                                        : ""
                                                            }
                                                        </strong>점
                                                        <strong className="color">(
                                                            {
                                                                this.state.activeTab === 1 ?

                                                                    this.state.myRank.length !== 0 ? this.state.myRank[0].rank : ""
                                                                    :
                                                                    this.state.myWordsRank.length !== 0 ? this.state.myWordsRank[0].rank : ""
                                                            }
                                                            등)</strong>
                                                    </p>
                                                    {/*<p className="t-score">*/}
                                                    {/*<strong>{this.state.score}</strong>점*/}
                                                    {/*<strong className="color">>({this.state.myRank.length !== 0 ? this.state.myRank[0].rank : ""}등)</strong>*/}
                                                    {/*</p>*/}
                                                </div>
                                            </div>

                                            <ul className="ranking-list">
                                                {this._renderRankers()}
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                :
                                ""
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.isFinished ?
                                    <>
                                        잘 하셨어요.<br/>
                                        다른 친구들과의 점수를 비교해 보세요!<br />
                                        더 높은 점수를 원하시면 다시 테스트 하세요.
                                    </>
                                    :
                                    <>
                                        다음 문장을 정확하고 빠르게 작성해 보세요.
                                        <div className="tip">
                                            정확할 수록 높은 점수를 받습니다. <br/>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </section>
                <Footer_TPSG
                    closeTip={this.props.closeTip}
                    pass={this.state.pass}
                    checkCorrect={this._checkCorrect}
                    accuracyList={this.state.accuracyList}
                    startCounter={this.state.startCounter}
                    liftScore={this._liftScore}
                    retry={this._retry}
                    isFinished={this.state.isFinished}
                    contentsLength={this.state.contentsLength}
                    goNextMenu={this.props.goNextMenu}
                />

            </>

        );
    };


    _renderSentence = () => {
        let lists = [];

        if(!this.props.contents) {
            return(
                ""
            )
        }

        for (let i = 0; i < this.state.sContents.length; i++) {

            lists.push(
                <DiffInput
                    sentence={this._trim(this.state.sContents[i])}
                    idx={i}
                    sContentsLength={this.state.sContents.length}
                    triggerCounter={this._triggerCounter}
                    goResultPage={this._goResultPage}
                    setAccuracy={this._setAccuracy}
                    letReset={this.state.letReset}
                />

            )
        }

        return (
            lists
        )
    };

    _setAccuracy = (correct, wrong, idx) => {
        let {accuracyList} = this.state;

        accuracyList[idx] = {
            'numCorrect':correct,
            'numWrong':wrong,
        };


        this.setState({
            accuracyList,
            updateAccuracy: this.state.updateAccuracy + 1,
        })
    };

    _liftScore = (score) => {
        this.setState({
            score
        });

        this._uploadUserScore(score);
    };


    _goResultPage = () => {
        //점수 계산!

        this.setState({
            isFinished: true,
            startCounter:false,
            letReset:false,
        });
        //this._stopCounter();
    }


    _toggleRankPopup = () => {
        if(!this.state.popShow) { //열때, 랭킹 갱신함.
            this._getTopRanks();
            this._getUserRank();

        }
        this.setState({
            popShow:!this.state.popShow

        })

    }

    _renderRankers = () => {
        let rankers = [];

        if(this.state.activeTab === 1){
            for(let i = 0 ;i < this.state.topRanks.length; i++) {
                rankers.push(
                    <li className={"ranking-list"}>
                    <span className={this.state.topRanks[i].rank < 4 ? "rank top-rank":"rank"}>
                        {this.state.topRanks[i].rank}
                    </span>
                        <img className={"thumb"} src={this.state.topRanks[i].skin_alphabet == null ? `${this.state.characterRootURL}/character/character_a.png`: `${this.state.characterRootURL}/character/character_${this.state.topRanks[i].skin_alphabet.replace(/\s/gi,"")}.png`} alt = "프로필사진" />
                        <span className={"name"}>
                        {this.state.topRanks[i].Mem_Name}
                    </span>
                        <span className={"t-score"}>
                        <strong>{this.state.topRanks[i].score}</strong>점
                    </span>

                    </li>
                )
            }
        } else {
            for(let i = 0 ;i < this.state.topWordsRanks.length; i++) {
                rankers.push(
                    <li className={"ranking-list"}>
                    <span className={this.state.topWordsRanks[i].rank < 4 ? "rank top-rank":"rank"}>
                        {this.state.topWordsRanks[i].rank}
                    </span>
                        <img className={"thumb"} src={this.state.topWordsRanks[i].skin_alphabet == null ? `${this.state.characterRootURL}/character/character_a.png`: `${this.state.characterRootURL}/character/character_${this.state.topWordsRanks[i].skin_alphabet.replace(/\s/gi,"")}.png`} alt = "프로필사진" />
                        <span className={"name"}>
                        {this.state.topWordsRanks[i].Mem_Name}
                    </span>
                        <span className={"t-score"}>
                        <strong>{this.state.topWordsRanks[i].score}</strong>점
                    </span>

                    </li>
                )
            }
        }




        return(
            rankers
        )

    }



    _getUserRank = () => {
        Promise.all([axios({
                method: 'get',
                url: `${this.props.apiUrl}/class/typing/rank/my/${this.props.serverData.mem_idx}/${this.props.lessonInfo.lesson_idx}/${this.props.currentMenu}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //request success
            this.setState({
                myRank: res.data,
            })
        }).catch(err => {
            //에러 발견

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

    _uploadUserScore = (score) => {
        if(this.state.uploadSuccess) {
            //이미 업로드 완료하여 리턴
            return;
        }

        Promise.all([axios({
                method: 'put',
                url: `${this.props.apiUrl}/class/lesson/typing/score/g/${this.props.attend_idx}/${this.props.currentMenu}/${this.props.lessonInfo.lesson_idx}/${score}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res3]) => {
            //request success
            this.setState({
                uploadSuccess: true,
            })
        }).catch(err => {
            //에러 발견
            //console.log(err);

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

    _getTopRanks = () => {
        Promise.all([axios({
                method: 'get',
                url: `${this.props.apiUrl}/class/typing/rank/top/${this.props.currentMenu}/${this.props.lessonInfo.lesson_idx}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //request success
            this.setState({
                topRanks: res.data,
            })
        }).catch(err => {
            //에러 발견1

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

    _triggerCounter = () => {

       // console.log("카운터 트리거");
        if(this.state.startCounter) {
            return;
        }
        this.setState({
            startCounter: true,
        })
    };


    //
    // _startCounter = () => {
    //
    //     //카운터가 이미 작동중이면 리턴
    //     if(this.mCounter !== null){
    //         return;
    //     }
    //
    //     this.mCounter = setInterval(() => {
    //             this.setState({
    //                 time: this.state.time + 1,
    //             });
    //
    //         }, 1000
    //     );
    // }
    //
    // _stopCounter = () => {
    //     clearInterval(this.mCounter);
    //     this.mCounter = null;
    // }

    _trim = (text) => {
        let trimmed;
        trimmed = text.replace(/[＂”]/gi,'"');
        trimmed = trimmed.replace(/[´’＇]/gi,'\'');
        //trimmed = trimmed.replace(/\s+$/, '');



        return trimmed.trim();

    }
    _retry = () => {
        this.setState({
            contentsIdx: 0,
            currentPage: 1,
            accuracy: 0,
            accuracyList: [],
            speed: 0,
            isFinished:false,
            updatedIdx:0,
            popShow:false,
            score:0,
            uploadSuccess:false,
            letReset:true,
        })
    }

    _toggleTab = (idx) => {
        if(idx === 0 && this.state.topWordsRanks.length === 0){
            //탑 랭커 점수 로딩
            Promise.all([
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/class/typing/rank/top/2/${this.props.lessonInfo.lesson_idx}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }),
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/class/typing/rank/my/${this.props.serverData.mem_idx}/${this.props.lessonInfo.lesson_idx}/2`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }),

            ]).then(([res1, res2]) => {
                //request success
                this.setState({
                    topWordsRanks: res1.data,
                    myWordsRank: res2.data,
                })
            }).catch(err => {
                //에러 발견

            }).then(() => {
                //에러 발견시, 콜백
            });
        }

        this.setState({
            activeTab:idx,
        })

    }

}


export default withContents()(SentenceGame);
    
    