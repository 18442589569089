import React, { Component } from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import {isMobile} from "../../asset/js/myFunctions";

const withRecognizerPST = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            isError:false,
            listening: false,
            text:null,
            recognizerReady:false,
            isRecognizeSuccess: false,
            sumText:"",
            isFirst:true,


        };


        initialize() {
            // alert("start recognition");
            const Recognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!Recognition) {
                alert(
                    '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
                );
                return;
            }

            this.recognition = new Recognition();
            this.recognition.lang = process.env.REACT_APP_LANGUAGE || 'en-US';
            this.recognition.continuous = true;
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = event => {
                let sumText = "";
                for(let i=0; i < event.results.length; i ++){
                    sumText = sumText + event.results[i][0].transcript;

                }

                this.setState({
                    //text,
                    sumText,
                    isRecognizeSuccess: true,
                });
                // if(!this.props.isPlaying) {
                //     this.props.setResult(this.state.sumText);
                //     this.setState({
                //         sumText:"",
                //     })
                // }

                // const text = event.results[0][0].transcript;
                // //console.log('transcript', text);
                // const sumText = this.state.sumText +". "+ text;
                // this.setState({
                //     text,
                //     sumText,
                //     isRecognizeSuccess: true,
                // });
                // // this.props.setResult(sumText);
                // this.setState({
                //     text:null,
                // })
            };

            this.recognition.onspeechend = () => {
                //console.log('stopped');

            };

            this.recognition.onnomatch = event => {
                //console.log('no match');
                this.setState({ text: null });
            };

            this.recognition.onstart = () => {
                //console.log('Recognizer start');
                if(!isMobile) {
                    let startAudio = new Audio(StartSound);
                    startAudio.play();
                }
                // let startAudio = new Audio(StartSound);
                // startAudio.play();
                this.setState({
                    text:null,
                    listening: true,
                    recognizerReady:false,
                    sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                });
                this.props.setRecognizeComplete(false);
                // this.props.checkTesting(true);
                // //console.log('Recognizer start');
                // this.props.setRecognizeComplete(false);
                // if(this.state.isFirst){
                //     if(!isMobile) {
                //         let startAudio = new Audio(StartSound);
                //         startAudio.play();
                //     }
                //     this.props.checkTesting(true);
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //         sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                //         isFirst:false,
                //     });
                // } else {
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //     });
                //
                // }
            };

            this.recognition.onend = () => {
                this.setState({listening:false}); //어쩄든 종료되면 listening:false,

                if(!isMobile){
                    let endSound = new Audio(EndSound);
                    endSound.play();
                }
                this.props.setRecognizeComplete(true);
                this.setState({ //state초기화
                    isError:false,
                    listening: false,
                    recognizerReady:true,
                });

                if(!this.state.isError) {
                    this.props.setResult(this.state.sumText);
                    this.setState({
                        sumText:"",
                    })
                }

                //
                // //let currentTime = new Date().getTime();
                // // let timeSinceFirstStart = new Date().getTime() - this.state.firstStartedAt;
                //
                // if(!this.props.isPlayingComplete) { //음원이 재생중이라면 자동 재시작.
                //
                //  //   this.endRecognition(); //종료후 재시작
                //     this.startRecognition();
                //
                // } else {//음원이 재생중이 아니라면 녹음 종료
                //
                //     if(!this.state.isError){//녹음 종료 후에 결과값 전송함.
                //         this.props.setResult(this.state.sumText);
                //     } else { //에러 발생 후, 녹음이 종료 된 경우
                //         if(this.state.sumText !== ""){ //에러가 발생했지만, 결과값이 null 이 아닌경우도 결과값 전송함.
                //             this.props.setResult(this.state.sumText);
                //         }else {
                //             if(this.props.handleError){ //에러 발생하고, 결과값이 null인경우는 에러 전송
                //                 this.props.handleError(["음성인식에 실패하였습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"문제가 계속된다면 ", <strong>마이크를 점검</strong>,"하세요."]);
                //             }
                //         }
                //     }
                //
                //     if(!isMobile){
                //         let endSound = new Audio(EndSound);
                //         endSound.play();
                //     }
                //     this.props.setRecognizeComplete(true);
                //     this.props.checkTesting(false);
                //     this.setState({ //state초기화
                //         isError:false,
                //         listening: false,
                //         recognizerReady:true,
                //         isFirst:true,
                //
                //     });
                //    // this.endRecognition();
                // }

            };

            this.recognition.onerror = event => {
                //console.log('error', event);
                //alert("마이크 응답이 없습니다. 마이크 연결과 전원을 확인하세요.");

                if(this.props.audioStop){ //에러 발생시 재생중인 음원 종료
                    this.props.audioStop();
                    this.props.checkTesting(false);
                }

                if(this.props.handleError){
                    this.props.handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                        <strong>원격 지원</strong>,"을 받아주세요."]);
                }
                this.setState({
                    isError:true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                });
            };
        }

        componentDidMount(){
            this.initialize(); //Initialize
            if(this.state.recognizerReady === false){
                //console.log("recognizer is ready");
                this.setState({
                    recognizerReady:true
                })
            }
        }


        componentWillUnmount() {
            this.recognition.stop();
        }


        startRecognition(){
            if(this.state.listening === true){
                //console.log("already listening");
                return;
            }
            this.recognition.start();
            // //console.log("start recognition");
        }

        endRecognition(){
            if(this.state.listening === false){
                //console.log("already stopped");
                return;
            }
            this.recognition.stop();
        }

        fakeStartRecognition() {
            let startAudio = new Audio(StartSound);
            startAudio.play();
            this.setState({
                text:null,
                listening: true,
                recognizerReady:false,
            });
            this.props.setRecognizeComplete(false);
        }

        fakeEndRecognition() {
            this.setState({listening:false}); //어쩄든 종료되면 listening:false,

            let endSound = new Audio(EndSound);
            endSound.play();
            this.props.setRecognizeComplete(true);
            this.setState({ //state초기화
                isError:false,
                listening: false,
                recognizerReady:true,
            });

            if(!this.state.isError) {
                this.props.setResult(this.props.sumContents);
            }
        }

        render(){
            return (
                <WrappedComponent {...this.props}
                                  recognizerReady={this.state.recognizerReady}
                                  text={this.state.text}
                                  listening={this.state.listening}
                                  startRecognition={this.startRecognition.bind(this)}
                                  endRecognition={this.endRecognition.bind(this)}
                                  recognizeComplete={this.state.recognizeComplete}
                                  fakeStartRecognition={this.fakeStartRecognition.bind(this)}
                                  fakeEndRecognition={this.fakeEndRecognition.bind(this)}
                />
            );
        }
    }

};

export default withRecognizerPST;