import React, {Component} from 'react';
import MicVisualizer from "../other/MicVisualizer";

/***
 * Preparation For Presentation 에서 사용
 */

class Footer_PET extends Component {


    render() {

        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>

                {this.props.isPlaying ?
                    <MicVisualizer /> //녹음 하는 척만 하도록
                    :
                    <button
                        type="button"
                        className="f-btn f-center btn-play"
                        onClick={()=>{this._fakeRecordingWithAudioPlay()}}
                    >재생하기</button>
                }

                {this.props.isPlayingComplete ?

                        // onClick={
                        //     () => {
                        //         if (this.props.currentMenu === 114) {
                        //             this.props.handleCurrentMenu(127);
                        //         } else {
                        //             this.props.goNextMenu()
                        //         }
                        //
                        //     }}
                        //PT에서, 메뉴이동이 이상해서위해서 이렇게 했던거같음.
                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={
                                () => this.props.goNextMenu()}>
                        다음메뉴
                    </button>
                    : <button type="button" className="f-btn">Empty</button>
                }

            </section>

        );
    }

    _fakeRecordingWithAudioPlay = () => {
        //this.props.start(); //녹음 시작
        // this.props.audioPlay("kor", this.props.content_seq-1);
        this.props.audioPlayKr(this.props.content_seq - 1);
        this.props.closeTip();
    };

    _recordingAndAudioStop = () => {
        //this.props.stop(); //녹음 종료
        this.props.audioStop();
    }


}

export default Footer_PET;

