import React, {Component} from 'react';


class Footer_RNU extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
        };

    }

    render(){

        return(


            <section className="view-footer">
                <button
                    type="button"
                    className="f-btn f-center btn-next-page"
                    onClick={() => {
                        this.props.goNextMenu();
                    }}>다음
                </button>
            </section>

        )
    }


//
// {/*<div className="ShowAnimation">*/}
// {/*<section className="view-footer">*/}
// {/*<button*/}
// {/*type="button"*/}
// {/*className="f-btn f-center btn-next-page"*/}
// {/*onClick={() => {*/}
// {/*this.props.goNextMenu();*/}
// {/*}}>다음*/}
// {/*</button>*/}
// {/*</section>*/}
// {/*</div>*/}
}

export default Footer_RNU;
    
    