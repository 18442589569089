import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_SKT from '../../Footer_SKT';
import {brTagActivator, getCurrentMenuInfo, isMobile,isIOS} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";


class SpeakingTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 69,
            isStart: false,
            isTesting: false,
            hasResult: false,
            result:{
            },
            isPlaying: false,
            audioURL: null,
            doUpload: false,
            uploadComplete: false,
            letUpload:false,
            errCount:0,
            err:null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        //nextProps.audioStop();
        if(nextProps.currentMenu !== prevState.thisMenuNumber){
            return({
                index: 0,
                thisMenuNumber: nextProps.currentMenu,
                isStart: false,
                isTesting: false,
                hasResult: false,
                result:{
                },
                isPlaying: false,
                audioURL: null,
                doUpload: false,
                uploadComplete: false,
                letUpload:false,
            })
        }
        return null;
    }

    componentDidMount(){
        this.props.myJS();
    }


    componentDidUpdate(prevProps, prevState){
        this.props.myJS();
        if(!prevState.hasResult && this.state.hasResult){
            //녹음파일을 받으면 팁창 오픈
            this.props.openTip();
        }
    }


    _getAudioURL = (url) => {
        if(this.state.audioURL === null){
            this.setState({
                audioURL: url,
            })
        }
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! " ,this.state.audioURL);
        //this.props.upload();
        this.setState({
            letUpload:true,
        });
        //this.props.goNextMenu();
    };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    _goNextMenu(){
        this.props.goNextMenu();
    }


    render() {
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        );

    }
    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={()=>{this._playRecordedFile()}}>녹음듣기</button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={()=>{this._uploadRecordedFile()}}>녹음제출</button>
                                </li>
                            </ul>
                            :
                            <figure className="figure figure-skt">
                                <img
                                    alt={"cover"}
                                    src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`} />
                            </figure>
                        }
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        제출이 완료되면 다음 메뉴로 이동합니다.
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            위에서 학습한 <strong>본문 내용</strong>을 떠올려 보세요.<br />
                                            준비가 되셨다면 테스트 버튼을 눌러 <br />
                                            <strong>1분간 영어로</strong> 발표하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_SKT
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    isPlaying = {this.state.isPlaying}
                    playRecordedFile = {this._playRecordedFile}
                    stopRecordedFile = {this._stopRecordedFile}
                    hasResult = {this.state.hasResult}
                    checkResult = {this._checkResult}
                    getAudioURL={this._getAudioURL}
                    totalTime={60}
                    letUpload = {this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                />
            </>

        );
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult:bool,
        })
    };

    _showSubmitPage = () => {
        //녹음파일 제출할 수 있는 페이지로 이동함.
        this.setState({
            ...this.state,
            isTesting:false,
            hasResult: true,
        });

    };
    _start = () => {
        this.setState({
            isStart: true,
            hasResult:false,
        })
        //this.props.setControlPanel(true);
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }


        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };



}


export default withContents()(SpeakingTest);

