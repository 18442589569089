import React, {Component} from 'react';
import screenfull from 'screenfull';
import MainPanel from './MainPanel';
import MenuList from './MenuList';
import LessonHeader from './LessonHeader';
import $ from 'jquery';
import axios from "axios/index";
import {isMobile, updateStdLesson} from "../../asset/js/myFunctions";
import {apiKey} from "../../secret/keys";

axios.defaults.headers.common['Authorization'] = apiKey;
class ViewContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMenu: 0,
            currentPage: 1,
            maxPage: 0,
            currentMenuInfo: null,
            menu_no: 1, //중단값
            al_menu_no: 31,
            active_menu_no: 1,
            attend_idx: null,
            bodyHeight: window.innerHeight,
            step123: 1, //1단계 = 1, 2단계 = 2, 3단계 = 3
            currentPercent: 0,
            starNum: 5, //응용학습 완료 시 획득하는 별 개수
            ptStarNum: 10, //PT완료 시 획득하는 별 개수
            illustRootURL:'https://dev.itcenglish.com/contents/ts',
            soundRootURL: 'https://dev.itcenglish.com/contents/ts/sound',
        };


        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
        this._handleCurrentMenu = this._handleCurrentMenu.bind(this);
        this._myJS = this._myJS.bind(this);
        this._setControlPanel = this._setControlPanel.bind(this);
        this._initToggleMenu = this._initToggleMenu.bind(this);
        this._initTipToggle = this._initTipToggle.bind(this);
        this._initNavToggle = this._initNavToggle.bind(this);
        this._setResult = this._setResult.bind(this);
        this._setMaxPage = this._setMaxPage.bind(this);
        this._endLesson = this._endLesson.bind(this);

        this._myJS();
        this.fullscreenContainer = document.getElementById('full');
    }


    componentDidMount() {
        //프리 레슨 이니셜라이징
        if(this.props.serverData.auth_type === "F"){
            Promise.all([axios({
                    method: 'put',
                    url: `${this.props.apiUrl}/free/${this.props.serverData.mem_idx=="" ? 0 :this.props.serverData.mem_idx }/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/F`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }
            )]).then(([res]) => {
                //res에서 attend_idx가져와야함.
                this.setState({
                    attend_idx: res.data.recordset[0].attend_idx,
                })
            }).catch(err => {
                //console.log("에러 발견", err);
            }).then(() => {
                //에러 발견시, 콜백
            });
        //관리자 이니셜라이징
        }else if (this.props.serverData.auth_type === "A") {

        //이니셜라이징
        } else {
            this.initialize();
        }

        let step123;
        let stepCode = this.props.lessonInfo.step_code.replace(/\s/gi, "");

        const level1 = ["L1", "L2", "L3", "L4", "L5", "J1", "J2", "J3"];
        const level2 = ["L6", "L7", "L8"];

        if (level1.includes(stepCode)) {
        //if (stepCode === ("S1")) {
            step123 = 1;
            //console.log("step123 = 1");
        } else if (level2.includes(stepCode)) {
            step123 = 2;
            //console.log("step123 = 2");
        } else {
            step123 = 3;
            //console.log("step123 = 3");
        }


        this.setState({
            headerHeight: $('.view-container .view-header').outerHeight(),
            footerHeight: $('.view-container .view-footer').outerHeight(),
            step123,
        })



        //production 모드일때 주석 풀기
        if(screenfull.isEnabled){

            if(isMobile && $(window).width() < 1200) { //모바일일때만 최대화면으로 변경
                screenfull.request(this.fullscreenContainer);

            }


        }

        //
        // if(isMobile){
        //     window.screen.orientation.lock('landscape');
        // }


    }

    componentWillUnmount(){
        screenfull.exit();
    }


    componentDidUpdate(prevProps, prevState) {
        ////console.log(this.header);

        if (prevState.headerHeight !== $('.view-container .view-header').outerHeight()) {
            this.setState({
                headerHeight: $('.view-container .view-header').outerHeight()
            })
        }

        if (prevState.footerHeight !== $('.view-container .view-footer').outerHeight()) {
            this.setState({
                footerHeight: $('.view-container .view-footer').outerHeight()
            })
        }


    }


    initialize() { //레슨 수강 기록 조회.

        if(this.props.lessonType === 'typing') { //타이핑게임의 레슨 수강 기록 조회
            Promise.all([
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/class/typing/${this.props.lessonInfo.lesson_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}/${this.props.lessonInfo.attend_gb}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ])
                .then(([res]) => {
                    // //console.log("component will mount contents is:", res);
                    const resData = res.data;
                    //console.log("수강기록 resData:", resData);
                    if (resData.status_code === 0 || resData.status_code === "0") {
                        //console.log("첫 수강으로, class attend에 추가. API 호출.");

                        let lessonInfo = this.props.lessonInfo;
                        lessonInfo.attend_gb = 'N';
                        this.props.setTypingLessonInfo(lessonInfo); //attend_gb 업데이트


                        Promise.all([axios({
                                method: 'put',
                                url: `${this.props.apiUrl}/class/typing/${this.props.serverData.class_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/${this.props.lessonInfo.attend_gb}`.replace(/\s/g, ''),
                                data: {
                                    apiKey: apiKey,
                                }
                            }
                        )]).then(([res]) => {
                            // //console.log("수강 기록이 없어, 레슨 수강 등록");
                            // //console.log("res:", res);
                            // //console.log("res.data.recordset[0].attend_idx:", res.data.recordset[0].attend_idx);

                            //res에서 attend_idx가져와야함.
                            this.setState({
                                attend_idx: res.data.recordset[0].attend_idx,
                            })
                        }).catch(err => {
                            //console.log("에러 발견", err);
                        }).then(() => {
                            //에러 발견시, 콜백

                        });

                    } else if (resData.status_code === 1 || resData.status_code === "1") {
                        //console.log("학습중이던 수강기록이 있음");
                        // //console.log("이미 학습한 수강이므로 menu_no, attend_idx 가져옴")
                        // //console.log("menu_no :", resData.menu_no);
                        // //console.log("attend_idx :", resData.attend_idx);
                        let lessonInfo = this.props.lessonInfo;
                        lessonInfo.attend_gb = resData.attend_gb;
                        this.props.setTypingLessonInfo(lessonInfo); //attend_gb 업데이트

                        this.setState({
                            menu_no: resData.menu_no,
                            attend_idx: resData.attend_idx,
                        })

                        //학습중인 수강기록 없음(status='E') 하지만 재수강 (attend_gb = 'R')
                    } else if (resData.status_code === 2 || resData.status_code === '2') {
                        let lessonInfo = this.props.lessonInfo;
                        lessonInfo.attend_gb = 'R';
                        this.props.setTypingLessonInfo(lessonInfo); //attend_gb 업데이트


                        //R 수강기록 생성
                        Promise.all([axios({
                                method: 'put',
                                url: `${this.props.apiUrl}/class/typing/${this.props.serverData.class_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/R`.replace(/\s/g, ''),
                                data: {
                                    apiKey: apiKey,
                                }
                            }
                        )]).then(([res]) => {
                            this.setState({
                                attend_idx: res.data.recordset[0].attend_idx,
                            })
                        }).catch(err => {
                            //console.log("에러 발견", err);
                        }).then(() => {
                            //에러 발견시, 콜백

                        });
                    }
                })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {
                });

        } else { //일반 학습(스피킹) 레슨 수강 기록 조회
            const ptSteps = ['L9', 'L10', 'L11', 'L12'];

            if (!ptSteps.includes(this.props.lessonInfo.step_code.replace(/\s/g, '')) && this.props.lessonInfo.group_no === 3) { //추가학습의 경우 이니셜라이징
                //console.log("추가학습 기록 조회");

                Promise.all([
                    axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/class/al/${this.props.lessonInfo.lesson_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}/${this.props.lessonInfo.attend_gb}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    })
                ])
                    .then(([res]) => {
                        // //console.log("component will mount contents is:", res);
                        const resData = res.data;
                        //console.log("수강기록 resData:", resData);
                        if (resData.status_code === 0 || resData.status_code === "0") {
                            //console.log("첫 수강으로, class attend에 추가. API 호출.");

                            let lessonInfo = this.props.lessonInfo;
                            let initMenuNo = this.props.initMenuNo;
                            lessonInfo.attend_gb = 'N';
                            this.props.setLessonInfo(lessonInfo, initMenuNo); //attend_gb 업데이트

                            //N 수강기록 생성
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/class/al/${this.props.serverData.class_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/N`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res]) => {
                                // //console.log("수강 기록이 없어, 레슨 수강 등록");
                                // //console.log("res:", res);
                                // //console.log("res.data.recordset[0].attend_idx:", res.data.recordset[0].attend_idx);

                                //res에서 attend_idx가져와야함.
                                this.setState({
                                    attend_idx: res.data.recordset[0].attend_idx,
                                })
                            }).catch(err => {
                                //console.log("에러 발견", err);
                            }).then(() => {
                                //에러 발견시, 콜백

                            });

                            // 학습중이던 수강기록이 있음(status = 'S'). attend_gb는 쿼리기록에서 가져옴
                        } else if (resData.status_code === 1 || resData.status_code === "1") {
                            //console.log("학습중이던 수강기록이 있음");
                            // //console.log("이미 학습한 수강이므로 menu_no, attend_idx 가져옴")
                            // //console.log("menu_no :", resData.menu_no);
                            // //console.log("attend_idx :", resData.attend_idx);
                            let lessonInfo = this.props.lessonInfo;
                            let initMenuNo = this.props.initMenuNo;
                            lessonInfo.attend_gb = resData.attend_gb;
                            this.props.setLessonInfo(lessonInfo, initMenuNo); //attend_gb 업데이트

                            this.setState({
                                al_menu_no: resData.menu_no,
                                attend_idx: resData.attend_idx,
                            })

                            //학습중인 수강기록 없음(status='E') 하지만 재수강 (attend_gb = 'R')
                        } else if (resData.status_code === 2 || resData.status_code === '2') {
                            let lessonInfo = this.props.lessonInfo;
                            let initMenuNo = this.props.initMenuNo;
                            lessonInfo.attend_gb = 'R';
                            this.props.setLessonInfo(lessonInfo, initMenuNo); //attend_gb 업데이트


                            //R 수강기록 생성
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/class/al/${this.props.serverData.class_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/R`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res]) => {
                                //console.log("수강 기록이 없어, 레슨 수강 등록");
                                //console.log("res:", res);
                                //console.log("res.data.recordset[0].attend_idx:", res.data.recordset[0].attend_idx);

                                //res에서 attend_idx가져와야함.
                                this.setState({
                                    attend_idx: res.data.recordset[0].attend_idx,
                                })
                            }).catch(err => {
                                //console.log("에러 발견", err);
                            }).then(() => {
                                //에러 발견시, 콜백

                            });
                        }
                    })
                    .catch(function (err) {
                        //console.log(err);
                    })
                    .then(function () {
                    });

            } else {
                // //console.log("본학습/빠른학습 기록 조회");

                Promise.all([
                    axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/class/${this.props.lessonInfo.lesson_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}/${this.props.lessonInfo.attend_gb}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    })
                ])
                    .then(([res]) => {
                        // //console.log("component will mount contents is:", res);
                        const resData = res.data;
                        //console.log("수강기록 resData:", resData);
                        if (resData.status_code === 0 || resData.status_code === "0") {
                            //console.log("첫 수강으로, class attend에 추가. API 호출.");
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/class/${this.props.serverData.class_idx}/${this.props.serverData.mem_idx}/${this.props.serverData.step_code}/${this.props.lessonInfo.lesson_no}/${this.props.lessonInfo.lesson_idx}/${this.props.lessonInfo.attend_gb}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res]) => {
                                // //console.log("수강 기록이 없어, 레슨 수강 등록");
                                // //console.log("res:", res);
                                // //console.log("res.data.recordset[0].attend_idx:", res.data.recordset[0].attend_idx);

                                //res에서 attend_idx가져와야함.
                                this.setState({
                                    attend_idx: res.data.recordset[0].attend_idx,
                                })
                            }).catch(err => {
                                //console.log("에러 발견", err);
                            }).then(() => {
                                //에러 발견시, 콜백

                            });

                        } else {
                            // //console.log("이미 학습한 수강이므로 menu_no, attend_idx 가져옴")
                            //console.log("menu_no :", resData.menu_no);
                            // //console.log("attend_idx :", resData.attend_idx);
                            this.setState({
                                menu_no: resData.menu_no,
                                attend_idx: resData.attend_idx,
                            })
                        }
                    })
                    .catch(function (err) {
                        //console.log(err);
                    })
                    .then(function () {
                    });
            }
        }

    };


    _initToggleMenu() {
        $('[data-section="lecture"]').toggleClass('menu-opened');
        if (isMobile && $(window).width() < 1200) {
            if ($('[data-section="lecture"]').hasClass('menu-opened')) {
                let mask = $('<div>').attr('data-mask', 'opened').css({
                    'position': 'fixed',
                    'top': 0,
                    'left': 0,
                    'width': '100%',
                    'height': '100%',
                    '-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)',
                    'filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)',
                    'opacity': 0.5,
                    'background': '#4a4a4a',
                    '-moz-opacity': 0.5,
                    'z-index': 1000,
                    'display': 'none'
                }).click(() => {
                    this._initToggleMenu()
                });
                $('body').append(mask);
                mask.fadeTo(300, 0.8);
            }
            else {
                $('[data-mask]').fadeOut(300, function () {
                    $('[data-mask]').remove();
                })
            }
        }

    }

    _initNavToggle() {
        let $pr = $(this).parent();
        if ($pr.hasClass('closed')) {
            $pr.find('.sub').slideDown('fast', function () {
                $pr.removeClass('closed');
            });
        }
        else {
            ////console.log("clicked!");
            $pr.find('.sub').slideUp('fast', function () {
                $pr.addClass('closed');
            });
        }
    }

    _initTipToggle() {
        $('.view-body', $('[data-section="lecture"]')).toggleClass('tip-opened');
    }

    _openTip() {
        $('.view-body', $('[data-section="lecture"]')).addClass('tip-opened');
    }

    _closeTip() {
        $('.view-body', $('[data-section="lecture"]')).removeClass('tip-opened');
    }

    _setControlPanel(bool = false) {
        this.setState({
            isControlPanelOpened: bool,
        })
    }

    _setResult = (result) => {
        this.setState({
            result: result,
        })
    };

    _setMaxPage = (maxPage) => {
        this.setState({
            maxPage: maxPage
        })
    };


    _myJS() {
        let Lect = {
            $container: $('[data-section="lecture"]'),
            init: function () {
                Lect.$container = $('[data-section="lecture"]');
                this.resizeViewBody();
                //this.initProgress();
            },

            resizeViewBody: function () {
                $('body').css('padding-top', 0);

                let $body = $('.view-body', Lect.$container);
                $(window).resize(function () {
                    $body.css('height', 800);
                    let wh = $(window).height(),
                        exH;
                    if ($('.view-container .view-footer').outerHeight() === undefined) {
                        exH = $('.view-container .view-header').outerHeight()
                    }
                    else {
                        exH = $('.view-container .view-header').outerHeight() + $('.view-container .view-footer').outerHeight();
                    }
                    $body.css('height', wh - exH);
                    $('.content-wrap', $body).css('height', wh - exH);
                }).resize();
            },
            initProgress: function () {
                let $progress = $('.right-menu .progress').clone();
                if ($('.view-header').find('.progress').length === 0) {
                    $('.view-header').prepend($progress);
                }


            },

        };

        (function ($) {
            $.fn.wPopLayer = function (opts) {
                return this.each(function () {
                    let $popup = $(this);
                    let mask = $('<div>').attr('data-mask', 'opened').css({
                        'position': 'fixed',
                        'top': 0,
                        'left': 0,
                        'width': '100%',
                        'height': '100%',
                        '-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)',
                        'filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)',
                        'opacity': 0.5,
                        'background': '#4a4a4a',
                        '-moz-opacity': 0.5,
                        'z-index': 1000,
                        'display': 'none'
                    });

                    $('[data-toggle="pop-layer"]').hide();

                    $('body').css('overflow-y', 'hidden').append(mask);
                    $('[data-mask]').fadeTo('fast', 0.8);

                    $popup.css({'z-index': 1002}).fadeIn('fast');


                    $popup.find('[data-button="pop-close"]').off('click.poplayer_close').on('click.poplayer_close', function () {
                        // 배경 마스크 fadeOut 감춘뒤 삭제
                        $popup.fadeOut('fast');

                        mask.fadeOut('300', function () {
                            mask.remove();
                            $("body").css({
                                'overflow-y': 'auto'
                            });
                        });
                    });
                });
            }
        })($);


        $(function () {
            Lect.init();

        });


    }


    render() {
        // //console.log("Container render is called!");
        ////console.log("In View Container, max Page is: ",this.state.maxPage);
        // //console.log("menuInfo is:", this.props.menuInfo);

        return (
            <div id={'full'} className={"full"}>
                <div className={isMobile ? "view-section" : "view-section menu-opened"}
                     data-section="lecture">
                    <button type="button" className="btn-window-close"
                            onClick={() => this.props.handleCurrentPage(1)}>윈도우
                        닫기
                    </button>
                    <button type="button" className="btn-menu-close" data-button="menu-toggle"
                            onClick={this._initToggleMenu}>메뉴 닫기
                    </button>
                    <button type="button" className="btn-menu-open" data-button="menu-toggle"
                            onClick={this._initToggleMenu}>메뉴 열기
                    </button>
                    <article className="view-container">
                        <LessonHeader
                            userInfo={this.props.userInfo}
                            lessonInfo={this.props.lessonInfo}
                            serverData={this.props.serverData}
                            currentPercent={this.state.currentPercent}
                        />
                        <MainPanel
                            {...this.props}
                            lessonState={this.state.lessonState}
                            currentPage={this.state.currentPage}
                            setMaxPage={this._setMaxPage}
                            maxPage={this.state.maxPage}
                            currentMenu={this.state.active_menu_no}
                            initMenuNo={this.props.initMenuNo}
                            userInfo={this.props.userInfo}
                            menuInfo={this.props.menuInfo}
                            lessonInfo={this.props.lessonInfo}
                            initTipToggle={this._initTipToggle}
                            openTip={this._openTip}
                            closeTip={this._closeTip}
                            setControlPanel={this._setControlPanel}
                            handleCurrentMenu={this._handleCurrentMenu}
                            goNextMenu={this._goNextMenu}
                            result={this.state.result}
                            myJS={this._myJS}
                            next={this._next}
                            prev={this._prev}
                            setResult={this._setResult}
                            endLesson={this._endLesson}
                            apiUrl={this.props.apiUrl}
                            serverData={this.props.serverData}
                            attend_idx={this.state.attend_idx}
                            menu_no={this.state.menu_no}
                            headerHeight={this.state.headerHeight}
                            footerHeight={this.state.footerHeight}
                            bodyHeight={this.state.bodyHeight}
                            step123={this.state.step123}
                            illustRootURL={this.state.illustRootURL}
                            soundRootURL={this.state.soundRootURL}
                        />
                    </article>
                    <MenuList
                        {...this.props}
                        //isMenuToggled={this.state.isMenuToggled}
                        lessonInfo={this.props.lessonInfo}
                        setMenuInfo={this.props.setMenuInfo}
                        currentMenu={this.state.active_menu_no}
                        handleCurrentMenu={this._handleCurrentMenu}
                        initNavToggle={this._initNavToggle}
                        isPT={this.props.isPT}
                        apiUrl={this.props.apiUrl}
                        serverData={this.props.serverData}
                        menu_no={this.state.menu_no}
                        al_menu_no={this.state.al_menu_no}
                        initToggleMenu={this._initToggleMenu}
                        setCurrentPercent = {this._setCurrentPercent}

                    />

                </div>

            </div>
        );
    }


    _next() {
        //console.log("go Next");

        this.setState({
            currentPage: this.state.currentPage + 1,
        });
    }

    _prev() {
        //console.log("Go prev");
        if (this.state.currentPage === 1) {
            return;
        }
        this.setState({
            currentPage: this.state.currentPage - 1,
        });
    }


    //다음 메뉴로 이동하는 함수
    //만약 마지막 메뉴라면 수강종료처리함
    //PT가 있는 경우는 수강종료처리 X
    _goNextMenu = () => {

        //타이핑게임의 다음 메뉴 조회
        if(this.props.lessonType === 'typing') {
            Promise.all([axios({
                method: 'get',
                url: `${this.props.apiUrl}/class/menu/next/typing/${this.state.active_menu_no}`,
                data: {
                    apiKey: apiKey,
                }

            })]).then(([res]) => {
                if(res.data.recordset[0] == null) { //다음 메뉴가 없어서 수강완료 처리
                    //console.log("타이핑 수강 완료");

                    //타이핑 수강완료에 따른 별 지급.
                    //중복 지급 방지는 API에서 처리함.
                    // let mem_idx = this.props.serverData.mem_idx;
                    // let lesson_idx = this.props.lessonInfo.lesson_idx;
                    Promise.all([axios({
                            method: 'put',
                            url: `${this.props.apiUrl}/users/star/I/${this.state.starNum}/${this.props.serverData.mem_idx}/S/T/${this.props.lessonInfo.lesson_idx}/4`.replace(/\s/g, ''),
                            data: {
                                apiKey: apiKey,
                            }
                        }
                    )]).then(([res3]) => {
                        //수강완료 처리 성공.
                        this._handleCurrentMenu(999);
                    }).catch(err => {
                        //console.log("에러 발견", err);
                        //메뉴 넘버 업데이트 실패
                    }).then(() => {
                        //에러 발견시, 콜백
                    });
                    this._handleCurrentMenu(999);

                }else if (res.data.recordset[0].menu_no > this.state.menu_no) { //다음 메뉴 넘버가 더 크면, UPDATE
                    //무료체험
                    if(this.props.serverData.auth_type === "F") {

                        //수강기록 하지 않고 active_menu_no만 업데이트
                        this.setState({
                            active_menu_no: res.data.recordset[0].menu_no,
                            currentPage: 1,
                        });

                        //관리자모드
                    } else if(this.props.serverData.auth_type === "A"){

                        //수강기록 하지 않고 active_menu_no만 업데이트
                        this.setState({
                            active_menu_no: res.data.recordset[0].menu_no,
                            currentPage: 1,
                        });
                        //일반
                    } else {
                        Promise.all([axios({
                                method: 'put',
                                url: `${this.props.apiUrl}/class/menu/typing/${this.state.attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                                data: {
                                    apiKey: apiKey,
                                }
                            }
                        )]).then(([res3]) => {
                            //메뉴번호 업데이트 성공
                            this.setState({
                                active_menu_no: res.data.recordset[0].menu_no,
                                menu_no:res.data.recordset[0].menu_no,
                                currentPage: 1,
                            })

                        }).catch(err => {
                            //console.log("에러 발견", err);
                            //메뉴 넘버 업데이트 실패
                        }).then(() => {
                            //에러 발견시, 콜백
                        });

                    }


                } else { //다음 메뉴 넘버가 더 작으면 state만 변경
                    this.setState({
                        active_menu_no: res.data.recordset[0].menu_no,
                        currentPage: 1,
                    });
                }



            }).catch(err => {
                //console.log("에러발생 :", err);
            }).then(() => {
               //에러 발생시 콜백
               //console.log("에러 발생 후 콜백 함수 호출");
            });

           // return;
        } else {
            const ptSteps = ['L9', 'L10', 'L11', 'L12'];


            if (!ptSteps.includes(this.props.lessonInfo.step_code.replace(/\s/g, '')) && this.props.lessonInfo.group_no === 3) { //응용학습의 경우 다음 메뉴 조회
                //다음 메뉴 조회
                Promise.all([axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/class/menu/next/${this.state.active_menu_no}/${this.props.serverData.step_code}/${this.props.lessonInfo.group_no}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    }
                )]).then(([res]) => {
                    //다음 메뉴 넘버 조회 성공.
                    //let nextMenuNo = res.data.recordset[0].menu_no;
                    //console.log("res.data.recordset[0] = ", res.data.recordset[0]);
                    if (res.data.recordset[0] === null || res.data.recordset[0] === undefined) { //다음 메뉴가 없을 경우 수강완료 처리
                        if(this.props.serverData.auth_type === "A"){ //관리자모드일 경우 그냥 레슨 화면으로 이동
                            this._handleCurrentMenu(999);

                        } else if (this.props.serverData.auth_type === "F"){//무료체험의 경우 수강완료처리
                            //console.log("무료체험 완료.");
                            //this._handleCurrentMenu(999);
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/free/lesson/end/${this.state.attend_idx}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res3]) => {
                                //수강완료 처리 성공.
                                //std_lesson 변경 안함.
                                this._handleCurrentMenu(999);
                            }).catch(err => {
                                //console.log("에러 발견", err);
                                //메뉴 넘버 업데이트 실패
                            }).then(() => {
                                //에러 발견시, 콜백
                            });
                        } else {
                            //console.log("추가학습 완료.");
                            //this._handleCurrentMenu(999);
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/class/lesson/end/al/${this.state.attend_idx}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res3]) => {
                                //수강완료 처리 성공.
                                //std_lesson 변경 안함.

                                //만약 학생이 신규 수강인 경우 수강완료에 따른 보상 지급
                                //router.put('/star/:in_out/:star_num/:mem_idx/:star_gubun/:gubun/:lesson_idx/:menu_no', (req, res, next) => {
                                let mem_idx = this.props.serverData.mem_idx;
                                let lesson_idx = this.props.lessonInfo.lesson_idx;
                                if(this.props.lessonInfo.attend_gb === "N"){
                                    Promise.all([axios({
                                            method: 'put',
                                            url: `${this.props.apiUrl}/users/star/I/${this.state.starNum}/${mem_idx}/S/E/${lesson_idx}/60`.replace(/\s/g, ''),
                                            data: {
                                                apiKey: apiKey,
                                            }
                                        }
                                    )]).then(([res4]) => {
                                       //별지급 성공
                                        //console.log("별지급완료");
                                    }).catch(err => {
                                        //console.log("에러 발견", err);
                                        //메뉴 넘버 업데이트 실패
                                    }).then(() => {
                                        //에러 발견시, 콜백
                                    });
                                }

                                this._handleCurrentMenu(999);
                            }).catch(err => {
                                //console.log("에러 발견", err);
                                //메뉴 넘버 업데이트 실패
                            }).then(() => {
                                //에러 발견시, 콜백
                            });
                        }



                    } else if (res.data.recordset[0].menu_no > this.state.al_menu_no) { //다음 메뉴 넘버가 더 크면, UPDATE
                        //관리자모드
                        if(this.props.serverData.auth_type === "A"){
                            //관리자 모드일 경우, 메뉴번호 업데이트 안함.
                            if (this.props.lessonInfo.step_code.replace(/\s/g, '') === "L8" && parseInt(this.props.lessonInfo.lesson_no) >= 33 && this.state.active_menu_no === 32) //2단계 중급 33과부터 예외처리함 32번 메뉴에서 41번으로 가지 않고 51번으로 이동
                            {
                                this.setState({
                                    active_menu_no: 51,
                                    al_menu_no: 51,
                                    currentPage: 1,
                                });
                            }
                            else {
                                this.setState({
                                    active_menu_no: res.data.recordset[0].menu_no,
                                    al_menu_no: res.data.recordset[0].menu_no,
                                    currentPage: 1,
                                });
                            }

                        //무료체험
                        } else if(this.props.serverData.auth_type === "F"){
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/free/menu/${this.state.attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res2]) => {
                                //메뉴 넘버 업데이트 성공.
                                //console.log("res2:", res2);
                                this.setState({
                                    active_menu_no: res.data.recordset[0].menu_no,
                                    al_menu_no: res.data.recordset[0].menu_no,
                                    currentPage: 1,
                                });
                            }).catch(err => {
                                //console.log("에러 발견", err);
                                //메뉴 넘버 업데이트 실패
                            }).then(() => {
                                //에러 발견시, 콜백

                            });
                        //일반 수강
                        }else {
                            if (this.props.lessonInfo.step_code.replace(/\s/g, '') === "L8" && parseInt(this.props.lessonInfo.lesson_no) >= 33 && this.state.active_menu_no === 32) //2단계 중급 33과부터 예외처리함 32번 메뉴에서 41번으로 가지 않고 51번으로 이동
                            {
                                Promise.all([axios({
                                        method: 'put',
                                        url: `${this.props.apiUrl}/class/menu/al/${this.state.attend_idx}/51`.replace(/\s/g, ''),
                                        data: {
                                            apiKey: apiKey,
                                        }
                                    }
                                )]).then(([res2]) => {
                                    //메뉴 넘버 업데이트 성공.
                                    //console.log("res2:", res2);
                                    this.setState({
                                        active_menu_no: 51,
                                        al_menu_no: 51,
                                        currentPage: 1,
                                    });
                                }).catch(err => {
                                    //console.log("에러 발견", err);
                                    //메뉴 넘버 업데이트 실패
                                }).then(() => {
                                    //에러 발견시, 콜백

                                });

                            } else {
                                Promise.all([axios({
                                        method: 'put',
                                        url: `${this.props.apiUrl}/class/menu/al/${this.state.attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                                        data: {
                                            apiKey: apiKey,
                                        }
                                    }
                                )]).then(([res2]) => {
                                    //메뉴 넘버 업데이트 성공.
                                    //console.log("res2:", res2);
                                    this.setState({
                                        active_menu_no: res.data.recordset[0].menu_no,
                                        al_menu_no: res.data.recordset[0].menu_no,
                                        currentPage: 1,
                                    });
                                }).catch(err => {
                                    //console.log("에러 발견", err);
                                    //메뉴 넘버 업데이트 실패
                                }).then(() => {
                                    //에러 발견시, 콜백

                                });
                            }
                        }

                    //다음 메뉴가 더 작으면 그냥 다음메뉴로 넘어감
                    } else {
                        if (this.props.lessonInfo.step_code.replace(/\s/g, '') === "L8" && parseInt(this.props.lessonInfo.lesson_no) >= 33 && this.state.active_menu_no === 32) //8단계 중급 33과부터 예외처리함 32번 메뉴에서 41번으로 가지 않고 51번으로 이동
                        {
                            //작으면 (즉, 메뉴를 다시 들은 경우) 그냥 다음 메뉴로 넘김
                            this.setState({
                                active_menu_no: 51,
                                currentPage: 1,
                            });

                        } else {
                            //작으면 (즉, 메뉴를 다시 들은 경우) 그냥 다음 메뉴로 넘김
                            this.setState({
                                active_menu_no: res.data.recordset[0].menu_no,
                                currentPage: 1,
                            });
                        }

                    }
                }).catch(err => {
                    //console.log("에러 발견", err);
                    //메뉴 넘버 업데이트 실패
                }).then(() => {
                    //에러 발견시, 콜백

                });

                //학습하기 및 빠른 학습하기의 경우 다음 메뉴 넘버 조회
            } else
            {
                //다음 메뉴 조회
                Promise.all([axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/class/menu/next/${this.state.active_menu_no}/${this.props.serverData.step_code}/${this.props.lessonInfo.group_no}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    }
                )]).then(([res]) => {
                    //다음 메뉴 넘버 조회 성공.
                    //let nextMenuNo = res.data.recordset[0].menu_no;
                    //console.log("res.data.recordset[0] = ", res.data.recordset[0]);
                    if (res.data.recordset[0] === null || res.data.recordset[0] === undefined) { //다음 메뉴가 없을 경우 수강완료 처리
                        //학습 등 수강완료 처리. std_lesson 변경

                        //하지만 PT가 있는 스텝인경우는 수강완료해도 수강완료 처리 안함. PT에서 수강완료처리함
                        const ptStepArr = ['L9', 'L10', 'L11', 'L12'];
                        if (ptStepArr.includes(this.props.lessonInfo.step_code.replace(/\s/g, '')) && this.props.lessonInfo.group_no !== 3) {

                            //현재 메뉴번호가 31보다 작으면 31로 넘김
                            if (this.state.menu_no < 31) {
                                Promise.all([axios({
                                        method: 'put',
                                        url: `${this.props.apiUrl}/class/menu/${this.state.attend_idx}/31`.replace(/\s/g, ''),
                                        data: {
                                            apiKey: apiKey,
                                        }
                                    }
                                )]).then(([res2]) => {
                                    //메뉴 넘버 업데이트 성공.
                                    //이제 학습완료 처리함 (EndLesson으로 이동)
                                    this._handleCurrentMenu(999);

                                }).catch(err => {
                                    //console.log("에러 발견", err);
                                    //메뉴 넘버 업데이트 실패
                                }).then(() => {
                                    //에러 발견시, 콜백

                                });
                            } else { //현재 메뉴번호가 31보다 크다면 그냥 학습완료 페이지로 이동
                                this._handleCurrentMenu(999);
                            }

                        } else {//PT가 없는 스텝인경우는 수강완료 처리
                            //무료체험
                            if(this.props.serverData.auth_type === "F") {
                                Promise.all([axios({
                                        method: 'put',
                                        url: `${this.props.apiUrl}/free/lesson/end/${this.state.attend_idx}`.replace(/\s/g, ''),
                                        data: {
                                            apiKey: apiKey,
                                        }
                                    }
                                )]).then(([res3]) => {
                                    //수강완료 처리 성공.
                                    //console.log("res:", res3);
                                    this._handleCurrentMenu(999);

                                }).catch(err => {
                                    //console.log("에러 발견", err);
                                    //메뉴 넘버 업데이트 실패
                                }).then(() => {
                                    //에러 발견시, 콜백

                                });

                            //관리자모드
                            } else if(this.props.serverData.auth_type === "A"){
                                //관리자 모드는 따로 수강 기록 조회하지 않음
                                this._handleCurrentMenu(999);
                            //일반
                            } else {
                                //console.log("다음메뉴가 조회되지 않아, 수강 완료 처리!");
                                Promise.all([axios({
                                        method: 'put',
                                        url: `${this.props.apiUrl}/class/lesson/end/${this.state.attend_idx}`.replace(/\s/g, ''),
                                        data: {
                                            apiKey: apiKey,
                                        }
                                    }
                                )]).then(([res3]) => {
                                    //수강완료 처리 성공.
                                    //console.log("res:", res3);
                                    //std_lesson update
                                    if (this.props.userInfo.std_lesson === this.props.lessonInfo.lesson_seq) {
                                        updateStdLesson(this.props.apiUrl, this.props.serverData.student_idx, this.props.userInfo.std_lesson + 1);
                                    }



                                    //프리젠테이션을 완료한 경우 별 지급
                                    if(ptStepArr.includes(this.props.lessonInfo.step_code.replace(/\s/g, '')) && this.props.lessonInfo.group_no === 3){
                                        let mem_idx = this.props.serverData.mem_idx;
                                        let lesson_idx = this.props.lessonInfo.lesson_idx;
                                        if(this.props.lessonInfo.attend_gb === "N"){
                                            Promise.all([axios({
                                                    method: 'put',
                                                    url: `${this.props.apiUrl}/users/star/I/${this.state.ptStarNum}/${mem_idx}/S/E/${lesson_idx}/60`.replace(/\s/g, ''),
                                                    data: {
                                                        apiKey: apiKey,
                                                    }
                                                }
                                            )]).then(([res4]) => {
                                                //별지급 성공
                                                //console.log("별지급완료");
                                            }).catch(err => {
                                                //console.log("에러 발견", err);
                                                //메뉴 넘버 업데이트 실패
                                            }).then(() => {
                                                //에러 발견시, 콜백
                                            });
                                        }
                                    }

                                    this._handleCurrentMenu(999);

                                }).catch(err => {
                                    //console.log("에러 발견", err);
                                    //메뉴 넘버 업데이트 실패
                                }).then(() => {
                                    //에러 발견시, 콜백

                                });
                            }

                        }


                    } else if (res.data.recordset[0].menu_no > this.state.menu_no) { //다음 메뉴 넘버가 더 크면, UPDATE
                        //무료체험
                        if(this.props.serverData.auth_type === "F") {
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/free/menu/${this.state.attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res2]) => {
                                //메뉴 넘버 업데이트 성공.
                                //console.log("res2:", res2);
                                this.setState({
                                    active_menu_no: res.data.recordset[0].menu_no,
                                    menu_no: res.data.recordset[0].menu_no,
                                    currentPage: 1,
                                });
                            }).catch(err => {
                                //console.log("에러 발견", err);
                                //메뉴 넘버 업데이트 실패
                            }).then(() => {
                                //에러 발견시, 콜백

                            });

                        //관리자모드
                        } else if(this.props.serverData.auth_type === "A"){

                            //menu_no  업데이트 하지 않고 active menu no 만 변경
                            this.setState({
                                active_menu_no: res.data.recordset[0].menu_no,
                                currentPage: 1,
                            });
                        //일반
                        } else {
                            Promise.all([axios({
                                    method: 'put',
                                    url: `${this.props.apiUrl}/class/menu/${this.state.attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                                    data: {
                                        apiKey: apiKey,
                                    }
                                }
                            )]).then(([res2]) => {
                                //메뉴 넘버 업데이트 성공.
                                //console.log("res2:", res2);
                                this.setState({
                                    active_menu_no: res.data.recordset[0].menu_no,
                                    menu_no: res.data.recordset[0].menu_no,
                                    currentPage: 1,
                                });
                            }).catch(err => {
                                //console.log("에러 발견", err);
                                //메뉴 넘버 업데이트 실패
                            }).then(() => {
                                //에러 발견시, 콜백

                            });
                        }


                    } else {
                        this.setState({
                            active_menu_no: res.data.recordset[0].menu_no,
                            currentPage: 1,
                        });
                    }
                }).catch(err => {
                    //console.log("에러 발견", err);
                    //메뉴 넘버 업데이트 실패
                }).then(() => {
                    //에러 발견시, 콜백

                });
            }
        }
    };

    _handleCurrentMenu = (menu_no) => {
        //console.log("메뉴 변경:", menu_no);
        //만약, 활성화된 메뉴를 직접 클릭한 경우는 그냥 메뉴 이동 시킴.
        this.setState({
            active_menu_no: menu_no,
            currentPage: 1,
        });
    };

    _endLesson = () => {
        //Upload record here
        alert("수강 완료!");
        //console.log("End Lesson.");
        this._updateUserInfo(); //std변경된 경우에 따라 userInfo 업데이트함.
        this.props.handleCurrentPage(1); //레슨 리스트 페이지:1

    }

    _updateUserInfo = () => {
        Promise.all([
            //axios.get(`${this.props.apiUrl}/users/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}`)
            axios({
                method: 'get',
                url: `${this.props.apiUrl}/users/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            })

        ])
            .then(([resUserInfo]) => {
                const userInfo = resUserInfo.data;
                //console.log("API에서 받아온 유저 정보:", userInfo);
                this.props.setUserInfo(userInfo);
            })
            .catch(function (err) {
                //console.log(err);
            })
            .then(function () {

            });
    }

    _setCurrentPercent = (currentPercent) => {
        this.setState({
            currentPercent
        })
    }

}

export default ViewContainer;
    
    