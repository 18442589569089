import React, {Component} from 'react';
import withRecognizerForMemorizeB from "./withRecognizerForMemorizeB";
import withRecognizerDialogue from "./withRecognizerDialogue";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";

class Footer_MDB extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
        };

    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.letStartRecognition && this.props.letStartRecognition) {
            this.props.startRecognition();
            if(this.props.serverData.user_kind === "K"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, this.props.contents[this.props.playingIndex].eng_content.length * 200)

            } else if(this.props.serverData.step_code === "L3"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, 1 + this.props.contents[this.props.playingIndex].eng_content.length * 110)
            }
        }
    }


    render() {

        if(this.props.showContinue) {
            return (
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.resetAudioContext();
                        }}> 테스트
                    </button>
                </section>
            )
        }

        if(!this.props.isPlaying && !this.props.listening && this.props.isTesting) {
            //대화가 넘어가는 틈 OR 녹음 도중 에러가 발생함.
            return (
                <section className="view-footer">
                    {this.props.err === null ?
                        <button type="button" className="f-btn">Empty</button>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                if(isIOS){
                                    this.props.fakeRecognitionStart();
                                    this.props.audioPlayMute("eng",this.props.testingIndex,
                                        () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
                                    );
                                } else {
                                    this.props.startRecognition();
                                    if(this.props.serverData.user_kind === "K"){
                                        // console.log(this.props.contents[this.props.testingIndex].eng_content.length * 80);
                                        if(this.props.testingIndex == null){
                                            // console.log(this.props.contents[this.props.startPracticePronunciation.index].eng_content.length * 80);
                                            setTimeout(() => {
                                                //console.log("timeout");
                                                this.props.endRecognition();
                                            }, this.props.contents[this.props.startPracticePronunciation.index].eng_content.length * 200)
                                        } else {
                                            setTimeout(() => {
                                                this.props.endRecognition();
                                            }, this.props.contents[this.props.testingIndex].eng_content.length * 200)
                                        }

                                    } else if(this.props.serverData.step_code === "L3"){
                                        if(this.props.testingIndex == null){
                                            // console.log(this.props.contents[this.props.startPracticePronunciation.index].eng_content.length * 80);
                                            setTimeout(() => {
                                                //console.log("timeout");
                                                this.props.endRecognition();
                                            }, 1 + this.props.contents[this.props.startPracticePronunciation.index].eng_content.length * 110)
                                        } else {
                                            setTimeout(() => {
                                                this.props.endRecognition();
                                            }, 1 + this.props.contents[this.props.testingIndex].eng_content.length * 110)
                                        }
                                    }
                                }

                                this.props.handleError(null);
                                this.props.closeTip();
                            }}> 테스트
                        </button>
                    }


                </section>
            )
        }

        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>
                {this.props.listening ?
                    <MicVisualizer/>
                    :
                    this.props.isPlaying ?
                        <button
                            type="button"
                            className="f-btn f-center btn-listen"
                        > 듣는중</button>
                        :
                        <div className="f-center-grouping">
                            <button
                                type="button"
                                className="f-btn f-center-multiple btn-listen-all"
                                onClick={() => this._readyTest()}>전체 듣기
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center-multiple btn-test"
                                onClick={() => {
                                    this.props.memorizeBTest(0);
                                }}> 테스트
                            </button>
                        </div>


                }

                {this.props.numOfPair <= this.props.currentPhase.index + 1?

                    this.props.currentPhase.pass && !this.props.isPlaying && !this.props.listening ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                this.props.goNextMenu()
                                //this._memorizeBFinalTest();
                            }}>다음 메뉴</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                    :
                    this.props.currentPhase.pass && !this.props.isPlaying && !this.props.listening ?
                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={() => {this.props.nextPhase()}}>다음페이즈</button>
                        :
                        <button type="button" className="f-btn">Empty</button>

                }

            </section>

        );
    }

    _readyTest = () => {
        // this.props.playTill();
        this.props.audioPlayRange(0, 0, (this.props.currentPhase.index * 2) + 1 )
        if(this.props.isReadyTest === false){
            this.props.toggleReadyTest();
        }
    };


    _memorizeBFinalTest = () => {
        this.props.memorizeBFinalTest();
    }

}

// export default withRecognizerForMemorizeB()(Footer_MDB);
export default withRecognizerDialogue()(Footer_MDB);
    
    