import React, {Component} from 'react';
import MicVisualizer from "../other/MicVisualizer";


class Footer_AMW extends Component {

    render() {

        return (
            <section className="view-footer">

                <button type="button" className="f-btn">empty</button>

                {this.props.isTesting ?
                    (
                        <MicVisualizer/>
                    )
                    :
                    (

                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this.props.startTest(true);
                                this.props.closeTip();
                            }}>
                        </button>
                    )
                }
                {
                    this.props.pass ?
                        <button type="button" className="f-btn btn-next-page" onClick={() => {
                            this.props.goNextMenu()
                        }}>다음메뉴</button>
                        :
                        <button type="button" className="f-btn">empty</button>

                }

            </section>

        );
    }

}

export default Footer_AMW;
    
    