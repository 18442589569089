import React, {Component} from 'react';
import {
    brTagActivator, calculateScore,
    getCurrentMenuInfo, isIOS, isMobile,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import Footer_KoreanEnglish_A from "../../Footer_KoreanEnglish_A";
import Footer_KoreanEnglish_B from "../../Footer_KoreanEnglish_B";
// import withContentsForKoreanEnglish from "../../withContentsForKoreanEnglish";
import withContents from "../../withContents";

import $ from "jquery";

class KoreanEnglish extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            phase:0, //phase == 1: 암기 단계, phase == 2: 한영전환 단계
            currentPage: 1,
            percentage: 0,
            letStartRecognition: false,
            isTesting: false,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            contents_a:[], //표현 콘텐츠
            contents_b:[], //스토리 콘텐츠
            count: 0,
            waiting: false,
            hasResult: false,
            result: {},
            err:null,
            errCount:0,
            checkPoint:1,
            isPlaying:false,
            letUpload:false,
            // audioURL: null,
            isUploadComplete: false,
            keIndex:0,
            pass:false,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if(nextProps.contents && prevState.contents_a.length === 0){
            let contents_a = [];
            let contents_b = [];

            for(let i = 0; i < nextProps.contents.length; i++){
                if(nextProps.contents[i].c_code_no === "1"){
                    contents_a.push(nextProps.contents[i]);
                } else {
                    contents_b.push(nextProps.contents[i]);
                }
            }

            return({
                contents_a,
                contents_b,
            })
        }

        if (nextProps.currentPage !== prevState.currentPage) {  //페이지 변경시

            return ({
                currentPage: nextProps.currentPage,
                checkPoint:nextProps.currentPage >= prevState.checkPoint ? nextProps.currentPage : prevState.checkPoint,
                percentage: 0,
                letStartRecognition: false,
                isTesting: false,
                wordInfo: { //update word info
                    num: nextProps.currentPage - 1,
                    getResult: false,
                },
                pass:false,
                waiting:false,

            })
        }
        return null;
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if (this.state.phase === 1) {

            return (
                this._renderMain()
            )

        }

        if( this.state.phase === 2){

            return(
                this._renderMain2()
            )
        }



    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
            phase: 1
        })
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="top-tip">다음 표현을 여러번 반복하여 말하면서 외우세요.</div>

                        <ul className="word-list voice-box">
                            <li>
                                <div
                                    className="word">{this.state.contents_a[this.state.currentPage - 1].eng_content}</div>
                                <div className="txt">{this.state.contents_a[this.state.currentPage - 1].kor_content}</div>
                                <span className="btn-wrap">
                                <button
                                    type="button"
                                    className="btn-play-sound"
                                    onClick={() => this._audioPlayWithURL(this.state.contents_a[this.state.currentPage - 1].eng_url)}
                                >
                                    발음듣기
                                </button>
                            </span>
                            </li>
                        </ul>
                        <div className="progress-bar">
                            <div className={`progress-value v${this.state.percentage}`}>{this.state.percentage}%</div>
                        </div>

                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span>/<span
                        className="total">{this.state.contents_a.length}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        다음 표현을 여러번<strong> 반복 하여 말하면서</strong> 외우세요.<br/>
                                        <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                        <div className="divider"/>
                                        <div className="tip">
                                            <strong>정확 하게 </strong>발음 하면 %가 더 <strong>빠르게</strong> 올라갑니다.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_KoreanEnglish_A
                    {...this.props}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    isTesting={this.state.isTesting}
                    startTest={this._startTest}
                    setResult={this._setResult}
                    wordInfo={this.state.wordInfo}
                    currentPage={this.state.currentPage}
                    letStartRecognition={this.state.letStartRecognition}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    waiting={this.state.waiting}
                    checkPoint = {this.state.checkPoint}
                    contents_a = {this.state.contents_a}
                    contents_b = {this.state.contents_b}
                    handlePhase = {this._handlePhase}
                />

            </>

        );
    };

    _renderMain2 = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        <div className="top-tip">다음 스토리를 읽고 한영전환을 연습하세요.</div>
                        <div
                            className={this.state.hasResult ? "story-practice-header opened" : "story-practice-header"}>
                            <button type="button" className="btn btn-meaning" onClick={() => this._showComprehension()}>해석 보기
                            </button>
                        </div>

                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={()=>{this._playRecordedFile()}}>녹음듣기</button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={()=>{this._uploadRecordedFile()}}>녹음제출</button>
                                </li>
                            </ul>
                            :
                            !this.state.isTesting ?
                                <div className="StoryContainer">
                                    <p className="larger">
                                        {this._renderStory()}
                                    </p>
                                </div>
                                :
                                <p className="direct-translation type1">
                                    {this.state.contents_b[this.state.keIndex].kor_content}
                                </p>
                                // this.props.playingIndex % 2 === 0 ?
                                //     <p className="direct-translation type1">
                                //         {this.state.contents_b[this.props.playingIndex].eng_content}
                                //     </p>
                                //     :
                                //     <p className="direct-translation type2">한국어로 뜻을 말하세요.</p>

                        }

                    </div>


                    {!this.state.hasResult && this.state.isTesting ?
                        <div className="lecture-step">
                            <span className="current">{this.state.keIndex + 1}</span> /
                            <span className="total">{this.state.contents_b.length}</span>
                        </div>
                        :
                        ""
                    }
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>테스트 버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            앞서 배운 표현들을 활용해 <strong>한영전환</strong>합니다.<br/>
                                            스토리를 잘 보고 한영전환을 충분히 연습 하신 후 <br />
                                            테스트버튼을 눌러 <strong>영어로 바꾸어 말하세요.</strong>
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>
                    {this._renderComprehension()}

                </section>
                <Footer_KoreanEnglish_B
                    {...this.props}
                    c_code={this.props.c_code}
                    startTestB = {this._startTestB}
                    isTesting={this.state.isTesting}
                    isPlaying = {this.state.isPlaying}
                    playRecordedFile = {this._playRecordedFile}
                    stopRecordedFile = {this._stopRecordedFile}
                    hasResult = {this.state.hasResult}
                    checkResult = {this._checkResult}
                    audioUploadComplete = {this._audioUploadComplete}
                    letUpload = {this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    audioStop={this.props.audioStop}
                />
            </>
        )
    };

    _renderStory = () => {
        let story = [];
        for(let i = 0 ; i < this.state.contents_b.length; i++) {
            story.push(<span key={i}>
                {this.state.contents_b[i].kor_content} <br />
            </span>)
        }
        return (
            story
        );
    };

    _audioPlayWithURL = (url) => {
        if (this.props.isPlaying) {
            //this.props.audioStop();
            return;
        }
        //this.props.audioPlayWithURL(url);


        //let audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`;
        let src = `${this.props.contents[0].step_code}/${this.props.contents[0].lesson_no}/${url}`;
        this.props.setHowler(
            src
        );

    };



    _handleLetStartRecognition = (bool) => {

        this.setState({
            letStartRecognition: bool,
        })

    };

    _handlePhase = (phase) => {
        this.props.openTip();
        this.setState({
            phase:phase,
        })
    }

    _startTest = () => {
        this.props.closeTip();

        this.setState({
            isTesting: true,
            letStartRecognition:true,

        })

    }

    _setResult = (result, index) => {
        if(this.state.phase === 1){ //암기 단계에서 사용하는 setResult
            let thisPercentage = calculateScore(result, this.state.contents_a[index].eng_content);
            if(isIOS){
                thisPercentage = 25;
            } else if (thisPercentage < 50) {
                thisPercentage = 20;
            } else if (thisPercentage >= 50 && thisPercentage < 70) {
                thisPercentage = 25;
            } else if (thisPercentage >= 70 && thisPercentage < 95) {
                thisPercentage = 30;
            } else if (thisPercentage >= 95) {
                thisPercentage = 35;
            } else {
                //console.log("점수가 잘못 계산되었습니다.");
            }

            if(this.state.percentage + thisPercentage >= 100){ //100프로 이상이어야 통과
                this.setState({
                    result: {
                        ...this.state.result,
                        [index]: result,
                    },
                    count: this.state.count + 1,
                    err: null,
                    waiting: false,
                    percentage: 100,
                    errCount: 0,
                    pass:true,
                    isTesting:false,
                });

                //결과 값 업데이트 워드 인포
                this._updateWordInfo(index, true);

            } else {

                this.setState({
                    result: {
                        ...this.state.result,
                        [index]: result,
                    },
                    count: this.state.count + 1,
                    err: null,
                    waiting: true,
                    percentage: this.state.percentage + thisPercentage,
                    errCount: 0,
                });

                //결과 값 업데이트 워드 인포
                this._updateWordInfo(index, true);


                //100퍼센트 못채운거에 따른 재시도
                setTimeout(() => {
                    this.setState({
                        waiting: false,
                    });
                    if (this.state.isTesting) {
                        //console.log("wordInfo 업데이트!");
                        //this._updateWordInfo(this.state.wordInfo.num + 1, false);
                        this._handleLetStartRecognition(true);
                    }
                }, 500);

            }

        } else if(this.state.phase === 2){ //직독직해 단계에서 사용하는 setResult



        }

    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count:0,
            isTesting:false,
            hasResult: true,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            waiting:false,
        });

        //console.log("the recorded results are:", this.state.result);
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }



        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };


    _audioUploadComplete = () => {
        this.setState({
            isUploadComplete:true,
        })
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ");
        //this.props.upload();
        this.setState({
            letUpload:true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this._endLesson();
    };


    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult:bool,
        })
    };

    _startTestB = (bool) => {
        if(bool === true){
            // this.props.audioPlayAll();
            this._startKe(0);
        }
        this.setState({
            isTesting:bool,
        })
    };

    //한영전환
    _startKe = (index) => {

        if(index >= this.state.contents_b.length) {
            this._startTestB(false); //테스트 종료
            return;
        }

        this.setState({
            keIndex:index,
            isWaitSpeak: false,
        });

        let duration = 2000 + this.state.contents_b[index].eng_content.length * 120;
        //console.log(duration);
        setTimeout(() => {
           this._startKe(index + 1);
        }, duration)
    }

    _showComprehension = () => {
        // $('.common-popup').fadeToggle('fast');
        $('.speak-poplayer').wPopLayer();
    };


    _renderComprehension = () => {
        // //console.log(this.props.lessonInfo);
        let answers = [];

        for(let i = 0 ; i < this.state.contents_b.length; i ++){
            answers.push(
                <li key = {i}>
                    <p className={"l5-comprehension"}>
                        {this.state.contents_b[i].kor_content.replace(/[<>]/g,"")}<br />
                        <span>{this.state.contents_b[i].eng_content.replace(/[<>]/g,"")}</span>
                    </p>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>해석 보기</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    };
}


// export default withContentsForKoreanEnglish()(KoreanEnglish);
export default withContents()(KoreanEnglish);
    
    