import React, {Component} from 'react';

import withRecognizerPST from "./withRecognizerPST";
import MicVisualizer from "../other/MicVisualizer";
import {isMobile} from "../../asset/js/myFunctions";

/**
 * Practice For Storytelling 모바일에서 STT기능 사용 X
 */


class Footer_PST extends Component {

    componentDidUpdate(prevProps, prevState) {
        if (this.props.listening !== prevProps.listening) {
            if (this.props.setListening !== null && this.props.setListening !== undefined) {
                this.props.setListening(this.props.listening);
            }

        }
    }

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">
                {
                    !this.props.isTesting ?

                        this.props.currentPage === 1 ?

                            <button type="button" className="f-btn">Empty</button>
                            :
                            <button type="button" className="f-btn btn-prev"
                                    onClick={() => this.props.prev()}>이전</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }
                {
                    this.props.isTesting ? (
                            <MicVisualizer/>
                        )
                        :
                        (
                            <div className="recording-controller">
                                <div className="timer-wrap">
                                    <div className="timer-value">
                                        {
                                            this.props.score === null
                                                ?
                                                ""
                                                :
                                                isMobile ?
                                                    "A"
                                                    :
                                                    this.props.score
                                        }
                                    </div>
                                    <h5>SCORE</h5>
                                </div>

                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this._Start();
                                        // this.props.startRecognition();
                                        // this.props.checkTesting(true);
                                        // this.props.audioPlayPart(this.props.currentIndexes, () => {
                                        //     setTimeout(() => {
                                        //         this.props.endRecognition();
                                        //         this.props.checkTesting(false);
                                        //     }, 1000);
                                        // });
                                        // this.props.closeTip();

                                    }}>
                                </button>
                            </div>
                        )
                }
                {
                    !this.props.isTesting ?
                        this.props.pass ?
                            this.props.butRealMaxPage === this.props.currentPage ?
                                <button type="button" className="f-btn btn-next-page" onClick={() => {
                                    this.props.goNextMenu()
                                }}>다음메뉴</button>
                                :
                                <button type="button" className="f-btn btn-next" onClick={() => {
                                    this.props.next()
                                }}>다음</button>
                            :
                            <button type="button" className="f-btn">Empty</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }

            </section>

        );
    }

    _Start = () => {
        if (isMobile) {
            this.props.fakeStartRecognition();
            this.props.checkTesting(true);
            //this.props.audioPlayPart(this.props.currentIndexes,)
            this.props.audioPlayList(this.props.currentIndexes, 0, () => {
                setTimeout(() => {
                   this.props.fakeEndRecognition();
                    this.props.checkTesting(false);
                }, 1000);
            });
            this.props.closeTip();
        } else {
            this.props.startRecognition();
            this.props.checkTesting(true);
            this.props.audioPlayList(this.props.currentIndexes, 0, () => {
                setTimeout(() => {
                    this.props.endRecognition();
                    this.props.checkTesting(false);
                }, 1000);
            });
            this.props.closeTip();
        }
    }


}

export default withRecognizerPST()(Footer_PST);
    
    