import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, calculateScore, FailScore, getCurrentMenuInfo, isIOS} from "../../../../asset/js/myFunctions";
import Footer_SP1 from "../../Footer_SP1";
import Score from "../../Score";


class SpeakingPractice1 extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 90,
            maxPage:0,
            isStart: false,
            count: 0,
            currentPage: 1,
            hasResult: false,
            result:"",
            isReadyToRecognize: 0, //0:not ready,  1:playing, 2:ready,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            err:null,
            errCount:0,
            pass:false,
            tryCount:0,
        }


    }


    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        // //console.log("getDerived~ 's nextProps and PrevState is:",nextProps.wordInfo.num+" and "+ prevState.currentWordInfo.num);

        if(nextProps.contents !== null && prevState.maxPage === 0){
            nextProps.setMaxPage(nextProps.contents.length);
            return({
                maxPage:nextProps.contents.length,
            })
        }

        if(nextProps.isPlaying === true && prevState.isReadyToRecognize === 0){
            return({
                isReadyToRecognize:1,
            })
        }

        if(nextProps.isPlaying === false && prevState.isReadyToRecognize === 1){
            return ({
                isReadyToRecognize:2,
            })
        }

        if(nextProps.currentPage !== prevState.currentPage){ //페이지 변경 시
            nextProps.closeTip();
            return ({
                index:nextProps.currentPage - 1,
                isReadyToRecognize: 0,
                currentPage:nextProps.currentPage,
                hasResult: false,
                result:"",
                pass:false,
                err:null,
                errCount:0,
                tryCount:0,
            })
        }

        if(nextProps.currentMenu !== prevState.thisMenuNumber){ //초기화
            nextProps.audioStop();

            return({
                index: 0,
                thisMenuNumber: nextProps.currentMenu,
                maxPage:0,
                isStart: false,
                count: 0,
                currentPage: 1,
                hasResult: false,
                result:"",
                isReadyToRecognize: 0, //0:not ready,  1:playing, 2:ready,
                wordInfo:{
                    num: 0,
                    getResult: false,
                },
                err:null,
                errCount:0,
                pass:false,
                tryCount:0,
            })
        }

        return null;

    }




    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }


        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        <div className="content-wrap">

                            <div className="sound-box">
                                <img src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`} className="sound-deco-img" alt="이미지" />
                                    <div className="sound-box-text sp1">
                                        {
                                            isIOS ?
                                                this.state.result ==="" ?
                                                    ""
                                                    :
                                                    this.props.contents[this.state.index].eng_content
                                                :
                                                <div className={"abc-score"}>
                                                    <Score result={this.state.result}
                                                           scoreType={1}
                                                           original={this.props.contents[this.state.index].eng_content}/>
                                                </div>
                                        }
                                    </div>
                            </div>

                        </div>

                        <div className="lecture-step">
                            <span className="current">{this.state.currentPage}</span>/<span className="total">{this.state.maxPage}</span>
                        </div>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        먼저 원어민 소리를 잘 듣고 <br />"삐" 소리 후 영어로 따라 하세요.
                                        <hr className="divider" />
                                        <div className="tip">녹음이 도중에 멈추면 빠르게 말해<br />주세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_SP1
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    handleError={this._handleError}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    // audioPlay={this.props.audioPlay}
                    audioPlay={this.audioPlay}
                    setHowlerMute={this.props.setHowlerMute}
                    isPlaying={this.props.isPlaying}
                    isReadyToRecognize={this.state.isReadyToRecognize}
                    pass={this.state.pass}
                />


            </>
        )

    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _setResult = (result) => {
        if(result === null){
            this.setState({
                result:null,
            });
            return;
        }



        this.setState({
            result: result,
            hasResult: true,
            isReadyToRecognize: 0,
            err:null,

        });

        let score = isIOS ? 0 : calculateScore(result,this.props.contents[this.state.index].eng_content);
        let pass = score >= FailScore || isIOS;

        this._decidePass(pass);

    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        this.props.openTip();
        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
                hasResult: true,
                isReadyToRecognize: 0,
            });

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            isReadyToRecognize:0,
        })

    };





}

export default withContents()(SpeakingPractice1);