import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_POP from '../../Footer_POP';
import Score from '../../Score';
import {TweenLite} from 'gsap/TweenLite';
import LoadingBar from "../../../other/LoadingBar";
import {brTagActivator, calculateScore, FailScore, getCurrentMenuInfo} from "../../../../asset/js/myFunctions";


class PracticePronunciation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            // index: 0,
            maxPage: 0,
            thisMenuNumber: 65,
            isAutoPlayed: false,
            result: null,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            err: null,
            pass: false,
            errCount: 0,
            tryCount: 0,
            // contentIdx:0,

        };


    }

    componentDidMount() {

    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.contents !== null && nextProps.contents.length !== 0) {
            if (prevState.maxPage === 0) {
                nextProps.setMaxPage(nextProps.contents.length);
                return {
                    index: 0,
                    maxPage: nextProps.contents.length,
                }
            }
            if (nextProps.currentPage !== prevState.index + 1) { //페이지가 변경된다면,
                nextProps.closeTip();
                return {
                    index:nextProps.currentPage - 1,
                    result: null,
                    // maxPage: nextProps.contents.length,
                    err: null,
                    pass: false,
                    errCount: 0,
                    tryCount: 0,
                }
            }

        }
        return null;
    }


    componentDidUpdate(prevProps, prevState) {
        // this.props.myJS();

        if (!prevState.isStart && this.state.isStart) { //스타트 누르면 첫번째 단어 음원 플레이
            //console.log("학습 시작!");
            TweenLite.to(".voice-result-box", 1, {
                y: 0,
                delay: 0,
                yPercent: -50,
                opacity: 1
            });
            if(this.props.contents){
                this.audioPlay(0);
            }
        } else if(this.props.currentPage !== prevProps.currentPage) { //페이지 변경 시
            if(this.props.contents){
                this.audioPlay(this.state.index);
            }
        }


    }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    render() {
        if (this.props.contents === null || this.props.menuInfo === null) {

            return (<LoadingBar type={1}/>);

        }
        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }
        ////console.log("PP Contents is :", this.props.contents);
        return (
            this._renderMain()
        )
    }


    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        <ul className="word-list voice-box">
                            <li>
                                <div className="word">{this.props.contents[this.state.index].eng_content}</div>
                                <div className="txt">{this.props.contents[this.state.index].kor_content}</div>
                                <span className="btn-wrap">
                                <button className="btn-play-sound CursorPointer"
                                        onClick={
                                            // () => this._playAudio()
                                            () => {
                                                this.audioPlay(this.state.index)
                                            }
                                        }>
                                    발음듣기
                                </button>
                            </span>
                            </li>
                        </ul>

                        <div className="voice-result-box">
                            {/*<p><span className="orange">A+</span> 또는 <span className="gray">is</span></p>*/}
                            <p>
                                {
                                    //Practice Pronunciation ABC로 출력
                                    <span className="orange">
                                        <Score
                                            step_code={this.props.serverData.step_code}
                                            scoreType={1}
                                            result={this.state.result}
                                            original={this.props.contents[this.state.index].eng_content}/>
                                    </span>
                                }
                            </p>
                        </div>
                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.index + 1}</span>/<span
                        className="total">{this.state.maxPage}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <div className="tip">발음을 잘 듣고, 충분히 연습 후 <br/><strong>테스트</strong>를 눌러 발음하세요.</div>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_POP
                    {...this.props}
                    c_code={this.props.c_code}
                    setResult={this._setResult}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    result={this.state.result}
                    pass={this.state.pass}
                />
            </>
        )
    };


    _renderIntro = () => {
        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu))}</div>*/}
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type={"button"} className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };


    _start = () => {
        this.setState({isStart: true})
    };

    _setResult = (result) => {
        if (result === null) {
            this.setState({
                result: null,
            });
            return;
        }


        this.setState({
            result: result,
            // hasResult: true,
            isReadyToRecognize: 0,
            err: null,
        });

        //iOS기능 추가시
        // if(result === "iOSResult"){
        //     this._decidePass(true);
        // } else {
        //     let score = calculateScore(result, this.props.contents[this.state.index].eng_content);
        //     let pass = score >= FailScore;
        //     this._decidePass(pass);
        // }

        let score = calculateScore(result, this.props.contents[this.state.index].eng_content);
        let pass = score >= FailScore;
        this._decidePass(pass);


    };


    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["다시 한번 ", <strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,
                    <div className="tip">점수가 잘 나오지 않는다면 <br/>더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
                err: null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });
            return;
        }

        this.props.openTip();
        if (this.state.errCount >= 1) {
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass: true,
                // hasResult: true,
                isReadyToRecognize: 0,
            });

            return;
        }

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
            isReadyToRecognize: 0,
        })

    };


    // _playAudio = () => {
    //     if (this.props.isPlaying) { //중복 음원 실행 방지
    //         return;
    //     }
    //     this.props.audioPlay("eng", this.state.index);
    // }

}


//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(PracticePronunciation);

