import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_WFT from '../../Footer_WFT';
import {brTagActivator, getCurrentMenuInfo, isIOS, isMobile} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import StartSound from '../../../../asset/sound/start_sound.mp3';


class WordsFinalTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            isStart: false,
            isTesting: false,
            hasResult: false,
            result: {},
            isPlaying: false,
            audioURL: null,
            doUpload: false,
            //uploadComplete: false,
            letUpload: false,
            err: null,
            errCount: 0,
            wordsIndex:0,
        };

    }

    componentWillUnmount(){
        if(this.timeout != null) {
            clearTimeout(this.timeout);
        }
    }

    componentDidMount() {
        // this.props.myJS();
    }

    componentDidUpdate(prevProps, prevState) {
        // this.props.myJS();
        if (!prevState.hasResult && this.state.hasResult) {
            //녹음파일을 받으면 팁창 오픈
            this.props.openTip();
        }

        if(!prevProps.hasErrorOnLoad && this.props.hasErrorOnLoad) { //onloaderror 발생시
            alert("OnLoadError: 다시 녹음 하세요. 문제가 지속 되면 수강창을 완전히 종료 후 다시 접속해 보세요.");
        }
        if(!prevProps.hasErrorOnPlay && this.props.hasErrorOnPlay) { //onplayerror 발생시
            alert("OnPlayError: 다시 녹음 하세요. 문제가 지속 되면 수강창을 완전히 종료 후 다시 접속해 보세요.");
        }
    }


    _getAudioURL = (url) => {
        if (this.state.audioURL === null) {
            this.setState({
                audioURL: url,
            })
        }
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ", this.state.audioURL);
        //this.props.upload();
        this.setState({
            letUpload: true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this.props.goNextMenu();
    };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.isPlayingComplete && prevState.isTesting) {//음원재생 완료 시,
            return({
                isTesting: false,
            })
        }
    }


    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }
        return (
            this._renderMain()
        );

    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer"
                            onClick={() => this._start()}>시작하기
                    </button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.hasResult ?
                                this.props.hasErrorOnLoad || this.props.hasErrorOnPlay ?
                                    ""
                                    :
                                    <ul className="btn-record-wrap">
                                        <li>
                                            <button type="button" className="btn-record-confirm" onClick={() => {
                                                this._playRecordedFile()
                                            }}>녹음듣기
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn-record-submit" onClick={() => {
                                                this._uploadRecordedFile()
                                            }}>녹음제출
                                            </button>
                                        </li>
                                    </ul>
                                :
                                this.state.isTesting ?
                                    // <p className="direct-translation type2">들려 주는 한글 단어를 영어로 말하세요.</p>
                                    <div className="single-memorize">
                                        <h2>{this.props.contents[this.state.wordsIndex].kor_content}</h2>
                                    </div>
                                    :
                                    <p className="direct-translation type2">화면에 나오는 한글 단어를 영어로 말하세요.</p>
                        }

                    </div>

                    {!this.state.hasResult && this.state.isTesting ?
                        <div className="lecture-step">
                            <span className="current">{this.state.wordsIndex +1}</span> /
                            <span className="total">{this.props.contents.length}</span>
                        </div>
                        :
                        ""
                    }

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={() => {
                        this.props.initTipToggle();
                    }}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <div className="divider"/>
                                        <div className="tip">
                                            결과가 만족스럽지 않다면 <br/>
                                            <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err === null ?
                                        <>
                                            오늘 배운 단어를 테스트합니다. <br/>
                                            준비가 되셨다면 테스트 버튼을 눌러 <br/>
                                            화면에 나오는 단어를 <strong>영어로</strong> 답하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>


                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_WFT
                    {...this.props}
                    c_code={this.props.c_code}
                    // startTest={this._startTest}
                    isPlaying={this.state.isPlaying}
                    playRecordedFile={this._playRecordedFile}
                    stopRecordedFile={this._stopRecordedFile}
                    hasResult={this.state.hasResult}
                    checkResult={this._checkResult}
                    isTesting={this.state.isTesting}
                    getAudioURL={this._getAudioURL}
                    //uploadComplete ={this.state.uploadComplete}
                    isKoreanPlayingComplete={this.props.isPlayingComplete}
                    // playKoreanWordsAll={this._playKoreanWordsAll}
                    letUpload={this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    showKoreanWordsAll={this._showKoreanWordsAll}
                />
            </>

        );
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult: bool,
        })
    };

    _showSubmitPage = () => {
        //녹음파일 제출할 수 있는 페이지로 이동함.
        this.setState({
            ...this.state,
            //isTesting: false,
            hasResult: true,
        });

    };
    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _showKoreanWordsAll = () => {

        this.setState({
            isTesting:true,
        })

        if(this.state.wordsIndex < this.props.contents.length - 1) {

            this.timeout = setTimeout(() => {
                this.setState({
                    wordsIndex:this.state.wordsIndex+1,
                });
                let sound = new Audio(StartSound);
                sound.play();
                this._showKoreanWordsAll();
            },2500);
        } else {
            this.timeout = setTimeout(() => {
                this.setState({
                    isTesting:false,
                    wordsIndex:0,
                });
                clearTimeout(this.timeout);
                this.timeout = null;
            }, 2500)

        }
    }

    // _playKoreanWords = () => {
    //     //this.props.audioPlayAll("kor");
    //     this.props.audioPlayInWFT(0);
    //     this.setState({
    //         isTesting:true,
    //     });
    //
    // };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }

        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };
}


export default withContents()(WordsFinalTest);

