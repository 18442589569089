import React, {Component} from 'react';
import Loader from 'react-loader-spinner';


class LoadingBar  extends Component{
    constructor(props){
        super(props);
        this.state = {
            timeOver: false,
        };

        this.timeOut = null;
    }

    componentDidMount(){
        this.timeOut = setTimeout(() => this.setState({timeOver:true}), 300);
    }

    componentWillUnmount(){
        if(this.timeOut != null){
            clearTimeout(this.timeOut);
            this.timeOut = null;
        }

        this.setState({
            timeOver:false,
        })

    }


    render(){
        // //console.log("current time over is ",this.state.timeOver);
        if(!this.state.timeOver){
            return ("");
        } else {
            return (
                <div className="LoadingBarAbsoluteCenter">
                    <Loader
                        type="Oval"
                        color="#006681"
                        height={160}
                        width={160}
                    />
                    <br />
                    Loading...
                </div>
            );
        }






        // switch(this.props.type){
        //     case 0:
        //         return (
        //             <section className="view-body">
        //                 <div className="content-wrap">
        //                     <Loader
        //                         type="CradleLoader"
        //                         color="#006681"
        //                         height={160}
        //                         width={160}
        //                     />
        //                     <br />
        //                     Loading...
        //                 </div>
        //             </section>
        //         );
        //     case 1:
        //         return (
        //             <div className="LoadingBarAbsoluteCenter">
        //
        //                 <Loader
        //                     type="CradleLoader"
        //                     color="#006681"
        //                     height={160}
        //                     width={160}
        //                 />
        //                 <br />
        //                 Loading Lesson List...
        //
        //             </div>
        //         );
        //     default:
        //         return (
        //             <section className="view-body">
        //                 <div className="content-wrap">
        //                     <Loader
        //                         type="CradleLoader"
        //                         color="#006681"
        //                         height={160}
        //                         width={160}
        //                     />
        //                     <br />
        //                     Loading...
        //                 </div>
        //             </section>
        //         );
        // }
    }
}

export default LoadingBar;