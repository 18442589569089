import React, {Component} from 'react';
import withUserData from "../lesson/withUserData";
import '../../asset/css/common.css';
import '../../asset/css/myStyle.css';
import $ from 'jquery';
import {isIOS} from "../../asset/js/myFunctions";


class Intro extends Component {


    constructor(props) {
        super(props);
        this.state = {
            characterRootURL: 'https://study.itcenglish.com/contents/common',
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            userInfo: null,
            currentStepIndex:0,
        }
    }

    _goLessonList = () => {
        if(isIOS) {
            alert("iOS에서는 음성인식 기능을 사용하실 수 없습니다. 음성인식 기능을 사용하시려면 PC 또는 안드로이드 디바이스를 이용해 주세요.");
        }

        this.props.handleCurrentPage(1);
    };


    componentDidMount() {

        $(function () {
            $(window).resize(function () {
                let mh = $('.main-section').innerHeight() - $('.user-info-wrap').outerHeight() - $('.grade-list').outerHeight();
                $('.section-footer').css('min-height', mh);
            }).resize();
        });


    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userInfo !== null && prevState.userInfo === null) {
            //console.log("유저info 저장. :", nextProps.userInfo);
            nextProps.setUserInfo(nextProps.userInfo);
            let currentStepIndex = 0;

            switch(nextProps.serverData.step_code){
                case "L1":
                case "J1":
                    currentStepIndex = 1;
                    break;
                case "L2":
                case "J2":
                    currentStepIndex = 2;
                    break;
                case "L3":
                case "J3":
                    currentStepIndex = 3;
                    break;
                case "L4":
                    currentStepIndex = 4;
                    break;
                case "L5":
                    currentStepIndex = 5;
                    break;
                case "L6":
                    currentStepIndex = 6;
                    break;
                case "L7":
                    currentStepIndex = 7;
                    break;
                case "L8":
                    currentStepIndex = 8;
                    break;
                case "L9":
                    currentStepIndex = 9;
                    break;
                case "L10":
                    currentStepIndex = 10;
                    break;
                case "L11":
                    currentStepIndex = 11;
                    break;
                case "L12":
                    currentStepIndex = 12;
                    break;
                default:
                    currentStepIndex = 1;
                    break;
            }

            return ({
                userInfo: nextProps.userInfo,
                currentStepIndex,
            })
        }
        return null;
    }

    componentDidUpdate() {

        let gradeListWidth = document.getElementById('gl-ul').offsetWidth;
        let offset = 80;
        let pwLength = gradeListWidth - offset;
        document.getElementById('pw').style.width = pwLength+'px';
        // document.getElementById('current-progress').style.width = pwLength * ((this.state.currentStepIndex - 1) / 11) - (3*this.state.currentStepIndex) +'px';
        document.getElementById('current-progress').style.width = (pwLength - 20)* ((this.state.currentStepIndex-1) / 11) +'px';
        // document.getElementById("gs").style.left = (pwLength - 20) * ((this.state.currentStepIndex - 1) / 11) - (5*this.state.currentStepIndex) +'px';
        // document.getElementById("gs").style.left = (pwLength-40) * ((this.state.currentStepIndex - 1) / 11)  - (11 / this.state.currentStepIndex)+'px';
        document.getElementById("gs").style.left = pwLength * ((this.state.currentStepIndex - 1) / 11) - 15 - (27 * (this.state.currentStepIndex - 1) / 11)+ 'px';


        // let Main = {
        //     $gradeContainer: $('.main-section .grade-list'),
        //     $progressContainer: $('.progress-wrap'),
        //     $currentClass: $('ul li.current', this.$gradeContainer),
        //     progressValue: [14, 105, 205, 305, 405, 570, 665, 765, 930, 1030, 1149],
        //     totalClassCount: 0,
        //     currentStep: 0,
        //     currentClassIndex: 0,
        //     init: function () {
        //         this.initProgress();
        //         this.initResizeEvent();
        //     },
        //     initResizeEvent: function () {
        //         $(window).resize(function () {
        //             Main.initSectionFooterHeight();
        //             Main.initGradeStepPosition();
        //         }).resize();
        //     },
        //     initProgress: function () {
        //         $('> li', Main.$gradeContainer).each(function (i) {
        //             let index = $(this).index(),
        //                 w = $(this).width(),
        //                 posX = $(this).offset().left - $('>li:first-child', Main.$gradeContainer).offset().left;
        //
        //             $('li', $(this)).each(function (t) {
        //                 if ($(this).hasClass('current')) {
        //                     Main.currentClassIndex = Main.totalClassCount;
        //                     Main.currentStep = i;
        //                 }
        //                 Main.totalClassCount++;
        //             });
        //
        //             // 단계표시 위치 조정
        //             $('.grade-step li', Main.$progressContainer).eq(index).css({
        //                 left: (w / 2) + posX
        //             });
        //         });
        //
        //         // 단계표시색상 칠하기
        //         $('.current-progress', Main.$progressContainer).css('width', Main.progressValue[Main.currentClassIndex] + 'px');
        //
        //         // 현재 단계의 타이틀 말풍선으로 변경
        //         $('.grade-step li', Main.$progressContainer).eq(Main.currentStep).addClass('current').siblings().removeClass('current');
        //
        //         // 현재단계로 말풍선 위치 변경
        //         $('.grade-step li', Main.$progressContainer).eq(Main.currentStep).css({
        //             left: (Main.progressValue[Main.currentClassIndex] + 14) + 'px'
        //         });
        //     },
        //     initSectionFooterHeight: function () {
        //         $('.section-footer').css('min-height', 0);
        //         let mh = $('.main-section').innerHeight() - $('.progress-wrap').outerHeight() - $('.user-info-wrap').outerHeight() - $('.grade-list').outerHeight();
        //
        //         $('.section-footer').css('min-height', mh);
        //     },
        //     initGradeStepPosition: function () {
        //         let w = $(window).width(),
        //             cw = Main.$currentClass.width();
        //
        //         Main.$gradeContainer.removeAttr('style');
        //         Main.$progressContainer.removeAttr('style');
        //
        //         // 화면 너비가 1220px 보다 작을경우만 실행
        //         if (w > 1220) return;
        //
        //         // 현재 current 클래스가 화면 중앙보다 오른쪽에 있다면?
        //         if (Main.$currentClass.offset().left > (w - cw) / 2) {
        //             // current class가 화면중앙에 오도록 translate값 계산
        //             let tx = ((w - cw) / 2) - Main.$currentClass.offset().left;
        //             let translate = {
        //                 '-webkit-transform': 'translateX(' + tx + 'px)',
        //                 '-moz-transform': 'translateX(' + tx + 'px)',
        //                 '-ms-transform': 'translateX(' + tx + 'px)',
        //                 '-o-transform': 'translateX(' + tx + 'px)',
        //                 'transform': 'translateX(' + tx + 'px)'
        //             };
        //
        //             Main.$gradeContainer.css(translate);
        //             Main.$progressContainer.css(translate);
        //         }
        //     }
        // };
        //
        // $(function () {
        //     Main.init();
        // });

        $(function () {
            $(window).resize(function () {
                let mh = $('.main-section').innerHeight() - $('.user-info-wrap').outerHeight() - $('.grade-list').outerHeight();
                $('.section-footer').css('min-height', mh);
            }).resize();
        });
    }

    render() {
        if (!this.props.userInfo) {
            return (<div>Loading...</div>);
        }

        return (
            <div>

                <ul className="sr-only sr-only-focusable">
                    <li><a href="#contents">컨텐츠 바로가기</a></li>
                    <li><a href="#nav">메뉴 바로가기</a></li>
                </ul>

                <header id="header" className="no-border">
                    <span id="logo">입으로 영어, ITC 통역스쿨</span>
                    <button type="button" id="btn-setting">환경설정</button>
                </header>


                <article className="main-section">

                    <div className="user-info-wrap">
                        <figure className="user-pic">
                            {/*<img src={require(`../../asset/images/samples/${this.props.serverData.캐릭터 파일이름}`)} alt="홍길동님 사진" />*/}
                            {/*<img src={`/illust/character/character_a.png`)} alt="프로필캐릭터"/>*/}
                            {
                                this.props.userInfo.skin_alphabet == null ?

                                    <img src={`${this.state.characterRootURL}/character/character_a.png`} alt="프로필캐릭터"/>
                                    :
                                    <img src={`${this.state.characterRootURL}/character/character_${this.props.userInfo.skin_alphabet.replace(/\s/gi,"")}.png`} alt="프로필캐릭터"/>
                            }

                            <span className="user-grade">{this.props.serverData.step_code}</span>
                        </figure>
                        <h4 className="user-name">{this.props.serverData.mem_name}</h4>
                        <p className="user-desc">{this.props.serverData.tea_name} 선생님
                            ({this.props.serverData.branch_name} 브랜치)</p>
                    </div>
                    {/*<div className="progress-wrap">*/}
                    {/*    <ul className="grade-step">*/}
                    {/*        <li>1단계</li>*/}
                    {/*        <li>2단계</li>*/}
                    {/*        <li>3단계</li>*/}
                    {/*    </ul>*/}
                    {/*    <div className="grade-progress">*/}
                    {/*        <div className="current-progress"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="progress-wrap" id={"pw"}>

                        <ul className="grade-step">
                            <li className={"current"} id={"gs"}>Level {this.state.currentStepIndex}</li>
                        </ul>

                        <div className="step-points-container">
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 1 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 2 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 3 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 4 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 5 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 6 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 7 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 8 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 9 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 10 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 11 ? "step-point-active" : ""} />
                            </span>
                            <span className="step-point">
                                <span className={this.state.currentStepIndex >= 12 ? "step-point-active" : ""} />
                            </span>
                        </div>

                        <div className="grade-progress">
                            <div className="current-progress"  id={'current-progress'}/>
                        </div>
                    </div>

                    <ul className="grade-list">
                        <li>
                            <ul id={"gl-ul"}>
                                <li className={this.props.serverData.step_code === "L1" || this.props.serverData.step_code === "J1" ? "current" : ""}>
                                    <span>{this.props.serverData.step_code === "J1"|| this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3"  ? "주니어1" : "1단계"}</span></li>
                                <li className={this.props.serverData.step_code === "L2" || this.props.serverData.step_code === "J2"? "current" : ""}>
                                    <span>{this.props.serverData.step_code === "J1"|| this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3"  ? "주니어2" : "2단계"}</span></li>
                                <li className={this.props.serverData.step_code === "L3" || this.props.serverData.step_code === "J3" ? "current" : ""}>
                                    <span>{this.props.serverData.step_code === "J1"|| this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3"  ? "주니어3" : "3단계"}</span></li>
                                <li className={this.props.serverData.step_code === "L4" ? "current" : ""}>
                                    <span>4단계</span></li>
                                <li className={this.props.serverData.step_code === "L5" ? "current" : ""}>
                                    <span>5단계</span></li>
                                <li className={this.props.serverData.step_code === "L6" ? "current" : ""}>
                                    <span>6단계</span></li>
                                <li className={this.props.serverData.step_code === "L7" ? "current" : ""}>
                                    <span>7단계</span></li>
                                <li className={this.props.serverData.step_code === "L8" ? "current" : ""}>
                                    <span>8단계</span></li>
                                <li className={this.props.serverData.step_code === "L9" ? "current" : ""}>
                                    <span>9단계</span></li>
                                <li className={this.props.serverData.step_code === "L10" ? "current" : ""}>
                                    <span>10단계</span></li>
                                <li className={this.props.serverData.step_code === "L11" ? "current" : ""}>
                                    <span>11단계</span></li>
                                <li className={this.props.serverData.step_code === "L12" ? "current" : ""}>
                                    <span>12단계</span></li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <ul>*/}
                        {/*        <li className={this.props.serverData.step_code === "L6" ? "current" : ""}>*/}
                        {/*            <span>초급</span></li>*/}
                        {/*        <li className={this.props.serverData.step_code === "L7" ? "current" : ""}>*/}
                        {/*            <span>중급</span></li>*/}
                        {/*        <li className={this.props.serverData.step_code === "L8" ? "current" : ""}>*/}
                        {/*            <span>고급</span></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <ul>*/}
                        {/*        <li className={this.props.serverData.step_code === "L9" ? "current" : ""}>*/}
                        {/*            <span>의학</span></li>*/}
                        {/*        <li className={this.props.serverData.step_code === "L10" ? "current" : ""}>*/}
                        {/*            <span>지학</span></li>*/}
                        {/*        <li className={this.props.serverData.step_code === "L11" ? "current" : ""}>*/}
                        {/*            <span>물리</span></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                    </ul>

                    <div className="section-footer HideScrollBar">
                        <button type={"button"} onClick={this._goLessonList.bind(this)} className="btn-go-list">시작하기</button>
                    </div>


                </article>
            </div>


        );
    }


}

export default withUserData()(Intro);
    
    