import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import Footer_Spelling from "../../Footer_Spelling";

class MemorizeSpelling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            inputs: [[], [], [], [], [], [], [], [], [], []],
            passIdx: {},
            totalScore: 0,
            err: null,
            errCount: 0,
            tryCount: 0,
            pass: false,
            count: 0,
            isTesting: false,
            waiting: false,
            hasResult: false,
            // currentPage: 1,
            // totalPage: 0,
            speaking: false,
            splitContents:null,
        };

        this.inputs = [[], [], [], [], [], [], [], [], [], []]; //focus()를 사용하기 위해 <input/>을 담아두는 곳


    }

    static getDerivedStateFromProps(nextProps, prevState) {

        // if (nextProps.contents && prevState.totalPage === 0) {
        //     return ({
        //         totalPage: Math.ceil(nextProps.contents.length / 5),
        //     })
        // }
        //
        // if (nextProps.currentPage !== prevState.currentPage) { //페이지 변경시
        //     return ({
        //         currentPage: nextProps.currentPage,
        //         hasResult: false,
        //         isTesting: false,
        //         pass: false,
        //     })
        // }

        if(prevState.splitContents === null && nextProps.contents) {
            let splitContents = [];
            for(let i = 0; i < nextProps.contents.length; i++){
                splitContents.push(nextProps.contents[i].eng_content.replace(/[^(a-zA-Z|\s)]/gi, '\'').split(/\s/gi));

            }
            return({
                splitContents,
            })

        }

        return null;

    }


    componentDidUpdate(prevProps, prevState) {
        if (!this.state.pass && this.props.contents) {
            let pass = true;
            for (let i = 0; i < this.props.contents.length; i++) {
                // //console.log("패스체크 :",i);
                // //console.log("this.state.passIdx.i = ",this.state.passIdx[i]);
                if (this.state.passIdx[i] === false || this.state.passIdx[i] === undefined) {
                    pass = false;
                    break;
                } else {
                    pass = true;
                }
            }
            ////console.log("pass:",pass);
            if (pass) {
                this.setState({
                    pass: pass,
                })
            }
        }
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.isTesting ?
                                this._renderTest()
                                :
                                this._renderWords()
                        }
                    </div>
                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span>/<span
                        className="total">{this.state.totalPage}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.isTesting ?
                                    <>
                                        단어가 생각나지 않는다면 <strong>이전 버튼</strong>을 눌러<br/>
                                        다시 확인 후 <strong>테스트</strong> 하세요.

                                    </>
                                    :
                                    <>
                                        단어의 <strong>스펠링을 외워주세요.</strong><br/>
                                        다 외우셨다면 <strong>테스트</strong> 합니다.
                                        <div className="divider" />
                                        <div className="tip">
                                            단어를 클릭하면 소리를 들어보실 수 있습니다.
                                        </div>

                                    </>

                            }

                        </div>
                    </div>
                </section>
                <Footer_Spelling
                    {...this.props}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    isTesting={this.state.isTesting}
                    waiting={this.state.waiting}
                    setResult={this._setResult}
                    handleError={this._handleError}
                    startTest={this._startTest}
                    //speaking={this.state.speaking}
                    goWordsList={this._goWordsList}
                    totalPage={this.state.totalPage}
                />

            </>
        )

    };


    _startTest = () => {
        this.setState({
            isTesting: true,
            result: {},
            err: null,
            errCount: 0,
            count: 0,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
            hasResult: false,
        })

        this.props.closeTip();
    }


    _ttsPlay = (text) => {
        if (window.speechSynthesis.speaking) { //재생중일땐 Return;
            return;
        }
        let tts = new SpeechSynthesisUtterance(text);
        tts.lang = 'en-US';
        tts.rate = 1;
        tts.pitch = 1;
        window.speechSynthesis.speak(tts);
        this.setState({
            speaking: true,
        });
        tts.onend = () => {
            this.setState({
                speaking: false,
            })
        }

    }
    //
    // _audioPlay = (lang, idx) => {
    //     if (this.state.activatedWords === idx && this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }


    _renderWords = () => {
        let wordsList = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {
                    this._onWordsClick(key)
                }} className={this.state.activatedWords === key ? "active" : ""}>
                    <div className="word">{this.props.contents[key].eng_content}</div>
                    <div className="txt">{this.props.contents[key].kor_content}</div>
                </li>);
        }

        return (
            <ul className="word-list word-list-grid-9">
                {wordsList}
            </ul>
        );
    };

    _onWordsClick = (key) => {
        ////console.log(window.speechSynthesis.speaking);
        // if (window.speechSynthesis.speaking) {
        //     return;
        // }
        // this._ttsPlay(this.props.contents[key].eng_content);
        // this._audioPlay("eng", key);
        this.audioPlay(key);
        this.setState({
            activatedWords: key,
        });
    };

    _renderTest = () => {
        let wordList = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            let divList = [];
            // let splitedContents = this.props.contents[i].eng_content.replace(/[^(a-zA-Z|\s)]/gi, '\'').split(/[^(a-zA-Z)]/gi);
            //let splitedContents = this.props.contents[i].eng_content.replace(/[^(a-zA-Z|\s)]/gi, '\'').split(/\s/gi);
            let inputList = [];
            for (let j = 0; j < this.state.splitContents[i][0].length; j++) {
                inputList.push(
                    <input
                        className={
                            !this.state.inputs[i][j] || this.state.inputs[i][j] == null ?
                                "input"
                                :
                                this.state.inputs[i][j].toUpperCase() === this.props.contents[i].eng_content.replace(/[^(a-zA-Z|\s)]/gi, '\'').replace(" ","").slice(j, j + 1).toUpperCase() ?
                                    "input correct"
                                    :
                                    "input wrong"
                        }
                        maxLength="1"
                        ref={ref => {
                            this.inputs[i][j] = ref
                        }}
                        value={this.state.inputs[i][j]}
                        onChange={event => this._inputChangeListener(i, j, event)}
                        onKeyDown={event => this._keyListener(i, j, event)}
                        autoFocus={i === 0 && j === 0}
                    />
                );
            }

            divList.push(
                <div
                    className={this.props.contents[i].eng_content.replace(/[^(a-zA-Z)]/gi, "").length >= 5 ? "input-wrap small" : "input-wrap"}>
                    {inputList}
                </div>
            )
            wordList.push(
                <div className="grid row-3">
                    {divList}
                    <div className="txt">{this.props.contents[i].kor_content}</div>
                </div>
            );
        }

        return (
            <div className="spell-practice">
                {wordList}
            </div>
        )
    };

    _inputChangeListener = (i, j, e) => {
        let currentInputs = this.state.inputs;
        currentInputs[i][j] = e.target.value;
        this.setState({
            inputs: currentInputs,
        });


        // if (e.target.value.toUpperCase() === this.props.contents[i].eng_content.replace(/[^(a-zA-Z)]/gi, "").slice(j, j + 1).toUpperCase()) {
        if (e.target.value.toUpperCase() === this.props.contents[i].eng_content.replace(/[^(a-zA-Z|\s)]/gi, '\'').replace(" ","").slice(j, j + 1).toUpperCase()) {
            //console.log(e.target.value.toUpperCase() + " 정답. 다음 인풋으로 이동");
            //console.log(this.inputs[i].length);
            if (this.inputs[i].length - 1 === j) { //마지막 문자면 다음 단어로 이동

                this.setState({ //passIdx에 pass여부 추가
                    passIdx: {
                        ...this.state.passIdx,
                        [i]: true,
                    }
                });


                //마지막 단어가 아니라면, 다음 단어로 focus변경
                if (i !== this.props.contents.length - 1) {
                    this.inputs[i + 1][0].focus();
                }


            } else {
                this.inputs[i][j + 1].focus();
            }

            //PASS  NON-PASS Check
            // if(this.state.currentPage === 1){
            //     let strInputs = null;
            //     for(let i = 0 ; i < this.state.inputs.length; i++){
            //         strInputs = strInputs + this.state.inputs[i].join('').toUpperCase();
            //     }
            //
            //     let strContents = null;
            //     for(let i = 0 ; i < this.props.contents.length; i++){
            //         strContents = strContents + this.props.contents[i].eng_content.replace(/[^(a-zA-Z)]/gi,"");
            //     }
            // } else if(this.state.currentPage === 2){
            //
            // }
            // let pass = true;
            // for(let i = (this.state.currentPage - 1) * 5; i < this.state.currentPage * 5; i ++) {
            //     //console.log("패스체크 :",i);
            //     //console.log("this.state.passIdx.i = ",this.state.passIdx[i]);
            //     if(this.state.passIdx[i] === false || this.state.passIdx[i] === undefined){
            //         pass = false;
            //         break;
            //     } else {
            //         pass = true;
            //     }
            // }
            //
            // //console.log("pass:",pass);
            //
            // this.setState({
            //     pass:pass,
            // })

        }
    }

    _keyListener = (i, j, event) => {
        if (event.keyCode === 13) { //enter를 누른 경우
            //console.log("엔터 눌림");
            // if (this.state.currentPage * 5 - 1 === i) {
            //     if (this.props.contents[i].eng_content.replace(/[^(a-zA-Z)]/gi, "").length - 1 === j && this.state.pass) {
            //         //마지막 INPUT인 경우,
            //
            //         if (this.state.currentPage !== this.state.totalPage) {
            //
            //             this.props.next();
            //         } else {
            //             this.props.goNextMenu();
            //
            //         }
            //     }
            // }
            if (this.state.pass) {
                this.props.goNextMenu();
            }
        }

        if (event.keyCode === 8) {
            //console.log("백스페이스 눌림");
            //console.log(this.state.inputs[i][j]);
            if (this.state.inputs[i][j] == null || this.state.inputs[i][j] === "") {
                if (j >= 1) {
                    this.inputs[i][j - 1].focus();
                }
            }
        }

    };


    _goWordsList = () => {
        this.setState({
            isTesting: false,
        })
    }


}


export default withContents()(MemorizeSpelling);
    
    