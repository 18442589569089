import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from '../other/MicVisualizer';
import {isIOS} from "../../asset/js/myFunctions";


class Footer_TKE1 extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

        <section className="view-footer">
            {
                this.props.listening ?
                    <MicVisualizer/>
                    :
                    <>
                        {
                            this.props.currentPage > 1 ?
                                <button
                                    type="button"
                                    className="f-btn btn-prev"
                                    onClick={() => this.props.prev()}>
                                    이전
                                </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn">
                                    Empty
                                </button>
                        }
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                if(isIOS){
                                    this.props.fakeRecognitionStart();
                                    this.props.audioPlayMute("eng",this.props.index,
                                        () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
                                    );
                                } else {
                                    this.props.startRecognition();
                                }
                                this.props.closeTip();
                                this.props.handleError(null);
                            }}/>
                        {
                            this.props.pass ?
                                this.props.maxPage <= this.props.currentPage ?
                                    <button
                                        type="button"
                                        className="f-btn btn-next-page"
                                        onClick={() => {
                                            this.props.goNextMenu()
                                        }}>다음메뉴
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="f-btn btn-next"
                                        onClick={() => {
                                            this.props.next()
                                        }}>다음</button>

                                :
                                <button
                                    type="button"
                                    className="f-btn">
                                    Empty
                                </button>
                        }
                    </>
            }
        </section>

        );
    }

}

export default withRecognizer()(Footer_TKE1);
    
    