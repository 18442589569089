import React, {Component} from 'react';

import withRecognizerRDN from "./withRecognizerRDN";
import MicVisualizer from "../other/MicVisualizer";
import {isMobile} from "../../asset/js/myFunctions";


/**
 * Reading 은 모바일에서 음성인식 사용 X
 */

class Footer_RDN extends Component {

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">

                {
                    !this.props.isTesting ?

                        this.props.currentPage === 1 ?

                            <button type="button" className="f-btn">Empty</button>
                            :
                            <button type="button" className="f-btn btn-prev"
                                    onClick={() => this.props.prev()}>이전</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }
                {this.props.isTesting ? (
                        <MicVisualizer/>
                    )
                    :
                    (
                        <div className="recording-controller">
                            <div className="timer-wrap">
                                <div className="timer-value">
                                    {
                                        this.props.score === null
                                            ?
                                            ""
                                            :
                                            isMobile ?
                                                "GOOD"
                                                :
                                                this.props.score
                                    }
                                </div>
                                <h5>SCORE</h5>
                            </div>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this.props.startRecognition();
                                    this.props.startCounter();
                                    this.props.closeTip();
                                }}>
                            </button>
                        </div>
                    )
                }
                {
                    !this.props.isTesting ?

                        this.props.pass ?
                            this.props.butRealMaxPage === this.props.currentPage ?
                                <button type="button" className="f-btn btn-next-page" onClick={() => {
                                    this.props.goNextMenu()
                                }}>다음메뉴</button>
                                :
                                <button type="button" className="f-btn btn-next" onClick={() => {
                                    this.props.next()
                                }}>다음</button>
                            :
                            <button type="button" className="f-btn">Empty</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }

            </section>

        );
    }

}

export default withRecognizerRDN()(Footer_RDN);
    
    