import React, {Component} from 'react';
import withUserData from "./withUserData";
import MenuList from './MenuList';
import $ from 'jquery';




class LessonMainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuToggled: true,
        };

        this.child = React.createRef();
        this._toggleMenu = this._toggleMenu.bind(this);



    }

    componentWillReceiveProps(nextProps){
        if(!this.props.userInfo) {
            this.props.setUserInfo(nextProps.userInfo);
        }

    }

    componentDidMount(){
        let rNav = {
            isOpened : false,
            breakPoint : 1220,
            animationSpeed : 'fast',
            $container : $('#view-nav'),
            $btn : $('[data-button="toggle-nav"]'),
            isMobile : function() {
                return ( $(window).width() <= this.breakPoint );
            },
            init : function() {
                $('.nav-list-title', this.$container).click(function(e){
                    $(this).parent().find('.sub').slideToggle(rNav.animationSpeed);
                });
                this.$btn.click(rNav.toggle);
                $(window).resize(this.onResize).resize();
                this.guide.init();
            },
            open : function() {
                if( this.isMobile() )
                {
                    let $mask = $('<div>').addClass('mask').css('z-index',101).attr('data-mask','mask');;
                    $('body').append($mask);
                    $mask.fadeTo(0, 0.8);
                }

                this.$container.slideDown(this.animationSpeed);
                $('i', this.$btn ).addClass('opened');
                this.$btn.addClass('opened');
                this.isOpened = true;
            },
            close : function() {
                if( this.isMobile() )
                {
                    $('[data-mask]').fadeOut(this.animationSpeed, function() {
                        $(this).remove();
                    });
                }

                this.$container.slideUp(this.animationSpeed);
                $('i', this.$btn ).removeClass('opened');
                this.$btn.removeClass('opened');
                this.isOpened= false;
            },
            toggle : function() {
                if( rNav.isOpened ) rNav.close();
                else rNav.open();
            },
            onResize : function() {
                if( $(window).width() > rNav.breakPoint ) {
                    rNav.open();
                    rNav.guide.open();
                }
                else {
                    rNav.close();
                    rNav.guide.close();
                }
            },
            guide : {
                isOpened : false,
                $container : $('#guide'),
                $btn : $('[data-button="toggle-guide"]'),
                init : function() {
                    this.$btn.click(rNav.guide.toggle);
                },
                open : function() {
                    rNav.guide.$container.slideDown(rNav.animationSpeed);
                    rNav.guide.isOpened = true;
                },
                close : function() {
                    rNav.guide.$container.slideUp(rNav.animationSpeed);
                    rNav.guide.isOpened = false;
                },
                toggle: function() {
                    if(rNav.guide.isOpened) rNav.guide.close();
                    else rNav.guide.open();
                }
            }
        };
        $(function() {
            rNav.init();
        });
    }

    _toggleMenu = () => {
        //console.log("toggle menu");
        this.setState({
            isMenuToggled: !this.state.isMenuToggled,
        });
        //this.child.current.getAlert();

    };

    _goLessonList = () => {
        this.props.handleCurrentPage(1);
    };


    render() {

        if(!this.props.userInfo){
            return ( <div> Loading User Info...</div> );
        }
        ////console.log("this.props.lessonInfo is ",this.props.lessonInfo);

        return (

            <div className="view-section menu-opened" data-section="lecture">

                <button type="button" className="btn-window-close" onClick={() => {this._goLessonList()}}>윈도우 닫기</button>
                <button type="button" className="btn-menu-close" data-button="menu-toggle" onClick={() => {this._toggleMenu()}}>메뉴 닫기</button>
                <button type="button" className="btn-menu-open" data-button="menu-toggle" onClick={() => {this._toggleMenu()}}>메뉴 열기</button>

                <article className="view-container">

                    <header className="view-header">
                        <h2>
                            <span className="grade">{this.props.serverData.step_code}</span>
                            <span className="title">Lesson {this.props.lessonInfo.lesson_no} {this.props.lessonInfo.lesson_title_en} {this.props.lessonInfo.lesson_title_kr}</span>
                        </h2>
                    </header>
                </article>

                <MenuList
                    isMenuToggled = {this.state.isMenuToggled}
                    lessonInfo = {this.props.lessonInfo}
                    setMenuInfo = {this.props.setMenuInfo}
                    currentMenu = {this.props.currentMenu}
                    handleCurrentMenu = {this.props.handleCurrentMenu}
                />

            </div>

        );
    }


}



export default withUserData(`http://127.0.0.1:8080/users/sample`)(LessonMainHeader);
    
