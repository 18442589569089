import React, {Component} from 'react';
import MicVisualizer from "../other/MicVisualizer";



class Footer_TRT_A extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:-1,
            recording: false,
            audioURL: null,
            waiting:false,
        };

    }

    // componentDidUpdate(prevProps, prevState){
    //     if(prevProps.wordInfo.getResult === false){
    //         //console.log("결과를 받지 못해 테스트를 일시 정지");
    //         //this.props.
    //     }
    //
    // }
    // componentDidUpdate(prevProps, prevState) {
    //     if(!prevProps.letRecognitionStart && this.props.letRecognitionStart) {
    //         this.props.startRecognition();
    //     }
    // }

    render() {

        return (
            <section className="view-footer">
                {this.props.listening ?
                    <MicVisualizer/>
                    :
                    (
                        this.props.isPlaying ?
                            <button
                                type="button"
                                className="f-btn f-center btn-listen"
                            >
                            </button>

                            :
                            this.props.waiting ?
                                <button type="button" className="f-btn">Empty</button>
                                :
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this.props.startRecognition();
                                        //this.props.continueTest();
                                        this.props.closeTip();
                                    }}
                                    // onClick={this.props.startRecognition}
                                >
                                </button>

                    )
                }
            </section>
        );
    }

}

// export default withRecognizer()(Footer_TRT_A);

export default Footer_TRT_A;
    