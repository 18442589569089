import React, {Component} from 'react';

import withContents from "../../withContents";
import Footer_ListenAndSolve_A from '../../Footer_ListenAndSolve_A';
import Footer_ListenAndSolve_B from '../../Footer_ListenAndSolve_B';
import Footer_ListenAndSolve_C from '../../Footer_ListenAndSolve_C';
import Footer_ListenAndSolve_D from '../../Footer_ListenAndSolve_D';
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";
import Score from '../../Score';
import {
    brTagActivator,
    getCurrentMenuInfo,
    calculateScore,
    FailScore,
    uploadAnswerAl, trimStrForUpload2, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";

class ListenAndSolve extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isInitialized: false,
            wordsContents: [],
            listenContents: [],
            numOfPage: 0,
            activatedWords: null,
            isStart: false,
            isTesting: false,
            count: 0,
            waiting: false,
            currentPage: 1,
            hasResult: false,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            err: null,
            errCount: 0,
            pass: false,
            tryCount: 0, //Pass Non-Pass 정하기 위한 스테이트
            totalScore: 0, //총 점수
            phase: 0,  //기본값 0
            listenComplete: false,
            isComplete: false,
            userAnswers: {},
            answerResult: {},

        };


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.isInitialized && nextProps.contents) {
            let wordsContents = [];
            let listenContents = [];
            for (let i = 0; i < nextProps.contents.length; i++) {
                if (nextProps.contents[i].c_code_no === "1") {
                    wordsContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "2") {
                    listenContents.push(nextProps.contents[i]);
                }
            }
            return ({
                wordsContents: wordsContents,
                listenContents: listenContents,
                isInitialized: true,
            })
        }

        if (nextProps.isPlayingComplete && !prevState.listenComplete) {
            return ({
                listenComplete: true,
            })
        }


        return null;

    }

    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }


        if (this.state.isTesting) {
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest());
        }

        if (this.state.phase === 1) {
            return (this._renderListenPhase());

        }

        if (this.state.phase === 2) {
            return (this._renderSolve());

        }


        return (
            this._renderMain()
        )
    }

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">


                        {
                            this.state.hasResult ?
                                this._render12WordsWithResult(this.props.currentPage - 1)
                                :
                                this._render12Words(this.props.currentPage - 1)
                        }
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        오늘의 <strong>단어/표현들을</strong> 뜻과 함께 외워주세요.<br/>다 외우셨다면 테스트 합니다.
                                        <hr className="divider"/>
                                        <div className="tip">원어민 발음을 듣고 넘어가시면<br/>이어지는 스토리 듣기에 도움이 될거에요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_ListenAndSolve_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest={this._startTest}
                    hasResult={this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                    //isSpeakingTTS={window.speechSynthesis.speaking}
                />
            </>

        );
    };

    _renderListenPhase = () => {
        //console.log("render main is called again");
        return (
            <>
                <section className={"view-body tip-opened"}>
                    <div className="content-wrap">

                        <div className="test-question small-card">
                            {/*<div className="test-header">*/}
                            {/*<button*/}
                            {/*type="button"*/}
                            {/*className="btn-play-sound big"*/}
                            {/*onClick={() => this.props.audioPlayWithURL(this.state.listenContents[0].eng_url)}*/}
                            {/*>*/}
                            {/*음성 듣기*/}
                            {/*</button>*/}
                            {/*</div>*/}

                            <ul className="word-list voice-box">
                                <li>
                                    {/*<div className="word">오늘의 스토리</div>*/}
                                    <div className="txt txt-big">오늘의 스토리</div>
                                    <span className="btn-wrap">
                                <button className="btn-play-sound CursorPointer"
                                        onClick={() => {
                                            this._audioPlayWithURL();
                                        }}>
                                    발음듣기
                                </button>
                            </span>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            화면의 <strong>스피커 버튼</strong>을 눌러<br/>
                            <strong>오늘의 스토리</strong>를 잘 들어 주세요.
                            <hr className="divider"/>
                            <div className="tip"><strong>모두 들은 후</strong> 테스트 합니다.</div>
                        </div>
                    </div>
                </section>
                <Footer_ListenAndSolve_C
                    {...this.props}
                    c_code={this.props.c_code}
                    listenComplete={this.state.listenComplete}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                />
            </>

        );
    };

    _onWordsClick = (key) => {
        //console.log(key);
        if (this.props.isPlaying && this.state.activatedWords === key) { //음원 재생중 중복 실행 방지
            return;
        }
        //this._ttsPlay(this.state.wordsContents[key].eng_content);
        // this.props.audioPlayWithURL(this.state.wordsContents[key].eng_url);

        let src = `${this.props.contents[0].step_code}/${this.props.contents[0].lesson_no}/${this.state.wordsContents[key].eng_url}`;
        this.props.setHowler(
            src
        );


        this.setState({
            activatedWords: key,
        })
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };
    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.props.closeTip();
        this.setState({isTesting: true});
    };

    _goWordsList = () => {
        this.setState({
            isTesting: false,
            err: null,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            count: 0,
        });
    };

    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["점수가 너무 낮습니다.",<br/>,"정답을 확인 후 다시 한번", <br/>, <strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };


    _renderTest = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.state.wordsContents[this.state.wordInfo.num].kor_content}</h2>
                        </div>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br/>뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_ListenAndSolve_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    goWordsList={this._goWordsList}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                />
            </>
        )

    };

    _renderSolve = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start PT70 PB30">

                        <div className="top-tip">들은 스토리를 잘 생각하여, 다음 질문에 답해보세요.</div>

                        <div className="story-practice">
                            {this._renderProblems()}
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        <div className="tip">
                                            잘 하셨어요.<br/>
                                            다음 메뉴에서 <strong>다시 한번</strong> <br/>
                                            <strong>스토리를 들어보세요.</strong>
                                        </div>
                                    </>
                                    :
                                    <>
                                        들은 스토리에 올바른 정답을 고르세요.
                                    </>

                            }

                        </div>
                    </div>
                </section>
                <Footer_ListenAndSolve_D
                    {...this.props}
                    pass={this.state.pass}
                    hasResult={this.state.hasResult}
                    checkCorrect={this._checkCorrect}
                    // audioPlayWithURL={this._audioPlayWithURL}
                />

            </>

        );
    };

    _audioPlayWithURL = () => {
        if (this.props.isPlaying) {
            return;
        }
        //this.props.audioPlayWithURL(this.state.listenContents[0].eng_url)

        let src = `${this.props.contents[0].step_code}/${this.props.contents[0].lesson_no}/${this.state.listenContents[0].eng_url}`;

        this.setState({
            listenComplete:false,
        })
        this.props.setHowler(
            src,
            () => {
                this.setState({
                    listenComplete:true,
                })
            }
        );
    };



    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // //console.log("M3w Component's err: "+this.state.err);
        this.setState({
            result: {
                ...this.state.result,
                [index]: result,
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            totalScore: this.state.totalScore + calculateScore(result, this.state.wordsContents[index].eng_content),
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.state.wordsContents.length) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);

            setTimeout(() => {

                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                }
                ;
                this.setState({
                    waiting: false,
                });
            }, 1000);
        }

        //결과를 다 받았다면,
        if (this.state.count >= this.state.wordsContents.length) {
            this._showResult();
            this._decidePass(this.state.totalScore / this.state.wordsContents.length >= FailScore || isIOS);
            //
            // if (this.state.totalScore / this.state.wordsContents.length < FailScore ) {
            //     this._decidePass(false);
            // } else {
            //     this._decidePass(true);
            // }
        }

    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.

        this.setState({
            ...this.state,
            count: 0,
            isTesting: false,
            hasResult: true,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
        });

        this.props.openTip();

        //점수 출력시 나오는 애니메이션
        $('.word-list li').each(function (index) {
            let $score = $('.score', $(this)),
                animationTime = 0.6,    // 애니메이션 전체 시간
                delay = (index - 1) * 0.3;  // 각 스코어별 delay

            TweenLite.to($score, animationTime, {
                y: 0,
                delay: delay,
                yPercent: 0,
                opacity: 1
            })
        });

        //console.log("the recorded results are:", this.state.result);
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }


        if (this.state.errCount >= 1) {
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };

    _render12Words = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }} className={this.state.activatedWords === i ? "active" : ""}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                </li>)
        }

        return (
            <ul className="word-list word-list-grid-12">
                {wordsList}
            </ul>
        );
    };

    _render12WordsWithResult = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li className={"CursorPointer"} key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                    <div className="score score-blue">
                        {
                            isIOS ?
                                "A"
                                :
                                <Score scoreType={1}
                                       result={this.state.result[i]}
                                       original={this.state.wordsContents[i].eng_content}/>
                        }

                    </div>

                </li>)
        }

        return (
            <ul className="word-list word-list-grid">
                {wordsList}
            </ul>
        );
    };

    _handlePhase = (phase) => {
        this.setState({
            phase: phase,
            hasResult: false,
        })
    }


    _checkCorrect = () => {
        let answers = '';
        let answerResult = {};

        for (let i = 1; i < this.state.listenContents.length; i++) {
            if (this.state.userAnswers[i] === undefined || this.state.userAnswers[i] == null) {
                //console.log("정답을 다 체크 안함");
                return;
            }

            answers = answers+this.state.userAnswers[i].toString();

            if(i !== this.state.listenContents.length -1){
                answers = answers + '|';
            }

            answerResult[i] = this.state.userAnswers[i].toString() === this.state.listenContents[i].t_answer.toString();

        }


        //유저 정답 업로드
        uploadAnswerAl(this.props.apiUrl, trimStrForUpload2(answers), this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,'T',this.props.serverData.auth_type);


        this.props.openTip();
        this.setState({
            hasResult: true,
            answerResult: answerResult
        })
    };

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    // audioPlay = (index) => {
    //     if(!this.props.contents) {
    //         console.log("not loaded contents yet");
    //         return;
    //     }
    //     let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
    //
    //     this.props.setHowler(
    //         src
    //     );
    // }


    _renderProblems = () => {
        let problems = [];
        for (let i = 1; i < this.state.listenContents.length; i++) {
            let options = [];
            if (this.state.listenContents[i].tpk_content === "O") { //OX타입
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 1 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 1)}
                            checked={
                                this.state.hasResult ?
                                    this.state.listenContents[i].t_answer === "1"
                                    :
                                    this.state.userAnswers[i] === 1
                            }/>
                        <span>O</span>
                    </label>
                );
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 2 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 2)}
                            checked={
                                this.state.hasResult ?
                                    this.state.listenContents[i].t_answer === "2"
                                    :
                                    this.state.userAnswers[i] === 2
                            }/>
                        <span>X</span>
                    </label>
                );

            } else { //4지 선다
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 1 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 1)}
                            checked={
                                this.state.hasResult ?
                                    this.state.listenContents[i].t_answer === "1"
                                    :
                                    this.state.userAnswers[i] === 1
                            }/>
                        <span>{this.state.listenContents[i].t_ex1}</span>
                    </label>
                );
                options.push(
                    <label className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                ""
                                :
                                this.state.userAnswers[i] === 2 ?
                                    "wrong"
                                    :
                                    ""
                            :
                            ""
                    }>
                        <input
                            type="radio"
                            name={`question_${i}`}
                            onClick={() => this._selectAnswer(i, 2)}
                            checked={
                                this.state.hasResult ?
                                    this.state.listenContents[i].t_answer === "2"
                                    :
                                    this.state.userAnswers[i] === 2
                            }/>
                        <span>{this.state.listenContents[i].t_ex2}</span>
                    </label>
                );
                if(this.state.listenContents[i].t_ex3 !== "") {
                    options.push(
                        <label className={
                            this.state.hasResult ?
                                this.state.answerResult[i] ?
                                    ""
                                    :
                                    this.state.userAnswers[i] === 3 ?
                                        "wrong"
                                        :
                                        ""
                                :
                                ""
                        }>
                            <input
                                type="radio"
                                name={`question_${i}`}
                                onClick={() => this._selectAnswer(i, 3)}
                                checked={
                                    this.state.hasResult ?
                                        this.state.listenContents[i].t_answer === "3"
                                        :
                                        this.state.userAnswers[i] === 3
                                }/>
                            <span>{this.state.listenContents[i].t_ex3}</span>
                        </label>
                    );
                }
                if(this.state.listenContents[i].t_ex4 !== "") {
                    options.push(
                        <label className={
                            this.state.hasResult ?
                                this.state.answerResult[i] ?
                                    ""
                                    :
                                    this.state.userAnswers[i] === 4 ?
                                        "wrong"
                                        :
                                        ""
                                :
                                ""
                        }>
                            <input
                                type="radio"
                                name={`question_${i}`}
                                onClick={() => this._selectAnswer(i, 4)}
                                checked={
                                    this.state.hasResult ?
                                        this.state.listenContents[i].t_answer === "4"
                                        :
                                        this.state.userAnswers[i] === 4
                                }/>
                            <span>{this.state.listenContents[i].t_ex4}</span>
                        </label>
                    )
                }
            }
            problems.push(
                <li
                    className={
                        this.state.hasResult ?
                            this.state.answerResult[i] ?
                                "correct"
                                :
                                "wrong"
                            :
                            ""
                    }>
                    <h2 className="question">{this.state.listenContents[i].t_question}</h2>
                    <div className="options long-option">
                        {options}
                    </div>
                </li>
            )


        }

        return (
            <ol className="question-list">
                {problems}
            </ol>

        )
    }

    _selectAnswer = (problemNum, optionsNum) => {
        let userAnswers = this.state.userAnswers;
        userAnswers[problemNum] = optionsNum;
        //console.log("current user answers:", userAnswers);
        this.setState({
            userAnswers: userAnswers,
        });
    }

    _ttsPlay = (text) => {
        if (window.speechSynthesis.speaking) { //재생중일땐 Return;
            return;
        }
        let tts = new SpeechSynthesisUtterance(text);
        tts.lang = 'en-US';
        tts.rate = 1;
        tts.pitch = 1;
        window.speechSynthesis.speak(tts);
        this.setState({
            speaking: true,
        });
        tts.onend = () => {
            this.setState({
                speaking: false,
            })
        }

    }

}


export default withContents()(ListenAndSolve);

