import React from 'react';


const Footer_RES_L4 = (props) => {
    return (

        <section className="view-footer">
            {
                props.isPlaying ?
                    <button
                        type="button"
                        className="f-btn f-center btn-listen"
                    >
                        재생중
                    </button>
                    :
                    props.pass ?
                        props.isComplete ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                props.goNextMenu()
                            }}>
                            다음 메뉴
                        </button>
                            :
                            <button
                                type="button"
                                className="f-btn btn-next"
                                onClick={() => {
                                    props.handleIsComplete(true);
                                }}>
                                다음 메뉴
                            </button>
                        :
                        <button
                            type="button"
                            className="f-btn"
                        >
                            EMpty
                        </button>
            }
        </section>


    )
}


export default Footer_RES_L4;
    
    