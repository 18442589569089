import React, {Component} from 'react';
import withRecorder from "./withRecorder";
import MicVisualizer from '../other/MicVisualizer';
import Uploading from '../other/Uploading';


class Footer_SKT extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            audioURL: null,
            time: "",
            callUpload: false,
            leftTime:999,
        };

        this.timer = null;

    }


    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {


        if(nextProps.isPlaying && !nextProps.nowPlaying){ //강좌패널에서 Play 버튼을 눌렀는데, 음원재생중이 아니라면 음원 플레이
            //console.log("녹음파일플레이");
            nextProps.play();
        }

        if(nextProps.letUpload && !prevState.callUpload){
            //console.log("녹음파일 업로드 호출");

            nextProps.stopPlaying();
            //console.log("녹음파일 재생 중이면 녹음파일 재생 종료");
            //let fileName = `${nextProps.serverData.mem_idx}_${nextProps.serverData.step_code}${nextProps.lessonInfo.lesson_no}_1_${Date.now()}.mp3`;
            nextProps.upload(nextProps.attend_idx, nextProps.currentMenu,  nextProps.device);
            // nextProps.upload();
            return({
                callUpload:true,
            })
        }

        return null;

    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentDidMount(){
        this.props.setDuration(this.props.totalTime * 1000);
    }

    _getIsMute = () => {
        if(this.state.leftTime <= 30){
            return;
        }
        this.props.stopWithoutResult();
        this.props.handleError(["녹음이 되지 않습니다.", <br />, <strong>마이크를 확인</strong>,"하신 후 다시 ",<strong>테스트</strong>,"하세요.",<div className="divider"/>,<div className="tip">마이크 <strong>전원과 볼륨</strong>을 확인해 보세요.</div> ]);
        clearInterval(this.timer);

    };




    render() {
        if(this.props.isPlaying === true){

            return (
                <section className="view-footer">

                    <button
                        type="button"
                        className="f-btn f-center btn-stop"
                        onClick={() => {
                            //this.props.stop()
                            //console.log("stop playing recorded file");
                            this.props.stopPlaying()
                        }}>
                    </button>

                </section>
            )

        }


        ////console.log("current is Recording is:", this.state.recording);
        return (

            <section className="view-footer">
                {this.props.recording ? (

                        <div className="recording-controller f-center-2">
                            <div className="timer-wrap">
                                <div className="timer-value">{this.state.time !== "" ? this.state.time : "02:00"}</div>
                                <h5>TIMER</h5>
                            </div>
                            <MicVisualizer
                                //getIsMute={this._getIsMute}
                                isRecording={true}
                            />
                            {
                                this.state.leftTime <= 30 ?
                                    <button type = "button" className="f-btn btn-record-done" onClick={() => this.props.stop()}>녹음완료</button>
                                    :
                                    <button type = "button" className="f-btn">Empty</button>
                            }

                        </div>

                    )
                    :


                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this._startRecording();
                            this.props.closeTip();
                        }}>
                    </button>

                }
                {
                    this.props.uploading ?
                        <Uploading/>
                        :
                        ""
                }
            </section>
        );
    }

    _getLeftTime = () => {
        let duration = this.props.totalTime;
        let minute = Math.floor(duration / 60);
        let seconds = duration % 60 < 10 ? `0${duration % 60}` : duration % 60;
        //initialize
        this.setState({
            time: `0${minute} : ${seconds}`
        });
        this.timer = setInterval(() => {
            duration = duration - 1;
            minute = Math.floor(duration / 60);
            seconds = duration % 60 < 10 ? `0${duration % 60}`: duration % 60;

            this.setState({
                time:`0${minute} : ${seconds}`,
                leftTime:duration
            });
            if(duration <= 0 || this.props.hasResult) {clearInterval(this.timer); this.props.stop();} //인터벌 종료 및 녹음 종료.
        }, 1000);
    };



    _startRecording = () => {
        this.props.start();
        this.props.closeTip();
        this.props.checkResult(false);
        this.props.handleError(null);
        this.setState({
            time: "",

        });
        this._getLeftTime();
    }


}

export default withRecorder()(Footer_SKT);
    
    