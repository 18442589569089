import React, {Component} from 'react';

import withContents from "../../withContents";
import Footer_ListenAndSpeak from '../../Footer_ListenAndSpeak';
import {
    brTagActivator,
    getCurrentMenuInfo,
    isMobile,
    isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";

class ListenAndSpeak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isInitialized: false,
            isStart: false,
            isTesting: false,
            waiting: false,
            hasResult: false,
            isRecordPlaying: false,
            result: {},
            err: null,
            errCount: 0,
            pass: false,
            listenComplete: false,
            doUpload: false,
            uploadComplete: false,
            letUpload: false,
            isRecording:false,
        };


    }

    static getDerivedStateFromProps(nextProps, prevState) {

        // if (nextProps.isPlayingComplete && !prevState.listenComplete) {
        //     return ({
        //         listenComplete: true,
        //     })
        // }


        return null;

    }

    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )
    }

    _renderMain = () => {
        //console.log("render main is called again");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.hasResult ?
                                <ul className="btn-record-wrap">
                                    <li>
                                        <button type="button" className="btn-record-confirm" onClick={() => {
                                            this._playRecordedFile()
                                        }}>녹음듣기
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="btn-record-submit" onClick={() => {
                                            this._uploadRecordedFile()
                                        }}>녹음제출
                                        </button>
                                    </li>
                                </ul>
                                :
                                this.state.isRecording ?
                                    ""
                                    :
                                    <ul className="word-list voice-box">
                                        <li>
                                            {/*<div className="word">오늘의 스토리</div>*/}
                                            <div className="txt txt-big">오늘의 스토리</div>
                                            <span className="btn-wrap">
                                            <button
                                                className="btn-play-sound CursorPointer"
                                                onClick={() => {
                                                    this._audioPlayWithURL();
                                            }}>
                                                발음듣기
                                            </button>
                                            </span>
                                        </li>
                                    </ul>
                        }
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>테스트버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            오늘의 스토리를 <strong>다시 한번</strong> 들어보세요.<br/>
                                            다 듣고 난 후, 1분간 <strong>영어로 발표</strong>합니다.<br/>
                                            <hr className="divider"/>
                                            <div className="tip">
                                                스토리를 다 듣고 발표를 시작할 수 있습니다.
                                            </div>
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>
                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_ListenAndSpeak
                    {...this.props}
                    c_code={this.props.c_code}
                    isRecordPlaying={this.state.isRecordPlaying}
                    playRecordedFile={this._playRecordedFile}
                    stopRecordedFile={this._stopRecordedFile}
                    hasResult={this.state.hasResult}
                    checkResult={this._checkResult}
                    getAudioURL={this._getAudioURL}
                    totalTime={60}
                    letUpload={this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    listenComplete={this.state.listenComplete}
                    handleIsRecording={this._handleIsRecording}
                />
            </>

        );
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };


    _getAudioURL = (url) => {
        if (this.state.audioURL === null) {
            this.setState({
                audioURL: url,
            })
        }
    };

    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ", this.state.audioURL);
        //this.props.upload();
        this.setState({
            letUpload: true,
            uploadComplete: true,
        });

    };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isRecordPlaying: true,
        });

    };

    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isRecordPlaying: false,
        })
    };


    _checkResult = (bool) => {

        if(bool && !this.state.hasResult) {
            this.props.openTip();
        }

        this.setState({
            hasResult: bool,
        })
    };

    _showSubmitPage = () => {
        //녹음파일 제출할 수 있는 페이지로 이동함.
        this.setState({
            ...this.state,
            isTesting: false,
            hasResult: true,
        });

    };
    _start = () => {
        this.setState({
            isStart: true,
            hasResult: false,
        })
        //this.props.setControlPanel(true);
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }


        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };

    _handleIsRecording = (bool) => {
      this.setState({
              isRecording: bool,
      });
    };

    _audioPlayWithURL = () => {
        if (this.props.isPlaying) {
            return;
        }

        for(let i = 0; i < this.props.contents.length; i++){
            if(this.props.contents[i].c_code_no === "2") {
                //this.props.audioPlayWithURL(this.props.contents[i].eng_url);
                this.setState({
                    listenComplete:false,
                })
                let src = `${this.props.contents[i].step_code}/${this.props.contents[i].lesson_no}/${this.props.contents[i].eng_url}`;

                this.props.setHowler(
                    src,
                    () => {
                        this.setState({
                            listenComplete:true,
                        })
                    }
                );

                break;
            }
        }
    };


}


export default withContents()(ListenAndSpeak);

