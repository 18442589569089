import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_SP1 extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:-1,
            recording: false,
            audioURL: null,
        };

    }


    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log("nextProps.isReadyToRecognize is ",nextProps.isReadyToRecognize);

        if(nextProps.isReadyToRecognize === 2 && nextProps.recognizerReady === true){
            ////console.log("received wordInfo for recognize: "+ nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            // //console.log()

            // if(isIOS){
            //     nextProps.fakeRecognitionStart();
            //
            //     let src = `${nextProps.contents[nextProps.index].step_code}/${nextProps.contents[nextProps.index].lesson_no}/${nextProps.contents[nextProps.index].eng_url}`;
            //     nextProps.setHowlerMute(
            //         src,
            //         () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
            //     );
            //
            // } else {
            //     nextProps.startRecognition();
            // }
            nextProps.startRecognition();
        }

    }

    render() {

        return (
            <section className="view-footer">
                {
                    this.props.currentPage === 1 ?
                        <button type="button" className="f-btn">Empty</button>
                        :
                        this.props.isReadyToRecognize === 0 ?
                            <button type="button" className="f-btn btn-prev" onClick={() => this.props.prev()}>이전</button>
                            :
                            <button type="button" className="f-btn">Empty</button>

                }


                {this.props.isReadyToRecognize === 0 ? (

                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                //this.props.audioPlay("eng", this.props.index);
                                this.props.audioPlay(this.props.index);
                                this.props.closeTip();
                            }}
                        />
                    )
                    :
                    (
                        this.props.isReadyToRecognize === 1 ?
                            <button
                                type="button"
                                className="f-btn f-center btn-listen"
                            >
                            </button>

                            :
                            //isReadyToRecognize === 2이면
                            <MicVisualizer />

                    )
                }

                {
                    this.props.pass ?
                        this.props.maxPage === this.props.currentPage ?
                            <button type="button" className="f-btn btn-next-page" onClick={() => {
                                this.props.goNextMenu()
                            }}>다음메뉴</button>
                            :
                            <button type="button" className="f-btn btn-next" onClick={() => {
                                this.props.next()
                            }}>다음</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }

            </section>
        );
    }

}

export default withRecognizer()(Footer_SP1);
    
    