import React, {Component} from 'react';


class Footer_NTP extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
        };

    }


    render() {

        if(this.props.isPlaying === true){
            return (
                <section className="view-footer">
                    {/*<button*/}
                        {/*type="button"*/}
                        {/*className="f-btn f-center btn-stop"*/}
                        {/*onClick={() => {*/}
                            {/*this.props.audioStop();*/}
                            {/*////console.log("stop playing recorded file");*/}
                            {/*//this.props.stopRecordedFile()*/}
                            {/*//this._recordingAndAudioStop();*/}
                        {/*}}>*/}
                    {/*</button>*/}
                    <button
                        type="button"
                        className="f-btn f-center btn-listen"
                    >
                        Listening
                    </button>
                </section>
            )

        }

        return (
            <section className="view-footer">
                {this.props.currentPage > 1 ?
                    <button
                        type="button"
                        className="f-btn btn-prev"
                        onClick={() => {
                            this.props.prev();
                        }}> 이전 페이지
                    </button>
                    :
                    <button type="button" className="f-btn">Empty</button>
                }

                <button
                    type="button"
                    className="f-btn f-center btn-play-sound"
                    onClick={() => {
                        this.props.audioPlay(this.props.index);
                        this.props.closeTip();
                    }}>
                </button>
                {/*{*/}
                {/*    this.props.isPlayingComplete.index + 1 > this.props.currentPage || (this.props.isPlayingComplete.index + 1 === this.props.currentPage && this.props.isPlayingComplete.isComplete)*/}
                {/*    ?*/}
                {/*    this.props.currentPage >= this.props.maxPage*/}
                {/*        ?*/}
                {/*        <button*/}
                {/*            type={"button"}*/}
                {/*            className="f-btn btn-next-page"*/}
                {/*            onClick={() => this.props.goNextMenu()}*/}
                {/*        >수강종료*/}
                {/*        </button>*/}
                {/*        :*/}
                {/*        <button*/}
                {/*            type={"button"}*/}
                {/*            className="f-btn btn-next"*/}
                {/*            onClick={() => {this.props.next()}}*/}
                {/*        >다음페이지*/}
                {/*        </button>*/}
                {/*    :*/}
                {/*    <button type="button" className="f-btn">Empty</button>*/}
                {/*}*/}

                {
                    this.props.pagePass
                        ?
                        this.props.currentPage >= this.props.maxPage
                            ?
                            <button
                                type={"button"}
                                className="f-btn btn-next-page"
                                onClick={() => this.props.goNextMenu()}
                            >수강종료
                            </button>
                            :
                            <button
                                type={"button"}
                                className="f-btn btn-next"
                                onClick={() => {this.props.next()}}
                            >다음페이지
                            </button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                }
            </section>
        );
    }





}

export default Footer_NTP;
    
    