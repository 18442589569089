import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo} from "../../../../asset/js/myFunctions";
import Footer_PET from "../../Footer_PET";


class PreparationForPresentation extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 999,
            isStart: false,
            currentPage:0,
            result:"",
            isScriptOpen:false,
            content_seq:null,
            isPlayingComplete:false
        }

    }

    componentWillUnmount(){
        this.props.audioStop();
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.contents !== null && prevState.content_seq === null){
            return({
                content_seq: nextProps.content_seq,
            })
        }

        if(nextProps.content_seq !== prevState.content_seq){
            return ({
                content_seq: nextProps.content_seq,
                isStart:false,
                result:"",
                isScriptOpen:false,
                isPlayingComplete:false,
            });
        }

        return null;
    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    {/*<label htmlFor="on-paragraph" className="toggle-switcher" data-toggle="toggle-switcher">*/}
                    {/*<span className="label">영어지문</span>*/}
                    {/*{this.state.isScriptOpen ? <input type="checkbox" value="Y" checked id="on-paragraph" /> : <input type="checkbox" value="N" id="on-paragraph" />}*/}

                    {/*<span className="toggler CursorPointer" onClick={() => {this.setState({isScriptOpen:!this.state.isScriptOpen})}}/>*/}
                    {/*</label>*/}
                    <div className="toggle-switcher switchToggle">
                        <span className={"label"}>
                            지문
                        </span>
                        <input type="checkbox" id="switch1"/>
                        <label htmlFor="switch1" onClick={() => {this.setState({isScriptOpen:!this.state.isScriptOpen})}}>Toggle</label>
                    </div>

                    <div className="content-wrap HideScrollBar">
                        {this.state.isScriptOpen ?
                            <div className="StoryContainer">
                                <p>
                                    {this._renderReading(this.state.index)}
                                </p>
                            </div>
                            :
                            <>
                                <div className="ImageContainer">
                                    <img
                                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[this.props.content_seq-1].tpk_url}`}
                                        className="CropImageInL8" alt="Reading Cover"/>
                                </div>
                            </>
                        }
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>

                        <div className="message-box">
                            발표 연습을 시작합니다.<br/>
                            들려주는 <strong>한글 발표</strong>를 <strong>영어로 바꾸어</strong> 발표하세요.<br/>
                            TEST버튼을 누른 후 발표를 시작하세요.
                        </div>

                    </div>
                </section>
                <Footer_PET
                    {...this.props}
                    setResult={this._setResult}
                    audioPlayKr={this.audioPlayKr}
                    audioStop={this.props.audioStop}
                    isPlaying={this.props.isPlaying}
                    content_seq={this.props.content_seq}
                    isPlayingComplete={this.state.isPlayingComplete}
                />

            </>
        )

    }

    _renderReading = (index) => {
        let reading = [];

        for(let i = 0 ; i < this.props.contents.length; i ++){
            // //console.log(this.props.contents[i].c_code_no);
            if(this.props.contents[i].c_code_no === this.props.c_code_no){
                ////console.log(this.props.contents[i].eng_content);
                reading.push(<>
                    {this.props.contents[i].kor_content} <br />
                </>);
            }
        }

        return(
            reading
        )

    };

    _setResult = (result) => {
        //console.log("결과받음: ", result);
        this.setState({
            result:result
        })
    }

    audioPlayKr = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].kor_url}`;

        this.props.setHowler(
            src,
            () => {
                this.setState({
                    isPlayingComplete:true
                })
            }
        );
    }


}

export default withContents()(PreparationForPresentation);