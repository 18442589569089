import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo} from "../../../../asset/js/myFunctions";
import Footer_RNU from "../../Footer_RNU";
import $ from 'jquery';

class ReadAndUnderstand extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 81,
            isStart: false,
            isScrollBottom: false,

        }

    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }


        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    {/*스크롤 시 푸터 생기는 애니메이션 삭제*/}
                    {/*<div className="content-wrap align-start" onScroll={this._handleScroll}>*/}
                    <div className="content-wrap align-start">

                        <div className="MyToggleContainer" data-toggle="toggle-container">

                            <div className="ImageContainer">
                                <img
                                    src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                    className="CropImageInL5" alt="Read and Understand Cover"/>
                            </div>



                            <div className="StoryContainer">
                                <p>
                                    {this._renderStory()}
                                </p>
                            </div>

                            {/*<div className="no-toggle-wrap">*/}
                                {/*<img src="./assets/images/samples/sample-12.png" alt="" />*/}

                            {/*</div>*/}

                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            오늘 배울 이야기 입니다.<br />
                            <strong>내용을 확인 후</strong> 다음 메뉴로 넘어가세요.
                            <hr className="divider" />
                                <div className="tip">
                                    내용을 확인하고 넘어가셔야<br />
                                    더욱 수월하게 진행이 가능합니다.
                                </div>
                        </div>
                    </div>

                </section>
                {/*{!this.state.isScrollBottom ?*/}
                    {/*""*/}
                    {/*:*/}
                    {/*<Footer_RNU*/}
                        {/*goNextMenu={this.props.goNextMenu}*/}
                        {/*handleCurrentMenu={this.props.handleCurrentMenu}*/}
                        {/*currentMenu={this.props.currentMenu}*/}
                    {/*/>*/}
                {/*}*/}
                <Footer_RNU
                    goNextMenu={this.props.goNextMenu}
                    handleCurrentMenu={this.props.handleCurrentMenu}
                    currentMenu={this.props.currentMenu}
                />



            </>
        )

    }

    _renderStory = () => {
        let story = [];
        for(let i = 0 ; i < this.props.contents.length; i ++) {
            story.push(<span key={i}>
                {this.props.contents[i].kor_content} <br />
            </span>)
        }
        return (
            story
        );
    }

    _handleScroll = (event) => {

        // //console.log("스크롤 감지", event);
        let element = event.target;

        //console.log("element.scrollHeight", element.scrollHeight);
        //console.log("element.scrollTop", element.scrollTop);
        //console.log("element.clientHeight", element.clientHeight);
        if(element.scrollHeight - element.scrollTop <= element.clientHeight) {
            this.setState({
                isScrollBottom: true,
            }, () => {
                $('.ShowAnimation').slideDown("fast");
                //$('.ShowAnimation').show('slide',{direction:'down'});

            })


            // $('.ShowingOnBottom').removeClass
            //$('.ShowingOnBottom').slideDown();
        }

    }





}

export default withContents()(ReadAndUnderstand);