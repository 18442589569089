import React from 'react';


const Footer_Theory = (props) => {
    return (

        <section className="view-footer">
            {
                props.phase === 1 ?
                    <button
                        type="button"
                        className="f-btn btn-next-page"
                        onClick={() => props.goNextMenu()}
                    >
                        다음 페이지
                    </button> :
                    props.pass ?

                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={() => props.handlePhase(1)}
                        >
                            다음 페이지
                        </button>

                        :
                        ""
            }
        </section>


    )
}


export default Footer_Theory;
    
    