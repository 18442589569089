import React, {Component} from 'react';
import {
    brTagActivator, calculateScore,
    getCurrentMenuInfo,
    FailScore, uploadScoreAl, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_Blinking from "../../Footer_Blinking";
import {TweenLite} from "gsap/TweenLite";

class Blinking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            totalScore:0,
            err: null,
            errCount: 0,
            tryCount:0,
            pass: false,
            count: 0,
            isTesting: false,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
            hasResult: false,

        };


    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.hasResult ?
                            <>
                                <div className="score-poplayer" id="score-poplayer">
                                    <div className="content">
                                        <p>
                                            <span className="sr-only">SCORE</span>
                                            <strong> {isIOS ? "Pass" : this.state.totalScore} </strong>
                                        </p>
                                        <button type={"button"} className="btn-answer"
                                           onClick={() => {$('#speak-poplayer').wPopLayer();}}>원어민듣기</button>
                                    </div>
                                </div>

                                {/*<p className = "score-help-block" > {this.state.pass ? "":`${FailScore}점 이상이어야 통과할 수 있습니다. 다시 테스트 하세요.`}</p>*/}
                            </>
                            :
                                <div className="l4-memorize">
                                    <h2>{this.props.contents[this.state.wordInfo.num].eng_content}</h2>
                                </div>
                        }

                    </div>
                    {
                        this.state.hasResult ?
                            ""
                            :
                            <div className="lecture-step">
                                <span className="current">{this.state.wordInfo.num + 1}</span>/<span className="total">{this.props.contents.length}</span>
                            </div>
                    }


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    this.state.hasResult ?
                                        <>
                                            잘 하셨어요. <br/>
                                            더 좋은 결과를 원하시면 <strong>다시 테스트</strong> 하세요.
                                        </>
                                        :

                                        <>
                                            화면에 나오는 영어 단어를 읽어주세요.
                                        </>

                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                    {this._renderAnswer()}
                </section>
                <Footer_Blinking
                    {...this.props}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    isTesting={this.state.isTesting}
                    waiting={this.state.waiting}
                    setResult={this._setResult}
                    handleError={this._handleError}
                    startTest={this._startTest}
                    err={this.state.err}
                    setHowlerMute={this.props.setHowlerMute}
                />

            </>
        )

    };


    // _audioPlay = (lang, idx) => {
    //     if (this.props.playingIndex === idx && this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }



    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["정답을 확인 후 다시 한번", <br/>, <strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
            })
        }


    };


    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        //console.log("NET Component did received result. index is: " + index + " and result is " + result);
        this.setState({
            result: {
                ...this.state.result,
                [index]: result
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 갯수만큼 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.props.contents.length) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);
            setTimeout(() => {
                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                    this.setState({
                        waiting: false,
                    })
                }
            }, 1000);
        }

        //contents.length만큼 결과를 받으면 show Result.
        if (this.state.count >= this.props.contents.length) {
            this._showResult();
            let totalScore = isIOS ? 0 : this._calculateTotalScore();

            uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드

            //let pass = totalScore >= FailScore;

            //this._decidePass(pass);
            this._decidePass(true); //난이도 조정을 위해 무조건 통과

        }
    };

    _updateWordInfo = (num, bool) => {
        if(bool){
            this.setState({
                wordInfo: {
                    num: num,
                    getResult: bool,
                },
            })
        } else {
            this.setState({
                wordInfo: {
                    num: num,
                    getResult: bool,
                },
            })
        }

    };

    _showResult = () => {

        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count: 0,
            isTesting: false,
            hasResult: true,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
        });

        this.props.openTip();

        $(function(){
            TweenLite.to( "#score-poplayer", 1, {
                y:0,
                delay:0,
                yPercent:0,
                opacity:1
            })

        });

        ////console.log("the recorded results are:", this.state.result);
    };

    _calculateTotalScore(){
        let sum = 0;

        for(let i = 0 ; i < this.props.contents.length; i ++){
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);

        }



        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / this.props.contents.length);
        }

        this.setState({
            totalScore:totalScore
        });

        return totalScore;


    }

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }



        if (this.state.errCount >= 1) {

            // this.setState({
            //     result: '##ERROR',
            //     err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
            //         <div className="tip">테스트 버튼을 눌러 다시 한번 발음해 보세요.</div>],
            //     pass: true,
            //     waiting: false,
            // });

            //에러가 한번 이상 발생하면 그냥 에러 결과를 입력하고, 다음 문제로 넘어감

            this._setResult("잘 인식되지 않았습니다.", this.state.wordInfo.num);

            return;
        }

        this.props.openTip();
        this.setState({
            err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
                <div className="tip">{this.props.contents[this.state.wordInfo.num].eng_content} <br/>{this.props.contents[this.state.wordInfo.num].kor_content}</div>],
            errCount: this.state.errCount + 1,
            waiting: false,
        })
    }

    _startTest = () => {
        this.setState({
            isTesting:true,
            result: {},
            err: null,
            errCount: 0,
            count: 0,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
            hasResult: false,
        });
        this.props.closeTip();
    }

    _renderAnswer = () => {
        ////console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 0 ; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p>
                        {this.props.contents[i].eng_content}<br />
                        <span>{this.props.contents[i].kor_content}</span>
                    </p>
                    <button
                        type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() =>
                            //this._ttsPlay(this.props.contents[i].eng_content)
                            // this._audioPlay("eng",i)
                            this.audioPlay(i)
                        }
                    >발음듣기</button>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    }


    _ttsPlay = (text) => {
        if(window.speechSynthesis.speaking){ //재생중일땐 Return;
            return;
        }
        let tts = new SpeechSynthesisUtterance(text);
        tts.lang = 'en-US';
        tts.rate = 1;
        tts.pitch = 1;
        window.speechSynthesis.speak(tts);
    }

}


export default withContents()(Blinking);
    
    