import React, { Component } from 'react';
import axios from 'axios';
import {apiKey} from "../../secret/keys";
axios.defaults.headers.common['Authorization'] = apiKey;
const withLessonList = () => (WrappedComponent) => {
    return class extends Component {
        constructor(props){
            super(props);
            this.state = {
                //rootURL: 'http://127.0.0.1:8080/lesson/list',
                //rootURL: 'http://api.itconline.co.kr/lesson/list',
            }
        }

        componentWillReceiveProps(nextProps){
            ////console.log("with Lesson List, componentWillReceiveProps called.")
            //console.log("withLessonList's nextProps is:", nextProps);
            if(!this.props.userInfo){
                this._getLessonList(nextProps.serverData.step_code);
            }
        }

        componentDidMount(){
            //console.log("withLessonList's nextProps is:", this.props);
            if(this.props.userInfo){
                //console.log("inLessonList, userinfo is :",this.props.userInfo);
                // if(this.props.serverData.step_code === 'L8' || this.props.serverData.step_code === 'L9' || this.props.serverData.step_code === 'L10' || this.props.serverData.step_code === 'L11'){ //PT가 있는 스텝코드의 경우는 menu_no도 조회하여, PT Lock 여부를 결정해야 함.
                //     this._getLessonList(this.props.serverData.step_code);
                //
                // } else {
                //     this._getLessonList(this.props.serverData.step_code);
                // }

                this._getLessonList(this.props.serverData.step_code);

            }
        }

        _getLessonList(stepCode){
            Promise.all([
                //axios.get(this.state.rootURL + `/${stepCode}`.replace(/\s/g, '')),  //Lesson List. http://127.0.0.1:8080/lesson/list/L1
                //axios.get(this.props.apiUrl + `/lesson/list/${stepCode}`.replace(/\s/g, '')),
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/lesson/list/${stepCode}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })

            ])
                .then(([resLessonList]) => {
                    const lessonList = resLessonList.data;
                    this.setState({
                        ...this.state,
                        lessonList,
                    });
                })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });
        }


        render(){
            ////console.log("withLessonList's state is :", this.state);
            return (
                <WrappedComponent {...this.props}
                                  lessonList={this.state.lessonList}
                />
            );
        }
    }

};

export default withLessonList;