import React, {Component} from 'react';

import withContents from "../../withContents";
import Footer_Practice_A from '../../Footer_Practice_A';
import Footer_Practice_B from '../../Footer_Practice_B';
import Footer_Practice_C from '../../Footer_Practice_C';
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";
import Score from '../../Score';
import {
    brTagActivator,
    getCurrentMenuInfo,
    calculateScore,
    trimStr,
    FailScore,
    uploadAnswerAl,
    trimStrForUpload, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";

class Practice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isInitialized: false,
            wordsContents: [],
            practiceContents: [],
            t_questions: [],
            t_answers: [],
            numOfPage: 0,
            activatedWords: null,
            isStart: false,
            isTesting: false,
            count: 0,
            waiting: false,
            phase: 0, //기본값 0
            hasResult: false,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            err: null,
            errCount: 0,
            pass: false,
            tryCount: 0, //Pass Non-Pass 정하기 위한 스테이트
            totalScore: 0, //총 점수
            listenComplete: false,
            isComplete: false,
            userAnswers: null,
            answerResult: {},
            speaking: false,
            elemInputs: [[]],
            isFillComplete: false,

        };
        this._div = null;


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.isInitialized && nextProps.contents) {
            let wordsContents = [];
            let practiceContents = [];
            let t_questions = [];
            let t_answers = [];
            let elemInputs = [];
            let userAnswer = [];
            for (let i = 0; i < nextProps.contents.length; i++) {

                if (nextProps.contents[i].c_code_no === "1") {
                    wordsContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "2") {
                    practiceContents.push(nextProps.contents[i]);
                    t_questions.push(nextProps.contents[i].t_question.split('|'));
                    t_answers.push(nextProps.contents[i].t_answer.split('|'));
                }
            }


            //Init ElemInputs
            elemInputs = new Array(practiceContents.length);
            userAnswer = new Array(practiceContents.length);
            for (let i = 0; i < practiceContents.length; i++) {
                elemInputs[i] = new Array(t_questions[i].length);
                userAnswer[i] = new Array(t_questions[i].length);
            }

            return ({
                wordsContents: wordsContents,
                practiceContents: practiceContents,
                t_questions: t_questions,
                t_answers: t_answers,
                isInitialized: true,
                elemInputs: elemInputs,
                userAnswers: userAnswer, //elemInputs와 이니셜라이징이 같음.
            })
        }

        if (nextProps.isPlayingComplete && !prevState.listenComplete) {
            return ({
                listenComplete: true,
            })
        }

        return null;

    }
    componentDidUpdate(prevProps, prevState) {

        if(!prevState.hasResult && this.state.hasResult){
            //점수 출력시 나오는 애니메이션
            $('.word-list li').each(function (index) {
                let $score = $('.score', $(this)),
                    animationTime = 0.6,    // 애니메이션 전체 시간
                    delay = (index - 1) * 0.3;  // 각 스코어별 delay

                TweenLite.to($score, animationTime, {
                    y: 0,
                    delay: delay,
                    yPercent: 0,
                    opacity: 1
                })
            });
        }

        //정답을 모두 적었는지 확인하는 FOR문
        if (!prevState.isFillComplete) {
            for (let i = 0; i < this.state.practiceContents.length; i++) {
                for (let j = 0; j < this.state.t_questions[i].length; j++) {
                    if (this.state.userAnswers[i][j] === undefined || this.state.userAnswers[i][j] == null) {
                        return;
                    }

                    if (i === this.state.practiceContents.length - 1 && j === this.state.t_questions[i].length - 1) {
                        this.setState({
                            isFillComplete: true,
                        })
                    }
                }
            }
        }

        if(prevState.phase !== this.state.phase && this.state.phase === 2) { //결과화면으로 이동 시
            this._div.scrollTop = 0;
        }
    }


    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }


        if (this.state.isTesting) {
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest());
        }

        if (this.state.phase === 1) {
            return (this._renderPractice());

        }

        if (this.state.phase === 2) {
            return (this._renderResult());

        }


        return (
            this._renderMain()
        )
    }

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">


                        {
                            this.state.hasResult ?
                                this._renderWordsWithResult()
                                :
                                this._renderWords()
                        }
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            직독직해에 사용하는 표현들입니다.<br/>다 외우신 후 테스트 하세요.
                            <hr className="divider"/>
                            {/*<div className="tip">원어민 발음을 듣고 넘어가시면<br/>이어지는 스토리 듣기에 도움이 될거에요.</div>*/}
                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_Practice_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest={this._startTest}
                    hasResult={this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                   // speaking={this.state.speaking}
                />
            </>

        );
    };

    _renderPractice = () => {
        // //console.log("render main is called again");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">

                        <div className="top-tip">각 문장에서 해당 부분을 찾고, 뜻을 파악해 보세요.</div>

                        {this._renderProblems()}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        다음 빈 칸에 해당되는 답을 입력하세요.<br/>
                                        정답을 <strong>모두</strong> 입력하신 후<br/>
                                        정답 보기 버튼을 눌러 정답을 확인하세요.
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_Practice_C
                    {...this.props}
                    c_code={this.props.c_code}
                    listenComplete={this.state.listenComplete}
                    handleError={this._handleError}
                    handlePhase={this._handlePhase}
                    //speaking={this.state.speaking}
                    checkCorrect={this._checkCorrect}
                />
            </>

        );
    };

    _ttsPlay = (text) => {
        if (window.speechSynthesis.speaking) { //재생중일땐 Return;
            return;
        }
        let tts = new SpeechSynthesisUtterance(text);
        tts.lang = 'en-US';
        tts.rate = 1;
        tts.pitch = 1;
        window.speechSynthesis.speak(tts);
        this.setState({
            speaking: true,
        });
        tts.onend = () => {
            this.setState({
                speaking: false,
            })
        }

    }

    _onWordsClick = (key) => {
        //console.log(key);
        if (this.state.speaking) { //음원 재생중 중복 실행 방지
            return;
        }

        if(this.state.activatedWords === key && this.props.isPlaying){
            return;
        }

        //this.props.audioPlay("eng", key);
        //this.props.audioPlayWithURL(this.state.wordsContents[key].eng_url);

        let src = `${this.state.wordsContents[key].step_code}/${this.state.wordsContents[key].lesson_no}/${this.state.wordsContents[key].eng_url}`;

        this.props.setHowler(
            src
        );
        //this._ttsPlay(this.state.wordsContents[key].eng_content);
        this.setState({
            activatedWords: key,
        })
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };
    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.props.closeTip();
        this.setState({isTesting: true});
    };

    _goWordsList = () => {
        this.setState({
            isTesting: false,
            err: null,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            count: 0,
        });
    };

    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
                err: ["정답을 확인 후 다시 한번", <br/>, <strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
                totalScore: 0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };


    _renderTest = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.state.wordsContents[this.state.wordInfo.num].kor_content}</h2>
                        </div>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br/>뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_Practice_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    goWordsList={this._goWordsList}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                   // speaking={this.state.speaking}
                    err={this.state.err}
                />
            </>
        )

    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // //console.log("M3w Component's err: "+this.state.err);
        this.setState({
            result: {
                ...this.state.result,
                [index]: result,
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            totalScore: this.state.totalScore + calculateScore(result, this.state.wordsContents[index].eng_content),
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.state.wordsContents.length) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);

            setTimeout(() => {

                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                }

                this.setState({
                    waiting: false,
                });
            }, 1000);
        }

        //결과를 다 받았다면,
        if (this.state.count >= this.state.wordsContents.length) {
            this._showResult();
            this._decidePass(this.state.totalScore / this.state.wordsContents.length >= FailScore || isIOS);
        }

    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count: 0,
            isTesting: false,
            hasResult: true,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
        });

        //console.log("the recorded results are:", this.state.result);
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }


        if (this.state.errCount >= 1) {
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };

    _renderWords = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }} className={this.state.activatedWords === i ? "active" : ""}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                </li>)
        }

        return (
            <ul className="word-list word-list-grid-12">
                {wordsList}
            </ul>
        );
    };

    _renderWordsWithResult = () => {
        let wordsList = [];
        for (let i = 0; i < this.state.wordsContents.length; i++) {
            let key = i;
            wordsList.push(
                <li className={"CursorPointer"} key={key} id={key} onClick={() => {
                    this._onWordsClick(i)
                }}>
                    <div className="word">{this.state.wordsContents[i].eng_content}</div>
                    <div className="txt">{this.state.wordsContents[i].kor_content}</div>
                    <div className="score score-blue">
                        {
                            isIOS ?
                                "A"
                                :
                                <Score scoreType={1}
                                       result={this.state.result[i]}
                                       original={this.state.wordsContents[i].eng_content}/>
                        }

                    </div>

                </li>)
        }

        return (
            <ul className="word-list word-list-grid">
                {wordsList}
            </ul>
        );
    };

    _handlePhase = (phase) => {

        this.setState({
            phase: phase,
            hasResult: false,
        })
    }


    _checkCorrect = () => {
        //비어있는 input이 있다면 해당 input으로 focus
        if(!this.state.isFillComplete) {
            //console.log("비어있는 정답칸이 있음");
            this.props.openTip();
            this.setState({
                err:["입력하지 않은 칸이 있습니다.",<br/>,<strong>모든 칸</strong>,"에 정답을 입력 하신 후",<br />,"정답 확인 버튼을 누르세요."]
            });

            for(let i = 0 ; i < this.state.userAnswers.length; i ++){
                for(let j = 0 ; j < this.state.userAnswers[i].length; j++){
                    if(this.state.userAnswers[i][j] == null || this.state.userAnswers[i][j] === undefined){
                        this.state.elemInputs[i][j].focus();
                        this.state.elemInputs[i][j].classList.add("warn");
                        return;
                    }
                }
            }

        } else {
            let answers = '';
            for(let i = 0 ; i < this.state.userAnswers.length; i ++){
                for(let j = 0 ; j < this.state.userAnswers[i].length; j++){
                    answers = answers + trimStrForUpload(this.state.userAnswers[i][j]);
                    if(j !== this.state.userAnswers[i].length -1){
                        answers = answers + ',';
                    }
                }
                if( i !== this.state.userAnswers.length - 1){
                    answers = answers + '|';
                }
            }
            //console.log(answers);

            //유저 정답 업로드
            uploadAnswerAl(this.props.apiUrl, answers, this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,'T', this.props.serverData.auth_type);

            this.setState({
                phase: 2,
            });
        }





    };
    //
    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    _renderProblems = () => {
        let problems = [];

        for (let i = 0; i < this.state.practiceContents.length; i++) {
            let inputs = [];
            let refInputs = this.state.elemInputs;
            for (let j = 0; j < this.state.t_questions[i].length; j++) {
                inputs.push(
                    <input
                        className="input"
                        placeholder={this.state.t_questions[i][j]}
                        onChange={event => this._inputChangeListener(i, j, event)}
                        value={this.state.userAnswers[i][j]}
                        onKeyDown={event => this._keyListener(i, j, event)}
                        ref={ref => {
                            refInputs[i][j] = ref;

                        }}
                        autoFocus={i === 0 && j === 0}
                        onFocus={() => {
                            //console.log(window.scrollY);
                            window.scrollTo(window.scrollX, window.scrollY);
                        }}
                    />
                )

            }

            if(this.state.elemInputs !== refInputs){
                this.setState({
                    elemInputs:refInputs
                });
            }

            problems.push(
                <li className="group">
                    <label className="origin"><strong>{i + 1}. </strong>{this.state.practiceContents[i].eng_content}
                    </label>
                    <div className="input-group">
                        {inputs}
                    </div>
                </li>
            )
        }

        return (
            <ul className="sentence-practice small l7">
                {problems}
            </ul>

        )
    }

    _renderResult = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start" ref={ref => {this._div = ref}}>
                        <div className="top-tip">정답을 확인해 보세요.</div>
                        {this._renderResultList()}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            결과를 확인해 보세요. <br/>
                        </div>
                    </div>
                </section>
                <Footer_Practice_C
                    {...this.props}
                    c_code={this.props.c_code}
                    phase={this.state.phase}
                />
            </>


        )
    }



    _renderResultList = () => {
        let problems = [];

        for (let i = 0; i < this.state.practiceContents.length; i++) {
            let results =[];
            for(let j = 0 ; j < this.state.userAnswers[i].length; j++){
                results.push(
                    <input
                        className={trimStr(this.state.t_answers[i][j]) === trimStr(this.state.userAnswers[i][j]) ? "input correct" : "input wrong"}
                        value={
                            trimStr(this.state.t_answers[i][j]) === trimStr(this.state.userAnswers[i][j]) ?
                                `${this.state.t_questions[i][j]}: ${this.state.t_answers[i][j]}`
                                :
                                `${this.state.t_questions[i][j]}: ${this.state.t_answers[i][j]} (나의 오답: ${this.state.userAnswers[i][j]})`}
                    />
                )
            }

            problems.push(
                <li className="group">
                    <label className="origin"><strong>{i + 1}. </strong>{this.state.practiceContents[i].eng_content}</label>
                    <div className="input-group">
                        {results}
                    </div>
                    <p className="best-answer">{this.state.practiceContents[i].kor_content}</p>
                </li>
            )


        }

        return (
            <ul className="sentence-practice small">
                {problems}
            </ul>

        )
    }



    //idx = contents idx, num = questions num
    _inputChangeListener = (idx, num, event) => {
        let userAnswer = event.target.value;
        //console.log(userAnswer);
        let currentUserAnswers = this.state.userAnswers;

        currentUserAnswers[idx][num] = userAnswer;

        this.setState({
            userAnswers: currentUserAnswers,

        });
    };

    _keyListener = (i, j, event) => {
        if (event.keyCode === 13) { //enter를 누른 경우
            if (this.state.isFillComplete) {
                //this._handlePhase(2); //결과 페이지로 이동
                this._checkCorrect();
            } else {
                //console.log("현재 input 좌표:" + i + ", " + j);
                if (j + 1 >= this.state.t_questions[i].length) {
                    if (i + 1 >= this.state.practiceContents.length) {
                        this._checkCorrect();
                        return;
                    }
                    this.state.elemInputs[i + 1][0].focus();
                } else {
                    this.state.elemInputs[i][j + 1].focus();
                }
            }

        }
    }

}


export default withContents()(Practice);

