import React, { Component } from 'react';
import axios from 'axios';
// import {Howl} from 'howler';
import {apiKey} from "../../secret/keys";
import ReactHowler from "react-howler";
axios.defaults.headers.common['Authorization'] = apiKey;
let playingAudio = null;
let mTimeout = null;
const withContentsReviewWords = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            contents: null,
            //soundRootURL: 'https://study.itcenglish.com/contents/ts/sound',
            soundRootURL: 'https://dev.itcenglish.com/contents/ts/sound',
            //soundRootURL: 'http://localhost:3000/contents/ts',
            playingIndex: 0,
            audioList: [],
            isPlaying: false,
            isAllPlaying: false,
            isPlayingComplete:false,
            // isAllPlayingComplete:false,
            c_code:null,
            playing:false,
            audioSrc:null,
            callback:null,
        };



        componentDidMount(){
            this.initialize();
        }


        componentWillUnmount() {
           //this._audioStop();
           clearTimeout(mTimeout);
        }

        componentDidUpdate(prevProps, prevState){
            if(prevState.c_code !== this.state.c_code){
                this.setState({
                    contents:null,
                    isAllPlayingComplete:false,
                });
                this.initialize();
            }
        }

        static getDerivedStateFromProps (nextProps, prevState) {

            if(nextProps.c_code !== prevState.c_code){
                //console.log("nextProps.c_code :", nextProps.c_code);
                //console.log("prevState.c_code :", prevState.c_code);

                return({
                    c_code:nextProps.c_code
                })
            }

            return null;
        }


        initialize() {

            Promise.all([
                axios({
                    method:'get',
                    url: `${this.props.apiUrl}/contents/review/${this.props.lessonInfo.step_code}/${this.props.lessonInfo.lesson_no}/${this.state.c_code}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ])
            .then(([resContents]) => {
                // //console.log("component will mount contents is:", res);
                const contents = resContents.data;
                //console.log("API에서 받아온 CONTENTS:", contents);
                this.setState({
                    ...this.state,
                    contents: contents,
                    //menuList,
                });
            })
            .catch(function (err) {
                //console.log(err);
            })
            .then(function () {

            });
        };

        // _audioFakePlay(index){ //playingIndex 만 업데이트 함. memorizeB에서 사용함.
        //     this.setState({
        //         playingIndex: index,
        //     });
        // }

        // _audioPlay(lang, index){
        //     let url = "";
        //
        //     this.setState({
        //         playingIndex: index,
        //     });
        //
        //     if(!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     if(lang ==="kor") {
        //         if(!this.state.contents[index].kor_url) {return;}
        //         url = this.state.contents[index].kor_url;
        //     } else {
        //         url = this.state.contents[index].eng_url;
        //     }
        //     //let audio = new Audio(process.env.PUBLIC_URL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`);
        //     playingAudio = new Audio(this.state.soundRootURL + `/sound/${this.state.contents[index].step_code}/${this.state.contents[index].lesson_no}/${url}`);
        //
        //     //audio.play();
        //     playingAudio.play();
        //     this.setState({
        //         isPlaying:true,
        //         playingAudioURL: this.state.soundRootURL + `/sound/${this.state.contents[index].step_code}/${this.state.contents[index].lesson_no}/${url}`,
        //     })
        //     playingAudio.onended = () => {
        //         this.setState({
        //             isPlaying:false,
        //         })
        //     };
        //     playingAudio.onerror = () => {
        //         console.log("Error occurred on play audio source");
        //         this.setState({
        //             isPlaying:false,
        //         })
        //     }
        // }

        // _audioPlayAllTwice(){
        //
        //     let audioList = [];
        //     if(this.state.contents === null) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //
        //     if(this.state.audioList.length <= 1) { //플레이리스트가 없다면(첫 함수 콜) 플레이 리스트 생성후 재생시작.
        //         this.setState({
        //             playingIndex: 0,
        //         }, () => {
        //             for(let i = 0 ; i < this.state.contents.length; i ++){
        //                 audioList[i*2] = [`${this.state.soundRootURL}/sound/${this.state.contents[i].step_code}/${this.state.contents[i].lesson_no}/${this.state.contents[i].kor_url}`];
        //                 audioList[i*2 + 1] = [`${this.state.soundRootURL}/sound/${this.state.contents[i].step_code}/${this.state.contents[i].lesson_no}/${this.state.contents[i].eng_url}`];
        //
        //             }
        //             //console.log(audioList);
        //             this.setState({
        //                 audioList:audioList,
        //
        //             }, () => {
        //                 let audio = new Howl({
        //                     src: this.state.audioList[this.state.playingIndex],
        //                     autoplay:true,
        //                     loop:false,
        //                     html5: true,
        //                     preload:true,
        //                     volume:this.state.playingIndex % 2 ? 0 : 0,
        //                     onend:() => {
        //                         // this.skip('next');
        //                         if(this.state.playingIndex < this.state.audioList.length  - 1) {
        //                             this._audioAutoSkip();
        //                         }
        //                     },
        //                     onloaderror: () => {
        //                         console.log("Error occured on load sound source.");
        //                         if(this.state.playingIndex < this.state.audioList.length  - 1) {
        //                             this._audioAutoSkip();
        //                         }
        //                     },
        //                     onplayerror: () => {
        //                         console.log("Error occured on play sound source.");
        //                         if(this.state.playingIndex < this.state.audioList.length  - 1) {
        //                             this._audioAutoSkip();
        //                         }
        //                     },
        //                 });
        //
        //                 //console.log("playing audio. audio url is:", this.state.audioList[this.state.playingIndex]);
        //                 audio.play();
        //                 this.setState({
        //                     isAllPlaying:true,
        //                     isPlayingComplete:false,
        //                 })
        //             })
        //         });
        //         ////console.log(audioList);
        //     } else { //플레이 리스트가 이미 있다면 재생리스트에서 시작.
        //         let audio = new Howl({
        //             src: this.state.audioList[this.state.playingIndex],
        //             autoplay:true,
        //             loop:false,
        //             html5: true,
        //             preload:true,
        //             volume:this.state.playingIndex % 2 ? 0 : 0,
        //             onend:() => {
        //                 // this.skip('next');
        //                 if(this.state.playingIndex >= this.state.audioList.length  - 1) {
        //                     //console.log("Finished!");
        //                     //this.props.startTest(false); //종료
        //
        //                     this.setState({ //오디오 리스트 초기화 해버림.
        //                         audioList:[],
        //                         isAllPlaying:false,
        //                         isPlayingComplete:true,
        //                     })
        //                 } else {
        //                     this._audioAutoSkip();
        //                 }
        //             },
        //             onloaderror: () => {
        //                 console.log("Error occured on load sound source.");
        //                 if(this.state.playingIndex >= this.state.audioList.length  - 1) {
        //                     this.setState({ //오디오 리스트 초기화 해버림.
        //                         audioList:[],
        //                         isAllPlaying:false,
        //                         isPlayingComplete:true,
        //                     })
        //                 } else {
        //                     this._audioAutoSkip();
        //                 }
        //             },
        //             onplayerror: () => {
        //                 console.log("Error occured on play sound source.");
        //                 if(this.state.playingIndex >= this.state.audioList.length  - 1) {
        //                     this.setState({ //오디오 리스트 초기화 해버림.
        //                         audioList:[],
        //                         isAllPlaying:false,
        //                         isPlayingComplete:true,
        //                     })
        //                 } else {
        //                     this._audioAutoSkip();
        //                 }
        //             },
        //         });
        //
        //         audio.play();
        //         this.setState({
        //             isAllPlaying:true,
        //             isPlayingComplete:false,
        //         })
        //     }
        //
        // }

        // _audioStop(){
        //     //playingAudio = new Audio(this.state.playingAudioURL);
        //     if(playingAudio !== null){
        //         playingAudio.pause();
        //         this.setState({
        //             isPlaying:false,
        //         })
        //     }
        //
        //     if(this.state.howlAudio !== null){
        //         this.state.howlAudio.stop();
        //         this.setState({
        //             isPlaying:false,
        //         })
        //     }
        //
        // }
        //
        // _audioPause(){
        //     if(playingAudio !== null){
        //         playingAudio.pause();
        //     }
        //
        // }



        // _audioAutoSkip = () => {
        //     this.setState({
        //         playingIndex: this.state.playingIndex + 1,
        //     }, () => {
        //         this._audioPlayAllTwice();
        //     });
        //
        // };

        handleOnLoadError = () => {
            console.log("on load error");
        }

        setHowler = (src, callback) => {
            this.setState({
                audioSrc:src,
                playing:true,
                callback
            })
        }

        audioStop = () => {
            this.setState({
                playing:false,
            })
        }



        render(){
            const { contents } = this.state;
            return(
                <>
                    {
                        this.state.audioSrc &&
                        <ReactHowler
                            playing={this.state.playing}
                            src={`${this.props.soundRootURL}/${this.state.audioSrc}`}
                            onLoadError={this.handleOnLoadError}
                            html5={true}
                            onEnd={() => {
                                console.log("end");
                                if (typeof this.state.callback === "function") {
                                    this.state.callback();
                                }
                            }
                            }
                            // onEnd={() => console.log("end")}
                        />
                    }
                    <WrappedComponent {...this.props}
                                      contents={contents}
                                      setHowler={this.setHowler}
                                      audioStop={this.audioStop}
                                      // audioPause={this._audioPause.bind(this)}
                                      // audioStop={this._audioStop.bind(this)}
                                      // audioPlay={this._audioPlay.bind(this)}
                        // audioPlayAllTwice={this._audioPlayAllTwice.bind(this)}
                                      playingIndex={this.state.playingIndex}
                                      isPlaying={this.state.isPlaying}
                                      isAllPlaying={this.state.isAllPlaying}
                                      isPlayingComplete={this.state.isPlayingComplete}
                    />
                </>
            )
        }
    }

};

export default withContentsReviewWords;