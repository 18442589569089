import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo, isMobile, isIOS} from "../../../../asset/js/myFunctions";
import Footer_Test from "../../Footer_Test";
import $ from "jquery";


class Test extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            thisMenuNumber: 102,
            isStart: false,
            maxPage: 0,
            currentPage: 0,
            result: "",
            isTesting: false,
            isPlaying: false,
            letUpload: false,
            // audioURL: null,
            isUploadComplete: false,
            err: null,
            errCount: 0,
            currentPhase: 0,  //0부터 contents.length * 2 - 1 까지 늘어남
            isTestingComplete: false, //녹음 종료 신호 보냄
            hasResult: false,

        }

        this.timeout = null;
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevState.hasResult && this.state.hasResult) {
            //녹음파일을 받으면 팁창 오픈
            this.props.openTip();
        }
    }

    render() {
        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }


        return (
            this._renderMain()
        )


    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer"
                            onClick={() => this._start()}>시작하기
                    </button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        //console.log("Current isTesting :",this.state.isTesting);
        return (
            <>
                <section className={"view-body tip-opened"}>


                    <div className="content-wrap">
                        <div
                            className={this.state.hasResult ? "story-practice-header opened" : "story-practice-header"}>
                            <button type="button" className="btn btn-meaning" onClick={() => this._showStory()}>해석 보기
                            </button>
                        </div>
                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={() => {
                                        this._playRecordedFile()
                                    }}>녹음듣기
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={() => {
                                        this._uploadRecordedFile()
                                    }}>녹음제출
                                    </button>
                                </li>
                            </ul>
                            :
                            !this.state.isTesting ?
                                ""
                                :
                                this.state.currentPhase % 2 === 0 ?
                                    <p className="direct-translation type1">
                                        {this.props.contents[Math.floor(this.state.currentPhase / 2)].eng_content}
                                    </p>
                                    :
                                    <p className="direct-translation type2">한국어로 뜻을 말하세요.</p>

                        }



                    </div>


                    {!this.state.hasResult && this.state.isTesting ?
                        <div className="lecture-step">
                            <span className="current">{Math.floor(this.state.currentPhase / 2) + 1}</span> /
                            <span className="total">{this.props.contents.length}</span>
                        </div>
                        :
                        ""
                    }
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            직독직해를 시작합니다.<br/>
                                            TEST를 누른 후 출력되는 <strong>영어</strong>를 먼저 읽고<br/>
                                            <strong>한국어</strong>로 뜻을 말하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>

                    {this._renderStory()}


                </section>
                <Footer_Test
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest={this._startTest}
                    isPlaying={this.state.isPlaying}
                    playRecordedFile={this._playRecordedFile}
                    stopRecordedFile={this._stopRecordedFile}
                    hasResult={this.state.hasResult}
                    checkResult={this._checkResult}
                    // getAudioURL={this._getAudioURL}
                    audioUploadComplete={this._audioUploadComplete}
                    letUpload={this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    audioStop={this.props.audioStop}
                    isTestingComplete={this.state.isTestingComplete}
                    currentPhase={this.state.currentPhase}
                />

            </>
        )

    };


    _setResult = (result) => {
        //console.log("결과받음: ", result);
        this.setState({
            result: result
        })
    }
    // _getAudioURL = (url) => {
    //     if(this.state.audioURL === null){
    //         this.setState({
    //             audioURL: url,
    //         })
    //     }
    // };

    _audioUploadComplete = () => {
        this.setState({
            isUploadComplete: true,
        })
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ");
        //this.props.upload();
        this.setState({
            letUpload: true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this._endLesson();
    };


    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult: bool,
        })
    };

    _startTest = () => {
        this.setState({
            isTesting: true,
        });

        this._controlPhase();

    }

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }


        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
            isTesting: false,
        })
    };

    //글자 갯수에 따라 콘텐츠를 넘김
    _controlPhase = () => {


        let duration = this.props.contents[Math.floor(this.state.currentPhase / 2)].eng_content.length * 120;
        //console.log("duration :",duration);
        if ((this.state.currentPhase + 1) >= (this.props.contents.length * 2 - 1)) { //currentPhase가 증가된 값이 마지막 이라면 duration 후에 녹음 종료 및 초기화
            this.timeout = setTimeout(() => {
                this.setState({
                    currentPhase: 0,
                    isTestingComplete: true, //녹음 종료 신호 보냄
                });
            }, duration)

        } else { //다음 페이즈가 남아 있으면 페이즈 1 증가 후, 다시 controlPhase 호출
            this.timeout = setTimeout(() => {
                this.setState((prevState) => ({
                    currentPhase: prevState.currentPhase + 1,
                }), this._controlPhase());
            }, duration);
        }

    }

    _showStory = () => {
        // $('.common-popup').fadeToggle('fast');
        $('.speak-poplayer').wPopLayer();
    }


    _renderStory = () => {
        // //console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 0 ; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p className={"l5-comprehension"}>
                        {this.props.contents[i].kor_content.replace(/[<>]/g,"")}<br />
                        <span>{this.props.contents[i].eng_content.replace(/[<>]/g,"")}</span>
                    </p>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>해석 보기</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    };

}

export default withContents()(Test);