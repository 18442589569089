import React from 'react';


const Footer_TRL = (props) => {
    return (

        <section className="view-footer">
            <button type="button" className="f-btn">Empty</button>
            {
                props.isPlaying ?
                    <button
                        type="button"
                        className="f-btn f-center btn-listen"
                    >듣는 중</button>
                    :
                    <button
                        type="button"
                        className="f-btn f-center btn-listen-all"
                        onClick={() => props.playAll(0)}>전체 듣기</button>
            }


            {
                props.isAllPlayingComplete ?    //전체 듣기 완료 후, 다음 버튼 출력
                    <button
                        type="button"
                        className="f-btn btn-next-page"
                        onClick={() => props.goNextMenu()}>다음메뉴</button>
                    :
                    <button type="button" className="f-btn">Empty</button>
            }

        </section>


    )
}

// class IOPanel_6 extends Component {
//
//     constructor(props) {
//         super(props);
//     }
//
//     _test3Words = () => {
//         this.props.startRecognition();
//
//     }
//
//     render() {
//         //console.log(this.props.currentPage + " and "+ this.props.maxPage);
//         //console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
//
//         return (
//
//         <section className="view-footer">
//             <button type="button" className="f-btn btn-prev-page" onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu - 1)}}>이전</button>
//             {this.props.hasResult ?
//                 <>
//                 <button
//                     type="button"
//                     className="f-btn f-center btn-retest"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 <button
//                     type="button"
//                     className="f-btn btn-next-page"
//                     onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu+1)}}>
//                     다음메뉴</button>
//                 </>
//                 :
//                 (<>
//                     <button
//                     type="button"
//                     className="f-btn f-center btn-test"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 </>)
//             }
//         </section>
//
//         );
//     }
//
// }

export default Footer_TRL;
    
    