import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo, isMobile, trimStrForUpload,
    uploadAnswerAl
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_WriteSentence from "../../Footer_WriteSentence";

class WriteSentence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            pass: false,
            userAnswers:{
            },
            isResultPage:false,
            isFillComplete:false,
            err:null,
            inputs:[],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevState.isFillComplete && prevProps.contents) {
            for(let i = 0 ; i < this.props.contents.length; i ++){
                if(this.state.userAnswers[i] === undefined || this.state.userAnswers[i] == null){
                    ////console.log("비어있는 칸이 있음");
                    ////console.log(this.state.userAnswers[i]);
                    break;
                }

                if(i === this.props.contents.length - 1){
                    this.setState({
                        isFillComplete: true,
                    })
                }
            }
        }

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.isResultPage){
            return(
                this._renderResult()
            );
        }


        return (
            this._renderMain()
        )




    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    };


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={isMobile||$(window).width() < 1200 ? "content-wrap justify-start":"content-wrap"}>
                        <div className="top-tip">다음 문장을 영어로 작문하세요.</div>
                        {this._renderSentence()}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            다음 한글 문장을 영어로 작문하세요.<br />
                            모두 작문해야 다음으로 넘어갈 수 있습니다.
                            <hr className="divider" />
                            <div className="tip">
                                작문에는 여러가지 정답이 있을 수 있습니다.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_WriteSentence
                    {...this.props}
                    pass={this.state.pass}
                    isFillComplete={this.state.isFillComplete}
                    goResultPage={this._goResultPage}
                />

            </>

        );
    };

    _renderResult = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">
                        {
                            this.state.isResultPage ?
                            ""
                            :
                            <div className="top-tip">다음 문장을 영어로 작문하세요.</div>
                        }

                        {this._renderAnswer()}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            잘 하셨어요. <strong>나의 작문</strong>과 <strong>모범 답안</strong>을 확인해 보세요.<br />
                            나의 작문은 <strong>학습 기록에</strong> 남으니<br/>
                            더 좋은 기록을 원하시면 <strong>다시 테스트</strong>하세요
                            <hr className="divider"/>
                            <div className="tip">
                                작문에는 여러가지 정답이 있을 수 있습니다.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_WriteSentence
                    {...this.props}
                    pass={this.state.pass}
                    goResultPage={this._goResultPage}
                    isResultPage={this.state.isResultPage}
                    retest={this._retest}
                />

            </>

        );
    };




    _audioPlay = (lang, idx) => {
        if (this.props.isPlaying) {
            //this.props.audioStop();
            return;
        }
        this.props.audioPlay(lang, idx);

    };

    _renderSentence = () => {
        let sentence = [];
        let refInputs = this.state.inputs;
        for (let i = 0; i < this.props.contents.length; i++) {
            sentence.push(
                <li className="group" key={i}>
                    <label className="origin">{this.props.contents[i].kor_content}</label>
                    <input
                        className="input"
                        onChange={event => this._inputChangeListener(i, event)}
                        onKeyDown = {event => this._keyListener(i, event)}
                        value={this.state.userAnswers[i]}
                        autoFocus={i === 0}
                        ref={ref => {
                            refInputs[i] = ref;
                            // this.state.inputs[i] = ref
                        }}
                    />
                </li>
            )
        }

        if(this.state.inputs !== refInputs){
            this.setState({
                inputs:refInputs
            })
        }

        return (
            <ul className="sentence-practice">
                {sentence}
            </ul>

        )
    };

    _renderAnswer = () => {
        let answers = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            answers.push(
                <li className="group" key={i}>
                    <label className="origin">{this.props.contents[i].kor_content}</label>
                    <p className="my-answer">{this.state.userAnswers[i]}</p>
                    <p className="best-answer">{this.props.contents[i].eng_content}</p>
                </li>
            )
        }

        return (
            <ul className="sentence-practice">
                {answers}
            </ul>

        )
    };


    //idx = contents idx,
    _inputChangeListener = (idx, event) => {
        let userAnswer = event.target.value;
        this.setState({
            userAnswers:{
                ...this.state.userAnswers,
                [idx]:userAnswer,
            }

        });
    };

    _goResultPage = () => {
        this.props.openTip();

        let answers = '';
        for(let i = 0 ; i <this.props.contents.length; i ++){
            answers = answers + trimStrForUpload(this.state.userAnswers[i]);
            if(i !== this.props.contents.length -1 ){
                answers = answers + '|';
            }
        }

        //롸이팅 기록 업로드
        uploadAnswerAl(this.props.apiUrl, answers, this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,'T',this.props.serverData.auth_type);

        this.setState({
            isResultPage:true,

        })
    };

    _retest = () => {
        this.setState({
            isResultPage:false,
            userAnswers:{},
            isFillComplete:false,
        })
    };


    _keyListener = (i, event) => {
        if (event.keyCode === 13) { //enter를 누른 경우
            if (this.state.isFillComplete) {
                this._goResultPage();
            } else {
                if(i+1 >= this.props.contents.length) { //outofindex인경우 리턴
                    return;
                }
                this.state.inputs[i+1].focus();
            }
        }
    }



}


export default withContents()(WriteSentence);
    
    