import React, {Component} from 'react';

import withRecognizerRDT2 from "./withRecognizerRDT2";


class Footer_RDT_A extends Component {


    render() {
        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>

                <button
                    type="button"
                    className="f-btn f-center btn-test"
                    onClick={() => this.props.startTest()}>
                    테스트</button>
                {this.props.pass ?
                    <button type="button" className="f-btn btn-next-page" onClick={() => {
                        this.props.goNextMenu()
                    }}>다음메뉴</button>
                    :
                    <button type="button" className="f-btn">Empty</button>}
            </section>


        );
    }

}

export default withRecognizerRDT2()(Footer_RDT_A);
    
    