import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_KoreanEnglish_A extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,

        };

    }

    _testContinue = () => {

        if(isIOS) {
            this.props.fakeRecognitionStart();
            setTimeout(() => {this.props.fakeRecognitionEnd()},2500)
        } else {
            this.props.startRecognition();
        }


        this.props.closeTip();
    };

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.isTesting && nextProps.letStartRecognition && !nextProps.listening) {
            //console.log("음성인식 시작");
            if(isIOS) {
                nextProps.fakeRecognitionStart();
                setTimeout(() => {nextProps.fakeRecognitionEnd()},2500)
            } else {
                nextProps.startRecognition();
            }

            nextProps.handleLetStartRecognition(false);
        }

        return null;
    }


    // componentDidMount(){
    //     this._test3Words();
    // }
    componentDidUpdate() {

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);
        if(this.props.isPlaying){
            return(
                <section className="view-footer">
                    <button type="button" className="f-btn f-center btn-listen"/>
                </section>
            )
        }
        if (!this.props.isTesting) {
            return (
                <section className="view-footer">
                    {
                        this.props.currentPage >= 2 ?
                            <button
                                type="button"
                                className="f-btn btn-prev"
                                onClick={() => this.props.prev()}
                            >이전</button>
                            :

                            <button
                                type="button"
                                className="f-btn"
                            >
                                Empty
                            </button>
                    }
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.startTest()
                        }}
                    >
                        TEST
                    </button>
                    {
                        this.props.checkPoint > this.props.currentPage ?
                            this.props.currentPage >= this.props.contents_a.length ?

                                <button
                                    type="button"
                                    className="f-btn btn-next"
                                    onClick={() => {
                                        this.props.handlePhase(2); //직독직해 단계로 이동
                                    }}
                                >
                                    NextPhase
                                </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn btn-next"
                                    onClick={() => {
                                        this.props.next()
                                    }}
                                >
                                    Next
                                </button>
                            :
                            this.props.pass ?
                                this.props.currentPage >= this.props.contents_a.length ?

                                    <button
                                        type="button"
                                        className="f-btn btn-next"
                                        onClick={() => {
                                            this.props.handlePhase(2); //직독직해 단계로 이동
                                        }}
                                    >
                                        NextPhase
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="f-btn btn-next"
                                        onClick={() => {
                                            this.props.next()
                                        }}
                                    >
                                        Next
                                    </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn"
                                >
                                    Empty
                                </button>
                    }
                </section>
            )
        }

        return (

            <section className="view-footer">

                {
                    this.props.isPlaying ?
                        <button type="button" className="f-btn f-center btn-listen"/> :

                        this.props.listening ? (
                                <MicVisualizer/>
                            )
                            :
                            (
                                this.props.waiting ?
                                    <button type="button" className="f-btn">Empty</button>
                                    :
                                    this.props.err === null ?

                                        <button type="button" className="f-btn">Empty</button>
                                        :
                                        <>

                                            <button type="button" className="f-btn">
                                                Empty
                                            </button>
                                            <button
                                                type="button"
                                                className="f-btn f-center btn-test"
                                                onClick={() => {
                                                    this._testContinue()
                                                }}>
                                            </button>
                                            <button type="button" className="f-btn">Empty</button>
                                        </>
                            )
                }

            </section>

        );
    }

}

export default withRecognizer()(Footer_KoreanEnglish_A);
    
    