import React, {Component} from 'react';
import DiffMatchPatch from 'diff-match-patch';
import {calculateScore, FailScore} from "../../asset/js/myFunctions";

class Score extends Component {

    constructor(props) {
        super(props);
        this._diff = this._diff.bind(this);
        this._prettyDiff = this._prettyDiff.bind(this);
        this.state = {
            original:"",
            result:"",
        }

    }



    static getDerivedStateFromProps(nextProps, prevState) {
        /**
         * 여기서 Score를 계산하기
         */
        if(nextProps.result !== null && nextProps.result !=="") { //result 가 null 이 아닌 경우
            if(prevState.result === nextProps.result && prevState.original === nextProps.original) { //같은 경우 return;
                return null;
            }

            let score = calculateScore(nextProps.result,nextProps.original);
            let abcScore = null;


            if(nextProps.step_code === "J1" || nextProps.step_code === "J2" || nextProps.step_code === "J3") {
                if(score < FailScore) {
                    score = FailScore;
                }
            }


            if (score < FailScore) { //FailScore = 70
                abcScore = "F";
            } else if (score >= FailScore && score < 80) {
                abcScore = "C";
            } else if (score >= 80 && score < 84) {
                abcScore = "C+";
            } else if (score >= 84 && score < 88) {
                abcScore = "B";
            } else if (score >= 88 && score < 92) {
                abcScore = "B+";
            } else if (score >= 92 && score < 98) {
                abcScore = "A";
            } else if (score >= 98) {
                abcScore = "A+";
            } else {
                //console.log("점수가 잘못 계산되었습니다.");
            }

            return {
                score,
                abcScore,
                original: nextProps.original,
                result: nextProps.result,
            }
        }



        ////console.log("received result is: ",nextProps.result);
        ////console.log("received original is: ",nextProps.original);
        if(nextProps.original !== prevState.original && prevState.original !== "") { //original 이 바뀌면 초기화.
            return {
               original: nextProps.original,
               result: "",
            }
        }

        return null;

    }


    _prettyDiff = (diffs) => {
        const DIFF_DELETE = -1;
        const DIFF_INSERT = 1;
        const DIFF_EQUAL = 0;

        let html = [];
        let pattern_amp = /&/g;
        let pattern_lt = /</g;
        let pattern_gt = />/g;
        let pattern_para = /\n/g;
        for (let x = 0; x < diffs.length; x++) {
            let op = diffs[x][0];    // Operation (insert, delete, equal)
            let data = diffs[x][1];  // Text of change.
            let text = data.replace(pattern_amp, '&amp;').replace(pattern_lt, '&lt;')
                .replace(pattern_gt, '&gt;').replace(pattern_para, '&para;<br>');
            switch (op) {
                case DIFF_INSERT:
                    html[x] =`<strong>` + text + `</strong>`;
                    break;
                case DIFF_DELETE:
                    //html[x] = '<del style="background:#ffe6e6;">' + text + '</del>';
                    //html[x] = '';
                    break;
                case DIFF_EQUAL:
                    html[x] = text;
                    break;
                default:
                    break;
            }
        }
        return {__html : html.join('')};
        //return html;
    };

    _diff(original, userResult){
        ////console.log(userResult);
        const dmp = new DiffMatchPatch();
        const diff = dmp.diff_main(original.toLowerCase(), userResult.toLowerCase());

        return diff;
        //return {__html:dmp.diff_prettyHtml(diff)};
    }


    // _getABCScore = () => {
    //     let score = calculateScore(this.props.result,this.props.original);
    //     let abcScore = null;
    //
    //
    //     if(this.props.step_code === "J1" || this.props.step_code === "J2" || this.props.step_code === "J3") {
    //         if(score < FailScore) {
    //             score = FailScore;
    //         }
    //     }
    //
    //
    //     if (score < FailScore) { //FailScore = 30
    //         abcScore = "F";
    //     } else if (score >= FailScore && score < 80) {
    //         abcScore = "C";
    //     } else if (score >= 80 && score < 84) {
    //         abcScore = "C+";
    //     } else if (score >= 84 && score < 88) {
    //         abcScore = "B";
    //     } else if (score >= 88 && score < 92) {
    //         abcScore = "B+";
    //     } else if (score >= 92 && score < 98) {
    //         abcScore = "A";
    //     } else if (score >= 98) {
    //         abcScore = "A+";
    //     } else {
    //         //console.log("점수가 잘못 계산되었습니다.");
    //     }
    //
    //     ////console.log("abcScore is : ",abcScore);
    //     // return (
    //     //     abcScore
    //     // )
    //
    //     return score.toString();
    //
    // };


    render() {
        if(!this.props.original) {return(<div>Not defined original text</div>)}

        if(this.props.result === "0" || this.props.result == null || this.props.result === ""){
            ////console.log("this.state.result is 0");
            return (<></>)
        }

        if(this.props.result === "iOSResult"){
            return(<>Good</>)
        }

        return( this.props.scoreType === 1 ? //scoreType === 0 이면 text, 1이면 abc
                <>
                    {this.state.abcScore}
                </>

                :
                (<div dangerouslySetInnerHTML={this._prettyDiff(this._diff(this.props.original, this.props.result))} />)

        );

        //return(<div>{JSON.stringify(this.props.result)}</div>)
    }

}




Score.defaultProps = {
    score: "임시점수A",
};

export default Score;
    
    