import React, {Component} from 'react';
import {
    brTagActivator, calculateScore,
    getCurrentMenuInfo,
    FailScore,uploadScore
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import Footer_PTP_L3 from "../../Footer_PTP_L3";
import {TweenLite} from "gsap/TweenLite";

class PatternPracticeJr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            isStart: false,
            result: {},
            wordInfo: {
                num: 1,  //0은 패턴
                getResult: false,
            },
            contents: null,
            err: null,
            errCount: 0,
            pass: false,
            waiting: null,
            count: 0,
            isTesting: false,
            selectedBubble: null,
            letStartRecognition: false,
            showResult: 0,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // //console.log(this.props);

        if (nextProps.contents && prevState.contents === null) {

            return {
                contents: nextProps.contents,
            }
        }

        if (nextProps.currentPage - 1 !== prevState.index) { //페이지 변경시
            return {
                index: nextProps.currentPage - 1,
                result: {},
                err: null,
                errCount: 0,
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {


        // this.props.myJS();
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if (this.state.showResult !== 0) {
            return (
                this._renderResult()
            )
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    {
                        this.state.isTesting ?
                            <>

                                <div className="content-wrap">
                                    <ul className="word-list">
                                        <li
                                            className={
                                                this.props.contents[0].tpk_content === "A" ?
                                                    this.props.isPlaying ? "" : "active"
                                                    :
                                                    this.props.isPlaying ? "active" : ""
                                            }>
                                            {
                                                this.props.contents[0].tpk_content === "A" ?
                                                    <>
                                                        <span className="decl">You</span>
                                                        <div
                                                            className="ism-expression-kr">
                                                            {this.props.contents[this.state.wordInfo.num*2 - 1].kor_content}
                                                        </div>
                                                        <div className="ism-txt">
                                                            ({this.props.contents[this.state.wordInfo.num*2 - 1].tpk_content})
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div
                                                            className="ism-expression">
                                                            {
                                                                this.props.contents[this.state.wordInfo.num*2-1].eng_content.replace(/[<>]/g, "")
                                                            }
                                                        </div>
                                                        <div className="ism-txt">
                                                            {this.props.contents[this.state.wordInfo.num*2-1].kor_content}
                                                        </div>
                                                    </>
                                            }


                                        </li>
                                        <li
                                            className={
                                                this.props.contents[0].tpk_content === "B" ?
                                                    !this.props.isPlaying ? "active" : ""
                                                    :
                                                    !this.props.isPlaying ? "" : "active"
                                            }>
                                            {
                                                this.props.contents[0].tpk_content === "B" ?
                                                    <>
                                                        <span className="decl">You</span>
                                                        <div
                                                            className="ism-expression-kr">
                                                            {this.props.contents[this.state.wordInfo.num*2].kor_content}
                                                        </div>
                                                        {/*<div className="ism-txt">*/}
                                                            {/*({this.props.contents[this.state.wordInfo.num*2].tpk_content})*/}
                                                        {/*</div>*/}
                                                    </>

                                                    :
                                                    <>
                                                        <div
                                                            className="ism-expression">
                                                            {this.props.contents[this.state.wordInfo.num*2].eng_content.replace(/[<>]/g, "")}
                                                        </div>
                                                        <div className="ism-txt">
                                                            {this.props.contents[this.state.wordInfo.num*2].kor_content}
                                                        </div>
                                                    </>
                                            }

                                        </li>
                                    </ul>
                                </div>
                                < div className="lecture-step">
                                    < span className="current">{this.state.wordInfo.num}</span>/<span
                                    className="total">{Math.floor(this.props.contents.length/2)}</span>
                                </div>


                                <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                                        onClick={this.props.initTipToggle}>팁 토글
                                </button>
                                <div className={`tip-container tip-bg-${this.props.step123}`}>
                                    <div className="message-box">
                                        {
                                            this.state.err === null ?
                                                <>
                                                    질문을 잘 듣고 제시된 상황에 맞추어 <br/>
                                                    <strong>영어로 대답</strong>하세요. <br/>
                                                    <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                                </>
                                                :
                                                this.state.err.map((textOrHtml, index) => <span
                                                    key={index}>{textOrHtml}</span>)
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="content-wrap">

                                    <div className="top-tip">오늘의 표현</div>
                                    <ul className="word-list voice-box big">
                                        <li>
                                            <div className="word">{this.props.contents[0].eng_content}</div>
                                            <div className="txt">{this.props.contents[0].kor_content}</div>
                                            <span className="btn-wrap">
                                            {/*<a href="#" className="btn-play-sound">발음듣기</a>*/}
                                                <button className="btn-play-sound CursorPointer"
                                                        onClick={
                                                            // () => this._audioPlay("eng",0)
                                                            () => this.audioPlay(0)
                                                        }
                                                >
                                                발음듣기
                                            </button>
                                        </span>
                                        </li>
                                    </ul>

                                </div>

                                <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                                        onClick={this.props.initTipToggle}>팁 토글
                                </button>
                                <div className={`tip-container tip-bg-${this.props.step123}`}>
                                    <div className="message-box">
                                        {
                                            this.state.err === null ?
                                                <>
                                                    오늘의 표현입니다.<br/>
                                                    다음 표현을 <strong>외우시고</strong> 다음으로 넘어가세요.<br/>
                                                    이 표현을 활용해 <strong>패턴 연습</strong>합니다.
                                                </>
                                                :
                                                this.state.err.map((textOrHtml, index) => <span
                                                    key={index}>{textOrHtml}</span>)
                                        }
                                    </div>
                                </div>
                            </>
                    }

                </section>
                <Footer_PTP_L3
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    letStartRecognition={this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                    pass={this.state.pass}
                />
            </>
        );
    };

    _renderResult = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.showResult === 1 ?
                                <div className="score-poplayer" id="score-poplayer">
                                    <div className="content">
                                        <p>
                                            <span className="sr-only">SCORE</span>
                                            <strong>
                                                {this.state.totalScore}
                                            </strong>
                                        </p>
                                        <a className="btn-answer CursorPointer" onClick={() => {
                                            this.setState({
                                                showResult:2
                                            })
                                        }}>해석보기
                                        </a>
                                    </div>
                                </div>
                                :
                                <div className="l3-conversation">
                                    <div className="answer-wrap">
                                        <div className="answer my-answer">
                                            {this._renderMyAnswer()}
                                        </div>
                                        <div className="answer correct-answer">
                                            {this._renderAnswer()}
                                        </div>
                                    </div>
                                </div>

                        }


                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            나의 대답과 모범 대답을 비교해 보세요.
                            <hr className="divider"/>
                            <div className="tip">대답에는 여러가지 정답이 있을 수 있습니다.</div>
                        </div>
                    </div>
                </section>

                <Footer_PTP_L3
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    letStartRecognition={this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                />
            </>
        )
    };

    // _audioPlay = (lang, idx) => {
    //     //console.log("play");
    //     if (this.props.isPlaying) { //동시 여러번 음원재생 방지
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }



    _setResult = (result, index) => {
        if(this.props.contents[0].tpk_content === 'B') { //B타입 패턴연습
            //결과를 받으면 결과를 state에 저장함.
            this.setState({
                result: {
                    ...this.state.result,
                    [index]: result
                },
                count: this.state.count + 1,
                err: null,
                waiting: true,
                errCount: 0,
            });

            //결과를 받았으니, wordInfo를 업데이트함.
            this._updateWordInfo(index, true);

            //하지만 결과를 전체 갯수만큼 받지 못했으면 wordInfo를 1초후에 다시 갱신함
            if (this.state.count < Math.floor(this.props.contents.length/2)) {
                //console.log("1초 후에 wordInfo 업데이트 됨.");
                //console.log("현재 count 는: " + this.state.count);
                setTimeout(() => {
                    this.setState({
                        waiting: false,
                    });
                    if (this.state.isTesting) {
                        //console.log("wordInfo 업데이트!");
                        this._updateWordInfo(this.state.wordInfo.num+1 , false);
                        this._continueRecognition(this.state.wordInfo.num*2-1);
                    }
                }, 1000);
            }

            //contents.length만큼 결과를 받으면 show Result.
            if (this.state.count >= Math.floor(this.props.contents.length/2)) {
                setTimeout(() => {
                    this._showResult();
                    this._decidePass(true); //무조건 통과

                    let totalScore = this._calculateTotalScore();

                    uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드

                }, 1000);

                // if(this.state.totalScore / this.props.contents.length < FailScore) {
                //     this._decidePass(false);
                // } else {
                //     this._decidePass(true);
                // }
            }
            //A타입 패턴연습
        } else { //먼저 음성인식 결과를 받으면 1초후에 다음 음원 재생 하고, 재생 끝난 후 페이지 넘기고 녹음시작
            //결과를 받으면 결과를 state에 저장함.
            this.setState({
                result: {
                    ...this.state.result,
                    [index]: result
                },
                count: this.state.count + 1,
                err: null,
                waiting: true,
                errCount: 0,
            });

            //결과를 받았으니, wordInfo를 업데이트함.
            this._updateWordInfo(index, true);


            //결과를 다 못받았다면 음원 재생 후 다음 페이지로 이동.
            if (this.state.count < Math.floor(this.props.contents.length/2)) {
                setTimeout(() => {  //1초후 실행
                    //음원 인덱스는 2,4,6,8..
                    // this.props.audioPlayWithCb("eng", this.state.wordInfo.num * 2, () => {
                    //     //음원 재생 끝나면 다음 페이지로 이동
                    //     this._updateWordInfo(this.state.wordInfo.num + 1, false);
                    //     //그 후 녹음 시작
                    //     this._handleLetStartRecognition(true);
                    // })

                    let src = `${this.props.contents[this.state.wordInfo.num * 2].step_code}/${this.props.contents[this.state.wordInfo.num * 2].lesson_no}/${this.props.contents[this.state.wordInfo.num * 2].eng_url}`;
                    this.props.setHowler(
                        src,
                        () => {
                            //음원 재생 끝나면 다음 페이지로 이동
                            this._updateWordInfo(this.state.wordInfo.num + 1, false);
                            //그 후 녹음 시작
                            this._handleLetStartRecognition(true);
                        },
                    );

                } , 1000);
                //contents.length만큼 결과를 받으면 음원 재생 후 show Result.
            } else {
                let totalScore = this._calculateTotalScore();
                setTimeout(() => {
                    // this.props.audioPlayWithCb("eng", this.state.wordInfo.num * 2, () => {
                    //     this._showResult();
                    //     this._decidePass(true); //무조건 통과
                    //
                    // });

                    let src = `${this.props.contents[this.state.wordInfo.num * 2].step_code}/${this.props.contents[this.state.wordInfo.num * 2].lesson_no}/${this.props.contents[this.state.wordInfo.num * 2].eng_url}`;
                    this.props.setHowler(
                        src,
                        () => {
                            this._showResult();
                            this._decidePass(true); //무조건 통과
                        },
                    );

                    uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드

                }, 1000);

            }







        }

    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },

        });
        //
        // this.props.audioPlayWithCb("eng",num,() => {
        //     this.setState({
        //         selectedBubble:1
        //     });
        //     this._handleLetStartRecognition(true);
        // })

    };

    _continueRecognition = (nextNum) => {
        this.setState({
            selectedBubble: 0
        });
        // this.props.audioPlayWithCb("eng", nextNum, () => {
        //     this.setState({
        //         selectedBubble: 1
        //     });
        //     this._handleLetStartRecognition(true);
        // })

        let src = `${this.props.contents[nextNum].step_code}/${this.props.contents[nextNum].lesson_no}/${this.props.contents[nextNum].eng_url}`;
        this.props.setHowler(
            src,
            () => {
                this.setState({
                    selectedBubble: 1
                });
                this._handleLetStartRecognition(true);
            },
        );
    }


    _startTest = () => {
        ////console.log("컨티뉴리코그나이져");
        this.props.closeTip();
        if(this.props.contents[0].tpk_content === 'B'){
            this.setState({
                isTesting: true,
                selectedBubble: 0,
                result: {},
                wordInfo: {
                    num: 1,
                    getResult: false,
                },
                showResult: 0,
                err: null,
                errCount: 0,
                waiting: false,
                count: 0,
                currentPhase:1,
            });
            // this.props.audioPlayWithCb("eng", 1, () => { //0은 패턴. 1번부터 재생함.
            //     this.setState({
            //         selectedBubble: 1
            //     });
            //     this._handleLetStartRecognition(true);
            // })
            let src = `${this.props.contents[1].step_code}/${this.props.contents[1].lesson_no}/${this.props.contents[1].eng_url}`;
            this.props.setHowler(
                src,
                () => {
                    this.setState({
                        selectedBubble: 1
                    });
                    this._handleLetStartRecognition(true);
                },
            );

        } else {  //A타입 패턴연습은 먼저 녹음부터 시작함.
            this.setState({
                isTesting: true,
                selectedBubble: 0,
                result: {},
                wordInfo: {
                    num: 1,
                    getResult: false,
                },
                showResult: 0,
                err: null,
                errCount: 0,
                waiting: false,
                count: 0,
                currentPhase:1,
            });
            this._handleLetStartRecognition(true);
        }

    };

    _handleLetStartRecognition = (bool) => {
        this.setState({
            letStartRecognition: bool,
        })
    };

    _showResult = () => {
        this.setState({
            showResult: 1,
            isTesting: false,
        });

        TweenLite.to( ".score-poplayer", 1, {
            y:0,
            delay:0,
            yPercent:-10,
            opacity:1
        });
    };

    _renderMyAnswer = () => {
        let answers = [];

        if(this.props.contents[0].tpk_content === "B") {
            for (let i = 1; i <= Math.floor(this.props.contents.length/2); i++) {
                answers.push(
                    <li>
                        <div className="q">{this.props.contents[i*2-1].eng_content}</div>
                        <div className="a">{this.state.result[i]}</div>
                    </li>
                )
            }
        } else {
            for (let i = 1; i <= Math.floor(this.props.contents.length/2); i++) {
                answers.push(
                    <li>
                        <div className="q">{this.state.result[i]}</div>
                        <div className="a">{this.props.contents[i*2].eng_content}</div>
                    </li>
                )
            }
        }


        return (
            <ul>
                {answers}
            </ul>
        )
    };

    _renderAnswer = () => {
        let answers = [];

        for (let i = 1; i <= Math.floor(this.props.contents.length/2); i++) {
            answers.push(
                <li>
                    <div className="q">{this.props.contents[i*2-1].eng_content}</div>
                    <div className="a">{this.props.contents[i*2].eng_content}</div>
                </li>
            )
        }

        return (
            <ul>
                {answers}
            </ul>
        )
    }

    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["다시 한번 ", <strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,
                    <div className="tip">점수가 잘 나오지 않는다면 <br/>더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass: isPass,
                err: null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }



        if (this.state.errCount >= 1) {


            //에러가 한번 이상 발생하면 그냥 에러 결과를 입력하고, 다음 문제로 넘어감

            this._setResult("잘 인식되지 않았습니다.", this.state.wordInfo.num);

            return;
        }

        this.props.openTip();
        this.setState({
            err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
                <div className="tip">{this.props.contents[this.state.wordInfo.num*2].kor_content} <br/>{this.props.contents[this.state.wordInfo.num*2].eng_content}</div>],
            errCount: this.state.errCount + 1,
            waiting: false,
        })
    }

    _calculateTotalScore(){
        let sum = 0;

        if(this.props.contents[0].tpk_content === "B"){ //B타입일 때 점수 계산방법
            for(let i = 1 ; i <= Math.floor(this.props.contents.length/2) ; i ++){
                sum += calculateScore(this.state.result[i], this.props.contents[i*2].eng_content);
            }
        } else { //A타입일 때 점수 게산 방법
            for(let i = 1 ; i <= Math.floor(this.props.contents.length/2) ; i ++){
                sum += calculateScore(this.state.result[i], this.props.contents[i*2 - 1].eng_content);
            }
        }

        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / Math.floor(this.props.contents.length/2));
        }

        this.setState({
            totalScore:totalScore
        });

        return totalScore;
    }

}


export default withContents()(PatternPracticeJr);
    
    