import React, { Component } from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import {isMobile} from "../../asset/js/myFunctions";



const withRecognizer = () => (WrappedComponent) => {
    //
    // let currentIndex = 0;
    // let lastDebounceTranscript = "";

    return class extends Component {
        state = {
            // show: false,
            listening: false,
            text:null,
            recognizerReady:false,
            isRecognizeSuccess: false,
        };

        initialize() {
            // alert("start recognition");
            const Recognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!Recognition) {
                alert(
                    '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
                );
                return;
            }

            this.recognition = new Recognition();
            this.recognition.lang = 'en-US';
            this.recognition.continuous = false;
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = event => {
                const text = event.results[0][0].transcript;
                // if(lastDebounceTranscript === text && currentIndex === this.props.wordInfo.num){
                //     return;
                // } else {
                //     lastDebounceTranscript = text;
                //     currentIndex = this.props.wordInfo.num;
                // }
                console.log('transcript', text);

                if(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)) { //어떤 이유로 음성인식을 한글로 받아옴.
                    this.setState({
                        text,
                        isRecognizeSuccess: true,
                    });
                   // this.props.handleError(text, this.props.wordInfo.num);
                    this.props.handleError(["오류: 음성인식이 한글로 인식 되었습니다.", <br />, "OS 버전이 정상적인 호환이 되지 않아 생기는 문제입니다.", <br />, "문제가 지속되면 ",<strong>휴대폰 설정 > 언어 및 입력 > 음성검색</strong>, <br />,"에서 언어를 영어로 변경해 주세요. "]);
                    this.setState({
                        text:null,
                    })

                } else {
                    this.setState({
                        text,
                        isRecognizeSuccess: true,
                    });


                    this.props.setResult(text, this.props.wordInfo.num);
                    // this.setState({
                    //     text:null,
                    // })
                }



            };

            // this.recognition.onspeechend = () => {
            //     //console.log('stopped');
            //
            //     this.setState({
            //         show: true,
            //     });
            // };

            this.recognition.onnomatch = event => {
                //console.log('no match');
                this.setState({ text: null });
            };

            this.recognition.onstart = () => {
                //console.log('Recognizer start');
                if(!isMobile) {
                    let startAudio = new Audio(StartSound);
                    startAudio.play();
                }

                // let startAudio = new Audio(StartSound);
                // startAudio.play();

                this.setState({
                    text:null,
                    listening: true,
                    recognizerReady:false,
                    isRecognizeSuccess: false,
                });
            };

            this.recognition.onend = () => {
                //console.log('Recognizer end');
                if(!isMobile){
                    let endSound = new Audio(EndSound);
                    endSound.play();
                }

                // if(this.props.audioPause() !== null){ //쉐도잉 할때, 녹음 종료되면 음원도 같이 종료됨.
                //     this.props.audioPause();
                // }

                if(this.state.text === null && this.state.isRecognizeSuccess === false && this.props.handleError){
                    this.props.handleError(["죄송합니다. 알아 듣지 못했습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"가급적 조용한 환경에서 수업해 주세요."]);
                }

                //reset
                this.setState({
                    listening: false,
                    text:null,
                    recognizerReady:true,
                    isRecognizeSuccess: false,
                })



                //this.endRecognition();


            };

            this.recognition.onerror = event => {
                console.log('error', event);
                //alert("error occured!");
                if(this.props.handleError){
                    //this.props.handleError("ERROR:"+event.toString());
                    this.props.handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                        <strong>원격 지원</strong>,"을 받아주세요."]);
                }
                this.setState({
                    // show: true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                });
            };
        }

        componentDidMount(){
            this.initialize(); //Initialize
            if(this.state.recognizerReady === false){
                //console.log("recognizer is ready");
                this.setState({
                    recognizerReady:true
                })
            }
        }

        componentWillUnmount() {
            this.recognition.stop();
        }

        startRecognition(){
            if(this.state.listening === true){
                //console.log("already listening");
                return;
            }
            //this.recognition.stop();
            this.recognition.start();
            //console.log("start recognition");
        }

        endRecognition(){
            this.recognition.stop();
        }
        //
        // handleClose(){
        //
        //     this.setState({
        //         show:false,
        //     })
        // }

        fakeRecognitionStart(){
            let startAudio = new Audio(StartSound);
            startAudio.play();

            //console.log("페이크 시작");
            this.setState({
                text:null,
                listening: true,
                recognizerReady:false,
                isRecognizeSuccess: false,
            });
        }

        fakeRecognitionEnd(){
            let endSound = new Audio(EndSound);
            endSound.play();
            //console.log("페이크 끝.");
            this.props.setResult("iOSResult", this.props.wordInfo.num);
            this.setState({
                text:null,
                isRecognizeSuccess: true,
                listening: false,
                recognizerReady:true,
            });
        }

        render(){
            return (
                <WrappedComponent {...this.props}
                                  recognizerReady={this.state.recognizerReady}
                                  text={this.state.text}
                                  listening={this.state.listening}
                                  startRecognition={this.startRecognition.bind(this)}
                                  endRecognition={this.endRecognition.bind(this)}
                                  fakeRecognitionStart={this.fakeRecognitionStart.bind(this)}
                                  fakeRecognitionEnd={this.fakeRecognitionEnd.bind(this)}
                                  // handleClose={this.handleClose.bind(this)}
                />
            );
        }
    }

};

export default withRecognizer;