import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {
    brTagActivator,
    calculateScore,
    FailScore,
    getCurrentMenuInfo, isIOS,
} from "../../../../asset/js/myFunctions";
import Footer_RDN from "../../Footer_RDN";


class Reading extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            isStart: false,
            maxPage: 0,
            currentPage:0,
            butRealMaxPage: 5,
            result:null,
            err:null,
            minDuration:0,
            listening:false,
            sumContents:"",
            score:null,
            pass:false,
            errCount:0,
            tryCount:0,
            isTesting:false,

        }

    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null && prevState.maxPage === 0){
            nextProps.setMaxPage(nextProps.contents.length);
            return({
                maxPage:nextProps.contents.length,

            })
        }

        if(nextProps.currentPage !== prevState.currentPage && nextProps.contents !== null){ //페이지 변경시
            nextProps.closeTip();
            return({
                currentPage: nextProps.currentPage,
                index: nextProps.currentPage -1,
                result:null,
                pass:false,
                score:null,
                err:null,
                errCount:0,
                tryCount:0,
                isTesting:false,
            })
        }

        return null;
    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap HideScrollBar align-start">

                        <div className="ImageContainer">
                            <img
                                src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                className="CropImageInL7" alt="ReadingCover"
                            />
                        </div>
                        <div className="StoryContainer">
                            <p>
                                {this._renderReading(this.state.index)}
                            </p>
                        </div>
                        {/*<h2>*/}
                            {/*{!this.state.listening ? this._calculateTotalScore() : ""}*/}
                        {/*</h2>*/}
                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.props.currentPage}</span> /
                        <span className="total">{this.state.butRealMaxPage}</span>
                    </div>


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        다음 스토리를 읽어보세요.<br/>
                                        <hr className="divider"/>
                                        <div className="tip">
                                            녹음이 도중에 멈춘다면<br />
                                            조금 더 빠르게 읽어주세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_RDN
                    {...this.props}
                    butRealMaxPage={this.state.butRealMaxPage}
                    setResult={this._setResult}
                    handleError={this._handleError}
                    maxDuration={this.state.maxDuration}
                    setListening = {this._setListening}
                    isListening={this.state.listening}
                    isTesting={this.state.isTesting}
                    score={this.state.score}
                    pass={this.state.pass}
                    checkTesting={this._checkTesting}
                    sumContents={this.state.sumContents}
                />

            </>
        )

    }

    _renderReading = (index) => {
        let reading = [];
        // //console.log(this.props.contents[0]);
        let length = 0;
        let sumContents = "";

        for(let i = 0 ; i < this.props.contents.length; i ++){
            // //console.log(this.props.contents[i].c_code_no);
            if(this.props.contents[i].c_code_no === (index + 1).toString()){
                ////console.log(this.props.contents[i].eng_content);
                reading.push(<>
                    {this.props.contents[i].eng_content} <br />
                </>);
                length = length + this.props.contents[i].eng_content.length;
                sumContents = sumContents + this.props.contents[i].eng_content;
            }
        }

        if(this.state.maxDuration !== length * 90){ //여러번 호출되는걸 방지..

            this.setState({
                maxDuration:length * 90,
                sumContents: sumContents,

            });
        }



        return(
            reading
        )

    }

    _getABCScore = (score) => {
        let abcScore = null;
        // if (score < FailScore) { //FailScore = 30
        //     abcScore = "F";
        // } else if (score >= FailScore && score < 40) {
        //     abcScore = "C";
        // } else if (score >= 40 && score < 50) {
        //     abcScore = "C+";
        // } else if (score >= 50 && score < 60) {
        //     abcScore = "B";
        // } else if (score >= 60 && score < 70) {
        //     abcScore = "B+";
        // } else if (score >= 70 && score < 85) {
        //     abcScore = "A";
        // } else if (score >= 85) {
        //     abcScore = "A+";
        // } else {
        //     //console.log("점수가 잘못 계산되었습니다.");
        // }

        if (score < FailScore) { //FailScore = 70
            abcScore = "F";
        } else if (score >= FailScore && score < 80) {
            abcScore = "C";
        } else if (score >= 80 && score < 84) {
            abcScore = "C+";
        } else if (score >= 84 && score < 88) {
            abcScore = "B";
        } else if (score >= 88 && score < 92) {
            abcScore = "B+";
        } else if (score >= 92 && score < 98) {
            abcScore = "A";
        } else if (score >= 98) {
            abcScore = "A+";
        } else {
            //console.log("점수가 잘못 계산되었습니다.");
        }

        ////console.log("abcScore is : ",abcScore);
        return (
            abcScore
        )

    };


    _setResult = (result) => {
        if(result === null){
            this.setState({
                result:null,
            });
            return;
        }

        let score = isIOS ? 0 : calculateScore(result,this.state.sumContents);
        let pass = score >= FailScore || isIOS;
        let abcScore = this._getABCScore(score);


        this.setState({
            result: result,
            err:null,
            score:abcScore,
        });


        this._decidePass(pass);

    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 ",<strong>테스트</strong>, "를 눌러 읽어주세요.",<br />,"C 이상이어야 통과입니다.",
                    <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 말해보세요.</div>],
            })
        } else {
            if(this.state.score === "A" || this.state.score === "A+" || this.state.score === "B+"){
                this.props.closeTip();
                this.setState({
                    pass:isPass,
                    err:null,
                })
            } else {
                this.props.openTip();
                this.setState({
                    pass:isPass,
                    err:["잘 하셨어요. 더 높은 점수를 원하시면",<br />,"다시 ", <strong>테스트</strong>, "를 눌러 읽어주세요.",
                        <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 말해보세요.</div>],
                })
            }


        }


    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        this.props.openTip();
        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
                hasResult: true,
                isReadyToRecognize: 0,
            });

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            isReadyToRecognize:0,
        })

    };


    _checkTesting = (bool) => {
        this.setState({
            isTesting:bool
        })
    }

}

export default withContents()(Reading);