import React, {Component} from 'react';



class Footer_ListenAndSolve_C extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
        };
    }


    componentDidUpdate() {

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);

        return (

            <section className="view-footer">

                {this.props.isPlaying ? (
                        <button
                            type="button"
                            className="f-btn btn-listen"
                            >
                        </button>
                    )
                    :
                    (
                        this.props.listenComplete ?
                            <>
                                <button
                                    type="button"
                                    className="f-btn btn-prev"
                                    onClick={() => {
                                        this.props.handlePhase(0)
                                    }}>
                                </button>
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this.props.handlePhase(2);
                                    }}>
                                </button>
                                <button type="button" className="f-btn">Empty</button>
                            </>
                            :
                            <>

                                <button
                                    type="button"
                                    className="f-btn btn-prev"
                                    onClick={() => {
                                        this.props.handlePhase(0)
                                    }}>
                                </button>
                                <button type="button" className="f-btn f-center">Empty</button>
                                <button type="button" className="f-btn">Empty</button>
                            </>
                    )
                }

            </section>

        );
    }

}

export default Footer_ListenAndSolve_C;
    
    