import React, {Component} from 'react';
import withContents from "../../../withContents";
import LoadingBar from "../../../../other/LoadingBar";
import $ from "jquery";
import axios from "axios/index";
import {getCurrentMenuInfo, brTagActivator} from "../../../../../asset/js/myFunctions";
import Footer_TPSP from '../../../Footer_TPSP';
import {apiKey} from "../../../../../secret/keys";
import DiffTextNoSound from "../../../../other/DiffTextNoSound";
axios.defaults.headers.common['Authorization'] = apiKey;

class SentencePractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contentsIdx:0,
            currentPage:1,
            isStart: false,
            err: null,
            errCount: 0,
            pass: false,
            userInput:"",
            isLoaded:false,
            shouldPressCode:0,
            diffResult:[],
            diffOriginal:[],
            sContents:[], //마침표 단위로 자른 콘텐츠
        };

        this.mTimeout = null;

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents && !prevState.isLoaded) { //콘텐츠 로딩 완료
            let sContents = [];
            for(let i = 0 ; i < nextProps.contents.length; i ++){
                let temp = nextProps.contents[i].eng_content.trim().replace(/[´’＇]/gi,'\'').replace(/[＂”]/gi,'"').replace(/<br>/gi,' ').replace(/\."/gi, `"`).replace(/Mr./gi,'Mr').replace(/Mrs./gi, 'Mrs').replace(/Mt./gi, 'Mt').replace(/C:/gi,'').replace(/B:/gi,'').split('.');
                for(let j = 0; j < temp.length; j++){
                    if(temp[j].trim() !== "") {
                        let types = ["?","!",",","\""];
                        if(types.includes(temp[j].slice(-1))) {
                            sContents.push(temp[j]);
                        } else {
                            sContents.push(temp[j] + ".");
                        }
                    }
                }

            }
            return({
                isLoaded:true,
                sContents,
                diffOriginal:sContents[0].split(''),
            })
        }


        if(prevState.currentPage !== nextProps.currentPage) {//페이지 변경시


            //console.log("페이지 변경에 따른 diffOriginal 변경");
            return({
                currentPage:nextProps.currentPage,
                contentsIdx:prevState.contentsIdx + 1,
                userInput:"",

            })
        }

        return null;

    }


    componentWillUnmount() {

    }

    // componentDidUpdate(prevProps, prevState) {
    //     this.props.myJS();
    // }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={"view-body"}>
                    <div className="content-wrap typing-game-01">
                        <div className="top-tip">다음 문장을 작성해 보세요.</div>

                        <div className={"typing-sentence"}>
                            <DiffTextNoSound diffOriginal={this.state.diffOriginal} />

                            <div className={"H30"} />
                        </div>

                        <div className="input-wrap sentence">
                            <input
                                key={"typingInput"}
                                id="typingInput"
                                onChange={event => this._handleInputChange(event)}
                                onKeyDown={event => this._onKeyDown(event)}
                                onKeyUp={event => this._onKeyUp(event)}
                                value={this.state.userInput}
                                autoComplete={"off"}
                                spellCheck={"off"}
                                autoFocus={true}
                                onPaste={(event) => {event.preventDefault()}}
                                onDrop={(event) => {event.preventDefault()}}
                            />

                        </div>

                        <div className={"keyboard"}>
                            {this._renderKeyboard()}
                        </div>

                    </div>

                </section>
                <Footer_TPSP
                    {...this.props}
                    pass={this.state.pass}
                    checkCorrect={this._checkCorrect}
                />

            </>

        );
    };

    _diffCheck = (original, userResult) => {
        if(userResult == null){
            return [];
        }

        if(original === userResult) {
            //맞은 경우 엔터에 pressed 표시함
            $(`#key_13`).addClass('pressed');
        } else {
            $(`#key_13`).removeClass('pressed');
        }

        let arrOriginal = original.split('');
        let arrUserResult = userResult.split('');

        let diffOriginal = [];
        for(let i = 0 ; i < arrOriginal.length; i ++){
            if(arrUserResult[i] == null) {
                diffOriginal[i] = <>{arrOriginal[i]}</>
            } else if(arrOriginal[i] === arrUserResult[i]) {
                diffOriginal[i] = <span className={"tp01-s-correct"}>{arrOriginal[i]}</span>
            } else {
                diffOriginal[i] = <span className={"tp01-s-wrong"}>{arrOriginal[i]}</span>
            }
        }

        return diffOriginal;
    }


    _checkCorrect = () => {
        if (this.state.userInput == null || this.state.userInput==="") {

            return;
        }


        if(this.state.userInput === this._trim(this.state.sContents[this.state.contentsIdx])) {

            if(this.state.contentsIdx+1 >= this.state.sContents.length){
                //다음 메뉴로 이동
                this.props.goNextMenu();
                return;
            }
            //통과
            this.setState({
                userInput:"",
                contentsIdx:this.state.contentsIdx+1,
               // diffResult:[],
                diffOriginal:this.state.sContents[this.state.contentsIdx+1].split(''),
            })
        }

    };


    _handleInputChange = (e) => {

        //let diffResult = this._diffCheck(this._trim(this.state.sContents[this.state.contentsIdx]), e.target.value.toString());
        let diffOriginal = this._diffCheck(this._trim(this.state.sContents[this.state.contentsIdx]), e.target.value.toString());

        //console.log("키 입력에 따른 diffOriginal 변경");
        this.setState({
            userInput: e.target.value.toString(),
            diffOriginal,
        });

    }

    _onKeyDown = (event) => {
        $(`#key_${event.keyCode}`).addClass('pressed');

        if (event.keyCode === 13) { //enter를 누른 경우
            this._checkCorrect();
        }

        // if (event.keyCode === 8) {
        //     // //console.log(this.state.inputs[i][j]);
        //     // if (this.state.inputs[i][j] == null || this.state.inputs[i][j] === "") {
        //     //     if (j >= 1) {
        //     //         this.inputs[i][j - 1].focus();
        //     //     }
        //     // }
        // }

    };

    _onKeyUp = (event) => {
        $(`#key_${event.keyCode}`).removeClass('pressed');
        //$(`#key_${16}`).removeClass('pressed'); //shift on
    };

    _renderKeyboard = () => {
        let buttons = [];

        let keys =  [
            {key:'Q', keyCode:81,rows:1},
            {key:'W', keyCode:87,rows:1},
            {key:'E', keyCode:69,rows:1},
            {key:'R', keyCode:82,rows:1},
            {key:'T', keyCode:84,rows:1},
            {key:'Y', keyCode:89,rows:1},
            {key:'U', keyCode:85,rows:1},
            {key:'I', keyCode:73,rows:1},
            {key:'O', keyCode:79,rows:1},
            {key:'P', keyCode:80,rows:1},
            {key:'A', keyCode:65,rows:2},
            {key:'S', keyCode:83,rows:2},
            {key:'D', keyCode:68,rows:2},
            {key:'F', keyCode:70,rows:2},
            {key:'G', keyCode:71,rows:2},
            {key:'H', keyCode:72,rows:2},
            {key:'J', keyCode:74,rows:2},
            {key:'K', keyCode:75,rows:2},
            {key:'L', keyCode:76,rows:2},
            {key:'Z', keyCode:90,rows:3},
            {key:'X', keyCode:88,rows:3},
            {key:'C', keyCode:67,rows:3},
            {key:'V', keyCode:86,rows:3},
            {key:'B', keyCode:66,rows:3},
            {key:'N', keyCode:78,rows:3},
            {key:'M', keyCode:77,rows:3},
            {key:'Tab',keyCode:9,rows:1},
            {key:'Enter', keyCode:13,rows:1},
            {key:'CapsLock', keyCode:20,rows:2},
            {key:'LShift', keyCode:16,rows:3},
            {key:'RShift', keyCode:'16B', right:true,rows:3},
            {key:'LCtrl', keyCode:17,rows:4},
            {key:'LAlt', keyCode:18,rows:4},
            {key:'RCtrl', keyCode:17, right:true,rows:4},
            {key:'RAlt', keyCode:18, right:true,rows:4},
            {key:'Space', keyCode:32,rows:4},
            {key:'[', keyCode:219,rows:1},
            {key:']', keyCode:221,rows:1},
            {key:';', keyCode:59,rows:2},
            {key:'"', keyCode:222,rows:2},
            {key:'<', keyCode:188,rows:3},
            {key:'>', keyCode:190,rows:3},
            {key:'/', keyCode:191,rows:3},
        ]

        for(let i = 0; i < keys.length; i ++){
            buttons.push(
                <button
                    type={"button"}
                    className={keys[i].right ? `rows-${keys[i].rows} right`:`rows-${keys[i].rows}`}
                    key={`key_${i}`}
                    data-keycode = {keys[i].keyCode === '16B' ? 16 : keys[i].keyCode}
                    id={`key_${keys[i].keyCode}`}
                >
                    {keys[i].key}
                </button>
            )

        }

        return(
            <>
                {buttons}
            </>
        )
    }

    _trim = (text) => {
        let trimmed;
        trimmed = text.replace(/[＂”]/gi,'"');
        trimmed = trimmed.replace(/[´’＇]/gi,'\'');
        //trimmed = trimmed.replace(/\s+$/, '');

        return trimmed.trim();
    }


}


export default withContents()(SentencePractice);
    
    