import React from 'react';


const Footer_ListenAndSolve_D = (props) => {
    return (

        <section className="view-footer">
            {
                props.hasResult ?
                    <button
                        type="button"
                        className="f-btn btn-next-page"
                        onClick={() => props.goNextMenu()}
                    >
                        다음메뉴
                    </button>
                    :
                    <button
                        type="button"
                        className="f-btn f-center btn-check-correct"
                        onClick={() => props.checkCorrect()}
                    >
                        정답확인
                    </button>
            }
        </section>


    )
}


export default Footer_ListenAndSolve_D;
    
    