import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";



class Footer_TKE2_B extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
        };

    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index){
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 녹음(음성인식)을 실행하고 index를 num에 맞춤.
            //console.log("received wordInfo is: "+ nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();
            if(isIOS){
                nextProps.fakeRecognitionStart();
                nextProps.audioPlayMute("eng",nextProps.wordInfo.num,
                    () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
                );
            } else {
                nextProps.startRecognition();
            }

            return{
                index: nextProps.wordInfo.num,
            }
        }

        return null;
    }

    render() {

        return (
            <section className="view-footer">
                    {this.props.listening ? (
                        <MicVisualizer/>
                    )
                    :
                    (
                        this.props.waiting ?
                            ""
                            :
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    if(isIOS){
                                        this.props.fakeRecognitionStart();
                                        this.props.audioPlayMute("eng",this.props.wordInfo.num,
                                            () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
                                        );
                                    } else {
                                        this.props.startRecognition();
                                    }
                                    this.props.closeTip();
                                    this.props.handleError(null);
                                }}>
                            </button>
                    )
                }
            </section>
        );
    }

}

export default withRecognizer()(Footer_TKE2_B);
    
    