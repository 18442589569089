import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


class Footer_M3W_A extends Component {

    _test3Words = () => {
        this.props.startRecognition();

    }

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

        <section className="view-footer">
            {this.props.currentPage === 1 ?
                <button type="button" className="f-btn">Empty</button>
                :
                <button type="button" className="f-btn btn-prev" onClick={() => {this.props.prev()}}>이전</button>
            }
            <button
                type="button"
                className="f-btn f-center btn-test"
                onClick={()=>this.props.startTest()}>
                테스트</button>
            {!this.props.pass ?
                <button type="button" className="f-btn">Empty</button>
                :
                this.props.numOfPage === this.props.currentPage ?
                    <button type="button" className="f-btn btn-next-page" onClick={() => {
                        this.props.goNextMenu()
                    }}>다음메뉴</button>
                    :
                    <button type="button" className="f-btn btn-next" onClick={() => {
                        this.props.next()
                    }}>다음</button>
            }
        </section>

        );
    }

}

export default withRecognizer()(Footer_M3W_A);
    
    