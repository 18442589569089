import React, {Component} from 'react';

import withContents from "../../withContents";
import Footer_PME_A from '../../Footer_PME_A';
import Footer_PME_B from '../../Footer_PME_B';
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";
import Score from '../../Score';
import {brTagActivator, getCurrentMenuInfo, calculateScore, FailScore, isIOS} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";


class MemorizeExpressions extends Component {


    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            thisMenuNumber:999,
            activatedWords: null,
            isStart: false,
            isTesting: false,
            count: 0,
            currentPage: 1,
            hasResult: false,
            result:{
            },
            wordInfo:{
                num: 0,
                getResult: false,
            },
            contents:null,
            err:null,
            waiting:false,
            errCount:0,
            pass:false,
            tryCount:0,
            totalScore:0,


        };
    }

    componentDidUpdate(prevProps, prevState){

        if(this.state.hasResult && !prevState.hasResult) {
            $('.word-list li').each(function(index){
                let $score = $('.score', $(this)),
                    animationTime = 0.6,    // 애니메이션 전체 시간
                    delay = (index -1 ) * 0.3;  // 각 스코어별 delay

                TweenLite.to( $score, animationTime, {
                    y:0,
                    delay:delay,
                    yPercent:0,
                    opacity:1
                })
            });
        }



    }

    static getDerivedStateFromProps(nextProps, prevState){
       // alert(nextProps.contents);

        if(nextProps.currentMenu !== prevState.thisMenuNumber){ //초기화
            return({
                index: 0,
                thisMenuNumber:nextProps.currentMenu,
                activatedWords: null,
                isStart: false,
                isTesting: false,
                count: 0,
                currentPage: 1,
                hasResult: false,
                result:{
                },
                wordInfo:{
                    num: 0,
                    getResult: false,
                },
                contents:null,
                err:null,
                waiting:false,
                errCount:0,
                pass:false,
                tryCount:0,
                totalScore:0,
            })
        }

        if(nextProps.contents !== null && prevState.contents === null){
            //현재 contents seq에 맞는 콘텐츠만 담음.
            let tempContents = [];
            //console.log("nextProps.contents :",nextProps.contents);
            //console.log("nextProps.c_code_no :",nextProps.c_code_no);
            for(let i =0 ; i < nextProps.contents.length; i++){
                if(nextProps.contents[i].c_code_no === nextProps.c_code_no){
                    tempContents.push(nextProps.contents[i]);
                }
            }

            return(
                {
                    contents:tempContents
                }
            )
        }



        if(prevState.contents !== null && prevState.contents[0].c_code_no !== nextProps.c_code_no){
            let tempContents = [];
            ////console.log("nextProps.contents :",nextProps.contents);
            ////console.log("nextProps.c_code_no :",nextProps.c_code_no);
            for(let i =0 ; i < nextProps.contents.length; i++){
                if(nextProps.contents[i].c_code_no === nextProps.c_code_no){
                    tempContents.push(nextProps.contents[i]);
                }
            }
            return({
                isStart: false,
                contents:tempContents,
                activatedWords: null,
            })
        }

        return null;
    }

    componentWillUnmount(){
        //alert("컴포넌트 제거");
    }

    render() {
        if(!this.props.contents){
        //if(true){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        if(this.state.isTesting){
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest(this.state.wordInfo.num));
        }

        return (
            this._renderMain()
        )

    }

    _renderMain = () => {
        //console.log("render main is called");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap HideScrollBar">
                        {this.state.hasResult ?
                            this._renderExpressionsWithResult()
                            :
                            this._renderExpressions()
                        }
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    !this.state.pass ?
                                        <>
                                            점수가 너무 낮습니다. 다시 확인 하신 후, 다시 테스트하세요.
                                            <hr className="divider"/>
                                            <div className="tip">특정 표현은 음성인식이 잘 안되실 수도 있습니다.<br/>점수가 낮다고 너무 상심하지 마세요.</div>
                                        </>
                                        :
                                        <>
                                            점수를 확인하시고 <br/>더 좋은 점수를 원하신다면 다시 테스트하세요.
                                            <hr className="divider"/>
                                            <div className="tip">표현을 클릭하면 발음을 들어볼<br/>수 있습니다.</div>
                                        </>
                                    :
                                    <>
                                        다음 표현을 뜻과 함께 외워주세요.<br/>다 외우셨다면 테스트 합니다.
                                        <hr className="divider"/>
                                        <div className="tip">표현을 클릭하면 발음을 들어볼<br/>수 있습니다.</div>
                                    </>
                            }

                        </div>
                    </div>
                </section>
                <Footer_PME_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    hasResult = {this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                />
            </>

        );
    };

    _onWordsClick = (key) => {
        //console.log("단어 클릭됨! key: ", key);
        if(this.props.isPlaying && this.state.activatedWords === key){ //중복 실행 방지
            return;
        }

        let content_seq = 0;
        for(let i = 0 ; i < this.props.contents.length; i ++){
            if(this.props.contents[i].c_code_no !== this.state.contents[key].c_code_no) {
                content_seq ++;
                continue;
            }

            if(this.props.contents[i].c_code_no === this.state.contents[key].c_code_no){
                if(this.props.contents[i].content_seq - 1 !== key){
                    content_seq++;
                    continue;
                } else {
                    //console.log("content_seq :", content_seq);
                    break;
                }
            }
        }
        // this.props.audioPlay("eng", content_seq);
        this.audioPlay(content_seq);
        this.setState({
            activatedWords:key,
        })
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu > this.props.initMenuNo ? this.props.currentMenu : this.props.initMenuNo).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu > this.props.initMenuNo ? this.props.currentMenu : this.props.initMenuNo).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.props.closeTip();
        this.setState({
            isTesting: true,
            hasResult:false,
        });
    };

    _goWordsList = () => {
        this.setState({
            isTesting:false,
            err:null,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            count: 0,
        });
    };

    _renderTest = (index) => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.state.contents[index].kor_content}</h2>
                        </div>

                        {/*<p className="Err">*/}
                            {/*{this.state.err}*/}
                        {/*</p>*/}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br />뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_PME_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    goWordsList={this._goWordsList}
                    waiting={this.state.waiting}
                    err={this.state.err}
                />
            </>
        )

    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        ////console.log("NET Component did received result. index is: "+index+ " and result is " + result);
        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
            totalScore:this.state.totalScore + calculateScore(result, this.state.contents[index].eng_content),
            errCount:0,
        });

        //console.log("결과 점수:", calculateScore(result, this.state.contents[index].eng_content));
        //console.log("total Score :", this.state.totalScore);

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 갯수만큼 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if(this.state.count < this.state.contents.length){
            ////console.log("1초 후에 wordInfo 업데이트 됨.");
            ////console.log("현재 count 는: "+this.state.count);
            setTimeout(() => {
                this.setState({
                    waiting:false,
                });
                if(this.state.isTesting){
                    ////console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                }
            }, 1000);
        }

        //contents.length만큼 결과를 받으면 show Result.
        if(this.state.count >= this.state.contents.length) {
            this._showResult();
            //console.log(this.state.totalScore/this.state.contents.length +"점");
            this._decidePass(this.state.totalScore / this.state.contents.length >= FailScore || isIOS);
            // if(this.state.totalScore / this.state.contents.length < FailScore) {
            //     //console.log(this.state.totalScore/this.state.contents.length +"점으로 FAIL");
            //     this._decidePass(false);
            // } else {
            //     this._decidePass(true);
            // }
        }
    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count:0,
            isTesting:false,
            hasResult: true,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            waiting:false,
        });

        if(!this.state.pass) { //패스 못한 경우, 팁 메시지 출력
            this.props.openTip();
        }

        //console.log("the recorded results are:", this.state.result);
    };

    _renderExpressions = () => {
        let wordsList = [];

        for(let i = 0 ; i < this.state.contents.length; i ++){
            wordsList.push(
                <li key={i} id={i} onClick={() => {
                    this._onWordsClick(i)
                }} className={this.state.activatedWords === i ? "active" : ""}>
                    <div className="expression">{this.state.contents[i].eng_content}</div>
                    <div className="txt">{this.state.contents[i].kor_content}</div>
                </li>
            )
        }

        return (
            <ul className="word-list word-list-grid">
                {wordsList}
            </ul>
        );
    };

    _renderExpressionsWithResult = () => {
        let wordsList = [];
        //console.log("Score type is :", this.props.userInfo.score_type);
        if(this.props.userInfo.score_type !== 0){
            for (let i = 0; i < this.state.contents.length; i++) {
                wordsList.push(
                    <li key={i} id={i} onClick={() => {
                        this._onWordsClick(i)
                    }}>
                        <div className="word">{this.state.contents[i].eng_content}</div>
                        <div className="txt">{this.state.contents[i].kor_content}</div>
                        <div className="score score-blue">
                            {
                                isIOS ?
                                    "A"
                                    :
                                    <Score scoreType={1}
                                           result={this.state.result[i]}
                                           original={this.state.contents[i].eng_content}/>
                            }

                        </div>

                    </li>)
            }

        } else {
            for(let i = 0 ; i < this.state.contents.length; i ++){
                ////console.log(this.state.result);
                wordsList.push(
                    <li key={i} id={i} onClick={() => {
                        this._onWordsClick(i)
                    }}>
                        <h4 className="paragraph">{this.state.contents[i].eng_content}</h4>
                        <div className="paragraph-txt">{this.state.contents[i].kor_content}</div>
                        <div className="my-txt my-txt-wrong">
                            <Score scoreType={1}
                                   result={this.state.result[i]}
                                   original={this.state.contents[i].eng_content}/>
                        </div>

                    </li>)
            }
        }

        return (
            this.props.userInfo.score_type !== 0 ?
                <ul className="word-list word-list-grid">
                    {wordsList}
                </ul>
                :
                <ul className="result-list result-list-grid">
                    {wordsList}
                </ul>

        );
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        if(this.state.errCount >= 2){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴
            return;
        }

        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["정답을 확인 후 다시 한번", <br />,<strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

}

export default withContents()(MemorizeExpressions);

