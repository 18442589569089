import React, {Component} from 'react';
// import withRecognizerForMemorizeB from "./withRecognizerForMemorizeB";
import withRecognizerDialogue from "./withRecognizerDialogue";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_RDB extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
            recording: false,
            audioURL: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {

        if(!prevProps.letStartRecognition && this.props.letStartRecognition){
            this.props.startRecognition();
            if(this.props.serverData.user_kind === "K"){
                // console.log(this.props.contents[this.props.currentIndex].eng_content.length * 80);
                setTimeout(() => {
                    this.props.endRecognition();
                }, this.props.contents[this.props.currentIndex].eng_content.length * 200)
            } else if(this.props.serverData.step_code === "L3"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, 1 + this.props.contents[this.props.currentIndex].eng_content.length * 110)
            }
        }
    }

    render() {
        if(this.props.showContinue){
            return(
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.resetAudioContext();
                            this.props.closeTip();
                        }}>파이널 테스트
                    </button>
                </section>
            )
        }

        if (!this.props.isPlaying && !this.props.listening && this.props.isTesting) {
            //대화가 넘어가는 틈 OR 녹음 도중 에러가 발생함.
            return (
                <section className="view-footer">
                    {this.props.err === null ?
                        <button type="button" className="f-btn">Empty</button>
                        :
                        this.props.isTesting ?

                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this.props.startRecognition();
                                    if(this.props.serverData.user_kind === "K"){
                                        setTimeout(() => {
                                            this.props.endRecognition();
                                        }, this.props.contents[this.props.currentIndex].eng_content.length * 200)
                                    } else if(this.props.serverData.step_code === "L3"){
                                        setTimeout(() => {
                                            this.props.endRecognition();
                                        }, 1 + this.props.contents[this.props.currentIndex].eng_content.length * 110)
                                    }
                                    this.props.handleError(null);
                                    this.props.closeTip();
                                }}> 테스트
                            </button>
                            :
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    //this.props.goNextMenu()
                                    this._memorizeBFinalTest();
                                }}>파이널 테스트 시작
                            </button>
                    }
                </section>
            )
        }

        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>
                {this.props.listening ?
                    <MicVisualizer/>
                    :
                    this.props.isPlaying ?
                        <button
                            type="button"
                            className="f-btn f-center btn-listen"
                        > 듣는중</button>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                //this.props.goNextMenu()
                                this._memorizeBFinalTest();
                            }}>파이널 테스트 시작
                        </button>
                }


                {
                    this.props.pass && !this.props.isPlaying && !this.props.listening ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                this.props.goNextMenu()
                                //this._memorizeBFinalTest();
                            }}>다음 메뉴</button>
                        :
                        <button type="button" className="f-btn">Empty</button>

                }

            </section>
        );
    }

    _memorizeBFinalTest = () => {
        this.props.closeTip();
        this.props.memorizeBFinalTest(0);
    }

}

export default withRecognizerDialogue()(Footer_RDB);

    
    