import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo} from "../../../../asset/js/myFunctions";
import Footer_NTP from "../../Footer_NTP";


class NativePresentation extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 999,
            isStart: false,
            maxPage:0,
            currentPage:0,
            // isPlaying:false,
            // isPlayingComplete:{
            //     index:0,
            //     isComplete:false,
            // },
            pagePass:false,

        }


    }



    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.contents !== null && prevState.maxPage === 0){
            return({
                maxPage:nextProps.contents.length
            });
        }

        //페이지 변경시
        if(nextProps.currentPage !== prevState.currentPage){
            //prevState.currentPage < nextProps.currentPage ? prevState.isPlayingComplete.index < nextProps.currentPage - 1 : prevState.isPlayingComplete.index,
            return({
                currentPage:nextProps.currentPage,
                index:nextProps.currentPage - 1,
                pagePass:false,
            })
        }
        //
        //
        // if(nextProps.isPlaying !== prevState.isPlaying) {
        //
        //     if(nextProps.isPlayingComplete) { //음원이 재생되거나 멈췄는데 isComplete 일 경우
        //         return({
        //             isPlaying:nextProps.isPlaying,
        //             isPlayingComplete:{
        //                 index: prevState.index,
        //                 isComplete:nextProps.isPlayingComplete,
        //             }
        //         });
        //     }
        //
        //     return({ //음원이 재생되거나 멈췄는데 isComplete 가 아닌 경우
        //         isPlaying:nextProps.isPlaying,
        //     })
        // }

        return null;
    }

    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    {/*<div>*/}
                    {/*{JSON.stringify(this.props.contents)}*/}
                    {/*</div>*/}
                    <div className="content-wrap HideScrollBar">
                        {/*<div className="ImageContainer">*/}
                            {/*<img*/}
                                {/*alt={"C.I. Presentation"}*/}
                                {/*src={require(`../../../../asset/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`)}*/}
                                {/*className="CropImageInL7"/>*/}
                        {/*</div>*/}
                        <figure className="figure small-figure">
                            <img
                                src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[this.state.index].tpk_url}`}
                                alt={"cover"}
                            />
                        </figure>

                        {/*<audio controls className={"mt-5"}>*/}
                            {/*<source src={sampleAudio} type="audio/mp3"/>*/}
                        {/*</audio>*/}

                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span> /
                        <span className="total">{this.state.maxPage}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            나의 발표와 원어민의 발표를 비교해 보세요.
                            <hr className="divider"/>
                            <div className="tip">모두 들어야 레슨을 종료할 수 있습니다.</div>
                        </div>
                    </div>
                    {/*<audio id="player" controls>*/}
                    {/*<source src={sampleAudio} type="audio/mp3" />*/}
                    {/*</audio>*/}



                </section>
                <Footer_NTP
                    {...this.props}
                    c_code={this.props.c_code}
                    index={this.state.index}
                    isPlaying = {this.props.isPlaying}
                    // isPlayingComplete={this.state.isPlayingComplete}
                    currentPage={this.state.currentPage}
                    maxPage={this.state.maxPage}
                    //endLesson={this._endLesson}
                    audioPlay={this.audioPlay}
                    pagePass={this.state.pagePass}

                />

            </>
        )
    }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }

        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src,
            () => {
                //재생할게 남은경우
                this.setState({
                    pagePass:true,
                })
            }
        );
    }

}

export default withContents()(NativePresentation);