import React, {Component} from 'react';
import withMenuList from "./withMenuList";
import "../../App.css";
import $ from 'jquery';
import {isMobile} from "../../asset/js/myFunctions";

class MenuList extends Component {

   constructor(props){
       super(props);

       this.state = {
           toggleMenu:false,
           toggleTitle:{

           },
           menuLength: 0,
           currentPercent:0,
       };

       this._toggleTitle = this._toggleTitle.bind(this);
       //this._toggleMenu = this._toggleMenu.bind(this);
       this._toggleMenuMobile = this._toggleMenuMobile.bind(this);

   }

    static getDerivedStateFromProps(nextProps, prevState) {



        if(nextProps.menus !== null){
            //console.log("nextProps is:", nextProps);
            if(nextProps.menus.length !== prevState.menuLength){
                return {menuLength: nextProps.menus.length};
            }
        }



        return null;
    }

   componentDidUpdate(prevProps, prevState){
       if(prevProps.isMenuToggled !== this.props.isMenuToggled){
           //console.log("MenuToggled");
           $(this.refs['toggle-menu']).slideToggle();
       }

       if(this.props.currentMenu !== prevProps.currentMenu && this.props.menus){ //메뉴 변경시
           let menuLength = 0;
           let currentMenuIndex = 0;

           for(let i = 0; i < this.props.menus.length; i ++){
               for(let j = 0 ; j < Object.values(this.props.menus[i].sub).length; j ++){
                   menuLength = menuLength + 1;
                   if(Object.values(this.props.menus[i].sub)[j].menu_no === this.props.currentMenu){
                       currentMenuIndex = menuLength;
                   }
               }
           }


           let currentPercent = Math.round( (currentMenuIndex / menuLength) * 100);
           this.props.setCurrentPercent(currentPercent);

           this.setState({
               currentPercent
           });


       }



   }

   _toggleMenuMobile = () => {
       if(!this.props.toggleMenu) {
           //console.log("menu show!");

       } else {
           //console.log("menu hide!");

       }

   };


   _toggleMenu = () => {
       $(this.refs['toggle-menu']).slideToggle();
       //console.log("clicked!");
   };

   _toggleTitle = (key) => {
       //console.log("clicked " ,key);

       if($(`#nav-${key}`).hasClass('closed')) {
           $(`#nav-${key}`).find('.sub').slideDown('fast', function(){
               $(`#nav-${key}`).removeClass('closed');
           });
       } else {
           $(`#nav-${key}`).find('.sub').slideUp('fast', function(){
               $(`#nav-${key}`).addClass('closed');
           });
       }
   };



    render(){
        if(!this.props.menus){
            return (<div>Loading Menu List..</div>);
        }

        return (
            <aside className="right-menu NoDrag">
                <div className="logo" />


                {this._renderMenuPercentage()}

                <div className="divider" />
                <div className="nav-lists">
                    <ul>
                        {this.props.isPT ?
                            this._renderPTMenuList(this.props.menus)
                            :
                            this.props.lessonInfo.group_no === 3 ?
                                this._renderALMenuList(this.props.menus)
                                :
                                this._renderMenuList(this.props.menus)
                        }
                    </ul>
                </div>
            </aside>
        )

    }


    _renderMenuList = (menus) => {
        ////console.log("Render Menu list. menus are: ",menus);
        return (
            menus.map(menu => {
                    if(menu.menu_group_sort > 10){
                        return null;
                    }



                    if(this.props.lessonInfo.attend_gb === 'R' || this.props.serverData.auth_type === "F" || this.props.serverData.auth_type === "A"){ //복습인 경우 메뉴 전체 OPEN
                        return(
                            <li className="" key={menu.menu_group_sort} id={`nav-${menu.menu_group_sort}`}>
                                <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menu.menu_group_sort)}}>{menu.group_title_en}</a>
                                <ul className="sub">
                                    {Object.values(menu.sub).map(sub => {
                                        return (
                                            <li
                                                key={sub.menu_idx}
                                                className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                                onClick={() => this._onMenuClick(sub.menu_no)}
                                            >
                                                <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    }


                    return( //복습이 아닌 경우
                        <li className="" key={'n'+menu.menu_group_sort} id={`nav-${menu.menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menu.menu_group_sort)}}>{menu.group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menu.sub).map(sub => {


                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.menu_no ? () => {} :() => this._onMenuClick(sub.menu_no)}
                                            >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )

                }
            )

        )
    };


    //추가학습 메뉴 렌더링
    _renderALMenuList = (menus) => {
        ////console.log("Render Menu list. menus are: ",menus);
        return (
            menus.map(menu => {
                    if(menu.menu_group_sort > 10){
                        return null;
                    }

                    //디버깅 중으로 모든 메뉴 오픈
                /////////////////////
                //     return(
                //         <li className="" key={menu.menu_group_sort} id={`nav-${menu.menu_group_sort}`}>
                //             <button type={"button"} className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menu.menu_group_sort)}}>{menu.group_title_en}</button>
                //             <ul className="sub">
                //                 {Object.values(menu.sub).map(sub => {
                //                     return (
                //                         <li
                //                             key={sub.menu_idx}
                //                             className={sub.menu_no === this.props.currentMenu ? "current": ""}
                //                             onClick={() => this._onMenuClick(sub.menu_no)}
                //                         >
                //                             <span className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</span>
                //                         </li>
                //                     )
                //                 })}
                //             </ul>
                //         </li>
                //     )
                ////////////////////////////
                /////////////////////////

                    if(this.props.lessonInfo.attend_gb === 'R' || this.props.serverData.auth_type === "F" || this.props.serverData.auth_type === "A"){ //복습인 경우 메뉴 전체 OPEN
                        return(
                            <li className="" key={menu.menu_group_sort} id={`nav-${menu.menu_group_sort}`}>
                                <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menu.menu_group_sort)}}>{menu.group_title_en}</a>
                                <ul className="sub">
                                    {Object.values(menu.sub).map(sub => {
                                        return (
                                            <li
                                                key={sub.menu_idx}
                                                className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                                onClick={() => this._onMenuClick(sub.menu_no)}
                                            >
                                                <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    }


                    return( //복습이 아닌 경우
                        <li className="" key={menu.menu_group_sort} id={`nav-${menu.menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menu.menu_group_sort)}}>{menu.group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menu.sub).map(sub => {


                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.al_menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.al_menu_no ? () => {} :() => this._onMenuClick(sub.menu_no)}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )

                }
            )

        )
    };


    _renderPTMenuList = (menus) => {
        ////console.log("Render Menu list. menus are: ",menus);
        let menuView = [];
        let num = 1;   //본론 1,2,3,4 숫자 붙이기 위한 변수
        if(this.props.lessonInfo.attend_gb === 'R' || this.props.serverData.lesson_type === "F" || this.props.serverData.lesson_type === "A"){ //복습인 경우 메뉴 전체 OPEN
            for(let i = 0 ; i < menus.length; i ++){
                if(menus[i].menu_group_sort <= 10){
                    continue;
                }
                if(menus[i].menu_group_sort === 11){
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>서론 - {menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={() => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else if (menus[i].menu_group_sort === 16) {
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>결론 - {menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={() => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else if(menus[i].menu_group_sort >= 17) {
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>{menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={() => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else { //본론인경우

                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>{`본론${num} - ${menus[i].group_title_en}`}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={() => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    );
                    num++;
                }

            }
        } else { //복습이 아닌 경우
            for(let i = 0 ; i < menus.length; i ++){
                if(menus[i].menu_group_sort <= 10){
                    continue;
                }
                if(menus[i].menu_group_sort === 11){
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>서론 - {menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.menu_no ? () => {} : () => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else if (menus[i].menu_group_sort === 16) {
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>결론 - {menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.menu_no ? () => {} : () => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else if(menus[i].menu_group_sort >= 17) {
                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>{menus[i].group_title_en}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.menu_no ? () => {} : () => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    )
                } else { //본론인경우

                    menuView.push(
                        <li className="" key={menus[i].menu_group_sort} id={`nav-${menus[i].menu_group_sort}`}>
                            <a className="nav-list-title CursorPointer" onClick={()=>{this._toggleTitle(menus[i].menu_group_sort)}}>{`본론${num} - ${menus[i].group_title_en}`}</a>
                            <ul className="sub">
                                {Object.values(menus[i].sub).map(sub => {
                                    return (
                                        <li
                                            key={sub.menu_idx}
                                            className={sub.menu_no > this.props.menu_no ? "disabled" :
                                                sub.menu_no === this.props.currentMenu ? "current": ""}
                                            onClick={sub.menu_no > this.props.menu_no ?  () => {} : () => {this._onMenuClick(sub.menu_no)}}
                                        >
                                            <a className={"CursorPointer MenuListFont"}>{sub.menu_title_en.replace("<br>"," ")}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                    );
                    num++;
                }

            }

        }

        return (
            menuView
        )
    };


    _renderMenuPercentage = () => {

        let progressClassName = `progress percent-${this.state.currentPercent}`;

        $('.view-header').prepend();
        return (
            <div className={progressClassName}>
                <div className="inner">
                    <div className="percent">
                        <span className="number">{this.state.currentPercent}</span>
                        <span className="bullet">%</span>
                    </div>
                </div>
            </div>
        )
    };


    _onMenuClick = (menu_no) => {
        ////console.log("MenuClicked!",idx);
        this.props.handleCurrentMenu(menu_no);

        if(isMobile) {
            this.props.initToggleMenu();
        }
    }




}

export default withMenuList()(MenuList);