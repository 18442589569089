import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, calculateScore, FailScore, getCurrentMenuInfo, isIOS} from "../../../../asset/js/myFunctions";
import Footer_TRP from "../../Footer_TRP";
import Score from '../../Score';
import hint_image from '../../../../asset/images/lecture/icon-hint.png';


class TranslationPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            index: 0,
            currentPage:1,
            maxPage: 0,
            thisMenuNumber: 82,
            result:"",
            wordInfo:{
                num: 0,
                getResult: false,
            },
            count:0,
            isOpenHint: false,
            pass:false,
            err:null,
            errCount:0,
            tryCount:0,

        };

    }


    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.contents !== null) {
            if (prevState.maxPage === 0) {
                return {
                    maxPage: nextProps.contents.length
                }
            }

            if (nextProps.currentPage !== prevState.currentPage) { //페이지가 변경된다면,
                //console.log("페이지 변경됨!");
                nextProps.closeTip();

                return {
                    index: nextProps.currentPage - 1,
                    currentPage: nextProps.currentPage,
                    result: "",
                    wordInfo:{
                        num:nextProps.currentPage -1,
                        getResult: false,
                    },
                    isOpenHint: false,
                    pass:false,
                    err:null,
                    errCount:0,
                    tryCount:0,
                }
            }
        }
        return null;
    }

    render() {
        if(this.props.contents === null || this.props.menuInfo === null){

            return (<LoadingBar />);

        }
        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }
        ////console.log("PP Contents is :", this.props.contents);
        return (
            this._renderMain()
        )
    }



    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {this.props.contents[this.state.index].tpk_content === "" ?
                            ""
                            :
                            (
                                this.state.isOpenHint ?
                                    <div className="hint-container CursorPointer" onClick={() => this._toggleHint()}>
                                        {this.props.contents[this.state.index].tpk_content}
                                    </div>
                                    :
                                    <div className="btn-hint-toggle CursorPointer"
                                         onClick={() => this._toggleHint()}>
                                        <img src={hint_image} alt={"hint"}/>{" HINT"}
                                    </div>

                            )
                        }


                        <div className="single-memorize">
                            <h4>{this.props.contents[this.state.index].kor_content}</h4>
                        </div>

                        <div className="answer-result-box tp">
                            {
                                isIOS ?
                                <div className="answer-result">
                                    {this.state.result === ""? "": this.props.contents[this.state.index].eng_content}
                                </div>
                                    :
                                <div className="answer-score">
                                    <Score scoreType={1}
                                           result={this.state.result}
                                           original={this.props.contents[this.state.index].eng_content}/>
                                </div>

                                // this.props.userInfo.score_type === 0 ?
                                // <p className="answer-result">
                                //     <Score scoreType={this.props.userInfo.score_type}
                                //            result={this.state.result}
                                //            original={this.props.contents[this.state.index].eng_content}/>
                                //
                                // </p>
                                // :
                                // <div className="answer-score">
                                //     <Score scoreType={this.props.userInfo.score_type}
                                //            result={this.state.result}
                                //            original={this.props.contents[this.state.index].eng_content}/>
                                // </div>
                            }
                        </div>
                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span>/<span className="total">{this.state.maxPage}</span>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">

                            {
                                this.state.err === null ?
                                    <>
                                        배운 단어와 표현을 활용하여<br /><strong>한글을 영어</strong>로 말하세요.
                                        <hr className="divider" />
                                        <div className="tip">번역에는 여러가지 정답이 있을 수 있습니다. <br /> 점수가 낮다고 실망하지 마세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_TRP
                    {...this.props}
                    c_code={this.props.c_code}
                    setResult={this._setResult}
                    wordInfo={this.state.wordInfo}
                    maxPage={this.state.maxPage}
                    currentPage={this.state.currentPage}
                    pass={this.state.pass}
                    handleError={this._handleError}
                    index={this.state.index}
                    setHowlerMute={this.props.setHowlerMute}
                />
            </>
        )
    };


    _renderIntro = () => {
        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({isStart: true})
    };

    _setResult = (result) => {
        if(result === null){
            this.setState({
                result:"",
            });
            return;
        }

        if(!this.props.isPlaying){ //음성인식 종료된 후만 결과값 컨트롤함.
            this.setState({
                result: result,
                err:null,

            });

            let score = isIOS ? 0 : calculateScore(result,this.props.contents[this.state.index].eng_content);
            let pass = score > FailScore || isIOS;

            this._decidePass(pass);

        }
    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 생각 후", <br />," ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">자신감있게 더 큰 목소리로 따라해보세요.<br />힌트가 있다면 힌트도 활용해 보세요.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };

    _toggleHint = () => {
        this.setState({
            isOpenHint: !this.state.isOpenHint,
        })
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
            });

            return;
        }


        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })

    };






}

export default withContents()(TranslationPractice);