import React from 'react';


const Footer_TPWP = (props) => {
    return (

        <section className="view-footer">

        </section>


    )
}


export default Footer_TPWP;
    
    