import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    isMobile,
    uploadAnswerAl, trimStrForUpload2, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_CVP_L2 from "../../Footer_CVP_L2";


class ConversationPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            characterRootURL: 'https://study.itcenglish.com/contents/common',
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index:0,
            isStart: false,
            result: {},
            wordInfo:{
                num: 0,
                getResult: false,
            },
            contents: null,
            err: null,
            errCount: 0,
            pass: false,
            waiting:null,
            count: 0,
            isTesting:false,
            selectedBubble:null,
            letStartRecognition:false,
            showResult:false,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // //console.log(this.props);

        if (nextProps.contents && prevState.contents === null) {

            return {
                contents: nextProps.contents,

            }
        }

        if(nextProps.currentPage-1 !== prevState.index){ //페이지 변경시
            return{
                index:nextProps.currentPage - 1,
                result: {},
                err:null,
                errCount:0,
            }
        }
        return null;
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.showResult){
            return (
                this._renderResult()
            )
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="top-tip">제시된 상황에 맞추어 질문에 영어로 대답하세요.</div>
                        <ul className="talk-list talk-list-2">
                            <li className="block left-block">
                                <img className="profile" src={require(`../../../../asset/images/lecture/character-0${this.props.lessonInfo.lesson_idx % 5 + 1}.png`)} alt="여자 이미지" />
                                    <div className={this.state.selectedBubble === 0 ? "chat-bubble primary" : "chat-bubble"}>
                                        <h2>{this.props.contents[this.state.wordInfo.num].eng_content}</h2>
                                    </div>
                            </li>
                            <li className="block right-block">
                                <img
                                    className="profile"
                                    src={`${this.state.characterRootURL}/character/character_${this.props.userInfo.skin_alphabet == null ? "a" : this.props.userInfo.skin_alphabet.trim()}.png`}
                                    alt="프로필" />
                                    <div className={this.state.selectedBubble === 1 ? "chat-bubble primary":"chat-bubble"}>
                                        <h2>
                                            {
                                                isIOS ?
                                                    this.props.contents[this.state.wordInfo.num].t_answer
                                                    :
                                                    this.state.result[this.state.wordInfo.num]
                                            }
                                        </h2>
                                        <p className="guide MT30">{this.props.contents[this.state.wordInfo.num].t_question}</p>
                                    </div>
                            </li>
                        </ul>
                    </div>
                    <div className="lecture-step">
                        <span className="current">{this.state.wordInfo.num + 1}</span>/<span className="total">{this.props.contents.length}</span>
                    </div>


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    isIOS ?
                                        <>
                                            질문을 잘 듣고 제시된 상황에 맞추어 <br/>
                                            <strong>영어로 대답</strong>하세요. <br />
                                            <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                            <div className={"divider"} />
                                            <div className={"tip"}>
                                                음성인식기능을 사용하시려면 PC나 안드로이드 기기를 사용해 주세요.
                                            </div>
                                        </>
                                        :
                                        <>
                                            질문을 잘 듣고 제시된 상황에 맞추어 <br/>
                                            <strong>영어로 대답</strong>하세요. <br />
                                            <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                        </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_CVP_L2
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition = {this._handleLetStartRecognition}
                    letStartRecognition = {this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                    pass={this.state.pass}
                />
            </>
        );
    };

    _renderResult = () => {
        return(
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={isMobile||$(window).width() < 1200 ? "content-wrap justify-start":"content-wrap"}>
                        <div className="l3-conversation">
                            <div className="answer-wrap">
                                <div className="answer my-answer">
                                    {this._renderMyAnswer()}
                                </div>
                                <div className="answer correct-answer">
                                    {this._renderAnswer()}
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            나의 대답과 모범 대답을 비교해 보세요.
                            <hr className="divider" />
                            <div className="tip">
                                {
                                    isIOS ?
                                        "음성인식 기능을 사용하시려면 PC나 안드로이드 기기를 사용해 주세요."
                                    :
                                        "대답에는 여러가지 정답이 있을 수 있습니다."
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <Footer_CVP_L2
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition = {this._handleLetStartRecognition}
                    letStartRecognition = {this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                />
            </>
        )

    };

    // _audioPlay = (lang, idx) => {
    //     if(this.props.isPlaying){ //동시 여러번 음원재생 방지
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
            errCount:0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 전체 갯수만큼 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if(this.state.count < this.props.contents.length){
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: "+this.state.count);
            setTimeout(() => {
                this.setState({
                    waiting:false,
                });
                if(this.state.isTesting){
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                    this._continueRecognition(this.state.wordInfo.num);
                }
            }, 2000);
        }

        //contents.length만큼 결과를 받으면 show Result.
        if(this.state.count >= this.props.contents.length) {
            setTimeout(() => {
                this._showResult();
                this._decidePass(true);

                let answers = '';
                if(!isIOS) {
                    for (let i = 0; i < this.props.contents.length; i++){
                        answers = answers + this.state.result[i];
                        if(i !== this.props.contents.length - 1){
                            answers = answers + '|'
                        }
                    }
                }
                //uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1);//점수 서버에 업로드
                uploadAnswerAl(this.props.apiUrl,trimStrForUpload2(answers), this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1, 'T',this.props.serverData.auth_type);

            }, 2000);

            // if(this.state.totalScore / this.props.contents.length < FailScore) {
            //     this._decidePass(false);
            // } else {
            //     this._decidePass(true);
            // }
        }
    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },

        });
        //
        // this.props.audioPlayWithCb("eng",num,() => {
        //     this.setState({
        //         selectedBubble:1
        //     });
        //     this._handleLetStartRecognition(true);
        // })

    };

    _continueRecognition = (nextNum) => {
        this.setState({
            selectedBubble:0
        });

        let src = `${this.props.contents[nextNum].step_code}/${this.props.contents[nextNum].lesson_no}/${this.props.contents[nextNum].eng_url}`;
        this.props.setHowler(
            src,
            () => {
                this.setState({
                    selectedBubble:1
                });
                this._handleLetStartRecognition(true);
            }

        )

        // this.props.audioPlayWithCb("eng",nextNum,() => {
        //     this.setState({
        //         selectedBubble:1
        //     });
        //     this._handleLetStartRecognition(true);
        // })
    }


    _startTest = () => {
        this.props.closeTip();
        this.setState({
            isTesting:true,
            selectedBubble:0,
            result: {},
            wordInfo:{
                num: 0,
                getResult: false,
            },
            showResult:false,
            err: null,
            errCount: 0,
            waiting:false,
            count: 0,
        });

        let src = `${this.props.contents[0].step_code}/${this.props.contents[0].lesson_no}/${this.props.contents[0].eng_url}`;
        this.props.setHowler(
            src,
            () => {
                this.setState({
                    selectedBubble:1
                });
                this._handleLetStartRecognition(true);
            }

        )
        // this.props.audioPlayWithCb("eng",0,() => {
        //     this.setState({
        //         selectedBubble:1
        //     });
        //     this._handleLetStartRecognition(true);
        // })

    };

    _handleLetStartRecognition = (bool) => {
        this.setState({
            letStartRecognition:bool,
        })
    };

    _showResult = () => {
        this.setState({
            showResult:true,
            isTesting:false,
        })
    };

    _renderMyAnswer = () => {
        let answers = [];
        // let answersForUpload = '';

        for(let i = 0 ; i< this.props.contents.length; i++){
            answers.push(
                <li>
                    <div className="q">{this.props.contents[i].eng_content}</div>
                    <div className="a">
                        {
                            isIOS ?
                                this.props.contents[i].t_answer
                                :
                                this.state.result[i]
                        }
                    </div>

                </li>
            )


        }



        return(
            <ul>
                {answers}
            </ul>
        )
    };

    _renderAnswer = () => {
        let answers = [];

        for(let i = 0 ; i< this.props.contents.length; i++){
            answers.push(
                <li>
                    <div className="q">{this.props.contents[i].eng_content}</div>
                    <div className="a">{this.props.contents[i].t_answer}</div>
                </li>
            )
        }

        return(
            <ul>
                {answers}
            </ul>
        )
    }

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }

        this.props.openTip();

        if(this.state.errCount >= 1){
            // this.setState({
            //     result: '##ERROR',
            //     err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요.",<div className="divider"/>,<div className="tip">테스트 버튼을 눌러 다시 한번 발음해 보세요.</div>],
            //     pass:true,
            //     waiting:false,
            // });
            this._setResult('인식되지 않았습니다', this.state.wordInfo.num);
            //
            // this.setState({
            //     waiting:false,
            // });
            // if(this.state.isTesting){
            //     //console.log("wordInfo 업데이트!");
            //     this._updateWordInfo(this.state.wordInfo.num + 1, false);
            //     this._continueRecognition(this.state.wordInfo.num);
            // }

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            waiting:false,
        })
    }
}


export default withContents()(ConversationPractice);
    
    