import React, { Component } from "react";



class WGInput extends Component {
    constructor(props){
        super(props);

        this.state = {
            value:"",
        }
    }




    _handleInputChange = (e) => {

        if (e.target.value === " " && this.state.value === '') { //첫 띄어쓰기 막음
            return;
        }

        if(this.state.value === "" && e.target.value !== "") {
            this.props.startCounter();
        }

        this.setState({
            value: e.target.value.toString(),
        });
    };


    _keyListener = (event) => {
        if (event.keyCode === 13) { //enter를 누른 경우

            if(this.state.value === ""){
                return;
            }
            this.props.checkCorrect(this.state.value);
            this.setState({
                value:"",
            })
        }

        if (event.keyCode === 8) {
            //지우기 사용하면 감점 1점
            // this.setState({
            //     minusPoint: this.state.minusPoint+1,
            // })

            this.props.minusPoint(1);
        }

    };




    render(){
        return(
            <input className="typing-input"
                   onChange={this._handleInputChange}
                   onKeyDown={this._keyListener}
                   value={this.state.value}
                   autoComplete={"off"}
                   spellCheck={"off"}
                   autoFocus={true}
                   onPaste={(event) => {event.preventDefault()}}
                   onDrop={(event) => {event.preventDefault()}}
            />
        )
    }




}


export default WGInput