import React from 'react';


const Footer_RES_L6 = (props) => {
    return (

        <section className="view-footer">
            {
                props.phase === 0 ?
                    props.pass || props.errCount > 2?

                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={() => {
                                props.handlePhase(1)
                            }}>
                            결과화면 이동
                        </button>
                        :
                        <button
                            type="button"
                            className="f-btn"
                        >
                            EMpty
                        </button>
                    :

                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                props.goNextMenu()
                            }}>
                            다음 메뉴
                        </button>

            }
        </section>


    )
}


export default Footer_RES_L6;
    
    