import React, { Component } from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import {isMobile} from "../../asset/js/myFunctions";

/**
 * Reading 모바일에서 음성인식 기능 사용 X
 */

const withRecognizerRDN = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            isError:false,
            listening: false,
            text:null,
            recognizerReady:false,
            isRecognizeSuccess: false,
            sumText:"",
            lastStartedAt:null,
            firstStartedAt:null,
            timeSinceLastStart:null,
            timeSinceFirstStart:null,
            isUserStopped: false,
            //maxTimeDuration: this.props.minDuration() ? this.props.minDuration() : 20000, //20초가 안지나면, 계속 다시 시작하도록 했음.(max가 아니라 minimum이 맞는듯 함.)

        };

        mCounter = null;

        componentWillUnmount() {
            this.recognition.stop();
            clearTimeout(this.mCounter);
            this.mCounter = null;
        }


        initialize() {
            // alert("start recognition");
            const Recognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!Recognition) {
                alert(
                    '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
                );
                return;
            }

            this.recognition = new Recognition();
            this.recognition.lang = process.env.REACT_APP_LANGUAGE || 'en-US';
            this.recognition.continuous = true;
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = event => {
                // const text = event.results[0][0].transcript;
                // //console.log('transcript', text);
                // const sumText = this.state.sumText + ". " + text;
                // this.setState({
                //     text,
                //     sumText,
                //     isRecognizeSuccess: true,
                // });
                // //this.props.setResult(sumText);
                // this.setState({
                //     text:null,
                // })
                let sumText = "";
                for(let i=0; i < event.results.length; i ++){
                    sumText = sumText + event.results[i][0].transcript;

                }

                this.setState({
                    //text,
                    sumText,
                    isRecognizeSuccess: true,
                });



                // if(!this.props.isPlaying) {
                //     this.props.setResult(this.state.sumText);
                //     this.setState({
                //         sumText:"",
                //     })
                // }
            };

            this.recognition.onspeechend = () => {
                //console.log('on speech end');
            };

            this.recognition.onnomatch = event => {
                //console.log('no match');
                this.setState({ text: null });
            };

            this.recognition.onstart = () => {
                //console.log('Recognizer start');

                if(!isMobile) {
                    let startAudio = new Audio(StartSound);
                    startAudio.play();
                }
                // let startAudio = new Audio(StartSound);
                // startAudio.play();
                this.setState({
                    text:null,
                    listening: true,
                    recognizerReady:false,
                    sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                });
                this.props.checkTesting(true);


                // if(this.state.firstStartedAt === null){
                //     if(!isMobile) {
                //         let startAudio = new Audio(StartSound);
                //         startAudio.play();
                //     }
                //     this.props.checkTesting(true);
                //     let time = new Date().getTime();
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //         firstStartedAt:time,
                //         lastStartedAt:time,
                //         sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                //     });
                // } else {
                //     let time = new Date().getTime();
                //     this.setState({
                //         text:null,
                //         listening: true,
                //         recognizerReady:false,
                //         lastStartedAt:time,
                //     });
                //
                // }
            };

            this.recognition.onend = () => {
                //console.log('onend');
                if(!isMobile){
                    this.setState({listening:false}); //어쩄든 종료되면 listening:false,
                    //let currentTime = new Date().getTime();
                    let endSound = new Audio(EndSound);
                    endSound.play();
                    this.props.checkTesting(false);
                    this.setState({ //state초기화
                        isError:false,
                        listening: false,
                        recognizerReady:true,
                        isUserStopped: false,
                    });

                    if(!this.state.isError){
                        this.props.setResult(this.state.sumText);
                        this.setState({
                            sumText:"",
                        })
                    }
                    clearTimeout(this.mCounter);
                    this.mCounter = null;
                }


                // let timeSinceFirstStart = new Date().getTime() - this.state.firstStartedAt;
                //
                // if(timeSinceFirstStart < this.props.minDuration && !this.state.isUserStopped) { //maxTimeDuration초가 안지났으고 강제 중지 안했으면 자동 재시작.
                //     //console.log("조건이 안되어 다시 시작함. timeSinceFirstStart: "+timeSinceFirstStart +", isUserStopped: "+this.state.isUserStopped);
                //     let timeSinceLastStart = new Date().getTime() - this.state.lastStartedAt; //단 재시작한지 1초 이내면 1초 채우고 난 후 재시작.
                //     if(timeSinceLastStart < 1000){
                //         setTimeout(() => {
                //          //   this.endRecognition(); //종료후 재시작
                //             this.startRecognition();
                //         }, 1000 - timeSinceLastStart);
                //     } else {
                //        // this.endRecognition(); //종료후 재시작
                //         this.startRecognition();
                //     }
                // } else if(timeSinceFirstStart >= this.props.minDuration || this.state.isUserStopped || this.state.isError) { //maxTimeDuration초가 지났거나 유저가 강제 종료하면 중지.
                //
                //     if(!this.state.isError){//녹음 종료 후에 결과값 전송함.
                //         this.props.setResult(this.state.sumText);
                //     } else { //에러 발생 후, 녹음이 종료 된 경우
                //         if(this.state.sumText !== ""){ //에러가 발생했지만, 결과값이 null 이 아닌경우도 결과값 전송함.
                //             this.props.setResult(this.state.sumText);
                //         }else {
                //             if(this.props.handleError){ //에러 발생하고, 결과값이 null인경우는 에러 전송
                //                 this.props.handleError(["음성인식에 실패하였습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"문제가 계속된다면 ", <strong>마이크를 점검</strong>,"하세요."]);
                //             }
                //         }
                //     }
                //
                //     //console.log("녹음 종료. timeSinceFirstStart: "+timeSinceFirstStart +", isUserStopped: "+this.state.isUserStopped + ", isError: "+this.state.isError);
                //     if(!isMobile){
                //         let endSound = new Audio(EndSound);
                //         endSound.play();
                //     }
                //     this.props.checkTesting(false);
                //     this.setState({ //state초기화
                //         isError:false,
                //         listening: false,
                //         recognizerReady:true,
                //         lastStartedAt:null,
                //         firstStartedAt:null,
                //         timeSinceLastStart:null,
                //         timeSinceFirstStart:null,
                //         isUserStopped: false,
                //
                //     });
                //     //this.endRecognition();
                // } else {//다른케이스면 에러 메시지 출력하고 종료.
                //     console.warn("Recognition Exception. Current State is:", this.state);
                //     if(!isMobile){
                //         let endSound = new Audio(EndSound);
                //         endSound.play();
                //     }
                //     this.setState({ //state초기화
                //         isError:false,
                //         listening: false,
                //         recognizerReady:true,
                //         lastStartedAt:null,
                //         firstStartedAt:null,
                //         timeSinceLastStart:null,
                //         timeSinceFirstStart:null,
                //         isUserStopped: false,
                //
                //     });
                //    // this.endRecognition();
                // }

            };

            this.recognition.onerror = event => {
                //console.log('error', event);
                //alert("마이크 응답이 없습니다. 마이크 연결과 전원을 확인하세요.");
                // if(this.state.sumText === ""){
                //     if(this.props.handleError){
                //         this.props.handleError(["음성인식에 실패하였습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"문제가 계속된다면 ", <strong>마이크를 점검</strong>,"하세요."]);
                //     }
                // }

                if(this.props.handleError){
                    this.props.handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                        <strong>원격 지원</strong>,"을 받아주세요."]);
                }

                this.setState({
                    isError:true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                });

                clearTimeout(this.mCounter);
                this.mCounter = null;

            };
        }

        componentDidMount(){
            this.initialize(); //Initialize
            if(this.state.recognizerReady === false){
                //console.log("recognizer is ready");
                this.setState({
                    recognizerReady:true
                })
            }
        }


        startRecognition(){
            if(this.state.listening === true){
                //console.log("already listening");
                return;
            }

            if(isMobile){ //fake
                let startAudio = new Audio(StartSound);
                startAudio.play();
                this.setState({
                    text:null,
                    listening: true,
                    recognizerReady:false,
                    sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                });
                this.props.checkTesting(true);
            } else {
                this.recognition.start();
            }

           // //console.log("start recognition");
        }

        endRecognition(){
            if(isMobile){ //fake
                let endSound = new Audio(EndSound);
                endSound.play();
                this.setState({listening:false}); //어쩄든 종료되면 listening:false,

                this.props.checkTesting(false);
                this.setState({ //state초기화
                    isError:false,
                    listening: false,
                    recognizerReady:true,
                    isUserStopped: false,
                });

                if(!this.state.isError){
                    this.props.setResult(this.props.sumContents);
                    this.setState({
                        sumText:"",
                    })
                }
                clearTimeout(this.mCounter);
                this.mCounter = null;
            } else {
                this.recognition.stop();
                this.props.checkTesting(true);
            }

        }

        _forceStop = () => {
            //console.log("유저가 녹음 종료");
            this.setState({
                isUserStopped: true
            });
            this.recognition.stop();

        };

        _startCounter = () => {
            this.mCounter = setTimeout(() => {
                this.endRecognition();
                this.mCounter = null;
            },this.props.maxDuration);
        }




        render(){
            return (
                <WrappedComponent {...this.props}
                                  recognizerReady={this.state.recognizerReady}
                                  text={this.state.text}
                                  listening={this.state.listening}
                                  startRecognition={this.startRecognition.bind(this)}
                                  endRecognition={this.endRecognition.bind(this)}
                                  forceStop={this._forceStop}
                                  startCounter={this._startCounter}
                />
            );
        }
    }

};

export default withRecognizerRDN;