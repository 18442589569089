import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from '../other/MicVisualizer';
import {isIOS} from "../../asset/js/myFunctions";


class Footer_PTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999, //인덱스 초기값
        }

    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index && nextProps.isTesting) {
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 테스트를 실행하고 index를 num에 맞춤.
            //console.log("received wordInfo is: " + nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();
            if(isIOS){
                nextProps.fakeRecognitionStart();
                nextProps.audioPlayMute("eng",nextProps.wordInfo.num,
                    () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
                );
            } else {
                nextProps.startRecognition();
            }

            return {
                index: nextProps.wordInfo.num,
            }
        }
        return null;
    }


    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
        return (


            <section className="view-footer">
                {
                    this.props.isTesting ?
                        this.props.listening ?
                            <button
                                type="button"
                                className="f-btn">Empty
                            </button>
                            :
                            this.props.waiting ?
                                <button
                                    type="button"
                                    className="f-btn">Empty
                                </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn btn-prev"
                                    onClick={() => {
                                        this.props.handleIsTest(false);

                                    }}
                                >패턴페이지로 이동
                                </button>
                        :
                        <button
                            type="button"
                            className="f-btn btn-prev"
                            onClick={() => {
                                this.props.handleIsTest(false);

                            }}
                        >패턴페이지로 이동
                        </button>
                }


                {
                    this.props.isTesting ?
                        this.props.listening ?
                            <MicVisualizer/>
                            :
                            this.props.waiting ?
                                <button
                                    type="button"
                                    className="f-btn">Empty
                                </button>
                                :
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        if(isIOS){
                                            this.props.fakeRecognitionStart();
                                            this.props.audioPlayMute("eng",this.props.wordInfo.num,
                                                () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
                                            );
                                        } else {
                                            this.props.startRecognition();
                                        }
                                        this.props.closeTip();

                                    }}/>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this._startTest();
                            }}/>

                }


                {
                    this.props.isTesting ?
                        this.props.listening ?
                            <button
                                type="button"
                                className="f-btn">Empty
                            </button>
                            :
                            this.props.waiting ?
                                <button
                                    type="button"
                                    className="f-btn">Empty
                                </button>
                                :
                                this.props.pass ?

                                    <button type="button" className="f-btn btn-next-page" onClick={() => {
                                        this.props.goNextMenu()
                                    }}>다음메뉴</button>
                                    :
                                    <button
                                        type="button"
                                        className="f-btn">Empty
                                    </button>

                        :
                        this.props.pass ?

                            <button type="button" className="f-btn btn-next-page" onClick={() => {
                                this.props.goNextMenu()
                            }}>다음메뉴</button>
                            :
                            <button
                                type="button"
                                className="f-btn">Empty
                            </button>
                }

            </section>

        );
    }

    _startTest = () => {
        //this.props.startRecognition();
        // this.props.handleShowResult(false);
        this.props.handleIsTesting(true); //테스트 시작
        this.props.closeTip();
        this.props.handleError(null);
    };



}

export default withRecognizer()(Footer_PTP);
    
    