import React, { Component } from 'react';
import axios from 'axios';
import {apiKey} from "../../secret/keys";
axios.defaults.headers.common['Authorization'] = apiKey;
const withMenuList = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            menus: null,


        };

        initialize() {
            // //console.log(`${this.state.menuListRootURL}/${this.props.lessonInfo.step_code}`);

            let url = "";

            if(this.props.lessonType === 'typing') { //타이핑 메뉴 리스트 로딩
                url = `${this.props.apiUrl}/menu/list/typing`;

            } else { //스피킹(본 학습) 메뉴 리스트 로딩
                if (this.props.lessonInfo.step_code.replace(/\s/gi, "") ==="L11" || this.props.lessonInfo.step_code.replace(/\s/gi, "") ==="L12") {
                    url = `${this.props.apiUrl}/menu/list/L10/${this.props.lessonInfo.group_no}`.replace(/\s/gi, "");
                } else if (this.props.lessonInfo.step_code.replace(/\s/gi, "") === 'L8') {
                    if(this.props.lessonInfo.lesson_seq >= 253) {
                        url = `${this.props.apiUrl}/menu/list/${this.props.lessonInfo.step_code}/${this.props.lessonInfo.group_no}/33`.replace(/\s/gi, "");
                    } else {
                        url = `${this.props.apiUrl}/menu/list/${this.props.lessonInfo.step_code}/${this.props.lessonInfo.group_no}/01`.replace(/\s/gi, "");
                    }


                    //주니어 예외처리
                } else if(this.props.lessonInfo.step_code.replace(/\s/gi,"") === ("J1"||"J2"||"J3")){
                    url = `${this.props.apiUrl}/menu/list/J1/${this.props.lessonInfo.group_no}`.replace(/\s/gi, "");

                } else {
                    url = `${this.props.apiUrl}/menu/list/${this.props.lessonInfo.step_code}/${this.props.lessonInfo.group_no}`.replace(/\s/gi, "");
                }
                /*끝*/
                ////console.log("menu list url: ",url);

            }

            Promise.all([
                //axios.get(`${this.state.menuListRootURL}/${this.props.lessonInfo.step_code}`),  //Contents
                //axios.get(url),  //Contents
                //axios.get(`http://localhost:8080/menu/list/L1`), //MenuList
                axios({
                    method: 'get',
                    url: url,
                    data: {
                        apiKey: apiKey,
                    }
                })
            ])
                .then(([resContents]) => {
                    // //console.log("component will mount contents is:", res);
                    const menus = resContents.data;
                    this.props.setMenuInfo(menus);
                    this.setState({
                        ...this.state,
                        menus: menus,
                        //menuList,
                    });
                })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });

            // if(this.props.lessonInfo.step_code.replace(/\s/gi, "") === "L2" || this.props.lessonInfo.step_code.replace(/\s/gi, "") === "L3") {
            //     url = `${this.props.apiUrl}/menu/list/L1/${this.props.lessonInfo.group_no}`;
            // }

        }

        componentDidMount(){
            this.initialize(); //get Contents Data from URL
        }



        render(){
            const { menus } = this.state;
            return(
                <WrappedComponent {...this.props}
                                  menus={menus}
                />
            )
        }
    }

};

export default withMenuList;