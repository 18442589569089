import React, {Component} from 'react';
import LoadingBar from '../../../other/LoadingBar';
import {
    brTagActivator,
    calculateScore,
    FailScore,
    getCurrentMenuInfo, isIOS, isMobile,
    uploadScore
} from "../../../../asset/js/myFunctions";
import Footer_TRT_A from "../../Footer_TRT_A";
import Footer_TRT_B from "../../Footer_TRT_B";
import $ from "jquery";
import withContents from "../../withContents";
import {TweenLite} from "gsap/TweenLite";
import StartSound from "../../../../asset/sound/start_sound.mp3";
import EndSound from "../../../../asset/sound/score_sound.mp3";
import MicVisualizer from "../../../other/MicVisualizer";


class TranslationTest extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            index: 0,
            isStart: false,
            isTesting: false,
            count: 0,
            currentPage: 1,
            transcript:null,
            hasResult: false,
            result:{
            },
            tryCount:0,
            totalScore:0,
            err:null,
            errCount:0,
            pass:false,
            waiting:false,
            // letRecognitionStart:false
        }

        this.mTimeout = null;
    }

    initialize = () => {
        // alert("start recognition");
        const Recognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!Recognition) {
            alert(
                '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
            );
            return;
        }

        this.recognition = new Recognition();
        this.recognition.lang = 'en-US';
        this.recognition.continuous = false;
        this.recognition.interimResults = false;
        this.recognition.maxAlternatives = 1;

        this.recognition.onresult = event => {
            const text = event.results[0][0].transcript;

            //console.log("onresult: ",text);
            if(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)) { //어떤 이유로 음성인식을 한글로 받아옴.
                this._handleError(["오류: 음성인식이 한글로 인식 되었습니다.", <br />, "OS 버전이 정상적인 호환이 되지 않아 생기는 문제입니다.", <br />, "문제가 지속되면 ",<strong>휴대폰 설정 > 언어 및 입력 > 음성검색</strong>, <br />,"에서 언어를 영어로 변경해 주세요. "])
            } else {
                //음성인식 결과 입력
                // this.props.setResult(text, this.props.wordInfo.num);


                this._setResult(text, this.state.wordInfo.num);
            }
        };

        this.recognition.onnomatch = event => {
            console.log('no match');
            //this.setState({ text: null });
            this._handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                <strong>원격 지원</strong>,"을 받아주세요."])

            this.setState({
                listening: false,
            });
        };

        this.recognition.onstart = () => {
            console.log('Recognizer start');
            if(!isMobile) {
                let startAudio = new Audio(StartSound);
                startAudio.play();
            }
            this.setState({
                listening:true,
                transcript:null,
            })
        };

        this.recognition.onend = event => {
            console.log('Recognizer end:',event);
            if(!isMobile){
                let endSound = new Audio(EndSound);
                endSound.play();
            }

            this.setState({
                listening:false,
            })

            // if(this.state.text === null && this.state.isRecognizeSuccess === false && this.props.handleError){
            //     this.props.handleError(["죄송합니다. 알아 듣지 못했습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"가급적 조용한 환경에서 수업해 주세요."]);
            // }
            if(this.state.transcript === null){
                this._handleError(["죄송합니다. 알아 듣지 못했습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"가급적 조용한 환경에서 수업해 주세요."]);
            }
        };

        this.recognition.onerror = event => {

            console.log("error");
            this._handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                <strong>원격 지원</strong>,"을 받아주세요."])

            this.setState({
                listening: false,
            });
        };
    }



    componentDidMount(){
        this.initialize(); //Initialize
        if(this.state.recognizerReady === false){
            //console.log("recognizer is ready");
            this.setState({
                recognizerReady:true
            })
        }
    }

    componentWillUnmount() {
        this.recognition.stop();
        clearTimeout(this.mTimeout);
        this.mTimeout = null;
    }

    startRecognition(){
        console.log("음성인식 시작");
        this.recognition.start();
    }



    // endRecognition(){
    //     this.recognition.stop();
    // }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log("getDerived~ 's nextProps and PrevState is:",nextProps.wordInfo.num+" and "+ prevState.currentWordInfo.num);

        if(nextProps.contents !== null && prevState.maxPage === 0){

            nextProps.setMaxPage(nextProps.contents.length);
            return {
                index: nextProps.currentPage -1,
                maxPage : nextProps.contents.length,
            }
        }
        return null;
    }



    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.isTesting ?
                                <figure className="figure small-figure">
                                    <img
                                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                        alt="일러스트"/>
                                </figure>
                                :

                                this.state.hasResult === true ?
                                    <>
                                        <div className="score-poplayer" id="score-poplayer">
                                            <div className="content">
                                                <p>
                                                    <span className="sr-only">SCORE</span>
                                                    <strong>
                                                        {/*{isIOS ? "Pass" : this.state.totalScore} */}
                                                        {this.state.totalScore}
                                                    </strong>
                                                </p>
                                                <button type="button" className="btn-answer"
                                                   onClick={() => {
                                                       $('#speak-poplayer').wPopLayer();
                                                   }}>정답보기</button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <figure className="figure small-figure">
                                        <img
                                            src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                            alt="일러스트"/>
                                    </figure>
                        }
                    </div>
                    {
                        this.state.isTesting ?
                            <div className="lecture-step">
                                <span className="current">{this.state.wordInfo.num + 1}</span>/<span className="total">{this.props.contents.length}</span>
                            </div>
                            :
                            ""
                    }

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">

                            {
                                this.state.err === null ?
                                    <>
                                        들려주는 <strong>한글 소리</strong>를 잘 듣고<br /> "삐" 소리 후 <strong>영어</strong>로 번역하세요.
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>

                    {this._renderAnswer()}

                </section>
                {
                    this.state.isTesting ?
                        // <Footer_TRT_A
                        //     {...this.props}
                        //     waiting={this.state.waiting}
                        //     c_code={this.props.c_code}
                        //     result={this.state.result}
                        //     setResult={this._setResult}
                        //     isTesting={this.state.isTesting}
                        //     index={this.state.index}
                        //     wordInfo={this.state.wordInfo}
                        //     listening={this.state.listening}
                        //     // audioPlay={this.props.audioPlay}
                        //     isPlaying={this.props.isPlaying}
                        //     err={this.state.err}
                        //     handleError={this._handleError}
                        //     startRecognition={this.startRecognition}
                        //     continueTest={this.continueTest}
                        //     // letRecognitionStart={this.state.letRecognitionStart}
                        // />

                        <section className="view-footer">
                            {this.state.listening ?
                                <MicVisualizer/>
                                :
                                (
                                    this.props.isPlaying ?
                                        <button
                                            type="button"
                                            className="f-btn f-center btn-listen"
                                        >
                                        </button>

                                        :
                                        this.state.waiting ?
                                            <button type="button" className="f-btn">Empty</button>
                                            :
                                            <button
                                                type="button"
                                                className="f-btn f-center btn-test"
                                                // onClick={() => {
                                                //     this.props.startRecognition();
                                                //     //this.props.continueTest();
                                                //     this.props.closeTip();
                                                // }}
                                                onClick={() => {
                                                    this.props.closeTip();
                                                    this.recognition.start();
                                                }}
                                                //onClick={this.startRecognition}
                                            >
                                            </button>

                                )
                            }
                        </section>
                        :
                        <Footer_TRT_B
                            {...this.props}
                            c_code={this.props.c_code}
                            startTest = {this._startTest}
                            hasResult = {this.state.hasResult}
                            pass={this.state.pass}
                        />



                }
            </>
        )

    };


    _renderAnswer = () => {
       // //console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 0 ; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p>
                        {this.props.contents[i].kor_content}<br />
                        <span>{this.props.contents[i].eng_content}</span>
                    </p>
                    <button
                        type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() =>
                           // this._audioPlay(i)
                            this.audioPlay(i)
                        }
                    >발음듣기</button>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    };

    // _audioPlay = (i) => {
    //     if(this.props.isPlaying){
    //         return;
    //     }
    //     this.props.audioPlay(i);
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }



    _startTest = () => {
        this.setState({
                isTesting: true,
                result: {},
                wordInfo: {
                    num: 0,
                },
                totalScore: 0,
                err: null,
            }
        );
        this._playKorean(0);
    };


    //한국음성 재생 후 녹음시작
    _playKorean = (idx) => {

        let src = `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].kor_url}`;
        this.props.setHowler(
            src,
            () => {
                // this.setState({
                //     letRecognitionStart:true,
                // })
                this.startRecognition();
            }
        )
    }

    // _updateWordInfo = (index) => {
    //     this.setState({
    //         wordInfo:{
    //             num:index,
    //         }
    //     })
    // }

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        //console.log("Translation Test Component did received result. index is: "+index+ " and result is " + result);
        //console.log("set result: ",result);

        if(result === null || result === ""){
            this._handleError(["죄송합니다. 알아 듣지 못했습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"가급적 조용한 환경에서 수업해 주세요."]);
            return;
        }

        this.setState({
            transcript:result,
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            isAudioEnd:false,
            err:null,
            errCount:0,
            wordInfo:{
                num:index + 1,
            },
             waiting:true,
        });

        //결과를 전체받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if(index < this.props.contents.length-1){
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: "+this.state.count);
            clearTimeout(this.mTimeout);
            this.mTimeout = setTimeout(() => {
                //console.log("wordInfo 업데이트!");
                //this.props.updateWordInfo(this.props.wordInfo.num + 1, false, false);
                this._playKorean(index + 1);
                this.setState({
                    waiting:false,
                })
            }, 1000);
        } else {
            this._showResult();
            let totalScore = this._calculateTotalScore(); //토탈점수 계산
            uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);
            this._decidePass(totalScore >= FailScore);
        }
    }


    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.props.openTip();

        $(function(){
            TweenLite.to( "#score-poplayer", 1, {
                y:0,
                delay:0,
                yPercent:0,
                opacity:1
            })

        });

        this.setState({
            ...this.state,
            count:0,
            isTesting:false,
            hasResult: true,
            wordInfo:{
                num:0,
                getResult:false,
                isAudioEnd:false,
            },
            err:null,
            waiting:false,
        });
        //this.props.updateWordInfo(-1, false, false);
        //console.log("the recorded results are:", this.state.result);
    };


    _calculateTotalScore(){
        let sum = 0;
        for(let i = 0 ; i < this.props.contents.length; i ++){
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);
        }

        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / this.props.contents.length);
        }

        this.setState({
            totalScore:totalScore,
        });
        return totalScore;
    }


    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["정답을 다시 한번 생각해 보신 후", <br />,"다시 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">{FailScore}점 이상이어야 통과 합니다.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass:isPass,
                err:["잘 하셨어요", <br />,"더 높은 ",<strong>점수</strong>,
                    "를 원하신다면", <br />, "다시", <strong>테스트</strong>, "하세요."],
            })
        }
    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        //첫 에러 발생 시,
        this.props.openTip();
        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };
}

// export default withContentsForTranslationTest()(TranslationTest);
export default withContents()(TranslationTest);