import React, {Component} from 'react';

import MicVisualizer from "../other/MicVisualizer";
import withRecognizerRDT2 from "./withRecognizerRDT2";
import {isIOS, isMobile} from "../../asset/js/myFunctions";


class Footer_RDT_B extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
        };

        this._testStart = this._testStart.bind(this);
    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index){
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 테스트를 실행하고 index를 num에 맞춤.
            ////console.log("received paragraphInfo is: "+ nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();
            if(isIOS){
                nextProps.fakeRecognitionStart();
                nextProps.audioPlayMute("eng",nextProps.wordInfo.num,
                    () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
                );
            } else {
                nextProps.startRecognition();
            }

            if(!isMobile){ //PC버전은 롱타입을 사용하기 때문에 종료시점을 정해줘야 함.

                // nextProps.audioPlayMute("eng",nextProps.wordInfo.num, () => {
                //     setTimeout(() => {
                //         nextProps.endRecognition();
                //     },1000)
                //
                //
                // });

                nextProps.setHowlerMute(
                    `${nextProps.contents[nextProps.wordInfo.num].step_code}/${nextProps.contents[nextProps.wordInfo.num].lesson_no}/${nextProps.contents[nextProps.wordInfo.num].eng_url}`,
                    () => {
                        setTimeout(() => {
                            nextProps.endRecognition();
                        },1000)
                    }
                )
            }


            return{
                index: nextProps.wordInfo.num,
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.listening !== this.props.listening){
            this.props.isListening(this.props.listening);
        }
    }

    render() {
        // //console.log(this.props.currentPage + " and " + this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">

                {
                    this.props.listening ?
                        <MicVisualizer/>
                        :
                        this.props.waiting ?
                            <button type="button" className="f-btn">Empty</button>
                            :
                            this.props.recognizerReady ?
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this._testStart()
                                    }}>
                                </button>
                                :
                                <button type="button" className="f-btn">Empty</button>
                }
            </section>

        );
    }


    _testStart = () => {
        this.props.closeTip();

        if(isIOS){
            this.props.fakeRecognitionStart();
            this.props.audioPlayMute("eng",this.props.wordInfo.num,
                () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
            );
        } else {
            this.props.startRecognition();
        }

        this.props.handleError(null);
    };


}

export default withRecognizerRDT2()(Footer_RDT_B);
    
    