import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


/**
 * New Expression Test 에서 TEST 하기 전에 나오는 패널.
 */

class Footer_PME_A extends Component {

    render() {
        //console.log(this.props.currentPage + " and " + this.props.maxPage);
        //console.log("In ControlPanel, currentMenuNumber is:" + this.props.currentMenu);

        return (

            <section className="view-footer">
                {this.props.hasResult ?
                    this.props.pass ?

                        <>
                            <button type="button" className="f-btn">Empty</button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => this.props.startTest()}>
                                테스트
                            </button>
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {
                                    this.props.goNextMenu()
                                }}>
                                다음메뉴
                            </button>
                        </>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => this.props.startTest()}>
                            테스트</button>
                    :
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => this.props.startTest()}>
                        테스트</button>
                }
            </section>

        );
    }

}

export default withRecognizer()(Footer_PME_A);
    
    