import React, {Component} from 'react';

import withContents from "../../withContents";
import Footer_NWT from '../../Footer_NWT';
import Footer_M12W_B from '../../Footer_M12W_B';
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";
import Score from '../../Score';
import {
    brTagActivator,
    getCurrentMenuInfo,
    calculateScore,
    FailScore,
    uploadScore
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";

const NUM_OF_WORDS = 12;

class NewWordsTest extends Component {


    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            numOfPage: 0,
            thisMenuNumber:68,
            activatedWords: null,
            isStart: false,
            isTesting: false,
            count: 0,
            waiting:false,
            currentPage: 1,
            hasResult: false,
            result:{
            },
            wordInfo:{
                num: 0,
                getResult: false,
            },
            err:null,
            errCount:0,
            pass:false,
            tryCount:0, //Pass Non-Pass 정하기 위한 스테이트
            totalScore: 0 //총 점수
        };
        this.props.setControlPanel(false); //시작시 컨트롤 패널 off

        this._onWordsClick = this._onWordsClick.bind(this);
        this._start = this._start.bind(this);
        this._startTest = this._startTest.bind(this);
        this._setResult = this._setResult.bind(this);
        this._updateWordInfo = this._updateWordInfo.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        //this.props.myJS();
        //점수 출력시 나오는 애니메이션
        if(!prevState.hasResult && this.state.hasResult) {
            $('.word-list li').each(function (index) {
                let $score = $('.score', $(this)),
                    animationTime = 0.6,    // 애니메이션 전체 시간
                    delay = (index - 1) * 0.3;  // 각 스코어별 delay

                TweenLite.to($score, animationTime, {
                    y: 0,
                    delay: delay,
                    yPercent: 0,
                    opacity: 1
                })
            });
        }
    }



    render() {
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }


        if(this.state.isTesting){
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest(this.state.wordInfo.num));
        }


        return (
            this._renderMain()
        )
    }

    _renderMain = () => {
        //console.log("render main is called again");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">

                        {this.state.hasResult ?
                            this._render12WordsWithResult(this.props.currentPage -1)
                            :
                            this._render12Words(this.props.currentPage - 1)
                        }

                        {/*<p className="Err">*/}
                            {/*{this.state.err}*/}
                        {/*</p>*/}
                    </div>


                    {/*<div className="lecture-step">*/}
                    {/*    <span className="current">{this.props.currentPage}</span>/<span className="total">{this.state.numOfPage}</span>*/}
                    {/*</div>*/}


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            오늘 배운 단어 전체를 뜻과 함께 외워주세요.<br />다 외우셨다면 테스트 합니다.
                            <hr className="divider" />
                            <div className="tip">단어를 클릭하면 발음을 들어볼<br />수 있습니다.</div>
                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_NWT
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    hasResult = {this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                />
            </>

        );
    };

    _onWordsClick = (key) => {
        //console.log(key);
        if(this.props.isPlaying && this.state.activatedWords === key){ //음원 재생중 중복 실행 방지
            return;
        }
        // this.props.audioPlay("eng", key);
        this.audioPlay(key);
        this.setState({
            activatedWords:key,
        })
    };

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };
    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.props.closeTip();
        this.setState({isTesting: true});
    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _goWordsList = () => {
        this.setState({
            isTesting:false,
            err:null,
            wordInfo:{
                num: (this.state.currentPage-1) * NUM_OF_WORDS,
                getResult: false,
            },
            count: 0,
        });
    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["정답을 확인 후 다시 한번", <br />,<strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };



    _renderTest = (index) => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.props.contents[index].kor_content}</h2>
                        </div>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br />뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_M12W_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    goWordsList={this._goWordsList}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                />
            </>
        )

    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // //console.log("M3w Component's err: "+this.state.err);
        let count = this.state.count;
        let newTotalScore = this.state.totalScore + calculateScore(result, this.props.contents[index].eng_content);

        this.setState({
            result: {
                ...this.state.result,
                [index]:result,
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
            totalScore: newTotalScore,
            errCount:0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        if(count + 1 < this.props.contents.length) {
            setTimeout(() => {
                this.setState({
                    waiting:false,
                });
                if(this.state.isTesting){
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                }
            }, 1000);
        } else {
            this._showResult();
            let avScore = Math.ceil(newTotalScore / (count + 1)) > 100 ? 100 :  Math.ceil(newTotalScore / (count + 1));

            //New Words Test는 점수를 서버에 업로드함.
            uploadScore(this.props.apiUrl, avScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드 uploadScore = (score, attendIdx, menu_no, tryCount)

            this._decidePass(avScore >= FailScore);
        }
    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count:0,
            isTesting:false,
            hasResult: true,
            wordInfo:{
                num: (this.state.currentPage - 1) * NUM_OF_WORDS,
                getResult: false,
            },
            waiting:false,
        });

        //console.log("the recorded results are:", this.state.result);
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }

        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };

    _render12Words = (index) => {
        let wordsList = [];
        for(let i = 0 ; i < this.props.contents.length; i ++){
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {this._onWordsClick(key)}} className={this.state.activatedWords === key ? "active" : ""}>
                    <div className="word">{this.props.contents[key].eng_content}</div>
                    <div className="txt">{this.props.contents[key].kor_content}</div>
                </li>)
        }

        return (
            <ul className="word-list word-list-grid-12">
                {wordsList}
            </ul>
        );
    };

    _render12WordsWithResult = (index) => {
        let wordsList = [];

        for(let i = 0 ; i < this.props.contents.length; i ++){
            ////console.log(this.state.result);
            let key=i;
            wordsList.push(
                <li className={"CursorPointer"} key={key} id={key} onClick={() => {
                    this._onWordsClick(key)
                }}>
                    <h4 className="paragraph">{this.props.contents[key].eng_content}</h4>
                    <p className="paragraph-txt">{this.props.contents[key].kor_content}</p>
                    <p className="my-txt">
                        <Score scoreType={1}
                               result={this.state.result[key]}
                               original={this.props.contents[key].eng_content}/>

                    </p>

                </li>)
        }

        return (
            this.props.userInfo.score_type === 0 ?
                <ul className="word-list word-list-grid">
                    {wordsList}
                </ul>
                :
                <ul className="result-list result-list-grid">
                    {wordsList}
                </ul>

        );
    };


}

export default withContents()(NewWordsTest);

