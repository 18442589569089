import React, {Component} from 'react';

//타이핑 문장게임


class Footer_TPSG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accuracy: 0,
            time:0,
            speed:0,
            score:0,
            correct:0,
            wrong:0,

        }

        this.mCounter = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.isFinished){
            return;
        }

        let accuracy = 0;
        let speed = 0;
        let correct = 0;
        let wrong = 0;

        for (let i = 0; i < nextProps.accuracyList.length; i++) {
            if(typeof nextProps.accuracyList[i].numCorrect !== "number" || typeof nextProps.accuracyList[i].numWrong !== "number") {
                continue;
            }
            correct += nextProps.accuracyList[i].numCorrect;
            wrong += nextProps.accuracyList[i].numWrong;

        }
        if ((correct === 0 && wrong === 0) || correct === undefined || wrong === undefined) {
            accuracy = 0;
            speed = 0;
        } else {
            if(prevState.time === 0) {
                speed = Math.ceil(((correct+wrong) / 0.1) * 60)
            } else {
                speed = Math.ceil(((correct+wrong) / prevState.time) * 60)
            }
            accuracy = Math.ceil(correct / (correct + wrong) * 100);


        }



        return ({
            accuracy,
            speed,
            correct,
            wrong,
        })

    }



    componentDidUpdate(prevProps, prevState){
        // console.log(this.props.startCounter);
        if(!prevProps.startCounter && this.props.startCounter){
            this._startCounter();
            this.props.closeTip()
        }

        if(prevProps.startCounter && !this.props.startCounter) {
            this._stopCounter();
        }
    }


    render() {
        return (

            <section className="view-footer">
                {
                    this.props.isFinished ?
                        <section className="view-footer">
                            <button
                                type="button"
                                className="f-btn"
                            >
                                공백
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => this.props.retry()}
                            >
                                다음메뉴
                            </button>
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => this.props.goNextMenu()}
                            >
                                다음메뉴
                            </button>
                        </section>
                        :
                        <div className="typing-game-04-footer">
                            <p className="type-info">
                                <i className="icon-correct-percent"/>
                                정확도 : <strong>{this.state.accuracy}</strong>%
                            </p>
                            <p className="type-info">
                                <i className="icon-words-count"/>
                                현재타수 : <strong>{this.state.speed}</strong>타
                            </p>
                            <p className="type-info">
                                <i className="icon-time-info"/>
                                진행시간 : <strong>{this.state.time}</strong>초
                            </p>
                        </div>
                }


            </section>


        )

    }




    _startCounter = () => {

        //카운터가 이미 작동중이면 리턴
        if(this.mCounter !== null){
            return;
        }

        //카운터 시작하면 리셋
        this.setState({
            time:0,
            accuracy: 0,
            speed:0,
            score:0,
            correct:0,
            wrong:0,
        });

        this.mCounter = setInterval(() => {
                this.setState({
                    time: this.state.time + 1,
                });

            }, 1000
        );
    }

    //여기서 점수를 계산함
    _stopCounter = () => {

        if(this.mCounter == null){
            return;
        }

        let acc = (this.state.correct + this.state.wrong) > this.props.contentsLength ? (this.state.correct / (this.state.correct + this.state.wrong) * 1000) : (this.state.correct / (this.props.contentsLength) * 1000);


        let score= Math.ceil(acc) * this.state.speed;

        this.props.liftScore(score);
        this.setState({
            score
        });


        clearInterval(this.mCounter);
        this.mCounter = null;
    }


}

// class IOPanel_6 extends Component {
//
//     constructor(props) {
//         super(props);
//     }
//
//     _test3Words = () => {
//         this.props.startRecognition();
//
//     }
//
//     render() {
//         //console.log(this.props.currentPage + " and "+ this.props.maxPage);
//         //console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
//
//         return (
//
//         <section className="view-footer">
//             <button type="button" className="f-btn btn-prev-page" onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu - 1)}}>이전</button>
//             {this.props.hasResult ?
//                 <>
//                 <button
//                     type="button"
//                     className="f-btn f-center btn-retest"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 <button
//                     type="button"
//                     className="f-btn btn-next-page"
//                     onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu+1)}}>
//                     다음메뉴</button>
//                 </>
//                 :
//                 (<>
//                     <button
//                     type="button"
//                     className="f-btn f-center btn-test"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 </>)
//             }
//         </section>
//
//         );
//     }
//
// }

export default Footer_TPSG;
    
    