import React from 'react';

const Footer_PTP_B = (props) => {
    return (

        <section className="view-footer">
            {
                props.isPlaying ?
                    <button
                        type="button"
                        className="f-btn btn-listen"
                        >다음메뉴</button>
                    :
                    <button
                        type="button"
                        className="f-btn btn-next"
                        onClick={() =>{
                            props.handleIsTest(true); //화면 변경
                            props.closeTip();
                            props.handleError(null);
                        }

                        }>다음메뉴</button>

            }


        </section>


    )
}

export default Footer_PTP_B;
    
    