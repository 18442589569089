import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo, isMobile,
    shuffleArr,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_RES_L4 from "../../Footer_RES_L4";

class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            pass: false,
            isAnimate: false,
            cardPuzzleOptions: {
                animationTime: 200,
            },
            clickedWordsList: null,
            isInitialize: false,
            isWrong: false,
            randomIdx: null,
            currentWordIdx: 0,
            lastClickedIdx:0,
            isComplete:false,
            tpkContent:null,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if (nextProps.contents && !prevState.isInitialize) {
            let clickedWordsList = {};
            let randomIdx = [];
            for (let i = 0; i < nextProps.contents[0].eng_content.length; i++) {
                clickedWordsList[i] = false;
                randomIdx.push(i);
            }


            let tpkContent = nextProps.contents[0].tpk_content.replace(/\?/gi,'?%').replace(/\./gi,'.%').split('%').join('<br />');

            return ({
                isInitialize: true,
                clickedWordsList: clickedWordsList,
                randomIdx: shuffleArr(randomIdx),
                tpkContent:tpkContent,

            })
        }

    }

    componentDidUpdate(prevProps, prevState) {


        if(!prevState.isStart && this.state.isStart){ //인트로 > 메인 들어가면 소리재생
            //this.props.audioPlay("eng",0);
            this.audioPlay(0);

        }

        // // 카드퍼즐 옵션 설정
        // let cardPuzzle = new CardPuzzle({
        //     animationTime: 500,     // 카드 이동 애니메이션 시간 (ms)
        //     onOptionCardClickWhenError: function () {    // 오답상태에서 선택지 카드를 클릭했을때 이벤트
        //         //console.log('오답상태에서 선택지 카드 클릭');
        //     },
        //     onPuzzleComplete: function () {  // 모든 카드를 정확히 배치한후 발생하는 이벤트
        //         location.href = './201909-26.html';
        //     }
        // });
        //
        // $(function () {
        //
        //     // 카드퍼즐 초기화
        //     cardPuzzle.initialize();
        //
        //     // 카드 리셋 버튼 연결
        //     $('[data-button="puzzle-card-reset"]').click(function () {
        //         cardPuzzle.reset();
        //     });
        // });

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.isComplete){

            return(
                this._renderComplete()
            )
        }

        return (
            this._renderMain()
        )



    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={isMobile && $(window).width() < 1200 ? "content-wrap bg-white" : "content-wrap"}>
                        <div className="top-tip">다음 소리를 잘 듣고, 스펠링을 맞춰보세요.</div>

                        <div className="test-question small-card">
                            <div className="test-header">
                                <button type="button" className={isMobile && $(window).width() < 1200 ? "btn-play-sound": "btn-play-sound big"}
                                        onClick={() => this.audioPlay(0)}>음성 듣기
                                </button>
                            </div>
                            <div className="answer-wrap">

                                {this._renderCards()}
                                <button
                                    type="button"
                                    className="btn-reset ML10"
                                    data-button="puzzle-card-reset"
                                    onClick={() => this._resetCardPuzzle()}
                                >
                                    다시하기
                                </button>
                            </div>
                            <div className="option-wrap">

                                {this._renderWords()}

                            </div>
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            소리를 잘 들으신 후, 스펠링을 맞춰보세요.
                            <div className="tip">
                                버튼을 눌러 <strong>여러번</strong> 들어보세요.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_RES_L4
                    {...this.props}
                    pass={this.state.pass}
                    isComplete={this.state.isComplete}
                    handleIsComplete={this._handleIsComplete}
                />

            </>

        );
    };

    _renderComplete = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">

                        <div className="test-question-result">
                            <div className="result-box">
                                <h2>{this.props.contents[0].eng_content}</h2>
                                <h4>{this.props.contents[0].kor_content}</h4>
                                <button
                                    type="button"
                                    className="btn-play-sound"
                                    onClick={() => this.audioPlay(0)}
                                >
                                    다시 듣기
                                </button>
                            </div>
                            <div className="result-commentary">
                                <h2 className="result-title">왜 이렇게 발음하는 걸까요?</h2>
                                <p>
                                    {brTagActivator(this.state.tpkContent)}
                                </p>
                                <h2 className="result-title">비슷한 단어 예제</h2>
                                {
                                    this._renderExample()

                                }
                                {/*<div className="word" onClick={() => this._audioPlay("eng",1)}>{this.props.contents[1].eng_content}{this.props.contents[1].tpk_content}</div>*/}
                                {/*<div className="txt">{this.props.contents[1].kor_content}</div>*/}

                                {/*<div className="word" onClick={() => this._audioPlay("eng",2)}>{this.props.contents[2].eng_content}{this.props.contents[2].tpk_content}</div>*/}
                                {/*<div className="txt">{this.props.contents[2].kor_content}</div>*/}
                            </div>
                        </div>
                    </div>

                </section>
                <Footer_RES_L4
                    {...this.props}
                    pass={this.state.pass}
                    isComplete={this.state.isComplete}
                    handleIsComplete={this._handleIsComplete}

                />

            </>

        );
    }

    _handleIsComplete = (bool) => {
        this.setState({
            isComplete:bool,
        })
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _renderCards = () => {
        let cards = [];
        for (let i = 0; i < this.props.contents[0].eng_content.length; i++) {
            cards.push(
                <li className={
                    this.state.currentWordIdx > i ?
                        this.state.isWrong ?
                            this.state.currentWordIdx - 1 === i ?
                                "s-card wrong"
                                :
                                "s-card correct"
                            :
                            "s-card correct"
                        :
                        "s-card empty"
                    }
                    key={i}
                    id={`card_${i}`}
                >
                    <button
                        type="button"
                        className="btn-remove-card"
                        onClick={() => this._cancelSelect()}
                    >
                        제거
                    </button>
                    <span>
                        {
                            this.state.currentWordIdx > i ?
                                this.state.isWrong ?
                                    this.state.currentWordIdx - 1 === i ?
                                        this.props.contents[0].eng_content.split('')[this.state.randomIdx[this.state.lastClickedIdx]]
                                        :
                                        this.props.contents[0].eng_content.split('')[i]
                                    :
                                    this.props.contents[0].eng_content.split('')[i]
                                :
                                ""
                        }
                    </span>
                </li>
            )
        }

        return (
            <ul className="sorting-card">
                {cards}
            </ul>
        )
    }


    _renderWords = () => {
        let words = [];
        for (let i = 0; i < this.props.contents[0].eng_content.length; i++) {
            words.push(
                <li
                    className={this.state.clickedWordsList[i] ? "s-card empty" : "s-card"}
                    onClick={(event) => {
                        this._clickWords(i, event)
                    }}
                    key={i}
                >
                    {this.props.contents[0].eng_content.split('')[this.state.randomIdx[i]]}
                </li>
            )
        }

        return (
            <ul className="sorting-card">
                {words}
            </ul>
        )
    }


    _clickWords = (idx, e) => {
        //console.log("클릭!:", idx);
        //console.log("클릭!:", e.target);
        if (this.state.isAnimate) { //애니메이션이 작동 중이라면 리턴
            return;
        }
        if (this.state.clickedWordsList[idx]) { //이미 클릭한 단어라면 리턴
            return;
        }

        if (this.state.isWrong) { //마지막 단어가 틀린 상태라면 리턴
            return;
        }

        this.setState({
            isAnimate: true, //애니메이션 시작에 따른 애니메이션 상태값 변경
            clickedWordsList: {
                ...this.state.clickedWordsList,
                [idx]: true
            },
            lastClickedIdx:idx,
        });

        let container = $('.test-question');
        let target = $(`#card_${this.state.currentWordIdx}`);
        let originalPosX = $(e.target).offset().left - container.offset().left;
        let originalPosY = $(e.target).offset().top - container.offset().top;
        let targetPosX = target.offset().left - container.offset().left;
        let targetPosY = target.offset().top - container.offset().top;


        let $cloneCard
            = $('<div>')
            .addClass('s-card')
            .css({
                'position':'absolute',
                'top' : originalPosY,
                'left' : originalPosX
            }).text( this.props.contents[0].eng_content.split('')[this.state.randomIdx[idx]] );
        container.append($cloneCard);

        $cloneCard.animate({
            top: targetPosY,
            left: targetPosX,
        }, this.state.cardPuzzleOptions.animationTime, () => {
            $cloneCard.remove();
            if (this.props.contents[0].eng_content.split('')[this.state.currentWordIdx] === this.props.contents[0].eng_content.split('')[this.state.randomIdx[idx]]) { //만약 클릭한 단어가 현재 들어가야 하는 단어가 같다면

                this.setState({
                    currentWordIdx: this.state.currentWordIdx + 1,
                    isWrong: false,
                    isAnimate: false,
                    pass:this.state.currentWordIdx + 1 > this.props.contents[0].eng_content.length - 1,
                })
            } else { //틀린 경우
                this.setState({
                    currentWordIdx: this.state.currentWordIdx + 1,
                    isWrong: true,
                    isAnimate: false,
                })
            }
        });

    };

    _cancelSelect = () => {
        this.setState({
            lastClickedIdx: null,
            clickedWordsList: {
                ...this.state.clickedWordsList,
                [this.state.lastClickedIdx]:false,
            },
            currentWordIdx: this.state.currentWordIdx - 1,
            isWrong:false,
        })
    }

    _resetCardPuzzle = () => {
        if (this.state.isAnimate) { //애니메이션중에 클릭하면 리턴
            return;
        }

        let clickedWordsList = {};
        for (let i = 0; i < this.props.contents[0].eng_content.length; i++) {
            clickedWordsList[i] = false;
        }


        this.setState({
            clickedWordsList: clickedWordsList,
            isWrong: false,
            currentWordIdx:0,

        })

    }

    _renderExample = () => {
        let examples = [];
        for(let i = 1 ; i < this.props.contents.length; i ++){
            examples.push(
                <>
                    <div className="word" onClick={() => this.audioPlay(i)}>
                        {this.props.contents[i].eng_content}{this.props.contents[i].tpk_content}
                    </div>
                    <div className="txt">{this.props.contents[i].kor_content}</div>
                </>
            )
        }

        return (
            <>
                {examples}
            </>

        )
    }




}


export default withContents()(TakeBreak);
    
    