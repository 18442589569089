import React, {Component} from 'react';
import withContentsForDirectTranslation from "../../withContentsForDirectTranslation";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo, isMobile,isIOS} from "../../../../asset/js/myFunctions";
import Footer_DTT from "../../Footer_DTT";


class DirectTranslation extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 102,
            isStart: false,
            maxPage: 0,
            currentPage:0,
            dtIndex:0,
            isWaitSpeak:false,
            result:"",
            isTesting:false,
            isPlaying:false,
            letUpload:false,
            // audioURL: null,
            isUploadComplete: false,
            err:null,
            errCount:0,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null && prevState.maxPage === 0){
            nextProps.setMaxPage(nextProps.contents.length);
            return({
                maxPage:nextProps.contents.length
            })
        }

        if(nextProps.currentPage !== prevState.currentPage){
            return({
                currentPage: nextProps.currentPage,
                index: nextProps.currentPage -1,
            })
        }

        return null;



    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        //console.log("Current isTesting :",this.state.isTesting);
        return (
            <>
                <section className="view-body tip-opened">

                    <div className="content-wrap">
                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={()=>{this._playRecordedFile()}}>녹음듣기</button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={()=>{this._uploadRecordedFile()}}>녹음제출</button>
                                </li>
                            </ul>
                            :
                            !this.state.isTesting ?
                                <figure className="figure small-figure">
                                    <img
                                        alt={"cover"}
                                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}/>
                                </figure>
                                :
                                this.state.isWaitSpeak ?
                                    <p className="direct-translation type2">한국어로 뜻을 말하세요.</p>
                                    :
                                    <p className="direct-translation type1">
                                        {this.props.contents[this.state.dtIndex].eng_content}
                                    </p>
                        }

                    </div>


                    {!this.state.hasResult && this.state.isTesting ?
                        <div className="lecture-step">
                            <span className="current">{this.state.dtIndex + 1}</span> /
                            <span className="total">{this.state.maxPage}</span>
                        </div>
                        :
                        ""
                    }
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            직독직해를 시작합니다.<br/>
                                            TEST를 누른 후 출력되는 <strong>영어</strong>를 먼저 읽고<br/>
                                            <strong>한국어</strong>로 뜻을 말하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>

                    </div>


                </section>
                <Footer_DTT
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    isTesting={this.state.isTesting}
                    isPlaying = {this.state.isPlaying}
                    playRecordedFile = {this._playRecordedFile}
                    stopRecordedFile = {this._stopRecordedFile}
                    hasResult = {this.state.hasResult}
                    checkResult = {this._checkResult}
                    // getAudioURL={this._getAudioURL}
                    audioUploadComplete = {this._audioUploadComplete}
                    letUpload = {this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    // letStopRecording={this.state.letStopRecording}
                />

            </>
        )

    };



    _setResult = (result) => {
        //console.log("결과받음: ", result);
        if(result && !this.state.result) {
            this.props.openTip();
        }

        this.setState({
            result:result
        })
    }
    // _getAudioURL = (url) => {
    //     if(this.state.audioURL === null){
    //         this.setState({
    //             audioURL: url,
    //         })
    //     }
    // };

    _audioUploadComplete = () => {
        this.setState({
            isUploadComplete:true,
        })
    };


    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ");
        //this.props.upload();
        this.setState({
            letUpload:true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this._endLesson();
    };


    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };

    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };

    _checkResult = (bool) => {
        this.setState({
            hasResult:bool,
        })
    };

    _startTest = (bool) => {
        if(bool === true){
            // this.props.audioPlayAllTwice();
            this._startDt(0);
        }
        this.setState({
            isTesting:bool,
        })
    }

    _startDt = (index) => {

        if(index >= this.props.contents.length) {
            this._startTest(false); //테스트 종료
            return;
        }

        this.setState({
            dtIndex:index,
            isWaitSpeak: false,
        });

        let duration = this.props.contents[index].eng_content.length * 80;

        setTimeout(() => {
            this._waitSpeak(index, duration);
        }, duration + 2000)
    }

    _waitSpeak = (index, duration) => {
        this.setState({
            isWaitSpeak: true,
        });
        setTimeout(() => {
            this._startDt(index + 1);
        }, duration + 4000);
    }

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }

        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
            isTesting:false,
        })
    };
}

export default withContentsForDirectTranslation()(DirectTranslation);