import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {
    brTagActivator,
    calculateScore,
    getCurrentMenuInfo,
    FailScore,
    isIOS,
    isMobile
} from "../../../../asset/js/myFunctions";
import Footer_PST from "../../Footer_PST";
import {scroller} from "react-scroll";


class PracticeForStorytelling extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            thisMenuNumber: 104,
            isStart: false,
            maxPage: 0,
            currentPage:0,
            butRealMaxPage: 5,
            result:null,
            isScriptOpen:false,
            currentIndexes:[],
            recognizeComplete:false,
            err:null,
            sumContents:"",
            pass:false,
            score:null,
            errCount:0,
            tryCount:0,
            isTesting:false,

        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null && prevState.maxPage === 0){
            nextProps.setMaxPage(nextProps.contents.length);
            let currentIndexes = [];
            let sumContents = "";
            for(let i =0 ; i < nextProps.contents.length; i ++){
                if(nextProps.contents[i].c_code_no === "1"){
                    currentIndexes.push(i);
                    //console.log(currentIndexes);
                }

                if(nextProps.contents[i].c_code_no === "1"){
                    sumContents = sumContents + nextProps.contents[i].eng_content;
                }
            }

            return({
                maxPage:nextProps.contents.length,
                currentIndexes:currentIndexes,
                sumContents,
            })
        }

        if(nextProps.currentPage !== prevState.currentPage){ //페이지 변경시
            let sumContents = "";
            let currentIndexes = [];
            if(nextProps.contents !== null){
                //console.log("create playing indexes");
                for(let i =0 ; i < nextProps.contents.length; i ++){
                    if(nextProps.contents[i].c_code_no.replace(/\s/g,'') === nextProps.currentPage.toString()){
                        currentIndexes.push(i);
                    }
                }
                ////console.log("current Playing Index is: ", currentIndexes);

                //create sumContents
                for(let i = 0 ; i < nextProps.contents.length; i++){
                    if(nextProps.contents[i].c_code_no === nextProps.currentPage.toString()){
                        sumContents = sumContents + nextProps.contents[i].eng_content;
                    }

                }
            }



            return({
                currentPage: nextProps.currentPage,
                sumContents,
                index: nextProps.currentPage -1,
                currentIndexes:currentIndexes,
                result:null, //페이지 바뀌면 결과 초기화
                score:null,
                err:null,
                pass:false,
                errCount:0,
                tryCount:0,
                isTesting:false,
            })
        }

        return null;
    }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    {/*<label htmlFor="on-paragraph" className="toggle-switcher" data-toggle="toggle-switcher">*/}
                        {/*<span className="label">영어지문</span>*/}
                        {/*{this.state.isScriptOpen ? <input type="checkbox" value="Y" checked id="on-paragraph" /> : <input type="checkbox" value="N" id="on-paragraph" />}*/}

                        {/*<span className="toggler CursorPointer" onClick={() => {this.setState({isScriptOpen:!this.state.isScriptOpen})}}/>*/}
                    {/*</label>*/}
                    <div className="toggle-switcher switchToggle">
                        <span className={"label"}>
                            영어지문
                        </span>
                        <input type="checkbox" id="switch1"/>
                            <label htmlFor="switch1" onClick={() => {this.setState({isScriptOpen:!this.state.isScriptOpen})}}>Toggle</label>
                    </div>

                    <div className="content-wrap HideScrollBar">
                        {this.state.isScriptOpen ?
                            <div className="StoryContainer">
                                <p>
                                    {this._renderReading(this.state.index)}
                                </p>
                            </div>
                            :
                            <>
                                <div className="ImageContainer">
                                    <img
                                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[0].tpk_url}`}
                                        className="CropImageInL7" alt="Reading Cover"/>
                                </div>
                            </>
                        }
                        {/*<p className="Err">*/}
                            {/*{this.state.err && this.state.result === "0" ? this.state.err : ""}*/}
                        {/*</p>*/}
                        {/*<h2>*/}
                            {/*{this.props.isPlayingComplete && !this.state.listening ? this._calculateTotalScore() : ""}*/}
                        {/*</h2>*/}
                    </div>



                    <div className="lecture-step">
                        <span className="current">{this.props.currentPage}</span> /
                        <span className="total">{this.state.butRealMaxPage}</span>
                    </div>


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        테스트 버튼을 눌러 <strong>원어민 소리와 함께</strong><br /> 동시에 따라 읽으세요.
                                        <hr className="divider" />
                                        <div className="tip">
                                            녹음이 도중에 멈추면 빠르게 읽어주세요.<br />
                                            <strong>헤드셋</strong>을 사용하세요.<br />
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_PST
                    {...this.props}
                    butRealMaxPage={this.state.butRealMaxPage}
                    setResult={this._setResult}
                    handleError={this._handleError}
                    minDuration={this.state.minDuration}
                    setListening = {this._setListening}
                    isListening={this.state.listening}
                    isTesting={this.state.isTesting}
                    score={this.state.score}
                    pass={this.state.pass}
                    checkTesting={this._checkTesting}
                    audioPlayList = {this.audioPlayList}
                    //audioPlay={this.props.audioPlay}
                    setRecognizeComplete = {this._setRecognizeComplete}
                    isPlayingComplete={this.props.isPlayingComplete}
                    currentIndexes={this.state.currentIndexes}
                    sumContents={this.state.sumContents}
                />

            </>
        )
    };

    _renderReading = (index) => {
        let reading = [];
        //let sumContents = "";

        for(let i = 0 ; i < this.props.contents.length; i ++){
            // //console.log(this.props.contents[i].c_code_no);
            if(this.props.contents[i].c_code_no === (index + 1).toString()){
                ////console.log(this.props.contents[i].eng_content);
                reading.push(<>
                    {this.props.contents[i].eng_content} <br/>
                </>);
               // sumContents = sumContents + this.props.contents[i].eng_content;
            }
        }
        //
        // if(this.state.sumContents !== sumContents){
        //     this.setState({
        //         sumContents:sumContents
        //     })
        // }


        return(
            reading
        )

    };



    _setRecognizeComplete = (isComplete) => {
        this.setState({
            recognizeComplete: isComplete
        })
    };




    _setListening = (bool) => {
        if(this.state.listening !== bool){
            this.setState({
                listening : bool,
            })
        }
    }


    _getABCScore = (score) => {
        let abcScore = null;
        if (score < FailScore) { //FailScore = 30
            abcScore = "F";
        } else if (score >= FailScore && score < 40) {
            abcScore = "C";
        } else if (score >= 40 && score < 50) {
            abcScore = "C+";
        } else if (score >= 50 && score < 60) {
            abcScore = "B";
        } else if (score >= 60 && score < 70) {
            abcScore = "B+";
        } else if (score >= 70 && score < 85) {
            abcScore = "A";
        } else if (score >= 85) {
            abcScore = "A+";
        } else {
            //console.log("점수가 잘못 계산되었습니다.");
        }

        ////console.log("abcScore is : ",abcScore);
        return (
            abcScore
        )

    };


    _setResult = (result) => {
        if(result === null){
            this.setState({
                result:null,
            });
            return;
        }

        let score = isIOS ? 0 : calculateScore(result,this.state.sumContents);
        let pass = score >= FailScore || isIOS;
        let abcScore = this._getABCScore(score);


        this.setState({
            result: result,
            err:null,
            score:abcScore,
        });


        this._decidePass(pass);

    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 ",<strong>테스트</strong>, "를 눌러 읽어주세요.",<br />,"C 이상이어야 통과입니다.",
                    <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 말해보세요.</div>],
            })
        } else {
            if(this.state.score === "A" || this.state.score === "A+" || this.state.score === "B+"){
                this.props.closeTip();
                this.setState({
                    pass:isPass,
                    err:null,
                })
            } else {
                this.props.openTip();
                this.setState({
                    pass:isPass,
                    err:["잘 하셨어요. 더 높은 점수를 원하시면",<br />,"다시 ", <strong>테스트</strong>, "를 눌러 읽어주세요.",
                        <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 말해보세요.</div>],
                })
            }


        }


    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        this.props.openTip();
        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
                hasResult: true,
                isReadyToRecognize: 0,
            });

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            isReadyToRecognize:0,
        })

    };


    _checkTesting = (bool) => {
        this.setState({
            isTesting:bool
        })
    }

    audioPlayList = (playList, index, cb) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        //Recursive
        if(playList.length - 1 < index) {
            cb();
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.props.closeTip();
            this.setState({
                playingIndex:index,
                isPlaying:true,
            });
        }

        let src = `${this.props.contents[playList[index]].step_code}/${this.props.contents[playList[index]].lesson_no}/${this.props.contents[playList[index]].eng_url}`;
        this.props.setHowler(
            src,
            () => {
                this.audioPlayList(playList, index + 1, cb);
            },
        );
    }
}

export default withContents()(PracticeForStorytelling);