import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


class Footer_Test33_A extends Component {

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">
                {
                    this.props.isPlaying ?
                        <button
                            type="button"
                            className="f-btn btn-listen"
                        >
                            듣는 중
                        </button>
                        :
                        <>
                            <button type="button" className="f-btn">Empty</button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => this.props.startTest()}>
                                테스트
                            </button>
                            {
                                !this.props.pass ?
                                    <button type="button" className="f-btn">Empty</button>
                                    :
                                    <button type="button" className="f-btn btn-next" onClick={() => {
                                        this.props.handlePhase(1)
                                    }}>다음</button>
                            }

                        </>

                }

            </section>

        );
    }

}

export default withRecognizer()(Footer_Test33_A);
    
    