import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_TKE2_A from '../../Footer_TKE2_A';
import Footer_TKE2_B from '../../Footer_TKE2_B';
import $ from "jquery";
import {TweenLite} from "gsap/TweenLite";
import {
    brTagActivator,
    getCurrentMenuInfo,
    calculateScore,
    uploadScore,
    FailScore, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContentsReviewWords from "../../withContentsReviewWords";
import withContentsTKE2 from "../../withContentsTKE2";
import HtmlParser from "react-html-parser";



/**
 * 2021-09-01 Edited by Sanmin Yun
 */


class TranslationKoreanEnglish2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            index: 0,
            maxPage: 0,
            thisMenuNumber: 75,
            isTesting: false,
            count: 0,
            currentPage: 1,
            // hasResult:true,
            // result:{
            //     0:"hi",1:"hi",2:"hi",3:"hi",4:"hi",5:"hi",6:"hi",7:"hi",8:"hi",9:"hi"
            // },
            hasResult: false,
            result:{
            },
            wordInfo:{
                num: 0,
                getResult: false,
            },
            tryCount:0,
            totalScore: 0,
            err:null,
            errCount:0,
            waiting:false,
            pass:false,

        };
    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.contents !== null){
            if(prevState.maxPage === 0) {
                //nextProps.setMaxPage(nextProps.contents.length);

                return {
                    index:0,
                    maxPage: nextProps.contents.length,
                }
            }

            if(nextProps.currentPage - 1 !== prevState.index){
                //페이지 변경 시
                //nextProps.closeTip();
                return {
                    index : nextProps.currentPage - 1,
                    result:null, //결과도 초기화
                    err:null,
                    pass:false,
                    errCount:0,
                    tryCount:0,
                }
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState){
        this.props.myJS();

        $(function(){
            TweenLite.to( "#score-poplayer", 1, {
                y:0,
                delay:0,
                yPercent:0,
                opacity:1
            })

        });

    }


    render() {
        if(this.props.contents === null || this.props.menuInfo === null){
            //if(true){
            // return(<div>Loading contents...</div>);
            return (<LoadingBar type={0}/>);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        if(this.state.isTesting){
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest(this.state.wordInfo.num));
        }

        return (
            this._renderMain()

        );
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu))}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        {this.state.hasResult ?
                            <>
                                <div className="score-poplayer" id="score-poplayer">
                                    <div className="content">
                                        <p>
                                            <span className="sr-only">SCORE</span>
                                            <strong> {isIOS ? "Pass" : this.state.totalScore} </strong>
                                        </p>
                                        <button type={"button"} className="btn-answer"
                                           onClick={() => {$('#speak-poplayer').wPopLayer();}}>정답보기</button>
                                    </div>
                                </div>

                                {/*<p className = "score-help-block" > {this.state.pass ? "":`${FailScore}점 이상이어야 통과할 수 있습니다. 다시 테스트 하세요.`}</p>*/}
                            </>
                            :
                            ""
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <strong>한글 문장</strong>을 잘 보고<br/>
                                        <strong>영어로 전환</strong>하세요.<br/>
                                        테스트 버튼을 눌러 시작합니다.
                                        <hr className="divider"/>
                                        <div className="tip">회화에서 배운 문장을 잘 생각해보세요.<br/></div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                    {this._renderAnswer()}
                </section>
                <Footer_TKE2_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    hasResult = {this.state.hasResult}
                    handleError={this._handleError}
                    pass={this.state.pass}
                />
            </>
        )
    };

    _renderAnswer = () => {
        //console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 0 ; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p>
                        {this.props.contents[i].kor_content}<br />
                        <span>{this.props.contents[i].eng_content}</span>
                    </p>
                    <button type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() =>
                            this.audioPlay(i)
                        }
                    >발음듣기</button>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close"  onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    }

    // _audioPlay = (i) => {
    //     if(this.props.isPlaying){
    //         return;
    //     }
    //     this.props.audioPlay("eng", i)
    //
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.setState({
            isTesting: true,
            hasResult:false,

            index: 0,
            count: 0,
            currentPage: 1,
            // hasResult:true,
            // result:{
            //     0:"hi",1:"hi",2:"hi",3:"hi",4:"hi",5:"hi",6:"hi",7:"hi",8:"hi",9:"hi"
            // },
            result:{
            },
            wordInfo:{
                num: 0,
                getResult: false,
            },
            totalScore: 0,
            err:null,
            errCount:0,
            waiting:false,
        });
    };

    _renderTest = (index) => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{HtmlParser(this.props.contents[index].kor_content)}</h2>
                        </div>
                        {/*<p className="Err">*/}
                            {/*{this.state.err}*/}
                        {/*</p>*/}

                    </div>
                    <div className="lecture-step">
                        <span className="current">{index + 1}</span>/<span className="total">{this.state.maxPage}</span>
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        다음 한글 문장을 영어로 전환하세요.<br />
                                        <hr className="divider" />
                                        <div className="tip">회화에서 배운 문장을 잘 생각해보세요.<br /></div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>

                </section>
                <Footer_TKE2_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                />
            </>
        )

    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        //console.log("TKE2 Component did received result. index is: "+index+ " and result is " + result);
        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            errCount:0,
            waiting:true,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //결과를 전체받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if(this.state.count < this.state.maxPage){
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: "+this.state.count);
            setTimeout(() => {
                //console.log("wordInfo 업데이트!");
                this.setState({waiting:false,})
                this._updateWordInfo(this.state.wordInfo.num + 1, false);
            }, 1000);
        }

        if(this.state.count >= this.state.maxPage) {
            this._showResult();
            let totalScore = isIOS ? 0 : this._calculateTotalScore();

            uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드 uploadScore = (score, attendIdx, menu_no, tryCount)

            let pass = totalScore >= FailScore || isIOS;
            this._decidePass(pass);

        }
    }


    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },

        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        setTimeout(() => {
            this.setState({
                ...this.state,
                count:0,
                isTesting:false,
                hasResult: true,
                wordInfo:{
                    num: 0,
                    getResult: false,
                }
            });
            //console.log("the recorded results are:", this.state.result);
        } , 200);

    };



    _calculateTotalScore(){
        let sum = 0;

        for(let i = 0 ; i < this.props.contents.length; i ++){
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);
        }

        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / this.props.contents.length);
        }

        this.setState({
            totalScore:totalScore
        });

        return totalScore;


    }

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["정답을 다시 한번 생각해 보신 후", <br />,"다시 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">{FailScore}점 이상이어야 통과 합니다.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }



        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        //첫 에러 발생 시,
        this.props.openTip();
        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };



}




//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContentsTKE2()(TranslationKoreanEnglish2);

