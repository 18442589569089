import React, {Component} from 'react';
import {Motion, spring} from 'react-motion';
import backgroundImage from '../../asset/images/lecture/mic-bg.png';
import $ from 'jquery';
import {isMobile} from "../../asset/js/myFunctions";

const propTypes = {};

const defaultProps = {};

class MicVisualizer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            adjustedLength: 0,
            checkMute:true, //기본값 true. 마이크가 뮤트면 true , 말하면 false.
            isMute: false, //이미 true가 되면 더이상 checkMute를 하지 않음.
        };

        this.checkMuteInterval = null;
        this.drawer = null;
    }

    componentDidUpdate(){


        /**
         * 음성 녹음 Mute Check 를 안하기로 결정함. 일부 디바이스에서 기기 성능에 따라 녹음 파일이 깨지는 경우가 다소 발생.
         * 20.02.25
         */
        // if(this.props.getIsMute && !isMobile){
        //     if(this.state.checkMute && this.checkMuteInterval === null && !this.state.isMute) {
        //         //console.log("뮤트 스타트");
        //         //뮤트상태가 되면 여기로 진입
        //         let count = 0;
        //         this.checkMuteInterval = setInterval(() => {
        //
        //             if(this.state.checkMute) {
        //                 count++;
        //                 //console.log("뮤트 Count: ",count);
        //                 if(count >= 50){
        //                     this.setState({
        //                         isMute:true
        //                     });
        //                     clearInterval(this.checkMuteInterval);
        //                     this.checkMuteInterval = null;
        //                     this.props.getIsMute(true);
        //                 }
        //             } else {
        //                 clearInterval(this.checkMuteInterval);
        //                 this.checkMuteInterval = null;
        //             }
        //         }, 200)
        //     }
        // }

    }

    componentWillUnmount(){
        clearInterval(this.checkMuteInterval);
        cancelAnimationFrame(this.drawer);

    }

    
    componentDidMount(){
        // let paths = document.getElementsByTagName('path');
        // let visualizer = document.getElementById('visualizer');
        // let mask = visualizer.getElementById('mask');
        // let h = document.getElementsByTagName('h1')[0];
        // let path;
        // let report = 0;
        if(!isMobile && !this.props.isRecording){ //STT기능을 사용하는 데스크톱의 경우에만 Real-time Mic Visualizer를 사용

            let soundAllowed = (stream) => {
                //Audio stops listening in FF without // window.persistAudioStream = stream;
                //https://bugzilla.mozilla.org/show_bug.cgi?id=965483
                //https://support.mozilla.org/en-US/questions/984179
                window.persistAudioStream = stream;
                let audioContent = new AudioContext();
                let audioStream = audioContent.createMediaStreamSource( stream );
                let analyser = audioContent.createAnalyser();
                audioStream.connect(analyser);
                analyser.fftSize = 1024;

                let frequencyArray = new Uint8Array(analyser.frequencyBinCount);
                //visualizer.setAttribute('viewBox', '0 0 255 255');

                //Through the frequencyArray has a length longer than 255, there seems to be no
                //significant data after this point. Not worth visualizing.
                // for (let i = 0 ; i < 255; i++) {
                //     path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                //     path.setAttribute('stroke-dasharray', '4,1');
                //     mask.appendChild(path);
                // }
                let doDraw = () => {
                    this.drawer = requestAnimationFrame(doDraw);
                    analyser.getByteFrequencyData(frequencyArray);
                    let adjustedLength = 0;

                    for (let i = 50 ; i < 150; i++) {
                        adjustedLength += frequencyArray[i];
                        //paths[i].setAttribute('d', 'M '+ (i) +',255 l 0,-' + adjustedLength);
                    }

                    this.setState({
                        adjustedLength: Math.floor(adjustedLength)*5 > 10000 ? 10000: Math.floor(adjustedLength)*5,
                        checkMute: adjustedLength < 1000,  //입력값이 500 이하면 Mute라고 판정함.  >> true
                    })
                };
                doDraw();
            };

            let soundNotAllowed = (error)  => {
                //console.log(error);
            };

            /*window.navigator = window.navigator || {};
            /*navigator.getUserMedia =  navigator.getUserMedia       ||
                                      navigator.webkitGetUserMedia ||
                                      navigator.mozGetUserMedia    ||
                                      null;*/
            navigator.getUserMedia({audio:true}, soundAllowed, soundNotAllowed);

        }




    }


    render() {
        if(isMobile || this.props.isRecording){ //모바일이거나 녹음할때는 Not Real Time MV
        //if(true){
            return(
                <div className="recorder-container">
                    <div className="mic-bg">
                        <img alt="mic" src={require(`../../asset/images/lecture/mic-bg.png`)} className="icon-microphone" />
                    </div>
                    <div className="outer" />
                    <div className="outer-2" />

                </div>
            )
        } else {
            return (
                <div style={{
                    display:styles.display,
                    justifyContent:'center',
                    margin: styles.margin,
                    width:'220px',
                    backgroundImage:styles.backgroundImage,
                    backgroundRepeat:styles.backgroundRepeat,
                    backgroundPosition: styles.backgroundPosition,
                    backgroundSize: $(window).width() < 900 ? styles.backgroundSize_m : styles.backgroundSize,
                    padding:'0 20px',

                }}>

                    <Motion
                        defaultStyle={{v: styles.defaultSize, w:styles.defaultSize, x:styles.defaultSize, y:styles.defaultSize, z: styles.defaultSize}}
                        style={{v:spring(styles.defaultSize+this.state.adjustedLength*styles.vCoe),w:spring(styles.defaultSize+this.state.adjustedLength*styles.wCoe), x:spring(styles.defaultSize+this.state.adjustedLength*styles.xCoe), y:spring(styles.defaultSize+this.state.adjustedLength*styles.yCoe), z:spring(styles.defaultSize+this.state.adjustedLength*styles.zCoe)}}
                    >
                        {(style) => (
                            <div style={{
                                display:styles.display,
                                alignSelf:styles.alignSelf,
                            }}>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.v}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.x}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.v}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.y}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.z}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.x}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: '0 20px 0 2px',
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>


                                {/*center*/}

                                <div style={{
                                    display: styles.display,
                                    margin: '0 2px 0 20px',
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.x}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.z}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.y}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.v}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.x}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.w}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                                <div style={{
                                    display: styles.display,
                                    margin: styles.margin_circle,
                                    width: styles.width,
                                    height: `${style.v}px`,
                                    borderRadius: styles.borderRadius,
                                    backgroundColor: styles.backgroundColor,
                                    opacity: styles.opacity,
                                    //transform: `scaleY(${style.x})`,
                                    alignSelf: styles.alignSelf,
                                }}>
                                </div>
                            </div>
                        )}
                    </Motion>
                </div>
            );
        }

    }



}

const styles = {
    display: 'flex',
    margin: '0 auto',
    defaultSize: $(window).width() < 900 ? 3 : 5,
    width: $(window).width() < 900 ? '3px' : '5px',
    height: '10px',
    margin_circle: '0 2px',
    big_height:'30px',
    borderRadius: '5px',
    backgroundColor:'#f0f0f0',
    opacity:'1',
    alignSelf: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'auto 36%',
    backgroundSize_m: 'auto 60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition:'center',
    vCoe:$(window).width() < 900 ? 0.0001 : 0.0002,
    wCoe:$(window).width() < 900 ? 0.00025 : 0.0005,
    xCoe:$(window).width() < 900 ? 0.00055 : 0.0011,
    yCoe:$(window).width() < 900 ? 0.001 : 0.002,
    zCoe:$(window).width() < 900 ? 0.00125 : 0.0025,

};

MicVisualizer.propTypes = propTypes;
MicVisualizer.defaultProps = defaultProps;

export default MicVisualizer;
    
    