import React, {Component} from 'react';
import $ from "jquery";



class DiffInput extends Component {

    constructor(props){
        super(props);
        this.state = {
            userInput:"",
            diffResult:null,
            diffOriginal:this.props.sentence.split(''),

        }
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.letReset && !prevProps.letReset){//리셋 명령
            if(this.state.diffResult !== null && this.state.userInput !== "") {
                this.setState({
                    userInput:"",
                    diffResult:null,
                })
            }
        }
    }


    render(){
        return (

            <li key={`sentence_${this.props.idx}`}>
                <p className="english">
                    {/*{this.props.sentence}*/}
                    {
                        this.state.diffOriginal.map((textOrHtml, index) =>
                            <>
                                {textOrHtml}
                            </>
                        )
                    }
                </p>


                <div className={"input-wrap"}>
                    <input
                        id={`input_${this.props.idx}`}
                        className="input"
                        onChange={event => this._handleInputChange(event)}
                        onKeyDown={event => this._onKeyDown(event, this.props.idx)}
                        value={this.state.userInput}
                        autoComplete={"off"}
                        spellCheck={"off"}
                        autoFocus={this.props.idx === 0}
                        onPaste={(event) => {
                            event.preventDefault()
                        }}
                        onDrop={(event => {
                            event.preventDefault()
                        })}
                    />
                    {/*<span*/}
                        {/*className={"fake-text-2 input-visual"}*/}
                    {/*/>*/}
                </div>

            </li>
        )
    }



    _handleInputChange = (e) => {

        let diffResult = this._diffCheck(this.props.sentence, e.target.value.toString());
        this.setState({
            userInput: e.target.value.toString(),
            diffOriginal:diffResult[0],
            correct:diffResult[1],
            wrong:diffResult[2],
        });

        this.props.setAccuracy(diffResult[1], diffResult[2], this.props.idx);
        this.props.triggerCounter();
    };

    _onKeyDown = (event, i) => {
        if (event.keyCode === 13) { //enter를 누른 경우
            //move focus
            //console.log("엔터 클릭 +",i);
            if(i < this.props.sContentsLength - 1) {
                $(`#input_${i+1}`).focus();
            } else if (i === this.props.sContentsLength - 1){
                this.props.goResultPage();
            }
        }
    };
    //
    // _diffCheck = () => {
    //
    //     let userInputArr = this.state.userInput.split('');
    //     let splitContents = this.props.sentence.split('');
    //     let diffResult = [];
    //
    //     let correct = 0;
    //     let wrong = 0;
    //
    //     for (let i = 0; i < userInputArr.length; i++) {
    //         if (splitContents[i] === userInputArr[i]) {
    //             correct++;
    //             if (userInputArr[i] === " ") {
    //                 diffResult[i] = <span className={"white-space-2"}/>
    //
    //             } else {
    //                 diffResult[i] = <span>{userInputArr[i]}</span>
    //             }
    //         } else {
    //             wrong++;
    //             if (userInputArr[i] === " ") {
    //                 diffResult[i] = <span className={"wrong-space-2"}/>
    //             } else {
    //                 diffResult[i] = <strong>{userInputArr[i]}</strong>;
    //             }
    //         }
    //     }
    //
    //     return [diffResult, correct, wrong];
    //
    //
    // };

    _diffCheck = (original, userResult) => {
        let correct = 0, wrong = 0;


        if(userResult == null){
            return [];
        }

        if(original === userResult) {
            //맞은 경우 엔터에 pressed 표시함
            $(`#key_13`).addClass('pressed');
        } else {
            $(`#key_13`).removeClass('pressed');
        }

        let arrOriginal = original.split('');
        let arrUserResult = userResult.split('');

        let diffOriginal = [];
        for(let i = 0 ; i < arrOriginal.length; i ++){
            if(arrUserResult[i] == null) {
                diffOriginal[i] = <>{arrOriginal[i]}</>
            } else if(arrOriginal[i] === arrUserResult[i]) {
                // if(arrUserResult[i] === " ") {
                //
                //     diffResult[i] = <span className={"white-space sentence"}/>
                //     //diffResult[i] = `${&nbsp}`;
                //
                // } else {
                //     diffResult[i] = <>{arrUserResult[i]}</>
                // }
                diffOriginal[i] = <span className={"tp04-s-correct"}>{arrOriginal[i]}</span>
                correct++;

            } else {
                // if(arrUserResult[i] === " ") {
                //     diffResult[i] = <span className={"wrong-space sentence"}/>
                //     //diffResult[i] = `&nbsp`;
                // } else {
                //     diffResult[i] = <strong>{arrUserResult[i]}</strong>;
                // }
                diffOriginal[i] = <span className={"tp04-s-wrong"}>{arrOriginal[i]}</span>
                wrong++;
            }
        }

        if(userResult.length - arrOriginal.length > 0) {
            wrong += userResult.length-arrOriginal.length;
        }


        return [diffOriginal,correct, wrong];

    }
}


export default DiffInput;

