import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo, isMobile,isIOS} from "../../../../asset/js/myFunctions";
import Footer_SIP from "../../Footer_SIP";


class SIPresentation extends Component {

    constructor(props){
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            maxPage: 0,
            currentPage: 1,
            isStart: false,
            result:"",
            isScriptOpen:false,
            isRecordPlaying:false,
            hasResult:false,
            letRecordingPlay:false,
            uploadComplete: false,
            letUpload:false,
            err:null,
            errCount:0,
            playingIndex:0,
        }

    }

    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap HideScrollBar">
                        {this.state.hasResult ?
                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={()=>{this._playRecordedFile()}}>녹음듣기</button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={()=>{this._uploadRecordedFile()}}>녹음제출</button>
                                </li>
                            </ul>
                            :
                            <div className="ImageContainer">
                                <img
                                    alt={"C.I. Presentation"}
                                    src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[this.state.playingIndex].tpk_url}`}
                                    className="CropImageInL8"/>
                            </div>
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>



                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <dv className="divider"/>
                                        결과가 만족스럽지 않다면 <br/>
                                        <strong>TEST버튼</strong>을 눌러 다시 녹음하세요.
                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            동시 통역 발표를 시작합니다.<br/>
                                            들려주는 <strong>한글 발표</strong>를 <strong>영어로 바꾸어</strong> 발표하세요.<br/>
                                            TEST버튼을 누른 후 발표를 시작하세요.
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_SIP
                    {...this.props}
                    index={this.state.index}
                    startTest = {this._startTest}
                    isRecordPlaying={this.state.isRecordPlaying}
                    isAllPlayingComplete={this.state.isAllPlayingComplete}
                    playRecordedFile = {this._playRecordedFile}
                    stopRecordedFile = {this._stopRecordedFile}
                    hasResult = {this.state.hasResult}
                    checkResult = {this._checkResult}
                    getAudioURL={this._getAudioURL}
                    letUpload = {this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    handleError={this._handleError}
                    playAll={this._playAll}
                />
            </>
        )
    };

    _setResult = (result) => {
        //console.log("결과받음: ", result);
        this.setState({
            result:result
        })
    };


    _checkResult = (bool) => {
        this.setState({
            hasResult:bool,
        })
    };

    _getAudioURL = (url) => {
        if(this.state.audioURL === null){
            this.setState({
                audioURL: url,
            })
        }
    };

    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ");
        //this.props.upload();
        this.setState({
            letUpload:true,
        });
        //alert("파일 제출을 완료하였습니다.!");
        //this._endLesson();
    };


    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isRecordPlaying:true,
        })

    };

    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isRecordPlaying:false,
        })

    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }

        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };

    _playAll = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        //Recursive
        if(index === 0){
            this.props.closeTip();

            this.setState({
                //isAllPlayingComplete: false,
                playingIndex:0,
                isAllPlayingComplete:false,
                isPlaying:true,
            });


        } else if(index > this.props.contents.length - 1) {
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.setState({
                playingIndex:index
            });
        }

        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].kor_url}`;
        this.props.setHowler(
            src,
            () => {
                this._playAll(index + 1);
            },
        );
    }


}

export default withContents()(SIPresentation);