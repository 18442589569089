import React from 'react';
import $ from 'jquery';
import {isMobile} from "../../asset/js/myFunctions";

const LessonHeader = (props) => {
    ////console.log(props.serverData.step_code);


    //if(!props.userInfo) //console.log(props.userInfo); return (<div>Loading...</div>);
    //if(!props.lessonInfo) //console.log(props.lessonInfo); return (<div>Loading...</div>);


    let progressClassName = `progress percent-${props.currentPercent}`;

    return (
        <header className="view-header">
            {
                isMobile && $(window).width() < 1200 ?
                    <div className={progressClassName}>
                        <div className="inner">
                            <div className="percent">
                                <span className="number">{props.currentPercent}</span>
                                <span className="bullet">%</span>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }

            <h2>
                <span className="grade">{props.serverData.step_code}</span>
                <span className="title">Lesson {props.lessonInfo.lesson_no} {props.lessonInfo.lesson_title_en} {props.lessonInfo.lesson_title_kr}</span>
            </h2>


        </header>
    )


};

export default LessonHeader;