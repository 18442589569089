import React, {Component} from 'react';
import '../../asset/css/common.css';



class LessonListHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characterRootURL: 'https://study.itcenglish.com/contents/common',
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            isGuideOpen: false,
        };

        this._handleGuide = this._handleGuide.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.userInfo) {
            // //console.log("called setUserInfo");
            this.props.setUserInfo(nextProps.userInfo);
        }
    }

    _handleGuide = () => {
        this.setState({
            isGuideOpen: !this.state.isGuideOpen,
        });

    };


    render() {


        if (!this.props.userInfo) {
            return (<div> Loading User Info...</div>);
        }
        ////console.log("this.props is",this.props);

        return (
            <>
                <div className={this.state.isGuideOpen ? "help-mask MaskBoxFadeIn" : "help-mask MaskBoxFadeOut"}
                     data-mask="mask"/>
                <header id="header">
                    {/*{JSON.stringify(this.props.userInfo)}*/}
                    <span id="logo">입으로 영어, ITC 통역스쿨</span>
                    <div className="user-info">
                        <figure className="user-pic small">
                            {
                                this.props.userInfo.skin_alphabet == null ?
                                    <img src={`${this.state.characterRootURL}/character/character_a.png`}
                                         alt={this.props.userInfo.mem_name}/>
                                    :
                                    <img
                                        src={`${this.state.characterRootURL}/character/character_${this.props.userInfo.skin_alphabet.replace(/\s/gi, "")}.png`}
                                        alt={this.props.userInfo.mem_name}/>
                            }
                            <span className="user-grade">{this.props.serverData.step_code}</span>
                        </figure>
                        <div className="info">
                            <span className="user-grade">{this.props.serverData.step_code}</span>
                            <span className="user-name">{this.props.serverData.mem_name}</span>
                            <span
                                className="user-desc">{this.props.userInfo.branch_name} {this.props.serverData.tea_name}</span>
                        </div>
                    </div>
                    <button
                        type="button"
                        id="btn-help"
                        data-button="help"
                        className={this.state.isGuideOpen ? "opened" : ""}
                        onClick={() => {
                            this._handleGuide();
                            //console.log("clicked!");
                        }}
                    >
                        도움말
                    </button>
                </header>

                <aside
                    className={this.state.isGuideOpen ? "help-poplayer HelpBoxFadeIn" : "help-poplayer HelpBoxFadeOut"}
                    onClick={() => {
                        this._handleGuide();
                        //console.log("clicked!");
                    }}

                >
                    <div className="help-box">
                        <ul>
                            <li>
                                <h4>학습하기</h4>
                                <p>처음 학습하는 경우 이용하는 메뉴 입니다. 외우기 단계부터 시작하여 한 과를 모두 학습합니다.</p>
                            </li>
                            {
                                this.props.userInfo.student_gb === "S" ?
                                    "" //"S"로 막으면 스피드학습 이용 불가
                                    :
                                    this.props.userInfo.class_type === "O" ?
                                        "" //베이직 수강권 스피드학습 이용 불가
                                        :
                                        this.props.userInfo.class_type === "P" ?
                                            <li>
                                                <h4>스피드학습</h4>
                                                <p>학습을 완료 하신 후 이용하실 수 있는 메뉴입니다. 학습 완료한 과는 스피드학습을 이용해 빠르게 복습할 수 있습니다.  </p>
                                            </li>
                                            :
                                            <li>
                                                <h4>스피드학습</h4>
                                                <p>방문 수업을 통해 충분히 학습한 학생이라면, 또는 복습을 하는 경우 스피드학습을 이용해 보세요.  </p>
                                            </li>
                            }
                            {

                                this.props.userInfo.class_gb === "X" ?
                                    <li>
                                        <h4>응용학습하기</h4>
                                        <p>다양한 추가 응용학습을 이용해보세요. <br /> 응용학습은 온/오프라인 통합 회원 및 프리미엄 전화 수강권에만 서비스 됩니다.</p>
                                    </li>
                                    :
                                    <li>
                                        <h4>응용학습하기</h4>
                                        <p>더 많이 학습하고 싶으신 분들을 위해 준비된 커리큘럼입니다. <br /> 방문 학습에서 배우지 않았던 부분까지도 학습 할 수 있으니 열심히 학습하여 영어실력을 빠르게 키워보세요.</p>
                                    </li>
                            }
                            <li>
                                <h4>타이핑연습</h4>
                                <p>디지털 시대에서 영어 타자는 기본! 타자 실력도 늘리고 영어 스펠링도 함께 학습하세요. PC에서만 이용 가능합니다.</p>
                            </li>
                        </ul>
                    </div>
                </aside>
            </>
        );
    }


}


export default LessonListHeader;
    
