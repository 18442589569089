import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_Practice_B extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
        };
        this._testStart = this._testStart.bind(this);

    }

    _testStart = () => {
        if(isIOS) {
            this.props.fakeRecognitionStart();
            setTimeout(() => {this.props.fakeRecognitionEnd()},2500)
        } else {
            this.props.startRecognition();
        }
        this.props.closeTip();
    };

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index){
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 테스트를 실행하고 index를 num에 맞춤.
            //console.log("received wordInfo is: "+ nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();
            if(isIOS){
                nextProps.fakeRecognitionStart();
                setTimeout(() => {nextProps.fakeRecognitionEnd()},2500)
            } else {
                nextProps.startRecognition();
            }
            return{
                index: nextProps.wordInfo.num,
            }
        }
        return null;
    }


    // componentDidMount(){
    //     this._test3Words();
    // }
    componentDidUpdate(){

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);

        return (

        <section className="view-footer">

            {this.props.listening ? (
                <MicVisualizer />
            )
                :
                (
                    !this.props.waiting && this.props.err !== null?
                        <>

                            <button
                                type="button"
                                className="f-btn btn-prev"
                                onClick={() => {
                                    this.props.goWordsList()
                                }}
                            >
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this._testStart()
                                }}>
                            </button>
                            <button type="button" className="f-btn">Empty</button>
                        </>
                        :
                        <button type="button" className="f-btn">Empty</button>

                )
            }

        </section>

        );
    }

}

export default withRecognizer()(Footer_Practice_B);
    
    