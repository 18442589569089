import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from "../../../other/LoadingBar";
import {brTagActivator, getCurrentMenuInfo, getTodayDate, isMobile} from "../../../../asset/js/myFunctions";
import Footer_LTD from "../../Footer_LTD";
import $ from 'jquery';
import { Element ,  scroller } from 'react-scroll'



class ListenToTheDialogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            // thisMenuNumber: 71,
            isStart:false,
            playingIndex: 0,
            // isPlayingComplete:false,
            isAllPlayingComplete: false,
        };
        // this.props.myJS();
        // this._renderDialogue = this._renderDialogue.bind(this);
        // this._playAll = this._playAll.bind(this);

    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     if(nextProps.isPlayingComplete && !prevState.isPlayingComplete){ //전체 듣기 완료 후, isPlayingComplete = true
    //         return({
    //             isPlayingComplete:nextProps.isPlayingComplete,
    //         })
    //     }
    //
    //     if(nextProps.playingIndex !== prevState.playingIndex){
    //
    //         scroller.scrollTo(`dialogue${nextProps.playingIndex}`,{
    //             duration:800,
    //             delay:0,
    //             smooth:true,
    //             containerId: 'dialogueContainer',
    //             offset:isMobile && $(window).width() < 1200 ? -150 : -300,
    //         });
    //
    //         return {playingIndex: nextProps.playingIndex};
    //     }
    //
    //
    //     return null;
    // }



    componentDidMount(){
        // this.props.myJS();
    }

    render() {
        if(this.props.contents === null || this.props.menuInfo === null){
        //if(true){
            return (<LoadingBar />);
        }
        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }
        return (
            this._renderMain()
        )
    }

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <Element className="content-wrap align-start" id={"dialogueContainer"}>
                        <ul className="talk-list">
                            <li className="datetime"><span>{getTodayDate()}</span></li>
                            {this._renderDialogue()}
                        </ul>
                    </Element>


                    {/*<div className="lecture-step">*/}
                    {/*<span className="current">{this.state.index + 1}</span>/<span*/}
                    {/*className="total">{this.state.maxPage}</span>*/}
                    {/*</div>*/}

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            전체 듣기 버튼을 눌러<br/>
                            오늘 배울 대화를 들어보세요.
                            <div className="divider"/>
                            <div className="tip">말풍선을 클릭하면 해당 대화를<br/>다시 들어볼 수 있습니다.</div>
                        </div>
                    </div>
                </section>

                <Footer_LTD
                    {...this.props}
                    c_code={this.props.c_code}
                    playAll={this.playAll}
                    isAllPlayingComplete={this.state.isAllPlayingComplete}
                    isPlaying={this.state.isPlaying}
                />
            </>
        );
    };



    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({isStart: true})
    };
    _renderDialogue = () => {
        let dialogues = [];
        ////console.log("current activatedbubble is :", this.state.activatedBubble);
        for(let i = 0 ; i < this.props.contents.length; i++){
            dialogues.push(
            <li
                key={(this.props.contents[i].contents_idx)}
                className={
                    // this.props.playingIndex === i ?
                    this.state.playingIndex === i ?
                    (i % 2 === 0 ? "block left-block clicked" : "block right-block clicked")
                        :
                    (i % 2 === 0 ? "block left-block" : "block right-block")}
                onClick={() => {this._selectBubble(i)}}
            >
                <img
                    className="profile"
                    src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[i].mobile_url}`}
                    alt={"다이어로그 캐릭터 프로필"} />
                <Element
                    name={`dialogue${i}`}
                    className={this.props.isPlaying ? "chat-bubble" : "chat-bubble CursorPointer"}>
                    <h2>{brTagActivator(this.props.contents[i].eng_content)}</h2>
                    <p>{brTagActivator(this.props.contents[i].kor_content)}</p> {/*there is no kor_content sound file.*/}
                </Element>
            </li>)
        }

        return (
            dialogues
        );
    };

    // _playAll = () => {
    //     //console.log("play all");
    //     this.props.closeTip();
    //     this.props.audioPlayAll("eng", 0);
    //     //this.props.audioPlay("eng", this.state.index)
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        this.props.setHowler(
            src,
            () => {console.log("end")},
        );
    }

    playAll = (index) => {

        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }

        //Recursive
        if(index === 0){
            this.props.closeTip();

            this.setState({
                isAllPlayingComplete: false,
                playingIndex:0,
                isPlaying:true,
            });

            scroller.scrollTo(`dialogue${index}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });

        } else if(index > this.props.contents.length - 1) {
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.setState({
                playingIndex:index
            });
            scroller.scrollTo(`dialogue${index}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });
        }


        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        //let src = `test.mp3`;
        // this.props.setHowler(
        //     src,
        //     () => {
        //         this.playAll(index + 1);
        //     },
        // );
        this.props.setHowlerError(
            src,
            () => {
                this.playAll(index + 1);
            },
            () => {
                console.log("on load error");
                this.props.setSrc(null);
                setTimeout(() => {
                    this.playAll(index + 1);
                }, 1000)
            },
        );
    }


    _selectBubble = (key) => {
        //console.log(key + " is clicked");
        if(this.props.isPlaying) { //음원 재생중에는 중복 클릭 안되도록 수정함.
            return;
        }
        // this.props.audioPlay("eng", key);
        this.setState({
            playingIndex:key,
        })
        this.audioPlay(key);
    }

}




//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(ListenToTheDialogue);

