import React, {Component} from 'react';

import withRecorder from "./withRecorder";
import MicVisualizer from "../other/MicVisualizer";
import Uploading from '../other/Uploading';

class Footer_Test33_B extends Component {

    constructor(props) {
        super(props);
        this.state = {
            callUpload: false,
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.isPlaying && !nextProps.nowPlaying){ //강좌패널에서 Play 버튼을 눌렀는데, 음원재생중이 아니라면 음원 플레이
            //console.log("녹음파일플레이");
            nextProps.play();
        }


        if(nextProps.letUpload && !prevState.callUpload){
            //console.log("녹음파일 업로드 호출");

            nextProps.stopPlaying();
            //console.log("녹음파일 재생 중이면 녹음파일 재생 종료");
            //let fileName = `${nextProps.serverData.mem_idx}_${nextProps.serverData.step_code}${nextProps.lessonInfo.lesson_no}_1_${Date.now()}.mp3`;
            nextProps.upload(nextProps.attend_idx, nextProps.currentMenu,  nextProps.device);
            return({
                callUpload:true,
            })
        }

        return null;

    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isUpload === false && this.props.isUpload === true){
            this.props.audioUploadComplete()
        }

        if(!prevProps.isTestingComplete && this.props.isTestingComplete){
            this.props.stop();
        }


    }

    render() {
        ////console.log(this.props.currentPage + " and "+ this.props.maxPage);
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
        if(this.props.nowPlaying === true){ //녹음된 파일이 재생 중이면, 재생 정지 버튼 출력.

            return (
                <section className="view-footer">

                    <button
                        type="button"
                        className="f-btn f-center btn-stop"
                        onClick={() => {
                            //this.props.stop()
                            //console.log("stop playing recorded file");
                            this.props.stopPlaying();
                        }}>
                    </button>

                </section>
            )

        }

        return (



            <section className="view-footer">
                {this.props.recording ?
                    (
                        <MicVisualizer
                            //getIsMute={this._getIsMute}
                            isRecording={true}
                        />
                    )
                    :
                    (

                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this.props.start();
                                this.props.startTest();
                                this.props.closeTip();
                            }}>
                        </button>
                    )
                }
                {/*<button type="button" className="f-btn btn-next-page" onClick={() => {this.props.goNextMenu()}}>다음메뉴</button>*/}
                {/*Pass Non-Pass 조건 붙여야 함*/}
                {
                    this.props.uploading ?
                        <Uploading/>
                        :
                        ""
                }
            </section>

        );
    }

    _getIsMute = () => {
        if(this.props.currentPhase > 5){ //어느정도 진행된 이후에는 녹음 정지 X
            return;
        }
        this.props.stopWithoutResult();
        this.props.audioStop();
        this.props.handleError(["녹음이 되지 않습니다.", <br />, <strong>마이크를 확인</strong>,"하신 후 다시 ",<strong>테스트</strong>,"하세요.",<div className="divider"/>,<div className="tip">마이크 <strong>전원과 볼륨</strong>을 확인해 보세요.</div> ]);
        this.props.clearTimeout();

    };

}

export default withRecorder()(Footer_Test33_B);
    
    