import React, {Component} from 'react';

import withRecorder from "./withRecorder";
import MicVisualizer from "../other/MicVisualizer";
import Uploading from '../other/Uploading';

/**
 * Word Final Test 등에서 녹음 하는 패널
 */


class Footer_WFT extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
            callUpload: false,
            // isKoreanPlayingComplete: false,
        };

    }

    componentDidUpdate(prevProps, prevState){
        if(!this.props.isTesting && prevProps.isTesting) { //testing > !testing 이면 녹음 종료
            this._stopRecording();
        }
    }


    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.isPlaying && !nextProps.nowPlaying){ //강좌패널에서 Play 버튼을 눌렀는데, 음원재생중이 아니라면 음원 플레이
            //console.log("녹음파일플레이");
            nextProps.play();
        }

        if(nextProps.letUpload && !prevState.callUpload){
            nextProps.stopPlaying();
            nextProps.upload(nextProps.attend_idx, nextProps.currentMenu,  nextProps.device);
            return({
                callUpload:true,
            })
        }

        return null;

    }


    render() {

        if(this.props.nowPlaying === true){ //녹음된 파일이 재생 중이면, 재생 정지 버튼 출력.

            return (
                <section className="view-footer">

                    <button
                        type="button"
                        className="f-btn f-center btn-stop"
                        onClick={() => {
                            //this.props.stop()
                            //console.log("stop playing recorded file");
                            this.props.stopPlaying();
                        }}>
                    </button>

                </section>
            )

        }

        return (
            <section className="view-footer">
                {this.props.recording ? (
                        <MicVisualizer
                            //getIsMute={this._getIsMute}
                            isRecording={true}
                        />
                    )
                    :
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            //this.props.start()
                            //this._startRecordingAndPlayingKorean();
                            this.props.showKoreanWordsAll();
                            this.props.start();
                            this.props.closeTip();
                        }}>
                    </button>
                }

                {
                    this.props.uploading ?
                        <Uploading/>
                        :
                        ""
                }
            </section>
        );
    }

    _stopRecording = () => {
        //console.log("녹음 종료");
        this.props.stop(); //한글음원재생이 종료 상태이고, recording 중이면  녹음을 정지함.
    }
}

export default withRecorder()(Footer_WFT);
    
    