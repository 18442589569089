import React, {Component} from 'react';
import withContentsReviewWords from "../../withContentsReviewWords";
import {
    brTagActivator,
    getCurrentMenuInfo,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import Footer_AME from '../../Footer_AME';
import StartSound from '../../../../asset/sound/start_sound.mp3';
import EndSound from '../../../../asset/sound/score_sound.mp3';


class ReviewExpressions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            index: 0,
            isTesting: false,
            count: 0,
            activatedWords: null,
            tryCount: 0,
            pass: false,
            currentIndex: 0,

        };

        this.mInterval = null;

    }

    componentDidMount() {
       // this.props.myJS();
    }

    componentWillUnmount() {
        if(this.mInterval != null){
            clearInterval(this.mInterval);
        }
    }

    render() {
        if (this.props.contents === null || this.props.menuInfo === null) {
            //if(true){
            // return(<div>Loading contents...</div>);
            return (<LoadingBar type={0}/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()

        );
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu))}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className={this.state.isTesting ? "content-wrap" : "content-wrap align-start"}>
                        {
                            this.state.pass ?
                                this._renderWords()
                                :
                                this.state.isTesting ?

                                    (
                                        <div className="single-memorize">
                                            <h2>{this.props.contents[this.state.currentIndex].kor_content}</h2>
                                        </div>
                                    )

                                    :
                                    // ""
                                    this._renderWords()
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.pass ?
                                    <>
                                        잘 하셨어요.<br/>
                                        테스트한 표현들을 <strong>다시 확인</strong>하세요.
                                        <div className="divider"/>
                                        <div className="tip">
                                            표현을 <strong>클릭</strong>하면 <strong>소리를</strong><br/>
                                            들어볼 수 있습니다.
                                        </div>
                                    </>
                                    :
                                    <>
                                        배웠던 표현을 복습합니다.<br/>
                                        표현을 모두 확인 하신 후 테스트 버튼을 눌러<br/>
                                        화면에 나오는 <strong>한글 표현</strong>을 <strong>영어로</strong> 말하세요.<br/>
                                    </>
                            }

                        </div>
                    </div>
                </section>
                <Footer_AME
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest={this._startTest}
                    isTesting={this.state.isTesting}
                    pass={this.state.pass}
                />
            </>
        )
    };


    _renderWords = () => {
        let wordsList = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            let key = i;
            wordsList.push(
                <li key={key} id={key} onClick={() => {
                    this._onWordsClick(key)
                }} className={this.state.activatedWords === key ? "active" : ""}>
                    <div className="word">{this.props.contents[key].eng_content}</div>
                    <div className="txt">{this.props.contents[key].kor_content}</div>
                </li>)
        }

        return (
            <ul className="word-list word-list-grid-12">
                {wordsList}
            </ul>
        );
    };

    _onWordsClick = (key) => {
        //console.log(key);
        if (this.props.isPlaying && this.state.activatedWords === key) { //음원 재생중 중복 실행 방지
            return;
        }
        this.audioPlay(key);
        this.setState({
            activatedWords: key,
        })
    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    // _startTest = (bool) => {
    //     if (bool === true) {
    //         let startSound = new Audio(StartSound);
    //         startSound.play();
    //         setTimeout(() => {
    //             this.props.audioPlayAllTwice();
    //         }, 500);
    //
    //     }
    // };

    _startTest = (bool) => {
        if (bool === true) {
            if(this.mInterval != null){
                console.log("interval already running. restart interval.");
                clearInterval(this.mInterval);
                this.mInterval = null;
            }

            let startSound = new Audio(StartSound);
            startSound.play();
            this.setState({
                isTesting:true,
                pass:false,
            })
            this.mInterval = setInterval(() => {
                if(this.state.currentIndex >= this.props.contents.length - 1) {
                    this.setState({
                        pass:true,
                        currentIndex: 0,
                        isTesting:false,
                    })
                    let endSound = new Audio(EndSound);
                    endSound.play();
                    this.props.openTip();
                    clearInterval(this.mInterval);
                    this.mInterval = null;
                } else {
                    let startSound = new Audio(StartSound);
                    startSound.play();
                    this.setState({
                        currentIndex: this.state.currentIndex + 1,
                    });
                }

            }, 3500)

        }
    };


}


//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContentsReviewWords()(ReviewExpressions);

