import React from 'react';
import DiffMatchPatch from 'diff-match-patch';
import axios from 'axios';
import {apiKey} from "../../secret/keys";

axios.defaults.headers.common['Authorization'] = apiKey;

const getCurrentMenuInfo = (menuInfo, menu_no) => {
    ////console.log("received menuInfo is: ",menuInfo);
    ////console.log("received menu_no is: ",menu_no);
    let currentMenuInfo;
    if(menuInfo === null){return null;}
    for (let i = 0; i < menuInfo.length; i++) {
        if (menuInfo[i].sub[`${menu_no}`]) {
            currentMenuInfo = menuInfo[i].sub[`${menu_no}`];
            ////console.log("return currentMenu is:",currentMenuInfo);

            return currentMenuInfo;
        }
        // //console.log(menuInfo[i].sub);
        //return (menuInfo[i]);
    }

    //console.log("return current menu is null");
    //return menuInfo[0];
    return null;
};

const brTagActivator = (input) => {
    if(input === null){
        return null;
    }

    let arr = input.split(/<br\s*\/?>/i);
    //let reducer = (el, a) => el.concat(a, <br />);
    return arr.map((str, i) => {
        if(i === (arr.length - 1)) {
            return (<span key={`brtagact-${i}`}>{str}</span>)
        }
        return (<span key={`brtagact-${i}`}>{str}<br /> </span>)
    });

    //return arr.reduce((el, a) => el.concat(a, <br/>),[]);

};

const strongTagChanger = (input) => {
    if(input == null){
        return null;
    }

    let output = input.replace(/</g,`&`);
    output = output.replace(/>/g,'_');
    output = output.replace(/&/g,'<strong>');
    output = output.replace(/_/g,'</strong>');
    //return arr.reduce((el, a) => el.concat(a, <br/>),[]);

    //console.log("strong output:",output);
    return output;

};


const brTagRemover = (input) => {
    if(input === null){
        return null;
    }

    let arr = input.split(/<br\s*\/?>/i);
    //let reducer = (el, a) => el.concat(a, <br />);
    return arr.map((str, i) => {return (<span key={i}>{str}</span>)});

    //return arr.reduce((el, a) => el.concat(a, <br/>),[]);

};

const calculateScore = (result, original) => { //result = 원본 콘텐츠의 lower case 버전
   // //console.log("result: ",result);
   // //console.log("original: ",original);

    //"##ERROR" 코드로 들어오는 경우, 점수를 무조건 C정도로 출력시킴
    if(result === "##ERROR") {
        return FailScore;
    }

    if(result === "" || result == null) {
        console.warn("결과값이 없습니다.");
        return 0;
    }

    if(result.toLowerCase().match(/([0-9|a-z])/g) == null) {
        console.warn("결과값에 알파벳 또는 숫자 문자열이 없습니다.");
        return FailScore;
    }


    result = retouchResult(result, original); //특정 단어를 보정함
    
    let correct = 0;
    let incorrect = 0;
    let diff;
    const DIFF_DELETE = -1;
    const DIFF_INSERT = 1;
    const DIFF_EQUAL = 0;
    const dmp = new DiffMatchPatch();
    // _diff(original, userResult){
    //     //console.log(userResult);
    //     const dmp = new DiffMatchPatch();
    //     const diff = dmp.diff_main(original.toLowerCase(), userResult.toLowerCase());
    //
    //     return diff;
    //     //return {__html:dmp.diff_prettyHtml(diff)};    // }

    if(typeof result !== "string" || typeof original !== "string"){
        console.warn("인자의 Type 이 올바르지 않거나, 인자값이 존재하지 않습니다.");
        return 0;
    }

    let trimmedOriginal = original.replace(/<br>/gi,"");
    trimmedOriginal = trimmedOriginal.toLowerCase().match(/([0-9|a-z])/g).join("");
   // //console.log(`trimmedOriginal=${trimmedOriginal}`);
    let trimmedResult = result.toLowerCase().match(/([0-9|a-z])/g).join("");
    ////console.log(`trimmedResult=${trimmedResult}`);
    if(trimmedOriginal)
    diff = dmp.diff_main(trimmedOriginal, trimmedResult);
    ////console.log(diff);

    for(let i = 0 ; i < diff.length; i ++){
        let op = diff[i][0];
        switch(op){
            case DIFF_INSERT:
                incorrect += diff[i][1].length;
                break;
            case DIFF_EQUAL:
                correct += diff[i][1].length;
                break;
            case DIFF_DELETE:
                incorrect += diff[i][1].length;
                break;
            default:
                break;
        }

    }

    ////console.log(`Correct: ${correct}, Incorrect: ${incorrect}`);
    //return Math.ceil(20 + ((correct / (incorrect + correct)) * 80));
    let t = correct / (incorrect + correct);
    let score = Math.ceil(t*40);
    //let score = Math.floor(-(t*(t-2))*100);
    // console.log(`${result}와 ${original}에 의한 점수는 ${score+60}`);
    return score + 60;
};

const getTodayDate = () => {
    let today = new Date();
    let options = {weekday: 'long', year: 'numeric', month:'long', day:'numeric'};
    return today.toLocaleDateString('ko-KR', options);
};


//특정 점수가 잘 안나오는 단어들을 보정하는 함수
const retouchResult = (result, original) => {
    
    result = result.toLowerCase();
    /////스위치 문으로 특정 단어들 처리함. 추가해야함
    switch(original){
        case "is":
            if(result === "he's" || result === "ease" || result==="east" || result==="yes"){
                return "is";
            }
            return result;
        case "too":
            if(result === "2" || result === 2 || result === "two"){
                return "too";
            }
            return result;
        case "Oh, I see".toLowerCase():
            if(result === "YC" || result === "IC" || result === "OIC"){
                return "oh i see";
            }
            return result;
        case "Oh, I see.".toLowerCase():
            if(result === "YC" || result === "IC" || result === "OIC"){
                return "oh i see";
            }
            return result;
        case "fourth":
            if(result === "4"){
                return "four";
            }
            return result;
        case "thirsty":
            if (result === "30") {
                return "thirty";
            }
            return result;
        case "knot":
            if (result === "not") {
                return "knot";
            }
            if (result === "no") {
                return "kno";
            }
            return result;
        case "grin":
            if (result === "green") {
                return "grin";
            }
            return result;
        case "tie":
            if (result === "Thai") {
                return "tie";
            }
            return result;
        case "wheat":
            if (result === "wait") {
                return "wheat";
            }
            return result;
        case "mill":
            if (result === "meal" || result === "men" || result === "mirror" || result === "meow") {
                return "mill";
            }
            return result;
        case "duck":
            if (result === "talk") {
                return "duck";
            }
            return result;

        case "nine":
            if(result === 9 || result === "9"){
                return "nine"
            }
            return result;
        case "seven":
            if(result === 7 || result === ""){
                return "seven"
            }
            return result;

        case "twelve":
            if(result === 12 || result === "12"){
                return "twelve"
            }
            return result;

        case "forty two":
            if(result === 42 || result === "42"){
                return "forty two"
            }
            return result;
        case "forty":
            if(result === 40 || result === "40"){
                return "forty"
            }
            return result;
        case "ten":
            if(result === 10 || result === "10"){
                return "ten"
            }
            return result;
        case "five":
            if(result === 5 || result === "5"){
                return "five"
            }
            return result;
        case "four":
            if(result === 4 || result === "4"){
                return "four"
            }
            return result;
        case "thirty":
            if(result === 30 || result === "30"){
                return "thirty"
            }
            return result;
        case "six thirty":
            if(result === 630 || result === "630"){
                return "six thirty"
            }
            return result;
        case "nineteenth":
            if(result === "9th"){
                return "nineteenth"
            }
            return result;
        case "seventh":
            if(result === "7th"){
                return "seventh"
            }
            return result;
        case "twenty-second":
            if(result === "22nd"){
                return "twenty-second"
            }
            return result;
        case "fifth":
            if(result === "5th"){
                return "fifth"
            }
            return result;
        case "twenty":
            if(result === 20 || result === "20"){
                return "twenty"
            }
            return result;
        case "forty-five":
            if(result === 45|| result === "45"){
                return "forty-five"
            }
            return result;
        case "twenty-five":
            if(result === 25|| result === "25"){
                return "twenty-five"
            }
            return result;
        case "fifty":
            if(result === 50|| result === "50"){
                return "fifty"
            }
            return result;
        case "eleven":
            if(result === 11|| result === "11"){
                return "eleven"
            }
            return result;
        case "bee":
            if(result === "be" || result === "B") {
                return "bee"
            }
            return result;
        case "eight":
            if(result === 8 || result === "hey" || result === "8") {
                return "eight"
            }
            return result;
        case "day":
            if(result === "they" || result === "hey") {
                return "day"
            }
            return result;
        case "to":
            if(result === "2" || result === 2 || result === "two") {
                return "to"
            }
            return result;
        case "go":
            if(result === "call" || result === "cool") {
                return "go"
            }
            return result;
        default:
            return result;        
    }   

};


const uploadScore = (apiUrl, score, attendIdx, menu_no, tryCount, classType) => {
    console.log("Upload Score: ",score);
    if(typeof score !== "number") {
        score = 0;
    }

    if(classType === "A"){ //관리자 점수기록 X
        console.log("관리자는 점수기록을 하지 않습니다.");
        return;
    }


    // let device_from = isMobile ? "M" : "P";
    let device_from = isMobile ? isIOS ? "I" : "W" : "C"; //모바일이면 W, PC면 C, iOS면 I

    //console.log(`점수 업로드 시도. score = ${score}, attendIdx = ${attendIdx}, menu_no = ${menu_no}, tryCount = ${tryCount}. url is: ${apiUrl}/class/lesson/score/g/${attendIdx}/${menu_no}/${score}/${device_from}`);

    //무료체험 점수 기록
    if(classType === "F"){

        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/free/lesson/score/g/${attendIdx}/${menu_no}/${score}/${device_from}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            console.log("점수 기록 성공.", res);

        }).catch(err => {
            console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패
        }).then(() => {
            //에러 발견시, 콜백

        });

    } else if (classType === "A"){

    } else {
        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/class/lesson/score/g/${attendIdx}/${menu_no}/${score}/${device_from}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            //console.log("점수 기록 성공.", res);

        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패
            console.warn(err);
            alert("알수 없는 이유로 점수 등록에 실패 하였습니다. 다시 테스트해주세요. 문제가 지속되면 원격 지원센터로 문의 바랍니다.");
        }).then(() => {
            //에러 발견시, 콜백

        });
    }
};

const uploadScoreAl = (apiUrl, score, attendIdx, menu_no, tryCount, classType) => {
    // let device_from = isMobile ? "M" : "P";
    let device_from = isMobile ? isIOS ? "I" : "W" : "C"; //모바일이면 W, PC면 C, iOS면 I

    if(typeof score !== "number") {
        score = 0;
    }

    //관리자 점수기록 X
    if(classType === "A"){
        return;
    }



    //응용학습 무료체험 점수 기록
    if(classType === "F") {
        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/free/lesson/score/g/${attendIdx}/${menu_no}/${score}/${device_from}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            //console.log("점수 기록 성공.", res);

        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패
        }).then(() => {
            //에러 발견시, 콜백
        });

    } else if(classType === "A"){



    //응용학습 점수기록
    } else {
        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/class/lesson/score/g/al/${attendIdx}/${menu_no}/${score}/${device_from}`.replace(/\s/g, ''),
            data: {
                apiKey: apiKey,
            }
            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            //console.log("점수 기록 성공.", res);


        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패
            console.warn(err);
            alert("알수 없는 이유로 점수 등록에 실패 하였습니다. 다시 테스트해주세요. 문제가 지속되면 원격 지원센터로 문의 바랍니다.");

        }).then(() => {
            //에러 발견시, 콜백
        });
    }

};


//answer type = 'T' or 'G'
const uploadAnswerAl = (apiUrl, answer, attendIdx, menu_no, tryCount,answer_type, classType) => {
    // let device_from = isMobile ? "M" : "P";
    let device_from = isMobile ? isIOS ? "I" : "W" : "C"; //모바일이면 W, PC면 C, iOS면 I


    //관리자 점수기록 X
    if(classType === "A"){
        return;
    }


    //응용학습 무료체험 Answer점수 기록
    if(classType === "F") {
        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/free/lesson/answer/al/${attendIdx}/${menu_no}/${device_from}/${answer_type}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                    answer
                },

            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            //console.log("점수 기록 성공.", res);

        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패
        }).then(() => {
            //에러 발견시, 콜백
        });

    } else if(classType === "A"){



    //응용학습 Answer 점수기록
    } else {
        Promise.all([axios({
                method: 'post',
                url: `${apiUrl}/class/lesson/answer/al/${attendIdx}/${menu_no}/${device_from}/${answer_type}`.replace(/\s/g, ''),
                data: {
                    apiKey: apiKey,
                    answer
                }

            }
        )]).then(([res]) => {
            //점수 기록 업로드 성공
            //console.log("점수 기록 성공.", res);

        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패

            console.warn(err);
            alert("알수 없는 이유로 점수 등록에 실패 하였습니다. 다시 테스트해주세요. 문제가 지속되면 원격 지원센터로 문의 바랍니다.");
        }).then(() => {
            //에러 발견시, 콜백
        });
    }


};
//
//
// const reportEndLesson = (apiUrl, attendIdx) => {
//     //console.log("report end lesson called!");
//
//     Promise.all([axios({
//             method: 'put',
//             url: `${apiUrl}/class/lesson/end/${attendIdx}`.replace(/\s/g, ''),
//             data: {
//                 apiKey: apiKey,
//             }
//         }
//     )]).then(([res]) => {
//         //수강종료처리 완료
//         //console.log("수강 완료 처리 성공.", res);
//
//     }).catch(err => {
//         //console.log("에러 발견", err);
//         //메뉴 넘버 업데이트 실패
//     }).then(() => {
//         //에러 발견시, 콜백
//     });
//
// };

const updateStdLesson = (apiUrl, student_idx, nextStdLesson) => {
    //console.log("update std lesson called!");
    if(nextStdLesson > 340){
        return;
    }


    Promise.all([axios({
            method: 'put',
            url: `${apiUrl}/class/lesson/std/${student_idx}/${nextStdLesson}`.replace(/\s/g, ''),
            data: {
                apiKey: apiKey,
            }
        }
    )]).then(([res]) => {
        //std_lesson변경완료
        //console.log("std_lesson변경완료 성공.", res);

    }).catch(err => {
        //console.log("에러 발견", err);
        //메뉴 넘버 업데이트 실패
    }).then(() => {
        //에러 발견시, 콜백
    });
};


const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// const isMobile = true;
const isIOS = /iPhone|iPad/i.test(navigator.userAgent);
// const isIOS = true;



const updateMenuNo = (apiUrl, menuNo, activeMenuNo, step_code, group_no, attend_idx) => {
    Promise.all([axios({
            method: 'get',
            url: `${apiUrl}/class/menu/next/${activeMenuNo}/${step_code}/${group_no}`.replace(/\s/g, ''),
            data: {
                apiKey: apiKey,
            }
        }
    )]).then(([res]) => {
        //다음 메뉴 넘버 조회 성공.
        //let nextMenuNo = res.data.recordset[0].menu_no;
        ////console.log("res.data.recordset[0] = ",res.data.recordset[0]);
        if(res.data.recordset[0] === null || res.data.recordset[0] === undefined){ //res.data.recordset[0] == null
            ///////다음 메뉴 없으면 menu_no를 60으로 변경
            Promise.all([axios({
                    method: 'put',
                    url: `${apiUrl}/class/menu/${attend_idx}/60`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }
            )]).then(([res2]) => {
                //메뉴 넘버 업데이트 성공.
                //console.log("res2:",res2);
            }).catch(err => {
                //console.log("에러 발견", err);
                //메뉴 넘버 업데이트 실패
            }).then(() => {
                //에러 발견시, 콜백

            });

        }else if(res.data.recordset[0].menu_no > menuNo){ //다음 메뉴 넘버가 더 크면, UPDATE
            Promise.all([axios({
                    method: 'put',
                    url: `${apiUrl}/class/menu/${attend_idx}/${res.data.recordset[0].menu_no}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                }
            )]).then(([res2]) => {
                //메뉴 넘버 업데이트 성공.
                //console.log("res2:",res2);
            }).catch(err => {
                //console.log("에러 발견", err);
                //메뉴 넘버 업데이트 실패
            }).then(() => {
                //에러 발견시, 콜백

            });
        } else {
            //작으면 (즉, 메뉴를 다시 들은 경우) 아무것도 안함
            //console.log("메뉴 복습 중으로, menu_no Update 안함");
        }
    }).catch(err => {
        //console.log("에러 발견", err);
        //메뉴 넘버 업데이트 실패
    }).then(() => {
        //에러 발견시, 콜백

    });

};

const FailScore = 70;

const shuffleArr = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

const trimStr = (str) => { //알파벳과 숫자를 제외한 모든 문자 및 공백 제거 후 대문자로 치환
    return str.replace(/[^(a-zA-Z|0-9)]/gi, '').toUpperCase();
};

const trimStrForUpload = (str) => { //특수 문자 제거. ' > ´ 치환

    return str.replace(/'/g,'´').replace(/[{}[\]/,;:|)*~^\-_+<>@#$%&\\=(]/gi, '');
};

const trimStrForUpload2 = (str) => { // //특수 문자 제거 |,는 제거 안함.
    return str.replace(/'/g,'´').replace(/[{}[\]/;:)*~^\-_+<>@#$%&\\=(]/gi, '');
};


const capitalize = (str) => {
    if(typeof str !== "string"){
        //console.log("입력값이 String Type이 아닙니다.");
        return null;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);

}

//sort by c_code_no and content_seq
const _sort = (contents) => {
    return contents.sort((a,b) => {
        if(a.c_code_no === "" || a.c_code_no === " ") {
            if(a.content_seq < b.content_seq) {
                return -1;
            } else if(a.content_seq > b.content_seq){
                return 1;
            } else {
                return 0;
            }
        } else if(parseInt(a.c_code_no) < parseInt(b.c_code_no)) {
            return -1;
        } else if(parseInt(a.c_code_no) > parseInt(b.c_code_no)) {
            return 1;
        } else { //c_code_no 가 같은 경우 content_seq 로 정렬
            if(a.content_seq < b.content_seq) {
                return -1;
            } else if(a.content_seq > b.content_seq){
                return 1;
            } else {
                return 0;
            }
        }
    })
}

export {
    getCurrentMenuInfo,
    brTagActivator,
    calculateScore,
    brTagRemover,
    getTodayDate,
    uploadScore,
    uploadScoreAl,
    uploadAnswerAl,
    isMobile,
    isIOS,
    //reportEndLesson,
    updateStdLesson,
    updateMenuNo,
    FailScore,
    strongTagChanger,
    shuffleArr,
    trimStr,
    capitalize,
    trimStrForUpload,
    trimStrForUpload2,
    _sort,
}