import React, {Component} from 'react';
import axios from 'axios';
import ReactHowler from 'react-howler'
import {apiKey} from "../../secret/keys";
import {_sort} from "../../asset/js/myFunctions";
axios.defaults.headers.common['Authorization'] = apiKey;

//Process.env.PUBLIC_URL === ""

let mTimeout = null;
let tempAudio = null;

const withContents = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            contents: null,
            //rootURL: 'http://localhost:8080/contents',
            //rootURL: 'http://api.itconline.co.kr/contents',
            soundRootURL: 'https://dev.itcenglish.com/contents/ts/sound', //test server
            //soundRootURL: 'https://study.itcenglish.com/contents/ts/sound',
            //soundRootURL: 'http://localhost:3000/contents/ts',
            playingIndex: 0,
            audioList: [],
            isPlaying: false,
            isPlayingComplete: false,
            isAllPlayingComplete: false,
            hasErrorOnLoad:false,
            hasErrorOnPlay:false,
            c_code: null,
            playingAudioURL: null,
            loadCount:0,
            playing:false,
            audioSrc:null,
            callback:null,
            errorCallback:null,
            volume:1,
        };



        componentDidMount() {
            //console.log("헬로우");
            this.initialize();
            this.mounted = true;

        }


        componentWillUnmount() {
            //this._audioStop();
            clearTimeout(mTimeout);
            mTimeout = null;
            this.mounted = false;
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevState.c_code !== this.state.c_code) {
                this.setState({
                    contents: null,
                    isAllPlayingComplete: false,
                });
                this.initialize();
            }
            //console.log(this.state.playing);
        }

        static getDerivedStateFromProps(nextProps, prevState) {

            if (nextProps.c_code !== prevState.c_code) {
                //console.log("nextProps.c_code :", nextProps.c_code);
                //console.log("prevState.c_code :", prevState.c_code);

                return ({
                    c_code: nextProps.c_code
                })
            }

            return null;
        }


        initialize() {
            Promise.all([
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/contents/${this.props.lessonInfo.step_code}/${this.props.lessonInfo.lesson_no}/${this.state.c_code}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ])
                .then(([resContents]) => {
                    // //console.log("component will mount contents is:", res);
                    let contents = resContents.data;
                    //console.log("API에서 받아온 CONTENTS:", contents);
                    if(this.mounted) {
                        this.setState({
                            ...this.state,
                            contents: _sort(contents),
                            //menuList,
                        });
                    }

                })
                .catch(function (err) {
                    console.log(err);
                })
                .then(function () {
                });
        };

        // _audioFakePlay(index) { //playingIndex 만 업데이트 함. memorizeB에서 사용함.
        //     this.setState({
        //         playingIndex: index,
        //     });
        // }
        //
        // _audioPlayRetry(lang, index){
        //     let audioSource2 = "";
        //     if (lang === "kor") {
        //         if (!this.state.contents[index].kor_url) {
        //             console.log("There is no kor_Sound file.");
        //             return;
        //         }
        //         audioSource2 = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].kor_url}`
        //     } else if (lang === "tpk") {
        //         audioSource2 = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].tpk_url}`
        //     }
        //     else {
        //         audioSource2 = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].eng_url}`
        //     }
        //
        //     let audio = new Howl({
        //         src:audioSource2,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 1,
        //         onend: () => {
        //             // this.skip('next');
        //             tempAudio = null;
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplay: () => {
        //             //console.log("audio Play");
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // // howlAudio:audio,
        //                 playingIndex:index,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource2);
        //             audio.off();
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource2);
        //             audio.off();
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //
        //         },
        //     });
        //     audio.play();
        // }
        //
        // _audioPlay(lang, index) {
        //     let url = "";
        //
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     let audioSource = "";
        //     if (lang === "kor") {
        //         if (!this.state.contents[index].kor_url) {
        //             console.log("No kor_Sound File.");
        //             return;
        //         }
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].kor_url}`
        //     } else if (lang === "tpk") {
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].tpk_url}`
        //     }
        //     else {
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].eng_url}`
        //     }
        //
        //     let audio = new Howl({
        //         src:audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 1,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplay: () => {
        //            //console.log("audio Play");
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // // howlAudio:audio,
        //                 playingIndex:index,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //             audio.off();
        //             this._audioPlayRetry(lang, index);
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //         },
        //     });
        //     audio.play();
        // }
        //
        // _audioPlayWithURLRetry(url){
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     let audioSource = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`;
        //     //let audio = new Audio(process.env.PUBLIC_URL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`);
        //
        //     let audio = new Howl({
        //         src:audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 1,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source on retry: ",audioSource);
        //             audio.off();
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source on retry: ",audioSource);
        //             audio.off();
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //     });
        //     audio.play();
        // }
        //
        // _audioPlayWithURL(url) {
        //
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     let audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`;
        //     //let audio = new Audio(process.env.PUBLIC_URL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${url}`);
        //
        //     let audio = new Howl({
        //         src:audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 1,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //             audio.off();
        //             this._audioPlayWithURLRetry(url);
        //             // let tempAudio = new Audio(audioSource);
        //             // tempAudio.play();
        //             // tempAudio.onplay = () => {
        //             //     this.setState({
        //             //         isPlaying: true,
        //             //         isPlayingComplete: false,
        //             // //         howlAudio:audio,
        //             //         loadCount:0,
        //             //     })
        //             // }
        //             // tempAudio.onended = () => {
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //             // //         howlAudio:null,
        //             //     });
        //             // }
        //             // tempAudio.onerror = () => {
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //             // //         howlAudio:null,
        //             //     });
        //             // };
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,
        //             // //         howlAudio:null,
        //             //         loadCount:0,
        //             //     });
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayWithURL(url);
        //             //         }, 1000);
        //             //     })
        //             //
        //             // }
        //             // audio.stop();
        //             // audio.off();
        //             // audio = null;
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,
        //             // //         howlAudio:null,
        //             //         loadCount:0,
        //             //     });
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayWithURL(url);
        //             //         }, 1000);
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //     });
        //     audio.play();
        // }
        //
        // _audioPlayMute(lang, index, callback) {
        //     //let url = "";
        //
        //     this.setState({
        //         playingIndex: index,
        //     });
        //
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     let audioSource = "";
        //     if (lang === "kor") {
        //         if (!this.state.contents[index].kor_url) {
        //             //console.log("No kor_Sound File.");
        //             return;
        //         }
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].kor_url}`
        //
        //     } else {
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].eng_url}`
        //     }
        //
        //     let audio = new Howl({
        //         src:audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 0,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //
        //             callback();
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //             clearTimeout(mTimeout);
        //             if(this.state.loadCount >= 2){
        //                 this.setState({
        //                     isPlaying: false,
        //                     isPlayingComplete: true,
        //                     // howlAudio:null,
        //                     loadCount:0,
        //                 });
        //                 callback()
        //             } else {
        //                 this.setState({
        //                     loadCount: this.state.loadCount + 1,
        //                 }, () => {
        //                     mTimeout = setTimeout(() => {
        //                         this._audioPlayMute(lang, index, callback); //다시 시도
        //                     }, 1000);
        //                 })
        //             }
        //             audio.stop();
        //             audio = null;
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,
        //             // //         howlAudio:null,
        //             //         loadCount:0,
        //             //     });
        //             //     callback()
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayMute(lang, index, callback);
        //             //         }, 1000);
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //     });
        //
        //     audio.play();
        // }
        //
        // _audioPlayWithCb(lang = "eng", index, callback) {
        //     let url = "";
        //
        //
        //     this.setState({
        //         playingIndex: index,
        //     });
        //
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     let audioSource = "";
        //
        //     if (lang === "kor") {
        //         if (!this.state.contents[index].kor_url) {
        //             //console.log("No kor_Sound File.");
        //             return;
        //         }
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].kor_url}`
        //     } else if(lang === "eng"){
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].eng_url}`
        //     } else if(lang === "tpk"){
        //         audioSource = this.state.soundRootURL + `/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[index].tpk_url}`
        //     }
        //
        //     let audio = new Howl({
        //         src:audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         volume: 1,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //             callback();
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying: true,
        //                 isPlayingComplete: false,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //
        //             let tempAudio = new Audio(audioSource);
        //             tempAudio.play();
        //             tempAudio.onplay = () => {
        //                 this.setState({
        //                     isPlaying: true,
        //                     isPlayingComplete: false,
        //                     // howlAudio:audio,
        //                     loadCount:0,
        //                 })
        //             }
        //             tempAudio.onended = () => {
        //                 this.setState({
        //                     isPlaying: false,
        //                     isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                     // howlAudio:null,
        //                 });
        //                 callback();
        //             }
        //             tempAudio.onerror = () => {
        //                 this.setState({
        //                     isPlaying: false,
        //                     isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                     // howlAudio:null,
        //                 });
        //                 callback();
        //             };
        //             audio.stop();
        //             audio.off();
        //             audio = null;
        //
        //             /* Howl 에서 에러 발생 시 Audio Library 로 사용하는걸로 변경. */
        //
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,
        //             // //         howlAudio:null,
        //             //         loadCount:0,
        //             //     });
        //             //     callback();
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayWithCb(lang, index, callback); //다시 시도
        //             //         }, 1000);
        //             //     })
        //             //
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //             this.setState({
        //                 isPlaying: false,
        //                 isPlayingComplete: true,  //완전 재생이 끝난 경우 이런 변수를 뿌려줌.
        //                 // howlAudio:null,
        //             });
        //             callback();
        //             //clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         isPlaying: false,
        //             //         isPlayingComplete: true,
        //             // //         howlAudio:null,
        //             //         loadCount:0,
        //             //     });
        //             //     callback();
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayWithCb(lang, index, callback);
        //             //         }, 1000);
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //     });
        //     audio.play();
        // }
        //
        // _audioStop() {
        //     if(tempAudio !== null){
        //         tempAudio.pause();
        //         tempAudio.stop();
        //         this.setState({
        //             isPlaying: false,
        //             playingIndex:0,
        //             // howlAudio:null,
        //             loadCount:0,
        //         })
        //     }
        //
        //     // if (this.state.howlAudio !== null) {
        //     //     //console.log("howlAudio Stop");
        //     //     this.state.howlAudio.pause();
        //     //     this.state.howlAudio.stop();
        //     //     this.setState({
        //     //         isPlaying: false,
        //     //         playingIndex:0,
        //     //         // howlAudio:null,
        //     //         loadCount:0,
        //     //     })
        //     // }
        //
        // }
        //
        // _audioPause() {
        //     if(tempAudio !== null){
        //         tempAudio.pause();
        //         this.setState({
        //             isPlaying: false,
        //             isPlayingComplete: false,
        //         });
        //     }
        //     // if (this.state.howlAudio !== null) {
        //     //     //console.log("howlAudio Stop");
        //     //     this.state.howlAudio.pause();
        //     //     this.setState({
        //     //         isPlaying: false,
        //     //         isPlayingComplete: false,
        //     //     });
        //     // }
        // }
        //
        // _audioPlayAllRetry(lang, playIdx){
        //
        //     let audioSource = "";
        //     if (lang === "kor") {
        //         audioSource = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[playIdx].kor_url}`;
        //     } else { //eng
        //         audioSource = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[playIdx].eng_url}`;
        //     }
        //
        //     let audio = new Howl({
        //         src: audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             this.setState({
        //                 // howlAudio:null,
        //             },() => {
        //                 this._audioPlayAll(lang, playIdx + 1);
        //             });
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //             audio.off();
        //             this.setState({
        //                 // howlAudio:null,
        //             },() => {
        //                 this._audioPlayAll(lang, playIdx + 1);
        //             });
        //
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //             audio.off();
        //             this.setState({
        //                 // howlAudio:null,
        //             },() => {
        //                 this._audioPlayAll(lang, playIdx + 1);
        //             });
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying:true,
        //                 isPlayingComplete:false,
        //                 isAllPlayingComplete:false,
        //                 // howlAudio:audio,
        //                 playingIndex: playIdx,
        //                 loadCount:0,
        //             })
        //         }
        //     });
        //
        //     audio.play();
        // }
        //
        // _audioPlayAll(lang, playIdx) {
        //     if (!this.state.contents) {
        //         console.log("Not loaded contents yet.");
        //         return;
        //     }
        //     if(tempAudio !== null){
        //         if(tempAudio.playing()) {
        //             return;
        //         }
        //     }
        //
        //     if(this.state.howlAudio != null){
        //         if(this.state.howlAudio.playing()) {
        //             console.log("playing");
        //             return;
        //         }
        //     }
        //
        //     if(playIdx >= this.state.contents.length) { //재생 끝
        //         this.setState({
        //             isPlaying: false,
        //             isPlayingComplete: true,
        //             isAllPlayingComplete: true,
        //             playingIndex:0,
        //             // howlAudio:null,
        //             loadCount:0,
        //         });
        //         return;
        //     }
        //
        //     let audioSource;
        //     if (lang === "kor") {
        //         audioSource = `${this.state.soundRootURL}/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[playIdx].kor_url}`;
        //     } else { //eng
        //         audioSource = `${this.state.soundRootURL}/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[playIdx].eng_url}`;
        //     }
        //
        //
        //     let audio = new Howl({
        //         src: audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             this.setState({
        //                 // howlAudio:null,
        //             },() => {
        //                 this._audioPlayAll(lang, playIdx + 1);
        //             });
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSource);
        //             audio.off();
        //             this._audioPlayAllRetry(lang, playIdx);
        //             //audio.stop();
        //             //audio = null;
        //             // let tempAudio = new Audio(audioSource);
        //             // tempAudio.play();
        //             // tempAudio.onplay = () => {
        //             //     this.setState({
        //             //         isPlaying:true,
        //             //         isPlayingComplete:false,
        //             //         isAllPlayingComplete:false,
        //             // //         howlAudio:audio,
        //             //         playingIndex: playIdx,
        //             //         loadCount:0,
        //             //     });
        //             // }
        //             // tempAudio.onended = () => {
        //             //     this._audioPlayAll(lang, playIdx + 1);
        //             // }
        //             // tempAudio.onerror = () => {
        //             //     this._audioPlayAll(lang, playIdx + 1);
        //             // };
        //
        //             //
        //             // if(this.state.loadCount >= 2) {
        //             //     this.setState({
        //             //         loadCount:0,
        //             // //         howlAudio:null,
        //             //     }, () => {
        //             //         this._audioPlayAll(lang, playIdx + 1);
        //             //     })
        //             // } else {
        //             //     this.setState({
        //             //         loadCount:this.state.loadCount + 1,
        //             // //         howlAudio:null,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayAll(lang, playIdx); //다시 시도
        //             //         }, 500)
        //             //     })
        //             //
        //             // }
        //             // audio.stop();
        //             // audio.off();
        //             // audio = null;
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSource);
        //             audio.off();
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2) {
        //             //     this.setState({
        //             //         loadCount:0,
        //             // //         howlAudio:null,
        //             //     }, () => {
        //             //         this._audioPlayAll(lang, playIdx + 1);
        //             //     })
        //             // } else {
        //             //     this.setState({
        //             //         loadCount:this.state.loadCount + 1,
        //             // //         howlAudio:null,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayAll(lang, playIdx);
        //             //         }, 200)
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying:true,
        //                 isPlayingComplete:false,
        //                 isAllPlayingComplete:false,
        //                 // howlAudio:audio,
        //                 playingIndex: playIdx,
        //                 loadCount:0,
        //             })
        //         }
        //     });
        //
        //     audio.play();
        //
        // }
        //
        // _audioPlayPart = (indexes, currentIndex, callback) => {
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     if(currentIndex >= indexes.length) { //재생 끝
        //         this.setState({
        //             isPlaying: false,
        //             isPlayingComplete: true,
        //             playingIndex: 0,
        //         });
        //         callback();
        //         return;
        //     }
        //
        //
        //     let audioSrc = `${this.state.soundRootURL}/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[indexes[currentIndex]].eng_url}`;
        //
        //     let audio = new Howl({
        //         // src: this.state.audioList[this.state.playingIndex - start],
        //         src: audioSrc,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             // this.skip('next');
        //             this.setState({
        //                 // howlAudio:null,
        //             }, () => {
        //                 this._audioPlayPart(indexes, currentIndex + 1, callback);
        //             });
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 playingIndex: currentIndex,
        //                 isPlaying:true,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSrc);
        //             clearTimeout(mTimeout);
        //             if(this.state.loadCount >= 2){
        //                 this.setState({
        //                     loadCount:0,
        //                     // howlAudio:null,
        //                 }, () => {
        //                     this._audioPlayPart(indexes, currentIndex + 1, callback);
        //                 });
        //             } else {
        //                 this.setState({
        //                     loadCount: this.state.loadCount + 1,
        //                 }, () => {
        //                     mTimeout = setTimeout(() => {
        //                         this._audioPlayPart(indexes, currentIndex, callback); //다시 시도
        //                     }, 1000)
        //                 })
        //             }
        //             audio.stop();
        //             audio.off();
        //             audio = null;
        //         },
        //         onplayerror: () => {
        //             console.log("Error occurred on play sound source: ",audioSrc);
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this.setState({
        //             //         loadCount:0,
        //             // //         howlAudio:null,
        //             //     }, () => {
        //             //         this._audioPlayPart(indexes, currentIndex + 1, callback);
        //             //     });
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayPart(indexes, currentIndex, callback);
        //             //         }, 1000)
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //     });
        //     audio.play();
        // };
        //
        // //
        //
        // //0~num까지 플레이
        // _audioPlayTill(num, playIdx) {
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     //play complete
        //     if(playIdx >= num) {
        //         //do complete
        //         this.setState({
        //             isPlayingComplete: true,
        //             isPlaying:false, //재생 완료
        //             // howlAudio:null,
        //         })
        //         return;
        //     }
        //
        //     let audioSource = `${this.state.soundRootURL}/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[playIdx].eng_url}`
        //
        //     let audio = new Howl({
        //         src: audioSource,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             this._audioPlayTill(num, playIdx + 1); //다음 노래 재생
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 isPlaying: true,
        //                 playingIndex: playIdx,
        //                 isPlayingComplete: false,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occured on load sound source.");
        //             clearTimeout(mTimeout);
        //             if(this.state.loadCount >= 2){
        //                 this._audioPlayTill(num, playIdx + 1); //다음 노래 재생
        //             } else {
        //                 this.setState({
        //                     loadCount: this.state.loadCount + 1,
        //                 }, () => {
        //                     mTimeout = setTimeout(() => {
        //                         this._audioPlayTill(num, playIdx); //다시 한번 재생 시도
        //                     }, 1500)
        //                 })
        //                 audio.stop();
        //                 audio.off();
        //                 audio = null;
        //             }
        //         },
        //         onplayerror: () => {
        //             console.log("Error occured on play sound source.");
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this._audioPlayTill(num, playIdx + 1); //다음 노래 재생
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayTill(num, playIdx); //다시 한번 재생 시도
        //             //         }, 1500)
        //             //     })
        //             //     audio.stop();
        //             //     audio = null;
        //             // }
        //         },
        //     });
        //
        //     audio.play();
        // }
        //
        //
        // _audioPlayRangeRetry(start, end, index){
        //
        //     let audioSrc2 = `https://dev.itcenglish.com/contents/ts/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[start + index].eng_url}`;
        //     let audio = new Howl({
        //         // src: this.state.audioList[this.state.playingIndex - start],
        //         src: audioSrc2,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             this._audioPlayRange(start, end, index + 1);
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 playingIndex: start + index,
        //                 isPlaying:true,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source on retry: ",audioSrc2);
        //             this._audioPlayRange(start, end, index + 1);
        //         },
        //         onplayerror: () => {
        //             console.log("Error occured on play sound source on retry: ",audioSrc2);
        //             this._audioPlayRange(start, end, index + 1);
        //         },
        //     });
        //     audio.play();
        // }
        // _audioPlayRange(start, end, index) {
        //    // let audioList = [];
        //     if (!this.state.contents) {
        //         //console.log("NOt loaded contents yet.");
        //         return;
        //     }
        //
        //     if (start > end) {
        //         //console.log("start can't be smaller then end");
        //         return;
        //     }
        //
        //     if(this.state.howlAudio != null){
        //         if(this.state.howlAudio.playing()) {
        //             console.log("playing");
        //             return;
        //         }
        //     }
        //
        //     if(index > end - start) { //재생할게 없으면 종료
        //         this.setState({
        //             //playingIndex: 0,
        //             isPlaying:false,
        //             loadCount:0,
        //             // howlAudio:null,
        //         });
        //         return;
        //     }
        //
        //     let audioSrc = `${this.state.soundRootURL}/sound/${this.state.contents[0].step_code}/${this.state.contents[0].lesson_no}/${this.state.contents[start + index].eng_url}`;
        //
        //     let audio = new Howl({
        //         // src: this.state.audioList[this.state.playingIndex - start],
        //         src: audioSrc,
        //         autoplay: true,
        //         loop: false,
        //         html5: true,
        //         preload: true,
        //         onend: () => {
        //             this._audioPlayRange(start, end, index + 1);
        //         },
        //         onplay: () => {
        //             this.setState({
        //                 playingIndex: start + index,
        //                 isPlaying:true,
        //                 // howlAudio:audio,
        //                 loadCount:0,
        //             })
        //         },
        //
        //         onloaderror: () => {
        //             console.log("Error occurred on load sound source: ",audioSrc);
        //             audio.off();
        //             this._audioPlayRange(start, end, index);
        //             // let tempAudio = new Audio(audioSrc);
        //             // tempAudio.play();
        //             // tempAudio.onplay = () => {
        //             //     this.setState({
        //             //         playingIndex: start + index,
        //             //         isPlaying:true,
        //             // //         howlAudio:audio,
        //             //         loadCount:0,
        //             //     });
        //             // }
        //             // tempAudio.onended = () => {
        //             //     this._audioPlayRange(start, end, index + 1);
        //             // }
        //             // tempAudio.onerror = () => {
        //             //     this._audioPlayRange(start, end, index + 1);
        //             // };
        //
        //             //
        //             // clearTimeout(mTimeout);
        //             // if(this.state.loadCount >= 2){
        //             //     this._audioPlayRange(start, end, index + 1);
        //             //     audio.stop();
        //             //     audio = null;
        //             // } else {
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayRange(start, end, index); //다시 플레이 시도
        //             //         }, 1000)
        //             //     })
        //             //     audio.stop();
        //             //     audio.off();
        //             //     audio = null;
        //             // }
        //         },
        //         onplayerror: () => {
        //             console.log("Error occured on play sound source: ",audioSrc);
        //             // if(this.state.loadCount >= 2){ //로드 카운트 다 써서 그냥 다음으로 넘김
        //             //     if(index < end - start) { //재생할게 남은 상태
        //             //         this.setState({
        //             //             playingIndex: start + index + 1,
        //             //             loadCount: 0,
        //             // //             howlAudio:null,
        //             //         },() => {
        //             //             this._audioPlayRange(start, end, index + 1);
        //             //         });
        //             //     } else {
        //             //         this.setState({
        //             //             //playingIndex: 0,
        //             //             isPlaying:false,
        //             //             loadCount:0,
        //             // //             howlAudio:null,
        //             //         });
        //             //     }
        //             // } else { //재 시도
        //             //     this.setState({
        //             //         loadCount: this.state.loadCount + 1,
        //             //         howlAUdio:null,
        //             //     }, () => {
        //             //         mTimeout = setTimeout(() => {
        //             //             this._audioPlayRange(start, end, index);
        //             //         }, 1000)
        //             //     })
        //             // }
        //             // audio.stop();
        //             // audio = null;
        //         },
        //     });
        //     audio.play();
        // }


        resumeCtx = () => {
            this.player.howler.ctx.resume();
        }


        handleOnLoadError = (err) => {
            if(typeof this.state.errorCallback === "function") {
                this.state.errorCallback();
            } else {
                console.log("on load error: ",err);
                this.setSrc(null);
            }
        }

        setSrc = (src) => {
            this.setState({
                audioSrc:src,
                playing:false,
                isPlaying:false,
            })
        }

        setHowler = (src, callback) => {
            //console.log("set howler",src);
            this.setState({
                audioSrc:src,
                playing:true,
                isPlaying:true,
                volume:1,
                callback,
                errorCallback:null,
            })
        }

        setHowlerError = (src, callback, errorCallback) => {
            this.setState({
                audioSrc:src,
                playing:true,
                isPlaying:true,
                volume:1,
                callback,
                errorCallback,
            })
        }

        setHowlerMute = (src, callback) => {
            this.setState({
                audioSrc:src,
                playing:true,
                isPlaying:true,
                volume:0,
                callback,
                errorCallback:null,
            })
        }

        audioStop = () => {
            this.setState({
                playing:false,
                isPlaying:false,
            })
        }

        render() {
            const {contents} = this.state;
            return (
                <>
                    {
                        (this.state.audioSrc && this.state.playing) &&
                        <ReactHowler
                            preload={true}
                            playing={this.state.playing}
                            src={`${this.props.soundRootURL}/${this.state.audioSrc}`}
                            onLoadError={this.handleOnLoadError}
                            html5={true}
                            volume={this.state.volume}
                            onEnd={() => {
                                //console.log("end");
                                this.setState({
                                    playing:false,
                                    isPlaying:false,
                                })
                                if(typeof this.state.callback === "function") {

                                    this.state.callback();

                                }
                            }
                            }
                            // onEnd={() => console.log("end")}
                            ref={(ref) => (this.player = ref)}
                        />
                    }
                    <WrappedComponent {...this.props}
                                      contents={contents}
                                      setHowler={this.setHowler}
                                      setHowlerMute={this.setHowlerMute}
                                      setHowlerError={this.setHowlerError}
                                      setSrc={this.setSrc}
                                      audioStop={this.audioStop}
                                      // audioPause={this._audioPause.bind(this)}
                                      // audioStop={this._audioStop.bind(this)}
                                      // audioPlay={this._audioPlay.bind(this)}
                                      // audioPlayWithURL={this._audioPlayWithURL.bind(this)}
                                      // audioPlayWithCb={this._audioPlayWithCb.bind(this)}
                                      // audioPlayMute={this._audioPlayMute.bind(this)}
                                      // audioFakePlay={this._audioFakePlay.bind(this)}
                                      // audioPlayAll={this._audioPlayAll.bind(this)}
                                      // audioPlayTill={this._audioPlayTill.bind(this)}
                                      // audioPlayPart={this._audioPlayPart.bind(this)}
                                      // audioPlayRange={this._audioPlayRange.bind(this)}
                                      playingIndex={this.state.playingIndex}
                                      isPlaying={this.state.isPlaying}
                                      // isPlayingComplete={this.state.isPlayingComplete}
                                      // isAllPlayingComplete={this.state.isAllPlayingComplete}
                                      hasErrorOnLoad={this.state.hasErrorOnLoad}
                                      hasErrorOnPlay={this.state.hasErrorOnPlay}
                    />
                </>

            )
        }
    }


};

export default withContents;