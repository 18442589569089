import React, {Component} from 'react';
import axios from 'axios';
import {apiKey} from "../../secret/keys";

axios.defaults.headers.common['Authorization'] = apiKey;
const withUserData = () => (WrappedComponent) => {
    return class extends Component {
        state = {
            userInfo: null,
            //rootURL:'http://api.itconline.co.kr/'
        };

        _initialize() {

            ////console.log(this.props.tempStepInfo);
            ////console.log("this.props.apiUrl:",this.props.apiUrl);
            if (this.props.serverData.auth_type === "N") {
                Promise.all([
                    //axios.get(`${this.props.apiUrl}/users/${this.props.tempStepInfo.step}/${this.props.tempStepInfo.std_lesson}`.replace(/\s/g, '')),  //Contents
                    //axios.get(`${this.props.apiUrl}/users/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}`)
                    axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/users/${this.props.serverData.mem_idx}/${this.props.serverData.class_idx}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    })
                ])
                .then(([resUserInfo]) => {
                    const userInfo = resUserInfo.data;
                    //console.log("API에서 받아온 유저 정보:", userInfo);
                    this.setState({
                        ...this.state,
                        userInfo,
                    });
                })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });
            } else if (this.props.serverData.auth_type === "F") {

                let userInfo = {
                    std_lesson: 1,
                    lesson_no: 1,
                    skin_alphabet: 'a',
                    score_type: 1, //scoreType === 0 이면 text, 1이면 abc
                    class_type:'B',
                    student_gb:'N', //"S" 면 스피드 학습 안되게, "N"일때는 스피드 학습 가능
                };

                this.setState({
                    ...this.state,
                    userInfo,
                });
            } else if (this.props.serverData.auth_type === "A"){

                let userInfo = {
                    std_lesson: 1,
                    lesson_no: 1,
                    skin_alphabet: 'a',
                    score_type: 1, //scoreType === 0 이면 text, 1이면 abc
                    class_type:'B',
                    student_gb:'N', //"S" 면 스피드 학습 안되게, "N"일때는 스피드 학습 가능
                };

                this.setState({
                    ...this.state,
                    userInfo,
                });
            } else {
                let userInfo = {
                    std_lesson: 1,
                    lesson_no: 1,
                    skin_alphabet: 'a',
                    score_type: 1, //scoreType === 0 이면 text, 1이면 abc
                    class_type:'B',
                    student_gb:'N', //"S" 면 스피드 학습 안되게, "N"일때는 스피드 학습 가능
                };

                this.setState({
                    ...this.state,
                    userInfo,
                });
            }


        };

        componentDidMount() {
            this._initialize();
        }

        render() {
            const {userInfo} = this.state;
            return (
                <WrappedComponent {...this.props}
                                  userInfo={userInfo}
                    //userInfo={this.state.tempUserInfo}
                />
            )
        }
    }

};

export default withUserData;