import React, {Component} from 'react';
import withContentsForDirectTranslation from "../../withContentsForDirectTranslation";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo} from "../../../../asset/js/myFunctions";
import Footer_Theory from "../../Footer_Theory";


class Theory extends Component {

    constructor(props){
        super(props);
        this.state = {
            index: 0,
            isStart: false,
            pass:false,
            phase:0,
        }

        this.mTimeout = null;
    }

    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        if(this.state.phase === 1){
            return(
                this._renderTheory()
            )
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    componentWillUnmount() {
        if(this.mTimeout != null) {
            clearTimeout(this.mTimeout);
        }
    }


    _start = () => {
        this.setState({
            isStart: true,
        });

        this.mTimeout = setTimeout(() => { //60초 후에 다음버튼이 생성됨
            if(!this.state.pass){
                this.setState({
                    pass:true
                });
                this.mTimeout = null;
            }
        },60000);
    };


    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">

                        <div className="drdt-wrap">
                            <h2 className="tit">{this.props.contents[0].tpk_content}</h2>
                            <div className="video-wrapper">
                                <iframe src={this.props.contents[0].tpk_url} frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title={"1분 강의"}
                                />
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            영상 강의를 통해 학습합니다.<br/>
                        </div>

                    </div>


                </section>
                <Footer_Theory
                    {...this.props}
                    c_code={this.props.c_code}
                    pass={this.state.pass}
                    phase={this.state.phase}
                    handlePhase={this._handlePhase}
                />

            </>
        )

    };

    _renderTheory = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap align-start">
                        {this._renderDocument(this.props.lessonInfo.lesson_no)}

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            오늘 배운 내용을 다시 한번 확인하세요.
                        </div>

                    </div>


                </section>
                <Footer_Theory
                    {...this.props}
                    c_code={this.props.c_code}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                    phase={this.state.phase}
                />

            </>
        )

    };

    _handlePhase = (idx) => {
        this.setState({
            phase:idx,
        })
    }


    _renderDocument = (lesson_no) => {
        switch(lesson_no){
            case "01":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 1</h4>
                            <p>'주어 + 동사'를 하나의 의미단위로 보아라!</p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p>
                                <strong className="big">주어란?</strong><br/>
                                동작을 행하는 주체이다. <br/>
                                한국어 '~은, 는, 이, 가' 에 해당한다.<br/>
                            </p>
                            <p className="small">예제) <strong className="underline">He</strong> swims well.</p>
                            <p>
                                <strong className="big">동사란?</strong><br/>
                                동작이나 상태를 나타낸다. <br/>
                                한국어 '~하다, 이다' 에 해당한다.<br/>
                            </p>
                            <p className="small">예제) Jim <strong className="underline">played</strong> baseball.</p>

                        </div>
                    </div>
                );
            case "02":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 2</h4>
                            <p><strong className="big">가장먼저 '본동사'를 찾아라!</strong></p>
                            <p>
                                한국어는 결론<strong>(본동사)</strong>을 마지막에 말한다.<br/>
                                반면에 영어는 결론<strong>(본동사)</strong>을 가장 먼저 말한다.
                            </p>
                            <p className="small">예제)<br/>
                                (한국어) 나는 서울에서 큰 호랑이를 <strong className="underline">보았다.</strong> <br/>
                                (영어) 나는 <strong className="underline">보았다</strong> <strong>큰 호랑이를 서울에서</strong></p>

                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p>
                                <strong className="big">동사의 시제</strong><br/>
                                현재형: <strong className={"underline"}>지금 일어나는 일</strong>을 현재라고 한다. <br/>
                                - I am a student <strong>(나는 학생이다.)</strong> <br/>
                                과거형: <strong className={"underline"}>이미 지나간 일</strong>을 과거라고 한다. <br/>
                                - I was a student <strong>(나는 학생이었다.)</strong> <br/>
                                미래형: <strong className={"underline"}>아직 일어나지 않은 일</strong>을 미래라고 한다. <br/>
                                - I will be a student <strong>(나는 학생이 될 것이다.)</strong> <br/>
                            </p>

                        </div>
                    </div>
                );
            case "03":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 3</h4>
                            <p><strong className="big">'조동사+동사'를 이해하라</strong></p>
                            <p><strong className="big">'조동사+동사'가 있다.</strong></p>
                            <p className="small">예제)<br/>
                                ~할 것이다. + 잠자다 = 잠잘 것이다. <br/>
                                ~할 수 있다 + 수영하다 = 수영할 수 있다. <br/>
                                ~해야 한다 + 방문하다 = 방문해야 한다.
                            </p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">조동사</strong></p>
                            <p>
                                조동사란? 동사 앞에 쓰여서 동사에 의미를 더해준다.<br/>
                                종류: will, can, may, must, should, could 등이 있다.
                            </p>
                            <p className="small">예제)<br/>
                                You will go. (너는 갈 것이다.)<br/>
                                You can go. (너는 갈 수 있다.)<br/>
                                You may go. (너는 가도 된다.) <br/>
                                You must go. (너는 가야 한다.) <br/>
                                You should go. (너는 <strong>가야 한다.</strong>)<br/>
                                Could I go? (나는 가도 될까?)
                            </p>
                        </div>
                    </div>
                );
            case "04":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 4</h4>
                            <p><strong className="big">'do(does, did)+동사원형'을 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">do 조동사</strong></p>
                            <p>
                                do 조동사란? 일반 동사 앞에 쓰여서 부정문 또는 의문문을 만든다.<br/>
                                종류: 시제와 주어의 인칭에 따라 'do, does, did, don't, doesn't, didn't'가 있다.<br/>
                            </p>
                            <p className="small">예제)<br/>
                                I don't know him.<br/>
                                Do you know him? <br/>
                                I didn't know him.<br/>
                                Did you know him?
                            </p>
                            <p><strong className="big">'do 조동사+동사원형'으로 부정문과 의문문에서 사용된다.</strong></p>
                            <p className="small">예제)<br/>
                                공부하다 + ~하지 않는다 = 공부하지 않는다.<br/>
                                공부하다 + ~하니 = 공부하니? <br/>
                                게임하다 + ~하지 않는다 = 게임하지 않는다.<br/>
                                게임하다 + ~하니? = 게임하니?
                            </p>
                        </div>
                    </div>
                );
            case "05":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 5</h4>
                            <p><strong className="big">‘There is(are) ~’ 는 ‘~가 있다’ 로 해석해라!</strong></p>
                            <p><strong className="big">‘There was(were) ~’ 는 ‘~가 있었다’ 로 해석해라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">‘There + be동사’ 용법</strong></p>
                            <p>
                                • There is + (단수 명사): There is an orange.<br/>
                                • There are + (복수 명사): There are oranges. <br/>
                                • Is there + (단수 명사) ~?: Is there an orange?<br/>
                                • Are there + (복수 명사) ~?: Are there oranges?
                            </p>
                        </div>
                    </div>
                );
            case "06":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 전치사 이해
                            </p>
                            <p>
                                1) 집 + ~에 = 집에 (at home) <br/>
                                2) 집 + ~으로 = 집으로 (to home) <br/>
                                3) 집 + ~을 향해 = 집을 향해 (for home) <br/>
                            </p>
                            <p><strong className="big">시간, 때를 나타내는 전치사</strong></p>
                            <p>
                                ① at: 구체적인 시간<br/>
                                - at noon, at that time, at dawn<br/>
                                ② on : 날짜, 요일, 특정한 날, 특별한 날의 아침/점심/저녁<br/>
                                - on May 5th, on Monday, on my birthday<br/>
                                ③ in: 월, 년, 계절, 세기, 아침, 점심, 저녁<br/>
                                - in October, in 2019, in spring, in the morning<br/>
                            </p>
                        </div>
                    </div>
                );
            case "07":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 장소를 나타내는 전치사
                            </p>
                            <p>
                                ① at: ~에<br/>
                                - 특정한 한 지점을 나타내거나 비교적 좁은 장소 앞에 쓴다.<br/>
                                ② on: ~(위)에<br/>
                                - 표면에 접촉해 있는 것을 나타낸다.<br/>
                                ③ in: ~(안)에<br/>
                                - 공간 안을 나타내거나 비교적 넓은 장소 앞에 쓴다.<br/>
                            </p>
                        </div>
                    </div>
                );
            case "08":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 시간을 나타내는 전치사
                            </p>
                            <p>
                                ① from: ~부터<br/>
                                ② since: ~이래로, ~이후로<br/>
                                ③ by: ~까지<br/>
                                ④ until: ~까지<br/>
                                ⑤ before: ~전에<br/>
                                ⑥ after: ~후에<br/>
                                ⑦ for: ~동안<br/>
                                ⑧ during: ~동안<br/>
                            </p>
                        </div>
                    </div>
                );
            case "09":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 장소를 나타내는 전치사<br/>
                                ① above: ~보다 위에<br/>
                                ② below: ~보다 아래에<br/>
                                ③ over: ~바로 위에<br/>
                                ④ under: ~아래에<br/>
                            </p>
                            <p>
                                • 방향을 나타내는 전치사<br/>
                                ① up: ~위로<br/>
                                ② down: ~아래로<br/>
                                ③ into: ~안으로<br/>
                                ④ out of: ~밖으로
                            </p>
                        </div>
                    </div>
                );
            case "10":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 방향을 나타내는 전치사<br/>
                                ① across: ~을 가로질러<br/>
                                ② along: ~을 따라<br/>
                                ③ through: ~을 통과하여<br/>
                                ④ around: ~주위에<br/>
                            </p>
                            <p>
                                •  장소를 나타내는 전치사<br/>
                                ① by: ~옆에<br/>
                                ② in front of: ~앞에<br/>
                                ③ behind: ~뒤에<br/>
                                ④ between: (둘) ~사이에<br/>
                                ⑤ among: (셋 이상) ~사이에
                            </p>
                        </div>
                    </div>
                );
            case "11":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 6</h4>
                            <p><strong className="big">문장 속에서 ‘전치사구’를 찾아서, 전치사구를 하나의 의미단어로 보아라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 방향, 도구, 수단을 나타내는 전치사<br/>
                                ① to: ~로, ~에<br/>
                                ② for: ~로, ~을 향하여<br/>
                                ③ with: ~와 함께, ~의 몸에 지니고, ~을 가지고 있는, ~을 사용하여<br/>
                                ④ about: ~에 대해<br/>
                                ⑤ like: ~와 같은, ~처럼<br/>
                            </p>
                            <p>
                                • 수단을 나타내는 전치사<br/>
                                ① by: ~을 타고, ~로(방법), ~에 의해, ~만큼, ~함으로써<br/>
                                ② as: ~로서<strong>(자격)</strong><br/>
                                ③ in: ~을 입고 있는, ~로(크기), ~로(방법)
                            </p>
                        </div>
                    </div>
                );
            case "12":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 7</h4>
                            <p><strong className="big">'A of B'를 하나의 의미단어로 인식하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">전치사</strong></p>
                            <p>
                                • 의미: 명사 또는 대명사 앞에 놓여 그들과의 관계를 나타낸다.<br/>
                                • 종류: of
                            </p>
                            <p className="small">예제)<br/>
                                the water of the river<br/>
                                beautiful pieces of rock<br/>
                                one <strong>of</strong> them<br/>
                                a part of my life<br/>
                            </p>
                        </div>
                    </div>
                );
            case "13":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 8</h4>
                            <p><strong className="big">‘명사 + called(named) + 명사’ 를 하나의 의미단어로 인식하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">명사 + called(named)</strong></p>
                            <p className="small">예제)<br/>
                                왕이라 불리는 남자
                            </p>
                        </div>
                    </div>
                );
            case "14":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 9</h4>
                            <p><strong className="big">‘접속사’를 찾아서 문장을 단순화 시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">접속사</strong></p>
                            <p>
                                • 의미: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                ① 접속사: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                ② 단어 + 단어: <strong className={"underline"}>She and I</strong> like music. <br />
                                ③ 구 + 구: We can go there <strong className={"underline"}>by subway</strong> or <strong className={"underline"}>by bus</strong>. <br />
                                ④ 절 + 절: <strong className={"underline"}>He has no money</strong>, so <strong className={"underline"}>he can’t buy the car</strong>. <br />
                            </p>
                            <p>
                                • 종류 <br />
                                - and: 그리고 <br />
                                - but: 그러나 <br />
                                - or: 또는 <br />
                                - so: 그래서 <br />
                            </p>
                        </div>
                    </div>
                );
            case "15":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 9</h4>
                            <p><strong className="big">‘접속사’를 찾아서 문장을 단순화 시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">접속사</strong></p>
                            <p>
                                • 의미: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                ① 명령문: 주어가 생략되고, 동사로 시작된다. <br />
                                ② 명령문에 쓰인, ‘and’와 ‘or’ <br />
                                <strong>- 명령문 + and ~ : ~해라 그러면 ~</strong> <br />
                                <strong>- 명령문 + or ~ : ~해라 그렇지 않으면 ~</strong> <br />
                            </p>
                        </div>
                    </div>
                );
            case "16":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 9</h4>
                            <p><strong className="big">‘접속사’를 찾아서 문장을 단순화 시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">접속사</strong></p>
                            <p>
                                • 의미: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                • 종류<br />
                                ① because: 왜냐하면, ~ 때문에<br />
                                ② since: ~이래로<br />
                                ③ so: 그래서<br />
                                ④ as: ~처럼, ~때, ~때문에<br />
                            </p>
                        </div>
                    </div>
                );
            case "17":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 9</h4>
                            <p><strong className="big">‘접속사’를 찾아서 문장을 단순화 시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">접속사</strong></p>
                            <p>
                                • 의미: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                • 종류 <br />
                                ① when: ~할 때 <br />
                                ② before: ~전에 <br />
                                ③ after: ~후에 <br />
                                ④ if: 만약, ~인지 아닌지 <br />
                            </p>
                        </div>
                    </div>
                );
            case "18":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 9</h4>
                            <p><strong className="big">‘접속사’를 찾아서 문장을 단순화 시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">접속사</strong></p>
                            <p>
                                • 의미: 단어와 단어, 구와 구, 절과 절을 연결한다. <br />
                                • 종류 <br />
                                ① until: ~까지 <br />
                                ② while: ~하는 동안 <br />
                                ③ as soon as: ~하자마자 <br />
                            </p>
                        </div>
                    </div>
                );
            case "19":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 10</h4>
                            <p><strong className="big">‘진행형’에 나오는 동사를 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">진행형</strong></p>
                            <p>
                                • 의미: 계속되는 움직임을 나타낸다. <br />
                                • 종류 <br />
                                현재 진행형 (~하는 중이다) <br />
                                과거 진행형 (~하는 중이었다) <br />
                                미래 진행형 (~하는 중일 것이다) <br />
                            </p>
                            <p><strong className="big">진행형 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                공부하다 + ~하고 있다 = 공부하고 있다. <br />
                                공부하다 + ~하고 있었다 = 공부하고 있었다. <br />
                                공부하다 + ~하고 있다 + ~일 것이다 = 공부하고 있을 것이다. <br />
                                게임하다 + ~하고 있지 않다 = 게임하고 있지 않다. <br />
                                게임하다 + ~하고 있었다 = 게임하고 있었다. <br />
                                게임하다 + ~하고 있다 + ~일 것이다 = 게임하고 있을 것이다. <br />
                            </p>
                            <p><strong className="big">진행형 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                I am studying. <br />
                                I was studying. <br />
                                I will be studying. <br />
                                I am playing. <br />
                                I was playing. <br />
                                I will be playing. <br />
                            </p>
                        </div>
                    </div>
                );
            case "20":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 11</h4>
                            <p><strong className="big">‘완료형’에 나오는 동사를 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">완료형</strong></p>
                            <p>
                                • 의미: 현재, 과거 또는 미래까지 계속되는 동작이나 상태를 나타낸다. <br />
                                • 종류: 현재완료, 과거완료 <br />
                            </p>
                            <p><strong className="big">완료형 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: (과거부터~지금까지) 공부해왔다. <br />
                                • 과거완료: (과거 이전부터~과거까지) 공부해왔었다. <br />
                            </p>
                            <p><strong className="big">완료형 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: I have studied.<br />
                                • 과거완료: I had studied.<br />
                            </p>
                        </div>
                    </div>
                );
            case "21":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 11</h4>
                            <p><strong className="big">‘완료형’에 나오는 동사를 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">완료형</strong></p>
                            <p>
                                • 의미: 현재, 과거 또는 미래까지 계속되는 동작이나 상태를 나타낸다. <br />
                                • 종류: 현재완료, 과거완료 <br />
                            </p>
                            <p><strong className="big">완료형 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: (과거부터~지금까지) 공부해왔다. <br />
                                • 과거완료: (과거 이전부터~과거까지) 공부해왔었다. <br />
                            </p>
                            <p><strong className="big">완료형 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: I have studied.<br />
                                • 과거완료: I had studied.<br />
                            </p>
                        </div>
                    </div>
                );
            case "22":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 11</h4>
                            <p><strong className="big">‘완료형’에 나오는 동사를 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">완료형</strong></p>
                            <p>
                                • 의미: 현재, 과거 또는 미래까지 계속되는 동작이나 상태를 나타낸다. <br />
                                • 종류: 현재완료, 과거완료 <br />
                            </p>
                            <p><strong className="big">완료형 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: (과거부터~지금까지) 공부해왔다. <br />
                                • 과거완료: (과거 이전부터~과거까지) 공부해왔었다. <br />
                            </p>
                            <p><strong className="big">완료형 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: I have studied.<br />
                                • 과거완료: I had studied.<br />
                            </p>
                        </div>
                    </div>
                );
            case "23":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 11</h4>
                            <p><strong className="big">‘완료형’에 나오는 동사를 이해하라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">완료형</strong></p>
                            <p>
                                • 의미: 현재, 과거 또는 미래까지 계속되는 동작이나 상태를 나타낸다. <br />
                                • 종류: 현재완료, 과거완료 <br />
                            </p>
                            <p><strong className="big">완료형 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: (과거부터~지금까지) 공부해왔다. <br />
                                • 과거완료: (과거 이전부터~과거까지) 공부해왔었다. <br />
                            </p>
                            <p><strong className="big">완료형 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 현재완료: I have studied.<br />
                                • 과거완료: I had studied.<br />
                            </p>
                        </div>
                    </div>
                );
            case "24":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 12</h4>
                            <p><strong className="big">‘관계대명사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계대명사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                접속사와 다른 점은, 접속사와 대명사 역할을 동시에 하는 것이다. <br />
                            </p>
                            <p><strong className="big">who(whom)</strong></p>
                            <p>
                                선행사가 사람일 때 사용된다.
                            </p>
                            <p><strong className="big">관계대명사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 나는 한 소녀를 안다. + 그 소녀는 피아노를 잘 친다.<br />
                                = 나는 피아노를 잘 치는 소녀를 안다.<br />
                            </p>
                            <p><strong className="big">관계대명사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • I know a girl. + She (The girl) plays the piano well.<br />
                                = I know a girl who plays the piano well.<br />
                            </p>
                        </div>
                    </div>
                );
            case "25":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 12</h4>
                            <p><strong className="big">‘관계대명사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계대명사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                접속사와 다른 점은, 접속사와 대명사 역할을 동시에 하는 것이다. <br />
                            </p>
                            <p><strong className="big">which</strong></p>
                            <p>
                                선행사가 사물일 때 사용된다.
                            </p>
                            <p><strong className="big">관계대명사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 이것은 그 책이다. + 그것은 매우 재밌다.<br />
                                = 이것은 매우 재미있는 그 책이다.<br />
                            </p>
                            <p><strong className="big">관계대명사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • This is the book. + It is very fun. <br />
                                = This is the book which is very fun. <br />
                            </p>
                        </div>
                    </div>
                );
            case "26":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 12</h4>
                            <p><strong className="big">‘관계대명사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계대명사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                접속사와 다른 점은, 접속사와 대명사 역할을 동시에 하는 것이다. <br />
                            </p>
                            <p><strong className="big">whose + 명사</strong></p>
                            <p>
                                ‘~의 명사’라는 의미로써, 선행사가 사람, 사물일 때 사용된다.
                            </p>
                            <p><strong className="big">관계대명사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 그 집을 보아라. + 그것의 지붕은 빨간색이다.<br />
                                = 지붕이 빨간색인 그 집을 보아라.<br />
                            </p>
                            <p><strong className="big">관계대명사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • Look at the house. + The roof of it is red.<br />
                                = Look at the house whose roof is red.<br />

                            </p>
                        </div>
                    </div>
                );
            case "27":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 12</h4>
                            <p><strong className="big">‘관계대명사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계대명사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                접속사와 다른 점은, 접속사와 대명사 역할을 동시에 하는 것이다. <br />
                            </p>
                            <p><strong className="big">that</strong></p>
                            <p>
                                선행사가 사람, 사물, 동물일 때 다 쓸 수 있는 관계대명사이다.
                            </p>
                            <p><strong className="big">관계대명사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 이것은 집이다. + 그 집은 넓은 창문을 가지고 있다.<br />
                                = 이것은 넓은 창문을 가진 집이다.<br />
                            </p>
                            <p><strong className="big">관계대명사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • This is the house. + The house has wide windows.<br />
                                = This is the house that has wide windows.<br />
                            </p>
                        </div>
                    </div>
                );
            case "28":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 12</h4>
                            <p><strong className="big">‘관계대명사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계대명사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                접속사와 다른 점은, 접속사와 대명사 역할을 동시에 하는 것이다. <br />
                            </p>
                            <p><strong className="big">what</strong></p>
                            <p>
                                선행사 없이 단독으로 쓰인다. “~인 것, ~하는 것”이라는 의미이다.
                            </p>
                            <p><strong className="big">관계대명사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 나는 그것을 안다. + 너는 지난여름에 그것을 했다.<br />
                                = 나는 네가 지난여름에 했던 것을 안다.<br />
                            </p>
                            <p><strong className="big">관계대명사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • I know the thing. + You did it last summer.<br />
                                = I know what you did last summer.<br />
                            </p>
                        </div>
                    </div>
                );
            case "29":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 13</h4>
                            <p><strong className="big">‘관계부사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계부사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                관계대명사와 다른 점은 접속사와 부사 역할을 하는 것이다.
                            </p>
                            <p><strong className="big">where</strong></p>
                            <p>
                                ‘장소’가 선행사로 온다.
                            </p>
                            <p><strong className="big">관계부사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 이것은 그 집이다. + 그는 그 집에서 산다.<br />
                                = 이것은 그가 살고 있는 그 집이다.<br />
                            </p>
                            <p><strong className="big">관계부사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • This is the house. + He lives in the house.<br />
                                = This is the house where he lives.<br />
                            </p>
                        </div>
                    </div>
                );
            case "30":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 13</h4>
                            <p><strong className="big">‘관계부사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계부사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                관계대명사와 다른 점은 접속사와 부사 역할을 하는 것이다.
                            </p>
                            <p><strong className="big">when</strong></p>
                            <p>
                                ‘시간’이 선행사로 온다.
                            </p>
                            <p><strong className="big">관계부사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 오늘은 그 날이다. + 우리는 그 날 새 학기를 시작한다.<br />
                                = 오늘은 새 학기가 시작되는 날이다.<br />
                            </p>
                            <p><strong className="big">관계부사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • Today is the day. + We start the new semester on the day.<br />
                                = Today is the day when we start the new semester.<br />
                            </p>
                        </div>
                    </div>
                );
            case "31":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 13</h4>
                            <p><strong className="big">‘관계부사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계부사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                관계대명사와 다른 점은 접속사와 부사 역할을 하는 것이다.
                            </p>
                            <p><strong className="big">why</strong></p>
                            <p>
                                ‘이유’가 선행사로 온다.
                            </p>
                            <p><strong className="big">관계부사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 너 그 이유를 아니? + 그는 그 이유로 화났다.<br />
                                = 너는 그가 화난 그 이유를 아니?<br />
                            </p>
                            <p><strong className="big">관계부사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • Do you know the reason? + He was angry in the reason.<br />
                                = Do you know the reason why he was angry.<br />
                            </p>
                        </div>
                    </div>
                );
            case "32":
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 13</h4>
                            <p><strong className="big">‘관계부사’를 찾아 문장을 단순화시켜라!</strong></p>
                            <hr className="line" />
                            <h4>문법 용어 이해</h4>
                            <p><strong className="big">관계부사란?</strong></p>
                            <p>
                                두 문장을 하나의 문장으로 연결시켜주는 단어이다. <br />
                                관계대명사와 다른 점은 접속사와 부사 역할을 하는 것이다.
                            </p>
                            <p><strong className="big">how</strong></p>
                            <p>
                                ‘방법’이 선행사로 온다. <strong>그러나 다른 관계부사와 달리 선행사와 how 둘 중에 하나만 사용한다.</strong>
                            </p>
                            <p><strong className="big">관계부사 한국어</strong></p>
                            <p className="small">
                                예제) <br />
                                • 그 방법을 나에게 말해줘. + 너는 그 방법으로 그 문제를 풀었다. <br />
                                = 네가 그 문제를 푼 그 방법을 나에게 말해줘. <br />
                            </p>
                            <p><strong className="big">관계부사 영어</strong></p>
                            <p className="small">
                                예제) <br />
                                • Please tell me the way. + You solved the problem in the way. <br />
                                = Please tell me (the way) how you solved the problem. <br />
                            </p>
                        </div>
                    </div>
                );
            default:
                return(
                    <div className="document">
                        <h2 className="tit">오늘 배운 내용 정리</h2>
                        <div className="box">
                            <h4>직독직해 팁 1</h4>
                            <p>'주어 + 동사'를 하나의 의미단위로 보아라!</p>
                            <hr className="line" />
                                <h4>문법 용어 이해</h4>
                                <p>
                                    <strong className="big">주어란?</strong><br/>
                                    동작을 행하는 주체이다. <br/>
                                    한국어 '~은, 는, 이, 가' 에 해당한다.<br/>
                                </p>
                                <p className="small">예제) <strong className="underline">He</strong> swims well.</p>
                                <p>
                                    <strong className="big">동사란?</strong><br/>
                                    동작이나 상태를 나타낸다. <br/>
                                    한국어 '~하다, 이다' 에 해당한다.<br/>
                                </p>
                                <p className="small">예제) Jim <strong className="underline">played</strong> baseball.</p>

                        </div>
                    </div>

            );

        }

    }







}

export default withContentsForDirectTranslation()(Theory);