import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from "../../../other/LoadingBar";
import {
    brTagActivator,
    calculateScore,
    FailScore,
    getCurrentMenuInfo, isIOS, isMobile, strongTagChanger,
    uploadScoreAl
} from "../../../../asset/js/myFunctions";
import Footer_ISM_A from "../../Footer_ISM_A";
import Footer_ISM_B from "../../Footer_ISM_B";
import Footer_ISM_C from "../../Footer_ISM_C";
import {TweenLite} from "gsap/TweenLite";
import $ from "jquery";
import ReactHtmlParser from 'react-html-parser'
//import {uploadScoreAl} from "../../../../../../es/src/asset/js/myFunctions";


class MakeQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // contentsIndex: 0,
            phase: 0,
            currentPage: 0,
            contentsLength: 0,
            listening: 0, //PC가 말하는지, 사람이 말할 차례인지 결정하는 state
            toggleContents: false,
            hasResult: false,
            count: 0,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            isTesting: false,
            isPageOnePass: false,
            err: null,
            errCount: 0,
            tryCount: 0,
            totalScore: 0,
            waiting: false,

        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.currentPage !== prevState.currentPage) { //페이지 변경시
            nextProps.closeTip();
            return {
                currentPage: nextProps.currentPage,
                wordInfo: {
                    num: nextProps.currentPage - 1,
                    getResult: false,
                },
                errCount: 0,
                err: null,
            }
        }
        // 처음 콘텐츠를 props로 받게 된 경우, 콘텐츠의 길이를 통해 전체 페이지 수를 정함.
        if (nextProps.contents && prevState.contentsLength === 0) {

            return {
                contentsLength: nextProps.contents.length - 1,
            }
        }


        return null;

    }


    componentDidUpdate(prevProps, prevState) {
        // this.props.myJS();
        //
        // $(function () {
        //
        //     $('[data-toggle="exercise-question"]').click(function () {
        //         $('.exercise-question').toggleClass('plain question').find('.plain-wrap, .question-wrap').toggle();
        //
        //     })
        // });


        if(prevState.phase !== 3 && this.state.phase === 3){
            $(function () {
                TweenLite.to("#score-poplayer", 1, {
                    y: 0,
                    delay: 0,
                    yPercent: 0,
                    opacity: 1
                })

            });
        }
    }


    render() {

        if (this.props.contents === null || this.props.menuInfo === null) {
            //if(true){
            return (<LoadingBar/>);
        }
        if (this.state.phase === 0) {
            return (
                this._renderIntro()
            );
        }
        if (this.state.phase === 1) {
            return (
                this._renderMain()
            )
        }
        if (this.state.phase === 2) {
            return (
                this._renderTest()
            )
        }
        if (this.state.phase === 3) {
            return (
                this._renderResult()
            )
        }

        return (
            <div>ERROR: phase matched nothing</div>
        );
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type={"button"} className="btn-lecture-start CursorPointer" onClick={() => this._handlePhase(1)}>시작하기</button>
                </div>
            </section>
        )
    };


    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">


                        <div className="exercise-question plain">
                            {
                                isMobile && $(window).width() < 1200 ?
                                    <div className="H10"/>
                                    :
                                    ""
                            }
                            {
                                this.state.toggleContents ?
                                    <div>
                                        <h2 className="tit">{ReactHtmlParser(strongTagChanger(this.props.contents[0].eng_content))}</h2>
                                        <p className="sub-tit">{this.props.contents[0].kor_content}</p>
                                        {
                                            isMobile && $(window).width() < 1200 ?
                                                <div className="H30"/>
                                                :
                                                <div className="H50"/>
                                        }
                                        <button type="button" className="btn-change-plain"
                                                onClick={() => {
                                                    this._toggleContents()
                                                }}>평서문으로
                                            바꿔보기
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        <h2 className="tit">{ReactHtmlParser(strongTagChanger(this.props.contents[0].t_question))}</h2>
                                        <p className="sub-tit">{this.props.contents[0].t_answer}</p>
                                        {
                                            isMobile && $(window).width() < 1200 ?
                                                <div className="H30"/>
                                                :
                                                <div className="H50"/>
                                        }
                                        <button type="button" className="btn-change-question"
                                                onClick={() => {
                                                    this._handleIsPageOnePass(true);
                                                    this._toggleContents();
                                                }}
                                        >의문문으로
                                            바꿔보기
                                        </button>
                                    </div>
                            }

                            {
                                isMobile && $(window).width() < 1200 ?
                                    <div className="H30"/>
                                    :
                                    <div className="H50"/>
                            }

                            <div className={this.state.isPageOnePass ? "desc-box show" : "desc-box hide"}>
                                <div className="desc-sub"><i className="icon-info"/> 이 문장을 의문문으로 바꾸는 법</div>
                                <div className="desc-content">
                                    {ReactHtmlParser(strongTagChanger(this.props.contents[0].tpk_content))}
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            <strong>의문문(질문)으로 바꾸는 방법</strong>을 잘 확인하세요.<br/>
                            <strong>충분히 이해</strong>가 되셨다면 테스트 버튼을 눌러 <br/>
                            직접 <strong>의문문</strong>을 만들어 봅니다.
                        </div>
                    </div>
                </section>
                <Footer_ISM_A
                    {...this.props}
                    handlePhase={this._handlePhase}
                    handleError={this._handleError}
                    isPageOnePass={this.state.isPageOnePass}
                />
            </>

        )
    };


    _renderTest = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap HideScrollBar">
                        <div className="top-tip">{this.props.contents[this.state.wordInfo.num + 1].tpk_content}</div>
                        {
                            this.state.isTesting ?
                                <ul className="word-list">
                                    <li
                                        className={
                                            isIOS ?
                                                this.props.isPlaying && !this.state.listening ? "active" : ""
                                                :
                                                this.props.isPlaying ? "active" : ""
                                        }>
                                        <span className="decl">평서문</span>
                                        <div
                                            className="ism-expression">
                                            {this.props.contents[this.state.wordInfo.num + 1].t_question.replace(/[<>]/g, "")}
                                        </div>
                                        <div className="ism-txt">
                                            {this.props.contents[this.state.wordInfo.num + 1].t_answer}
                                        </div>
                                    </li>
                                    <li
                                        className={
                                            isIOS ?
                                                this.state.listening ? "active" : ""
                                                :
                                                !this.props.isPlaying ? "active" : ""
                                        }>
                                        <span className="qest">의문문</span>
                                        <div
                                            className="ism-expression">
                                            {/*{this.state.result[this.state.wordInfo.num] ? capitalize(this.state.result[this.state.wordInfo.num]) + "?" : "　"}*/}
                                            {this.state.result[this.state.wordInfo.num] ? this.props.contents[this.state.wordInfo.num+1].eng_content: "　"}
                                        </div>
                                        <div className="ism-txt">
                                            {this.props.contents[this.state.wordInfo.num + 1].kor_content}
                                        </div>
                                    </li>
                                </ul>
                                :
                                <div className="single-memorize">
                                    <h3>테스트 버튼을 눌러 의문문 만들기를 시작합니다.</h3>
                                </div>

                        }

                    </div>


                    <div className="lecture-step">
                        <span className="current">{this.state.wordInfo.num + 1}</span>/<span
                        className="total">{this.props.contents.length - 1}</span>
                    </div>


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        테스트 버튼을 눌러 평서문을 듣고<br/>
                                        <strong>의문문</strong>으로 바꿔 말하세요.<br/>
                                        준비 되셨다면 <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                        <hr className="divider"/>
                                        <div
                                            className="tip">{this.props.contents[this.state.wordInfo.num + 1].tpk_content}</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>

                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                <Footer_ISM_B
                    {...this.props}
                    handlePhase={this._handlePhase}
                    handleError={this._handleError}
                    handleIsTesting={this._handleIsTesting}
                    isListening={this._isListening}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    waiting={this.state.waiting}
                    isPlaying={this.props.isPlaying}
                    pass={this.state.pass}
                />
            </>

        )
    };


    _renderResult = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <div className="content-wrap">
                        <div className="score-poplayer" id="score-poplayer">
                            <div className="content">
                                <p>
                                    <span className="sr-only">SCORE</span>
                                    <strong> {isIOS ? "Pass" : this.state.totalScore} </strong>
                                </p>
                                <button type={"button"} className="btn-answer"
                                   onClick={() => {
                                       $('#speak-poplayer').wPopLayer();
                                   }}>정답보기</button>
                            </div>
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        잘 하셨어요!<br/>
                                        더 높은 점수를 원하시면<br/>
                                        <strong>테스트</strong>버튼을 눌러 다시 테스트하세요.<br/>
                                        <hr className="divider"/>
                                        <div className="tip">
                                            특정 문장은 인식이 잘 되지 않는 경우도 있습니다. <br/>
                                            발음 문제가 아니니 너무 상심하지 마세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                    {this._renderAnswer()}
                </section>
                <Footer_ISM_C
                    {...this.props}
                    pass={this.state.pass}
                    handleIsTesting={this._handleIsTesting}
                    handlePhase={this._handlePhase}
                    isListening={this._isListening}
                />
            </>

        )
    };


    _handlePhase = (idx) => {

        // if(idx === 0){
        //     this.setState({
        //         pageIndex: idx,
        //         result: {},
        //         wordInfo: {
        //             num: 0,
        //             getResult: false,
        //         },
        //         err: null,
        //         errCount: 0,
        //         totalScore: 0,
        //         waiting: true,
        //     })
        // }
        this.setState({
            phase: idx
        });
    };

    _toggleContents = () => {
        this.props.closeTip();
        this.setState({
            toggleContents: !this.state.toggleContents
        })
    }

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {

            this.setState({
                err: err,
            });

            return;
        }


        if (this.state.errCount >= 1) {
            // this.setState({
            //     result: '##ERROR',
            //     err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],
            //     pass: true,
            //     waiting: false,
            // });
            //
            // return;
            this._setResult("", this.state.wordInfo.num);
            return;
        }

        this.props.openTip();

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
            waiting: false,
        })
    }

    _handleIsPageOnePass = (bool) => {
        this.setState({
            isPageOnePass: bool
        })
    };

    _handleIsTesting = (bool) => {
        if (!bool) {
            this.setState({
                isTesting: bool,
            })
        } else {
            this.setState({
                isTesting: bool,
                result: {},
                wordInfo: {
                    num: 0,
                    getResult: false,
                },
                err: null,
                errCount: 0,
                totalScore: 0,
                waiting: true,
            })
        }

    }

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        this.setState({
            result: {
                ...this.state.result,
                [index]: result
            },
            count: this.state.count + 1,
            err: null,
            errCount: 0,
            waiting: true,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //결과를 전체받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if (this.state.count < this.state.contentsLength) {
            //console.log("1초 후에 wordInfo 업데이트 됨.");
            //console.log("현재 count 는: " + this.state.count);
            setTimeout(() => {
                //console.log("wordInfo 업데이트!");
                this._updateWordInfo(this.state.wordInfo.num + 1, false);
            }, 1500);
        }

        if (this.state.count >= this.state.contentsLength) {
            setTimeout(() => {
                this._showResult();
                this._handleIsTesting(false);

                let totalScore = isIOS ? 0 : this._calculateTotalScore();

                uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드


                //uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount + 1,this.props.serverData.auth_type);//점수 서버에 업로드 uploadScore = (score, attendIdx, menu_no, tryCount)

                // let pass = totalScore >= FailScore;
                // this._decidePass(pass);
                this._decidePass(true); //무조건 통과함
            }, 1800);


        }
    };


    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },
        })
    };

    _showResult = () => {
        //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            phase: 3,
            count: 0,
            isTesting: false,
            hasResult: true,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            waiting: false,
        });
        //console.log("the recorded results are:", this.state.result);
        this.props.openTip();
        // setTimeout(() => {
        //     this.setState({
        //         ...this.state,
        //         phase: 3,
        //         count: 0,
        //         isTesting: false,
        //         hasResult: true,
        //         wordInfo: {
        //             num: 0,
        //             getResult: false,
        //         },
        //         waiting: false,
        //     });
        //     //console.log("the recorded results are:", this.state.result);
        //     this.props.openTip();
        // }, 200);

    };

    _calculateTotalScore() {
        let sum = 0;

        for (let i = 1; i < this.props.contents.length; i++) {
            sum += calculateScore(this.state.result[i - 1], this.props.contents[i].eng_content);
        }


        let totalScore;

        if (sum === 0) {
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / (this.props.contents.length - 1));
        }

        this.setState({
            totalScore: totalScore
        });

        return totalScore;
    };


    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],
            });

            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["정답을 다시 한번 생각해 보신 후", <br/>, "다시 ", <strong>테스트</strong>, "하세요.",
                    <div className="divider"/>, <div className="tip">{FailScore}점 이상이어야 통과 합니다.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass: isPass,
                err: null,
            })
        }
    };

    _renderAnswer = () => {
        //console.log(this.props.lessonInfo);
        let answers = [];
        for (let i = 1; i < this.props.contents.length; i++) {
            answers.push(
                <>
                    <li key={"s" + i}>
                        <p>
                            {this.props.contents[i].t_question}<br/>
                            <span>{this.props.contents[i].t_answer}</span>
                        </p>
                        <button
                            type={"button"}
                            className={"btn-play-sound CursorPointer"}
                            onClick={() =>
                                // this.props.audioPlay("tpk", i)
                                this.audioPlayTpk(i)
                            }
                        >발음듣기</button>

                    </li>
                    <li key={i}>
                        <p>
                            {this.props.contents[i].eng_content}<br/>
                            <span>{this.props.contents[i].kor_content}</span>
                        </p>
                        <button
                            type={"button"}
                            className={"btn-play-sound CursorPointer"}
                            onClick={() =>
                                // this.props.audioPlay("eng", i)
                                this.audioPlay(i)
                            }
                        >발음듣기</button>

                    </li>


                </>
            )
        }

        return (
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    audioPlayTpk = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].tpk_url}`;

        this.props.setHowler(
            src
        );
    }

    _isListening = (bool) => {
        this.setState({
            listening:bool,
        })
    }
}

export default withContents()(MakeQuestions);
    
    