import React, { Component } from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import {isMobile} from "../../asset/js/myFunctions";

const withRecognizerDialogue = () => (WrappedComponent) => {

    return class extends Component {
        state = {
            // show: false,
            isError:false,
            listening: false,
            sumText:"",
            text:null,
            recognizerReady:false,
            isRecognizeSuccess: false,
        };

        initialize() {
            // alert("start recognition");
            if(this.recognition){
                console.log("already recognition activated");
            } else {
                console.log("init recognition");
            }

            const Recognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!Recognition) {
                alert(
                    '음성인식 기능을 사용할 수 없습니다. PC 또는 Android 기기에서 Chrome 브라우저를 이용해 주세요.'
                );
                return;
            }

            this.recognition = new Recognition();
            this.recognition.lang = process.env.REACT_APP_LANGUAGE || 'en-US';
            this.recognition.continuous = (this.props.serverData.user_kind === "K" || this.props.serverData.step_code === "L3");
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = event => {

                let sumText = "";
                for(let i=0; i < event.results.length; i ++){
                    sumText = sumText + event.results[i][0].transcript;

                }

                this.setState({
                    //text,
                    sumText,
                    isRecognizeSuccess: true,
                });


                // if(this.props.serverData.user_kind !== "K"){
                //                 //
                //                 // }
                //                 // //console.log('transcript', text);
                //                 // this.setState({
                //                 //     text,
                //                 //     isRecognizeSuccess: true,
                //                 // });
                //                 // this.props.setResult(text, this.props.currentIndex);
                //                 // this.setState({
                //                 //     text:null,
                //                 // })
            };

            this.recognition.onspeechend = () => {
                //console.log('stopped');

                // this.setState({
                //     show: true,
                // });
            };

            this.recognition.onnomatch = event => {
                //console.log('no match');
                this.setState({ text: null });
            };

            this.recognition.onstart = () => {
                //console.log('Recognizer start');
                if(!isMobile) {
                    let startAudio = new Audio(StartSound);
                    startAudio.play();
                }

                // let startAudio = new Audio(StartSound);
                // startAudio.play();

                this.setState({
                    text:null,
                    listening: true,
                    recognizerReady:false,
                    sumText:"", //첫 녹음 시작시 sumText:null 로 초기화.
                    isRecognizeSuccess: false,
                    isError:false,
                });
            };

            this.recognition.onend = () => {
                if(!isMobile){
                    let endSound = new Audio(EndSound);
                    endSound.play();
                }
                //
                // let endSound = new Audio(EndSound);
                // endSound.play();
                // if(this.props.audioPause() !== null){ //쉐도잉 할때, 녹음 종료되면 음원도 같이 종료됨.
                //     this.props.audioPause();
                // }

                if(this.state.sumText === "" && this.state.isRecognizeSuccess === false && this.props.handleError && !this.state.isError){
                    this.props.handleError(
                        ["죄송합니다. 알아 듣지 못했습니다.",
                        <br/>,
                        "다시 한번 ",
                        <strong>테스트</strong>,
                        "하세요.",
                        <br/>,
                        "가급적 조용한 환경에서 수업해 주세요.",
                        <div className="divider"/>,
                        <div className="tip">충분히 연습하신 후 빠르게 말씀하시면 더 잘 인식합니다.</div>,
                    ]);
                } else if(!this.state.isError && this.state.sumText !== "") {
                    this.props.setResult(this.state.sumText, this.props.currentIndex);
                    this.setState({
                        sumText:"",
                    })
                }

                // if(this.state.text === null && this.state.isRecognizeSuccess === false && this.props.handleError){
                //     this.props.handleError(["음성인식에 실패하였습니다.", <br />, "다시 한번 ",<strong>테스트</strong>,"하세요.", <br />,"문제가 계속된다면 ", <strong>마이크를 점검</strong>,"하세요."]);
                // }
                this.setState({
                    listening: false,
                    recognizerReady:true,
                })

            };

            this.recognition.onerror = event => {
                //console.log('error', event);
                //alert("error occured!");
                if(this.props.handleError){
                    this.props.handleError(["음성이 입력되지 않았습니다. ", "마이크를 확인하시고 더 크게 말해주세요.",<br />,"문제가 계속된다면 ",
                        <strong>원격 지원</strong>,"을 받아주세요."]);
                }
                this.setState({
                    // show: true,
                    listening: false,
                    text: event.error,
                    isRecognizeSuccess: false,
                    isError:true,
                });
            };
        }

        componentDidMount(){
            this.initialize(); //Initialize
            if(this.state.recognizerReady === false){
                //console.log("recognizer is ready");
                this.setState({
                    recognizerReady:true
                })
            }
        }

        componentWillUnmount() {
            this.recognition.abort();

        }

        startRecognition(){
            if(this.state.listening === true){
                //console.log("already listening");
                return;
            }
            this.recognition.start();

           // //console.log("start recognition");
        }

        endRecognition(){
            this.recognition.stop();
        }

        // handleClose(){
        //
        //     this.setState({
        //         show:false,
        //     })
        // }


        fakeRecognitionStart(){
            let startAudio = new Audio(StartSound);
            startAudio.play();

            // console.log("페이크 시작");
            this.setState({
                text:null,
                listening: true,
                recognizerReady:false,
                isRecognizeSuccess: false,
            });
        }

        fakeRecognitionEnd(){
            let endSound = new Audio(EndSound);
            endSound.play();
            // console.log("페이크 끝.");
            this.props.setResult("iOSResult", this.props.currentIndex);
            this.setState({
                // show:true,
                text:null,
                isRecognizeSuccess: true,
                listening: false,
                recognizerReady:true,
            });

        }

        render(){
            return (
                <WrappedComponent {...this.props}
                                  recognizerReady={this.state.recognizerReady}
                                  text={this.state.text}
                                  listening={this.state.listening}
                                  startRecognition={this.startRecognition.bind(this)}
                                  endRecognition={this.endRecognition.bind(this)}
                                  // handleClose={this.handleClose.bind(this)}
                                  fakeRecognitionStart={this.fakeRecognitionStart.bind(this)}
                                  fakeRecognitionEnd={this.fakeRecognitionEnd.bind(this)}
                />
            );
        }
    }

};

export default withRecognizerDialogue;