import React, {Component} from 'react';
import withRecorder from "./withRecorder";
import MicVisualizer from "../other/MicVisualizer";
import Uploading from '../other/Uploading';

class Footer_SIP extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
            nowPlaying:false,
            letRecordingPlay:false,
            callUpload:false,
        };

    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {


        if(!nextProps.letUpload && prevState.callUpload) {
            //console.log("callUpload 초기화");
            return({ //callUpload 초기화
                callUpload:false,
            })
        }

        if(nextProps.letUpload && !prevState.callUpload){
            //console.log("녹음파일 업로드 호출");

            nextProps.stopPlaying();
            //console.log("녹음파일 재생 중이면 녹음파일 재생 종료");
            //let fileName = `${nextProps.serverData.mem_idx}_${nextProps.serverData.step_code}${nextProps.lessonInfo.lesson_no}_1_${Date.now()}.mp3`;
            nextProps.upload(nextProps.attend_idx, nextProps.currentMenu,  nextProps.device);
            // nextProps.upload();
            return({
                callUpload:true,
            })
        }

        return null;

    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.isAllPlayingComplete && this.props.isAllPlayingComplete){ //음원 종료 되면, 녹음도 종료.
            this._recordingAndAudioStop();
        }

        if(this.props.isRecordPlaying && !this.props.nowPlaying){ //강좌패널에서 Play 버튼을 눌렀는데, 음원재생중이 아니라면 음원 플레이
            //console.log("녹음파일플레이");
            this.props.play();
        }



    }

    //음원이 재생 중일 때, 녹음하며, 음원 재생 후 녹음 종료
    render() {
        if(this.props.recording === true){ //녹음 중에
            return (
                <section className="view-footer">

                    <MicVisualizer
                        // getIsMute={this._getIsMute}
                        isRecording={true}
                    />

                </section>
            )

        }

        if(this.props.nowPlaying === true){ //녹음된 파일이 재생중인 경우


            return (
                <section className="view-footer">

                    <button
                        type="button"
                        className="f-btn f-center btn-stop"
                        onClick={() => {
                            this.props.stopPlaying()
                        }}>
                    </button>

                </section>
            )

        }

        return (
            <section className="view-footer">
                 <button
                    type="button"
                    className="f-btn f-center btn-test"
                    onClick={() => {
                        this._recordingWithAudioPlay()
                    }}>
                </button>
                {
                    this.props.uploading ?
                        <Uploading/>
                        :
                        ""
                }
            </section>
        );
    }

    _recordingWithAudioPlay = () => {
        this.props.closeTip();
        this.props.start(); //녹음 시작
        //this.props.audioPlayAll("kor", 0);
        this.props.playAll(0);
    };

    _recordingAndAudioStop = () => {
        this.props.stop(); //녹음 종료
        this.props.audioStop();
    };

    _getAudioURL = (url) => {
        if(this.state.audioURL === null){
            this.setState({
                audioURL: url,
            })
        }
    };


    // _uploadRecordedFile = () => {
    //     if(this.state.uploadComplete === true) {
    //         alert("이미 제출을 완료하였습니다.");
    //         return;
    //     }
    //     this.setState({
    //         uploadComplete:true
    //     }, () => {
    //         //console.log("upload recorded file! " ,this.state.audioURL);
    //         alert("파일 제출을 완료하였습니다.!");
    //         this._goNextMenu();
    //     });
    // };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.props.play();

    };

    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");

    };

    _getIsMute = () => {
        if(this.props.playingIndex >= 1){
            return;
        }
        this.props.stopWithoutResult();
        this.props.audioStop();
        this.props.handleError(["녹음이 되지 않습니다.", <br />, <strong>마이크를 확인</strong>,"하신 후 다시 ",<strong>테스트</strong>,"하세요.",<div className="divider"/>,<div className="tip">마이크 <strong>전원과 볼륨</strong>을 확인해 보세요.</div> ]);
    };


}

export default withRecorder()(Footer_SIP);
    
    