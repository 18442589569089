import React from 'react';


const Footer_TPSP = (props) => {
    return (

        <section className="view-footer">

        </section>


    )
}

// class IOPanel_6 extends Component {
//
//     constructor(props) {
//         super(props);
//     }
//
//     _test3Words = () => {
//         this.props.startRecognition();
//
//     }
//
//     render() {
//         //console.log(this.props.currentPage + " and "+ this.props.maxPage);
//         //console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);
//
//         return (
//
//         <section className="view-footer">
//             <button type="button" className="f-btn btn-prev-page" onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu - 1)}}>이전</button>
//             {this.props.hasResult ?
//                 <>
//                 <button
//                     type="button"
//                     className="f-btn f-center btn-retest"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 <button
//                     type="button"
//                     className="f-btn btn-next-page"
//                     onClick={() => {this.props.handleCurrentMenu(this.props.currentMenu+1)}}>
//                     다음메뉴</button>
//                 </>
//                 :
//                 (<>
//                     <button
//                     type="button"
//                     className="f-btn f-center btn-test"
//                     onClick={()=>this.props.startTest()}>
//                     테스트</button>
//                 </>)
//             }
//         </section>
//
//         );
//     }
//
// }

export default Footer_TPSP;
    
    