import React, {Component} from 'react';

class Footer_TRT_B extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
        };

    }


    render() {

        return (
            <section className="view-footer">

                {
                    this.props.hasResult && this.props.pass ?
                        <>
                            <button
                                type="button"
                                className="f-btn">
                                Empty
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this.props.startTest();
                                    this.props.closeTip();
                                }}>
                            </button>
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {this.props.goNextMenu()}}>다음메뉴
                            </button>
                        </>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this.props.startTest();
                                this.props.closeTip();
                            }}>
                        </button>


                }

                {/*{!this.props.hasResult  ?*/}
                        {/*(*/}

                            {/*<button*/}
                                {/*type="button"*/}
                                {/*className="f-btn f-center btn-test"*/}
                                {/*onClick={() => {*/}
                                    {/*this.props.startTest();*/}
                                    {/*this.props.closeTip();*/}
                                {/*}}>*/}
                            {/*</button>*/}


                        {/*) :*/}
                        {/*(*/}
                            {/*<>*/}
                                {/*<button*/}
                                    {/*type="button"*/}
                                    {/*className="f-btn f-center btn-test"*/}
                                    {/*onClick={() => {*/}
                                        {/*this.props.startTest()*/}
                                    {/*}}>*/}
                                {/*</button>*/}
                                {/*<button*/}
                                    {/*type="button"*/}
                                    {/*className="f-btn btn-next-page"*/}
                                    {/*onClick={() => {this.props.goNextMenu()}}>다음메뉴*/}
                                {/*</button>*/}
                                {/*/!*현재 점수가 70점이 넘어야지 다음메뉴 버튼이 생기는것은 적용 안되어 있음.*!/*/}
                            {/*</>*/}
                        {/*)*/}
                {/*}*/}
            </section>
        );
    }


}

// export default withRecognizer()(Footer_TRT_B);

export default Footer_TRT_B;
    