import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_CVP from "../../Footer_CVP";

class ConversationPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            contents: null,
            err: null,
            errCount: 0,
            pass: false,
            randomLeftIndexes: [],
            randomRightIndexes: [],
            selected: {
                left: null,
                right: null,
            },
            completed: [],
            wrongPopShow: false,
            correctPopShow: false,
            canvasHeight: 0,
            canvasWidth: 0,
            hint: {
                eng: null,
                kor: null,
            }

        };

        this.mTimeout = null;
        this.canvasContainer = null;
        this.mCtx = null;


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // //console.log(this.props);

        if (nextProps.contents && prevState.contents === null) {
            return {
                contents: nextProps.contents,
            }
        }
        return null;
    }

    componentDidMount() {
        this._setRandomIndexes();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.selected.left !== this.state.selected.left) || (prevState.selected.right !== this.state.selected.right)) {
            this._checkRight();
        }
        // this.props.myJS();

        if (this.canvasContainer === null && this.state.isStart) { //init canvas
            this._initCanvas();
        }

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="top-tip">아래의 대화를 올바르게 연결해 보세요.</div>

                        <div className="conversation-practice">

                            {this._renderLeft()}

                            <canvas className="line-canvas"/>
                            {this._renderRight()}


                        </div>

                        {/*<div className={this.state.wrongPopShow ? "alert-wrong display" : "alert-wrong"}>*/}
                        <div className={"alert-wrong"}>
                            <div className="mask" onClick={() => {
                                this._handleWrongPop(false)
                            }}/>
                            <div className="alert-wrap">
                                <h2 className="tit">다시 한번 생각해 보세요</h2>
                                <h4 className="tit1">{this.state.hint.eng}</h4>
                                <p className="tit2">{this.state.hint.kor}</p>
                            </div>
                        </div>

                        <div className="alert-correct">
                            <div className="mask" onClick={() => {
                                this._handleCorrectPop(false)
                            }}/>
                            <div className="alert-wrap">
                                <h2 className="tit1">정답입니다.</h2>
                                <div className="H10"/>
                            </div>
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        위의 문장을 클릭하여 대화를 올바르게 연결하세요.
                                        <div className="divider"/>
                                        <div className="tip">
                                            스피커 버튼을 눌러 <strong>원어민 소리</strong>를 들어볼 수 있습니다.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_CVP
                    {...this.props}
                    pass={this.state.pass}
                />

            </>

        );
    };

    _renderLeft = () => {
        if (this.state.randomLeftIndexes.length === 0) {
            return;
        }
        let lefts = [];

        for (let i = 0; i < this.state.contents.length; i++) {
            lefts.push(
                <li
                    key={i}
                    className={this.state.selected.left === this.state.randomLeftIndexes[i] || this.state.completed.includes(this.state.randomLeftIndexes[i]) ? "selected" : ""}

                >
                    <span
                        className="text"
                        onClick={() => {
                            if (this.props.isPlaying) {
                                return;
                            } //음원 재생중에는 클릭 x
                            if (this.state.completed.includes(this.state.randomLeftIndexes[i])) {
                                return;
                            } //완료된 리스트는 클릭 X
                            this._selectSentence("left", this.state.randomLeftIndexes[i]);
                        }}
                    >{this.props.contents[this.state.randomLeftIndexes[i]].eng_content}
                    </span>
                    <button
                        type="button"
                        className="btn-play-sound"
                        onClick={() => {
                            //this._audioPlay("eng", this.state.randomLeftIndexes[i])
                            this.audioPlay(this.state.randomLeftIndexes[i])
                        }}
                    >음성 듣기
                    </button>
                    <span className="dot" id={`left_dot_${this.state.randomLeftIndexes[i]}`}/>
                </li>
            )
        }
        return (
            <ul className="left-list">
                {lefts}
            </ul>
        )
    }
    _renderRight = () => {
        if (this.state.randomRightIndexes.length === 0) {
            return;
        }
        let rights = [];

        for (let i = 0; i < this.state.contents.length; i++) {
            rights.push(
                <li
                    key={i}
                    className={this.state.selected.right === this.state.randomRightIndexes[i] || this.state.completed.includes(this.state.randomRightIndexes[i]) ? "selected" : ""}

                >
                    <span
                        className="text"
                        onClick={() => {
                            if (this.props.isPlaying) {
                                return;
                            } //음원 재생중에는 클릭 x
                            if (this.state.completed.includes(this.state.randomRightIndexes[i])) {
                                return;
                            } //완료된 리스트는 클릭 X
                            this._selectSentence("right", this.state.randomRightIndexes[i]);
                        }}
                    >
                        {this.props.contents[this.state.randomRightIndexes[i]].t_question}
                        </span>
                    <button
                        type="button"
                        className="btn-play-sound"
                        onClick={() => {
                            //this._audioPlay("kor", this.state.randomRightIndexes[i])

                            this.audioPlayKr(this.state.randomRightIndexes[i])
                        }}
                    >
                        음성 듣기
                    </button>
                    <span className="dot" id={`right_dot_${this.state.randomRightIndexes[i]}`}/>
                </li>
            )
        }
        return (
            <ul className="right-list">
                {rights}
            </ul>
        )
    }

    _setRandomIndexes = () => {
        let leftIndex = [0, 1, 2, 3];
        let rightIndex = [0, 1, 2, 3];
        this.setState({
            randomLeftIndexes: this._shuffle(leftIndex),
            randomRightIndexes: this._shuffle(rightIndex),
        })

    };

    _shuffle = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) { //동시 여러번 음원재생 방지
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    //실질적으로 한글 음원이 재생되는 것이 아니라 정답 음원이 재생됨.
    audioPlayKr = (index) => {
        console.log("오른쪽 패널 음원 재생",index);
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].kor_url}`;

        this.props.setHowler(
            src
        );
    }


    _selectSentence = (side, idx) => {
        if (side === "left") {
            this.setState({
                selected: {
                    ...this.state.selected,
                    left: idx,
                }
            })
        } else if (side === "right") {
            this.setState({
                selected: {
                    ...this.state.selected,
                    right: idx,
                }
            })
        } else {
            //console.log("인덱스가 올바르지 않습니다.");
            return null;
        }
    }

    _checkRight = () => {
        if (this.state.selected.left !== null && this.state.selected.right !== null) {
            if (this.state.selected.left === this.state.selected.right) {
                //alert("정답");
                this._drawLine(this.state.selected.left);
                let completed = this.state.completed;
                completed.push(this.state.selected.left);
                if (completed.length === 4) { //4개 다 맞추면 pass:true
                    this._handleCorrectPop(true);
                    this.setState({
                        completed: completed,
                        selected: {
                            left: null,
                            right: null,
                        },
                        pass: true,
                    });
                } else {
                    this.setState({
                        completed: completed,
                        selected: {
                            left: null,
                            right: null,
                        }
                    });
                }

            } else {
                // alert("오답");
                this._handleWrongPop(true);
                this.setState({
                    selected: {
                        left: null,
                        right: null,
                    },
                    hint: {
                        kor: this.state.contents[this.state.selected.left].kor_content,
                        eng: this.state.contents[this.state.selected.left].eng_content
                    }
                })

            }
        }
    };

    _handleWrongPop = (isShow) => {
        if (isShow) {
            this.setState({
                wrongPopShow: isShow
            });
            $('.alert-wrong').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.wrongPopShow) {
                    $('.alert-wrong').fadeOut('fast');
                    this.setState({
                        wrongPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 5000)
        } else {
            if (this.state.wrongPopShow) {
                $('.alert-wrong').fadeOut('fast');
                this.setState({
                    wrongPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    }

    _handleCorrectPop = (isShow) => {

        if (isShow) {
            this.setState({
                correctPopShow: isShow,
                pass: true, //통과
            });
            $('.alert-correct').fadeIn('fast');

            //Correct 창은 자동 종료 타임아웃 설정 안함.

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.correctPopShow) {
                    $('.alert-correct').fadeOut('fast');
                    this.setState({
                        correctPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 2000)
        } else {
            if (this.state.correctPopShow) {
                $('.alert-correct').fadeOut('fast');
                this.setState({
                    correctPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                //clearTimeout(this.mTimeout);
            }
        }

    };

    _drawLine = (idx) => {
        if (this.mCtx === null) {
            this._initCanvas();
        }
        let leftPosX, leftPosY, rightPosX, rightPosY;

        leftPosX = 0;
        leftPosY = $('#left_dot_' + idx).offset().top - this.canvasContainer.offset().top + 3;


        rightPosX = this.state.canvasWidth;
        rightPosY = $('#right_dot_' + idx).offset().top - this.canvasContainer.offset().top + 3;


        // this.mCtx.moveTo(leftPosX, leftPosY);
        // this.mCtx.lineTo(rightPosX, rightPosY);
        // this.mCtx.stroke();
        this.mCtx.moveTo(leftPosX, leftPosY);
        this.mCtx.lineTo(rightPosX, rightPosY);
        this.mCtx.stroke();

        ////console.log($('#left_dot_0').offset());
        //console.log($('#left_dot_' + idx).offset().left);
        //console.log($('#left_dot_' + idx).offset().top);
        //console.log($('#right_dot_' + idx).offset().left);
        //console.log($('#right_dot_' + idx).offset().top);


    };

    _initCanvas = () => {
        this.canvasContainer = $('canvas.line-canvas');
        //console.log(this.canvasContainer);
        this.mCtx = this.canvasContainer[0].getContext('2d');
        this.canvasContainer[0].height = this.canvasContainer.height();
        this.canvasContainer[0].width = 115;
        this.mCtx.strokeStyle = '#00759c';
        this.mCtx.strokeWidth = '3px';
        this.mCtx.beginPath();

        this.setState({
            canvasHeight: this.canvasContainer.height(),
            canvasWidth: 115,
        })
    }


}


export default withContents()(ConversationPractice);
    
    