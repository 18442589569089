import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    shuffleArr,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_WritePhrase from "../../Footer_WritePhrase";

class WritePhrase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            pass: false,
            isAnimate: false,
            cardPuzzleOptions: {
                animationTime: 200,
            },
            clickedWordsList: null,
            isInitialize: false,
            isWrong: false,
            randomIdx: null,
            currentWordIdx: 0,
            lastClickedIdx:0,
            currentPage:1,
            wordsList:[],
            currentPageWordsIdx:0,

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if (nextProps.contents && !prevState.isInitialize) {
            let clickedWordsList = {};
            let randomIdx = [];

            let wordsList = [];
            for(let i =0 ; i < nextProps.contents.length; i ++){
                // //console.log(nextProps.contents[i].eng_content.split(' '));
                wordsList = wordsList.concat(nextProps.contents[i].eng_content.split(' '));
            }

            for (let i = 0; i < wordsList.length; i++) {
                clickedWordsList[i] = false;
                randomIdx.push(i);
            }

            // let tpkContent = nextProps.contents[0].tpk_content.replace(/\?/gi,'?%').replace(/\./gi,'.%').split('%').join('<br />');

            return ({
                isInitialize: true,
                clickedWordsList: clickedWordsList,
                randomIdx: shuffleArr(randomIdx),
                wordsList:wordsList,

            })
        }

        if(nextProps.currentPage !== prevState.currentPage){ //페이지 변경시
            return({
                currentPage:nextProps.currentPage,
                pass: false,
                currentPageWordsIdx:0,
            })
        }

        return null;

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.isComplete){

            return(
                this._renderComplete()
            )
        }

        return (
            this._renderMain()
        )



    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">
                        <div className="test-question">
                            <div className="test-header l5">
                                <h2 className="tit">{this.props.contents[this.state.currentPage-1].kor_content}</h2>
                            </div>
                            <div className="answer-wrap border-none shorter">

                                {this._renderCards()}

                            </div>
                            <div className="option-wrap by5">
                                {this._renderWords()}
                            </div>
                        </div>

                    </div>
                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span>/<span
                        className="total">{this.props.contents.length}</span>
                    </div>


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            다음 구/문장에 해당하는 영어를 <br/>
                            <strong>단어를 조합</strong>하여 만들어 보세요.
                        </div>
                    </div>
                </section>
                <Footer_WritePhrase
                    {...this.props}
                    pass={this.state.pass}
                    handleIsComplete={this._handleIsComplete}
                    totalPage = {this.props.contents.length}
                    currentPage={this.state.currentPage}

                />

            </>

        );
    };

    //
    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    // audioPlay = (index) => {
    //     if(!this.props.contents) {
    //         console.log("not loaded contents yet");
    //         return;
    //     }
    //     let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
    //
    //     this.props.setHowler(
    //         src
    //     );
    // }


    _renderCards = () => {
        let cards = [];
        for (let i = 0; i < this.props.contents[this.state.currentPage-1].eng_content.split(' ').length; i++) {
            cards.push(
                <li className={
                    this.state.currentPageWordsIdx > i ?
                        this.state.isWrong ?
                            this.state.currentPageWordsIdx - 1 === i ?
                                "s-card wrong"
                                :
                                "s-card correct"
                            :
                            "s-card correct"
                        :
                        "s-card empty"
                }
                    key={i}
                    id={`card_${i}`}
                >
                    <button
                        type="button"
                        className="btn-remove-card"
                        onClick={() => this._cancelSelect()}
                    >
                        제거
                    </button>
                    <span>
                        {
                            this.state.currentPageWordsIdx > i ?
                                this.state.isWrong ?
                                    this.state.currentPageWordsIdx - 1 === i ?
                                        this.state.wordsList[this.state.randomIdx[this.state.lastClickedIdx]]
                                        :
                                        this.props.contents[this.state.currentPage-1].eng_content.split(' ')[i]
                                    :
                                    this.props.contents[this.state.currentPage-1].eng_content.split(' ')[i]
                                :
                                ""
                        }
                    </span>
                </li>
            )
        }

        return (
            <ul className="sorting-card">
                {cards}
                {this.props.contents[this.state.currentPage - 1].kor_content.slice(-1) === "?" ?<li className="plain">?</li> :""}
            </ul>
        )
    }


    _renderWords = () => {
        let words = [];
        for (let i = 0; i < this.state.wordsList.length; i++) {
            words.push(
                <li
                    className={this.state.clickedWordsList[i] ? "s-card empty" : "s-card"}
                    onClick={(event) => {
                        this._clickWords(i, event)
                    }}
                    key={i}
                >
                    {this.state.wordsList[this.state.randomIdx[i]]}
                </li>
            )
        }

        return (
            <ul className="sorting-card">
                {words}
            </ul>
        )
    }


    _clickWords = (idx, e) => {
        //console.log("클릭!:", idx);
        //console.log("클릭!:", e.target);
        if (this.state.isAnimate) { //애니메이션이 작동 중이라면 리턴
            return;
        }
        if (this.state.clickedWordsList[idx]) { //이미 클릭한 단어라면 리턴
            return;
        }

        if (this.state.isWrong) { //마지막 단어가 틀린 상태라면 리턴
            return;
        }

        if(this.state.currentPageWordsIdx + 1 > this.props.contents[this.state.currentPage - 1].eng_content.split(' ').length){ //더이상 빈 곳이 없으면 리턴
           return;
        }

        this.setState({
            isAnimate: true, //애니메이션 시작에 따른 애니메이션 상태값 변경
            clickedWordsList: {
                ...this.state.clickedWordsList,
                [idx]: true
            },
            lastClickedIdx:idx,
        });

        let container = $('.test-question');
        let target = $(`#card_${this.state.currentPageWordsIdx}`);
        let originalPosX = $(e.target).offset().left - container.offset().left;
        let originalPosY = $(e.target).offset().top - container.offset().top;
        let targetPosX = target.offset().left - container.offset().left;
        let targetPosY = target.offset().top - container.offset().top;


        let $cloneCard
            = $('<div>')
            .addClass('s-card')
            .css({
                'position':'absolute',
                'top' : originalPosY,
                'left' : originalPosX
            }).text( this.state.wordsList[this.state.randomIdx[idx]] );
        container.append($cloneCard);

        $cloneCard.animate({
            top: targetPosY,
            left: targetPosX,
        }, this.state.cardPuzzleOptions.animationTime, () => {
            $cloneCard.remove();
            if (this.state.wordsList[this.state.currentWordIdx] === this.state.wordsList[this.state.randomIdx[idx]]) { //만약 클릭한 단어가 현재 들어가야 하는 단어가 같다면

                this.setState({
                    currentWordIdx: this.state.currentWordIdx + 1,
                    isWrong: false,
                    isAnimate: false,
                    currentPageWordsIdx:this.state.currentPageWordsIdx + 1,
                    pass:this.state.currentPageWordsIdx + 1 >= this.props.contents[this.state.currentPage - 1].eng_content.split(' ').length,
                })
            } else { //틀린 경우
                this.setState({
                    currentWordIdx: this.state.currentWordIdx + 1,
                    currentPageWordsIdx: this.state.currentPageWordsIdx + 1,
                    isWrong: true,
                    isAnimate: false,
                })
            }
        });

    };

    _cancelSelect = () => {
        this.setState({
            lastClickedIdx: null,
            clickedWordsList: {
                ...this.state.clickedWordsList,
                [this.state.lastClickedIdx]:false,
            },
            currentWordIdx: this.state.currentWordIdx - 1,
            currentPageWordsIdx: this.state.currentPageWordsIdx - 1,
            isWrong:false,
        })
    }

    _resetCardPuzzle = () => {
        if (this.state.isAnimate) { //애니메이션중에 클릭하면 리턴
            return;
        }

        let clickedWordsList = {};
        for (let i = 0; i < this.props.contents[0].eng_content.length; i++) {
            clickedWordsList[i] = false;
        }


        this.setState({
            clickedWordsList: clickedWordsList,
            isWrong: false,
            currentWordIdx:0,

        })
    }
}


export default withContents()(WritePhrase);
    
    