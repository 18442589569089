import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


class Footer_TKE2_A extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
        };

    }


    render() {

        return (
            <section className="view-footer">


                {!this.props.hasResult  ?
                        (

                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this.props.startTest();
                                    this.props.closeTip();
                                }}>
                            </button>


                        ) :
                        (
                            <>
                                <button type="button" className="f-btn">Empty</button>
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        this.props.startTest();
                                        this.props.closeTip();
                                    }}>
                                </button>
                                {
                                    this.props.pass ?
                                        <button
                                            type="button"
                                            className="f-btn btn-next-page"
                                            //onClick={() => {this.props.goNextMenu()}}>다음메뉴
                                            onClick={() => {
                                                // this.UpdateEndLessonAndCallEndPage()
                                                this.props.goNextMenu()
                                            }}>레슨종료
                                        </button>
                                        :
                                        <button type="button" className="f-btn">Empty</button>
                                }
                            </>
                        )
                }
            </section>
        );
    }

    // UpdateEndLessonAndCallEndPage = () => {
    //     reportEndLesson(this.props.apiUrl, this.props.attend_idx);
    //     if(this.props.userInfo.std_lesson === this.props.lessonInfo.lesson_seq) {
    //         updateStdLesson(this.props.apiUrl, this.props.serverData.student_idx, this.props.userInfo.std_lesson + 1);
    //     }
    //     this.props.handleCurrentMenu(999);
    //
    // }

}

export default withRecognizer()(Footer_TKE2_A);
    
    