import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_ISM_C extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
        };
        this._testStart = this._testStart.bind(this);

    }

    _testStart = () => {
        this.props.handleIsTesting(true);
        this.props.closeTip();
        this.props.setHowler(
            `${this.props.contents[this.props.wordInfo.num + 1].step_code}/${this.props.contents[this.props.wordInfo.num + 1].lesson_no}/${this.props.contents[this.props.wordInfo.num + 1].eng_url}`,
            () => {
                this.props.startRecognition();
            },
        );
        // this.props.audioPlayWithCb("eng", this.props.wordInfo.num + 1, () => {
        //     if(isIOS){
        //         this.props.fakeRecognitionStart();
        //         this.props.isListening(true);
        //         this.props.audioPlayMute("eng",this.props.wordInfo.num+1,
        //             () => setTimeout(() => {this.props.fakeRecognitionEnd(); this.props.isListening(false)},1000)
        //         );
        //     } else {
        //         this.props.startRecognition();
        //     }
        // });
    };


    render() {

        return (

            <section className="view-footer">

                {
                    this.props.pass ?
                        <>
                            <button
                                type="button"
                                className="f-btn btn-prev"
                                onClick={() => {
                                    this.props.handlePhase(1);
                                }}
                            >
                                GoMain
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => {
                                    this._testStart()
                                }}>
                            </button>
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {
                                    this.props.goNextMenu();
                                }}>
                            </button>

                        </>
                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this._testStart()
                            }}>
                        </button>

                }

            </section>

        );
    }


}

export default withRecognizer()(Footer_ISM_C);
    
    