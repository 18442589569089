import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";


class Footer_NET_A extends Component {

    render() {
        //console.log(this.props.currentPage + " and "+ this.props.maxPage);
        //console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">
                {
                    this.props.isPlaying ?
                        <button
                            type="button"
                            className="f-btn f-center btn-listen">
                            테스트
                        </button>
                        :
                        this.props.hasResult ?
                            this.props.pass ?
                                <>
                                    <button type="button" className="f-btn">Empty</button>
                                    <button
                                        type="button"
                                        className="f-btn f-center btn-test"
                                        onClick={() => this.props.startTest()}>
                                        테스트
                                    </button>
                                    <button
                                        type="button"
                                        className="f-btn btn-next-page"
                                        onClick={() => {
                                            this.props.goNextMenu()
                                        }}>
                                        다음메뉴
                                    </button>
                                </>
                                :
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => this.props.startTest()}>
                                    테스트</button>
                            : <button
                                type="button"
                                className="f-btn f-center btn-test"
                                onClick={() => this.props.startTest()}>
                                테스트</button>
                }
            </section>

        );
    }

}

export default withRecognizer()(Footer_NET_A);
    
    