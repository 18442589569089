import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from "../../../other/LoadingBar";
import {TweenLite} from "gsap/TweenLite";
import {
    brTagActivator,
    getCurrentMenuInfo,
    brTagRemover,
    getTodayDate,
    calculateScore,
    uploadScore,
    FailScore, isMobile, isIOS
} from "../../../../asset/js/myFunctions";
import Footer_RDA from "../../Footer_RDA";
import {Element, scroller} from 'react-scroll'
import $ from "jquery";

class RoleplayA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            numOfPair: 0,   // 총 대화가 몇 쌍인지.
            thisMenuNumber: 72,
            isStart: false,
            playingIndex: 0,
            result: [{
                testResult: null,
            }],
            letStartRecognition: false,
            currentIndex:0,
            isTesting: false,
            isReadyTest: false,
            isFinalTest: false,
            finalScore: 0,
            currentPhase: {
                index: 0,
                pass: false,
                count: 0,
            },
            isComplete: false,
            tryCount: 0,
            err: null,
            pass: false,
            errCount: 0,
            waiting:false,
            showContinue:false,
        };
        // this._setResult = this._setResult.bind(this);
    }

    componentDidMount() {
        // this.props.myJS();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.contents !== null && prevState.numOfPair === 0) { //처음 세팅시에만 걸림
            let results = [];
            for (let i = 0; i < nextProps.contents.length; i++) { //results 초기화.
                results[i] = {testResult: null}
            }
            //console.log("result 초기화 완료. results: ", results);

            return ({
                numOfPair: Math.ceil(nextProps.contents.length / 2),
                result: results,
                currentPhase: {
                    index: Math.ceil(nextProps.contents.length / 2) - 1,
                    pass: false,
                    count: 0,
                }
            })
        }
        return null;
    }


    componentDidUpdate(prevProps, prevState) {

        // if (this.state.isTesting && prevProps.isPlaying && !this.props.isPlaying) { //테스트 중, 음원 플레이 종료시 걸림
        //
        //     this.props.audioFakePlay(this.props.playingIndex + 1); //다음 버블 선택
        //     this.setState({
        //         testingIndex: this.props.playingIndex + 1,
        //         errCount: 0,
        //     })
        // }


        if (this.state.playingIndex !== prevState.playingIndex) { //playingIndex변경시 걸림.
            scroller.scrollTo(`dialogue${this.state.playingIndex}`, {
                duration: 800,
                delay: 0,
                smooth: true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });
        }


        if(!prevState.isComplete && this.state.isComplete) {
            $(function () {
                TweenLite.to("#score-poplayer", 1, {
                    y: 0,
                    delay: 0,
                    yPercent: 0,
                    opacity: 1
                })

            });
        }

        if (prevState.isTesting === true && this.state.isTesting === false) { //테스트 종료 하고 나면 화면을 스크롤 시킴 제일 하단으로
            scroller.scrollTo(`dialogue${this.state.currentPhase.index * 2 + 1}`, {
                duration: 800,
                delay: 0,
                smooth: true,
                containerId: 'dialogueContainer',
                offset:isMobile && $(window).width() < 1200 ? -150 : -300,
            });
        }
    }

    render() {
        if (this.props.contents === null || this.props.menuInfo === null) {
            return (<LoadingBar/>);
        }
        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    {/*<h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>*/}
                    <h2 className="intro-title">{brTagRemover(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <span className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</span>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    {this.state.isComplete ?
                        <div className="content-wrap">
                            <div className="score-poplayer" id="score-poplayer">
                                <div className="content">
                                    <p>
                                        <span className="sr-only">SCORE</span>
                                        <strong> {this.state.finalScore} </strong>
                                    </p>
                                    <button type={"button"} className="btn-answer"
                                       onClick={() => {
                                           $('#speak-poplayer').wPopLayer();
                                       }}>정답보기</button>
                                </div>
                            </div>

                            {/*<p className = "score-help-block" >*/}
                            {/*{this.state.pass ?*/}
                            {/*<>잘 하셨어요.<br />더 높은 점수를 원하시면 다시 <strong>테스트</strong> 하세요.</>*/}
                            {/*:`${FailScore}점 이상이어야 통과할 수 있습니다.`*/}
                            {/*}*/}
                            {/*</p>*/}
                        </div>
                        :

                        <Element className="content-wrap align-start" id={"dialogueContainer"}>
                            <ul className="talk-list">
                                <li className="datetime"><span>{getTodayDate()}</span></li>
                                {this._renderDialogueAFinalTest()}
                            </ul>
                        </Element>
                    }
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        앞에서 외운 <strong>A의 대사</strong>를 <strong>모두</strong> 말합니다.<br/>
                                        테스트 버튼을 눌러 "삐" 소리 후 <strong>영어</strong>로 말하세요
                                        <hr className="divider"/>
                                        <div className="tip">
                                            특정 문장은 음성 인식이<br/>
                                            잘 되지 않는 경우도 있습니다.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                    {this._renderAnswer()}
                </section>

                <Footer_RDA
                    {...this.props}
                    startTest={this._startTest}
                    currentIndex={this.state.currentIndex}
                    setResult={this._setResult}
                    memorizeAFinalTest={this._memorizeAFinalTest}
                    letStartRecognition={this.state.letStartRecognition}
                    handleError={this._handleError}
                    currentPhase={this.state.currentPhase}
                    finalScore={this.state.finalScore}
                    isComplete={this.state.isComplete}
                    err={this.state.err}
                    pass={this.state.pass}
                    isTesting={this.state.isTesting}
                    waiting={this.state.waiting}
                    showContinue={this.state.showContinue}
                    resetAudioContext={this.resetAudioContext}

                />
            </>
        )
    };

    _renderDialogueAFinalTest = () => { //index = 0에서 시작.
        let dialogues = [];
        for (let i = 0; i < this.props.contents.length; i++) { //짝수개씩 끊음. 2,4,6,8,10 등
            dialogues.push(
                <li
                    key={(this.props.contents[i].contents_idx)}
                    className={
                        this.state.playingIndex === i ? //재생중인 대화는 clicked 처리. 홀수는 왼쪽, 짝수는 오른쪽 대화.
                            (i % 2 === 0 ? "block left-block clicked" : "block right-block clicked")
                            :
                            (i % 2 === 0 ? "block left-block" : "block right-block")}
                >
                    <img
                        className="profile"
                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[i].mobile_url}`}
                        alt={"다이어로그 캐릭터 프로필"}/>
                    <Element name={`dialogue${i}`} className="chat-bubble CursorPointer">
                        <p>
                            {
                                i % 2 === 1 ?
                                    brTagActivator(this.props.contents[i].kor_content)
                                    :
                                    this.state.result[i].testResult === "##ERROR" ?
                                        "에러: 잘 인식되지 않습니다."
                                        :
                                        //this.state.result[i].testResult
                                        this.state.result[i].testResult != null ?
                                            brTagActivator(this.props.contents[i].eng_content)
                                            :""
                            }
                        </p>
                    </Element>
                </li>
            );
        }

        return (
            dialogues
        )
    };

    _startTest = () => {
        this.setState({isTesting: true});
    };

    _start = () => {
        this.setState({isStart: true})
    };

    _selectBubble = (key) => {
        //console.log(key + " is clicked");
        // this.props.audioPlay("eng", key);
        this.setState({
            playingIndex:key
        })
        this.audioPlay(key);
    };

    resetAudioContext = () => {
        let context = new AudioContext();
        context.resume().then(() => {
            console.log('Playback resumed successfully');
            this.setState({
                showContinue:false,
            })
            this._memorizeAFinalTestRetry(this.state.currentIndex);
        });
    }

    showContinueButton = () => {
        this.setState({
            showContinue:true,
        })
    }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        this.props.setHowler(src);
    }

    _toggleReadyTest = () => {
        this.setState({
            isReadyTest: !this.state.isReadyTest
        })
    }

    _setResult = (result, index) => { //전체 대화문의 index와 result를 받아와서 해당 결과를 업데이트 함.
        let currentResult = this.state.result;
        currentResult[index] = {testResult: result};

        this.setState({
            result: currentResult,
            tryCount: this.state.tryCount + 1,
            err: null,
            letStartRecognition:false,
            waiting:true,
        });
        setTimeout(() => {
            this._memorizeAFinalTest(index + 1);
        }, 700)
    };


    // _memorizeAFinalTest = () => {
    //     //console.log("롤플레이 A시작!");
    //     let results = [];
    //     for (let i = 0; i < this.props.contents.length; i++) { //results 초기화.
    //         results[i] = {testResult: null}
    //     }
    //     this.props.audioFakePlay(0);
    //     this.setState({
    //         isFinalTest: true,
    //         result: results, //results 초기화
    //         testingIndex: 0,
    //         isTesting: true,
    //         isComplete: false,
    //         err: null,
    //         errCount: 0,
    //         currentPhase: {
    //             index: Math.ceil(this.props.contents.length / 2) - 1,
    //             pass: false,
    //             count: 0,
    //         },
    //     });
    //
    // };
    ///////

    //음원재생 실패한 경우 Context초기화 후 다시 테스트 시작
    _memorizeAFinalTestRetry = (index) => {
//console.log("호출",index);
        // if(this.state.isTesting){
        //     return;
        // }
        //console.log("롤플레이 A시작!");
        if(index >= this.props.contents.length) { //마지막까지 한 후 테스트 종료
            let finalScore = this._calculateTotalScore(this.state.result, this.props.contents); //최종 점수 입력
            uploadScore(this.props.apiUrl, finalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount, this.props.serverData.auth_type);
            let pass = finalScore >= FailScore;
            if(pass){
                this.setState({
                    isTesting:false,
                    waiting:false,
                    pass,
                    finalScore,
                    isComplete: true,
                    err: ["잘 하셨어요. 더 높은 점수를 원하시면", <br/>, "테스트 버튼을 눌러 다시 테스트하세요.",
                        <hr className="divider"/>,
                        <div className="tip">특정 문장은 인식이 잘 되지 않을 수 있습니다. <br/> 발음 문제가 아니니 너무 상심하지 마세요.</div>],
                });
                this.props.openTip();
            } else if(this.state.tryCount>2) {
                this.setState({
                    isTesting:false,
                    isComplete: true,
                    pass: true,
                    err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],
                });
                this.props.openTip();
            } else {
                this.setState({
                    isTesting:false,
                    isComplete: true,
                    err: ["더 높은 점수가 나올때 까지 다시 ", <strong>테스트</strong>, "하세요", <hr className="divider"/>,
                        <div className="tip">대화보기를 누르시면 <br/>대화를 다시 확인 할 수 있어요.</div>],
                    pass:false,
                });
                this.props.openTip();
            }
            return;
        }

        if(index === 0){
            //처음시작시 결과 리셋
            let results = [];
            for (let i = 0; i < this.props.contents.length; i++) { //results 초기화.
                results[i] = {testResult: null}
            }
            this.setState({
                isFinalTest: true,
                result: results, //results 초기화
                isTesting: true,
                isComplete: false,
                err: null,
                errCount: 0,
                currentPhase: {
                    index: Math.ceil(this.props.contents.length / 2) - 1,
                    pass: false,
                    count: 0,
                },
                waiting:false,
            });
            this.props.closeTip();
        }

        if(index % 2 === 0){ //A대사의 경우 Fake Play and Recognition Start
            //this.props.audioFakePlay(index);
            this.setState({
                letStartRecognition:true,
                currentIndex:index,
                playingIndex:index,
                waiting:false,
            })
        } else { //B대사의 경우 소리 재생
            this.setState({
                currentIndex:index,
                playingIndex:index,
                waiting:false,
            })

            let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
            //console.log("B대사 종료");
            // this.props.setHowler(
            //     src,
            //     () => {this._memorizeAFinalTest(index + 1)}
            // )
            this.props.setHowlerError(
                src,
                () => {this._memorizeAFinalTest(index + 1)},
                () => {
                    this.props.setSrc(null);
                    this._memorizeAFinalTest(index + 1)
                }
            )

            // this.props.audioPlayWithCb("eng", index, () => {
            //     this._memorizeAFinalTest(index + 1);
            // });
        }
    };

    _memorizeAFinalTest = (index) => {
        if(index >= this.props.contents.length) { //마지막까지 한 후 테스트 종료
            let finalScore = this._calculateTotalScore(this.state.result, this.props.contents); //최종 점수 입력
            uploadScore(this.props.apiUrl, finalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount, this.props.serverData.auth_type);
            let pass = finalScore >= FailScore;
            if(pass){
                this.setState({
                    isTesting:false,
                    waiting:false,
                    pass,
                    finalScore,
                    isComplete: true,
                    err: ["잘 하셨어요. 더 높은 점수를 원하시면", <br/>, "테스트 버튼을 눌러 다시 테스트하세요.",
                        <hr className="divider"/>,
                        <div className="tip">특정 문장은 인식이 잘 되지 않을 수 있습니다. <br/> 발음 문제가 아니니 너무 상심하지 마세요.</div>],
                });
                this.props.openTip();
            } else if(this.state.tryCount>2) {
                this.setState({
                    isTesting:false,
                    isComplete: true,
                    pass: true,
                    err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],
                });
                this.props.openTip();
            } else {
                this.setState({
                    isTesting:false,
                    isComplete: true,
                    err: ["더 높은 점수가 나올때 까지 다시 ", <strong>테스트</strong>, "하세요", <hr className="divider"/>,
                        <div className="tip">대화보기를 누르시면 <br/>대화를 다시 확인 할 수 있어요.</div>],
                    pass:false,
                });
                this.props.openTip();
            }
            return;
        }

        if(index === 0){
            //처음시작시 결과 리셋
            let results = [];
            for (let i = 0; i < this.props.contents.length; i++) { //results 초기화.
                results[i] = {testResult: null}
            }
            this.setState({
                isFinalTest: true,
                result: results, //results 초기화
                isTesting: true,
                isComplete: false,
                err: null,
                errCount: 0,
                currentPhase: {
                    index: Math.ceil(this.props.contents.length / 2) - 1,
                    pass: false,
                    count: 0,
                },
                waiting:false,
            });
            this.props.closeTip();
        }

        if(index % 2 === 0){ //A대사의 경우 Fake Play and Recognition Start
            //this.props.audioFakePlay(index);
            this.setState({
                letStartRecognition:true,
                currentIndex:index,
                playingIndex:index,
                waiting:false,
            })
        } else { //B대사의 경우 소리 재생
            this.setState({
                currentIndex:index,
                playingIndex:index,
                waiting:false,
            })

            let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
            //console.log("B대사 종료");
            // this.props.setHowler(
            //     src,
            //     () => {this._memorizeAFinalTest(index + 1)}
            // )
            this.props.setHowlerError(
                src,
                () => {this._memorizeAFinalTest(index + 1)},
                () => {
                    //console.log("on load error");
                    this.props.setSrc(null);
                    // this._handleError(["소리파일 로드를 실패하였습니다. 이어서 테스트 하세요."]);
                    this.setState({
                        err:["소리파일 로드를 실패하였습니다. 이어서 테스트 하세요."],
                        waiting:false,
                    });
                    this.props.openTip();
                    this.showContinueButton();
                }
            )

            // this.props.audioPlayWithCb("eng", index, () => {
            //     this._memorizeAFinalTest(index + 1);
            // });
        }
    };


    _calculateTotalScore = (results, originals) => {  //합산  점수 출력
        let sum = 0;
        for (let i = 0; i < originals.length; i++) {
            if (i % 2 === 0) { //Roleplay A에서 A의 대사만 점수 평가함.

                sum += calculateScore(results[i].testResult, originals[i].eng_content);
            }
        }

        if (sum === 0) {
            return FailScore;
        } else {
            return Math.ceil(sum / (originals.length / 2));
        }
    };


    _handleError = (err) => {
        //console.log("음성인식 실패.");
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });

            return;
        }

        if (this.state.errCount >= 1) {
            this._setResult('##ERROR', this.state.currentIndex); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        });

    };


    _renderAnswer = () => {
        ////console.log(this.props.lessonInfo);
        let answers = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            answers.push(
                <li key={i}>
                    <p>
                        {i % 2 === 0 ? "A: " : "B: "}
                        {brTagActivator(this.props.contents[i].kor_content)}<br/>
                        <span>{brTagActivator(this.props.contents[i].eng_content)}</span>
                    </p>
                    <button
                        type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() => this.audioPlay(i)}
                    >발음듣기</button>
                </li>
            )
        }

        return (
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close"  onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )
    }

    // audioPlay = (index) => {
    //     if(!this.props.contents) {
    //         console.log("not loaded contents yet");
    //         return;
    //     }
    //     let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
    //
    //     this.props.setHowler(
    //         src
    //     );
    // }
    // _audioPlay = (i) => {
    //     if (this.props.isPlaying) {
    //         return;
    //     }
    //     this.props.audioPlay("eng", i);
    // }
}


//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(RoleplayA);

