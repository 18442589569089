import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_M3W_A from '../../Footer_M3W_A';
import Footer_M3W_B from '../../Footer_M3W_B';
import {
    getCurrentMenuInfo,
    brTagActivator,
    calculateScore, FailScore, isIOS
} from '../../../../asset/js/myFunctions';
import {TweenLite} from "gsap/TweenLite";
import $ from 'jquery';
import Score from '../../Score';
import LoadingBar from "../../../other/LoadingBar";


const NUM_OF_WORDS = 3;



class Memorize3Words extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bodyHeight:window.innerHeight,
            init:false,
            index: 0,
            numOfPage: 0,
            thisMenuNumber:66,
            activatedWords: null,
            isStart: false,
            isTesting: false,
            count: 0,
            waiting:false,
            currentPage: 1,
            hasResult: false,
            result:{
            },
            wordInfo:{
                num: 0,
                getResult: false,
            },
            realContentsLength: 0,
            lastPageWordLeft: 0,
            err:null,
            errCount:0,
            pass:false,
            tryCount:0, //Pass Non-Pass 정하기 위한 스테이트
            totalScore: 0 //총 점수

        };

        this._onWordsClick = this._onWordsClick.bind(this);
        this._start = this._start.bind(this);
        this._startTest = this._startTest.bind(this);
        this._setResult = this._setResult.bind(this);
        this._updateWordInfo = this._updateWordInfo.bind(this);

        this.timeOut = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.currentPage !== prevState.currentPage){ //페이지 변경시
            nextProps.closeTip();
            return {
                currentPage: nextProps.currentPage,
                hasResult: false,
                wordInfo:{
                    num: (nextProps.currentPage-1) * NUM_OF_WORDS,
                    getResult: false,
                },
                pass:false,
                errCount:0,
                err:null,
                tryCount:0,
                totalScore: 0,
            }
        }
        // //console.log(this.props);
        if(nextProps.contents){
            return {
                numOfPage: Math.ceil(nextProps.contents.length / NUM_OF_WORDS),
                realContentsLength: 0,
                lastPageWordLeft: nextProps.contents.length % NUM_OF_WORDS,
            }
        }


        return null;

    }

    componentDidMount(){

        if(this.props.lessonInfo.lesson_no === "01"){
            this.props.openTip();
        }
    }

    componentWillUnmount(){
        if(this.timeOut != null){
            clearTimeout(this.timeOut);
            this.timeOut = null;
        }
        this.setState({
            init:false,
        })

    }


    componentDidUpdate(prevProps, prevState){
        // this.props.myJS();

        if(!prevState.hasResult && this.state.hasResult){
            $('.word-list li').each(function(index){
                let $score = $('.score', $(this)),
                    animationTime = 0.6,    // 애니메이션 전체 시간
                    delay = (index -1 ) * 0.3;  // 각 스코어별 delay

                TweenLite.to( $score, animationTime, {
                    y:0,
                    delay:delay,
                    yPercent:0,
                    opacity:1
                })
            });
        }
    }

    render() {

        if(!this.props.contents){
            return(<LoadingBar />);
        }
        if(this.props.currentPage > this.state.numOfPage) {
            return (<div>Error. Exceed contents.</div>);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }


        if(this.state.isTesting){
            //console.log("Rendered word is:" + this.state.wordInfo.num);
            return (this._renderTest(this.state.wordInfo.num));
        }


        return (
            this._renderMain()
        )

    }

    _renderMain = () => {
        ////console.log("render main is called again");
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>


                <div className="content-wrap HideScrollBar">



                    {this.state.hasResult ?
                        this._render3WordsWithResult(this.props.currentPage -1)
                    :
                        this._render3Words(this.props.currentPage - 1)
                    }

                    {/*<p className="Err">*/}
                        {/*{this.state.err}*/}
                    {/*</p>*/}
                </div>


                <div className="lecture-step">
                    <span className="current">{this.props.currentPage}</span>/<span className="total">{this.state.numOfPage}</span>
                </div>


                <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                <div className={`tip-container tip-bg-${this.props.step123}`}>
                    <div className="message-box">
                        {
                            this.state.err === null ?
                                <>
                                    다음 3단어를 뜻과 함께 외워주세요.<br />다 외우셨다면 테스트 합니다.
                                    <hr className="divider" />
                                    <div className="tip">단어를 클릭하면 발음을 들어볼<br />수 있습니다.</div>
                                </>
                                :
                                this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                        }

                    </div>
                </div>

                {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                {/*back={this._prev}*/}
                {/*next={this._next} />*/}
                </section>
                <Footer_M3W_A
                    {...this.props}
                    c_code={this.props.c_code}
                    startTest = {this._startTest}
                    hasResult = {this.state.hasResult}
                    numOfPage = {this.state.numOfPage}
                    handleError={this._handleError}
                    pass={this.state.pass}
                />
            </>

        );
    };




    _render3Words = (index) => {
        let wordsList = [];
        for(let i = 0 ; i < NUM_OF_WORDS; i ++){
            let key=(index*NUM_OF_WORDS) + i
            wordsList.push(
            <li key={key} id={key} onClick={() => {this._onWordsClick(key)}} className={this.state.activatedWords === key ? "active" : ""}>
                <div className="word m3word">{this.props.contents[(index*NUM_OF_WORDS)+i].eng_content}</div>
                <div className="txt m3txt">{this.props.contents[(index*NUM_OF_WORDS)+i].kor_content}</div>
            </li>)
        }

        return (
            <ul className="word-list">
                {wordsList}
            </ul>
        );
    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
                err: ["정답을 확인 후 다시 한번", <br />,<strong>테스트</strong>, "하세요."],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                totalScore:0, //pass non-pass 결정 후 점수 초기화
            })
        }


    };

    _render3WordsWithResult = (index) => {
        let wordsList = [];
        for (let i = 0; i < NUM_OF_WORDS; i++) {
            ////console.log(this.state.result);
            let key = (index * NUM_OF_WORDS) + i;
            wordsList.push(
                <li className={"CursorPointer"} key={key} id={key} onClick={() => {
                    this._onWordsClick(key)
                }}>
                    <h4 className="paragraph">{this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}</h4>
                    <p className="paragraph-txt">{this.props.contents[(index * NUM_OF_WORDS) + i].kor_content}</p>
                    <p className="my-txt">
                        <Score
                            scoreType={
                                //ABC로만 출력
                                //this.props.userInfo.score_type
                                1
                            }
                            result={this.state.result[key]}
                            original={this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}
                        />

                    </p>

                </li>)
        }
        // if(this.props.userInfo.score_type === 0){
        //     for (let i = 0; i < NUM_OF_WORDS; i++) {
        //         let key = (index * NUM_OF_WORDS) + i;
        //         wordsList.push(
        //             <li className={"CursorPointer"} key={key} id={key} onClick={() => {
        //                 this._onWordsClick(key)
        //             }}>
        //                 <div className="word">{this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}</div>
        //                 <div className="txt">{this.props.contents[(index * NUM_OF_WORDS) + i].kor_content}</div>
        //                 <div className="score score-blue">
        //                     <Score
        //                         scoreType={
        //                             //ABC로만 출력
        //                             //this.props.userInfo.score_type
        //                             1
        //                         }
        //                         result={this.state.result[key]}
        //                         original={this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}
        //                     />
        //                 </div>
        //
        //             </li>)
        //
        //     }
        //
        //
        // } else {
        //     for(let i = 0 ; i < NUM_OF_WORDS; i ++){
        //         ////console.log(this.state.result);
        //         let key=(index*NUM_OF_WORDS) + i;
        //         wordsList.push(
        //             <li className={"CursorPointer"} key={key} id={key} onClick={() => {
        //                 this._onWordsClick(key)
        //             }}>
        //                 <h4 className="paragraph">{this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}</h4>
        //                 <p className="paragraph-txt">{this.props.contents[(index * NUM_OF_WORDS) + i].kor_content}</p>
        //                 <p className="my-txt">
        //                     <Score
        //                         scoreType={
        //                             //ABC로만 출력
        //                             //this.props.userInfo.score_type
        //                             1
        //                         }
        //                         result={this.state.result[key]}
        //                         original={this.props.contents[(index * NUM_OF_WORDS) + i].eng_content}
        //                     />
        //
        //                 </p>
        //
        //             </li>)
        //     }
        //
        // }

        return (
            this.props.userInfo.score_type === 0 ?
                <ul className="word-list">
                    {wordsList}
                </ul>
                :
                <ul className="result-list">
                    {wordsList}
                </ul>

        );
    };

    //ABC인경우
    // _render3WordsWithResult = (index) => {
    //     //console.log("result is:"+this.state.result);
    //     let wordsList = [];
    //
    //
    //     return (
    //         <ul className="result-list">
    //             {wordsList}
    //         </ul>
    //     );
    // };



    _onWordsClick = (key) => {
        //console.log(key);
        if(this.props.isPlaying && this.state.activatedWords === key){ //음원 재생중 중복 실행 방지
            return;
        }
        this.audioPlay(key, "eng");
        this.setState({
            activatedWords:key,
        })
    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        this.props.setHowler(
            src,
            () => {console.log("end")},
        );
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };





    _start = () => {
        this.setState({isStart: true})
        //this.props.setControlPanel(true);
    };

    _startTest = () => {
        this.props.closeTip();
        this.setState({isTesting: true});
    };


    _renderTest = (index) => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="single-memorize">
                            <h2>{this.props.contents[index].kor_content}</h2>
                        </div>
                        {/*<p className="Err">*/}
                            {/*{this.state.err}*/}
                        {/*</p>*/}
                    </div>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        <div className="tip">기억이 나지 않는 단어가 있다면 <br />뒤로 돌아가 완벽히 암기 후 다시 테스트하세요.</div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_M3W_B
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    goWordsList={this._goWordsList}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                />
            </>
        )

    };


    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // if(calculateScore(result, this.props.contents[index].eng_content) <= FailScore) {
        //     this._decidePass(false);
        // }

        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
            totalScore: this.state.totalScore + calculateScore(result, this.props.contents[index].eng_content),
            errCount:0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 3개 받지 못했으면 wordInfo를 1초후에 다시 갱신함
        if(this.state.count < NUM_OF_WORDS){
            // //console.log("1초 후에 wordInfo 업데이트 됨.");
            // //console.log("현재 count 는: "+this.state.count);
            setTimeout(() => {

                if(this.state.isTesting){
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);

                }
                this.setState({
                    waiting:false,
                });
            }, 1000);
        }

        if(this.state.count >= NUM_OF_WORDS) {
            this._showResult();

            if(isIOS){
                this._decidePass(true);
            } else {
                if(this.state.totalScore / NUM_OF_WORDS < FailScore) {
                    this._decidePass(false);
                } else {
                    this._decidePass(true);
                }
            }
        }
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }



        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }

        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })
    };

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };

    _showResult = () => {
    //결과를 보여주고나면 wordInfo를 초기화 해야함.
        this.setState({
            ...this.state,
            count:0,
            isTesting:false,
            hasResult: true,
            wordInfo:{
                num: (this.state.currentPage - 1) * NUM_OF_WORDS,
                getResult: false,
            },
            waiting:false,
        });

        //console.log("the recorded results are:", this.state.result);
    }

    _goWordsList = () => {
        this.setState({
            isTesting:false,
            err:null,
            wordInfo:{
                num: (this.state.currentPage-1) * NUM_OF_WORDS,
                getResult: false,
            },
            count: 0,
        });
    };






}

//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(Memorize3Words);
export default withContents()(Memorize3Words);

