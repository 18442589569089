import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo, isMobile,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_RES_L6 from "../../Footer_RES_L6";
import axios from 'axios';
import {apiKey} from "../../../../secret/keys";
axios.defaults.headers.common['Authorization'] = apiKey;
class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            err: null,
            errCount: 0,
            pass: false,
            wrongPopShow: false,
            correctPopShow: false,
            alertPopShow: false,
            userAnswer: [], //유저가 맞춘 정답 출력을 위한 변수
            isInitialized: false,
            wordsContents: [],   //단어 콘텐츠
            answerContents: [], //정답 콘텐츠
            wrongContents: [], //오답 콘텐츠
            userInputsIdx: [], // 유저가 선택한 카드 IDX arr
            userInputs: [], //선택한 유저 인풋 출력을 위한 변수
            phase:0,
            hint: {
                eng:"",
                kor:"",
            },
            alertMsg:"",
            wrongIdx:null,
            totalStar:0,


        };

        this.mTimeout = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //initialize
        if (nextProps.contents && !prevState.isInitialized) {
            let wordsContents = [];
            let answerContents = [];
            let wrongContents = [];

            for (let i = 0; i < nextProps.contents.length; i++) {
                if (nextProps.contents[i].c_code_no === "1") { // c_code_no === 1 >> 단어 콘텐츠
                    wordsContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "2") { ///c_code_no === 2 >>정답 콘텐츠
                    answerContents.push(nextProps.contents[i]);
                } else if (nextProps.contents[i].c_code_no === "3"){ //오답 콘텐츠
                    wrongContents.push(nextProps.contents[i])
                }
            }

            return ({
                isInitialized: true,
                wordsContents: wordsContents,
                answerContents: answerContents,
                wrongContents: wrongContents
            })
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevState.phase === 0 && this.state.phase === 1) {
            Promise.all([
                axios({
                    method: 'put',
                    url: `${this.props.apiUrl}/users/star/I/${this.state.userAnswer.length}/${this.props.serverData.mem_idx}/S/M/${this.props.lessonInfo.lesson_idx}/${this.props.currentMenu}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ]).then(([resContents]) => {
                //기록 완료 후 별 개수 조회
                Promise.all([
                    axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/users/star/${this.props.serverData.mem_idx}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    })
                ]).then(([resContents]) => {
                    //별개수 기록
                    const star = resContents.data.star;
                    //console.log("API에서 받아온 star:", star);
                    this.setState({
                        totalStar:star,
                    });
                })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });

            })
            .catch(function (err) {
                //console.log(err);
            })
            .then(function () {

            });

        }


    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.phase === 1){
            return(
                this._renderResult()
            );
        }


        return (
            this._renderMain()
        )


    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start">
                        <div className="top-tip">다음 단어들 중 최소 3가지를 사용하여 올바른 문장을 만들어 보세요.</div>
                        {this._renderStars()}

                        <div className="test-question">
                            <div className="option-wrap style2">
                                {this._renderWords()}
                            </div>
                            <div className="answer-wrap style2">
                                <ul className="sorting-card">
                                    {this._renderUserInput()}

                                </ul>
                            </div>
                            <div className="H15"/>
                            <button
                                type="button"
                                className="btn-ok"
                                onClick={() => this._checkCorrect()}
                            >
                                정답체크
                            </button>
                            <div className={isMobile ? "H5" : "H45"}/>
                            {this._renderUserResult()}
                        </div>

                    </div>


                    <div className="alert-overlap">
                        <div className="mask" onClick={() => this._handleAlertPop(false)} />
                        <div className="alert-wrap">
                            <h2 className="tit">{this.state.alertMsg}</h2>
                        </div>
                    </div>

                    <div className="alert-correct">
                        <div className="mask" onClick={() => this._handleCorrectPop(false)} />
                        <div className="alert-wrap">
                            <h2 className="tit">정답입니다.</h2>
                            <h4 className="tit1">{this.state.hint.eng}</h4>
                            <p className="tit2">{this.state.hint.kor}</p>
                        </div>
                    </div>

                    <div className="alert-wrong">
                        <div className="mask" onClick={() => this._handleWrongPop(false)} />
                        <div className="alert-wrap">
                            <h2 className="tit">틀렸습니다.</h2>
                            <h4 className="tit1">다시 생각해 보세요!</h4>
                            <p className="tit2">{this.state.wrongIdx !== null ? this.state.wrongContents[this.state.wrongIdx].tpk_content : ""}</p>
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            다음 단어들을 사용하여 <br/>
                            <strong>올바른 문장</strong>을 만들어 보세요.
                            <div className="divider"/>
                            <div className="tip">
                                문장을 많이 만들수록<br/>
                                더 <strong>많은 별</strong>을 획득할 수 있습니다.
                            </div>
                        </div>
                    </div>

                </section>
                <Footer_RES_L6
                    {...this.props}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                    phase={this.state.phase}
                    errCount={this.state.errCount}

                />

            </>

        );
    };

    _renderResult = () => {

        return (
            <>
                <section className="view-body tip-opened">
                    <div className={isMobile ? "content-wrap justify-start":"content-wrap"}>
                        <div className="top-tip">맞추신 정답 외 다른 정답들도 확인해 보세요.</div>
                        {this._renderStars()}

                        {isMobile ? <div className={"H55"} /> : "" }
                        <div className="test-question">
                            {this._renderAnswers()}

                        </div>

                    </div>

                    <button
                        type="button"
                        className="btn-tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <span />
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            맞추신 정답 외 <strong>다른 정답들</strong>도 확인해 보세요.
                            <div className="tip">
                                한번 별을 획득한 과에서는 또 획득할 수 없습니다.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_RES_L6
                    {...this.props}
                    pass={this.state.pass}
                    handlePhase={this._handlePhase}
                />

            </>

        );
    };

    _handleIsComplete = (bool) => {
        this.setState({
            isComplete: bool,
        })
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };

    _handleWrongPop = (isShow) => {
        if (isShow) {
            //console.log("오답 팝업 출력");
            this.setState({
                wrongPopShow: isShow
            });

            $('.alert-wrong').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
                if (this.state.wrongPopShow) {
                    $('.alert-wrong').fadeOut('fast');
                    this.setState({
                        wrongPopShow: false,
                    })
                }
            }, 4000)
        } else {
            //console.log("오답 팝업 종료");
            if (this.state.wrongPopShow) {
                $('.alert-wrong').fadeOut('fast');
                this.setState({
                    wrongPopShow: false,

                });
                clearTimeout(this.mTimeout);

            }
        }
        //alert("오답");

        // if (isShow) {
        //     this.setState({
        //         alertPopShow: isShow,
        //     });
        //     $('.alert-overlap').fadeIn('fast');
        //
        //     this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
        //         if (this.state.alertPopShow) {
        //             $('.alert-overlap').fadeOut('fast');
        //             this.setState({
        //                 alertPopShow: false,
        //
        //             })
        //         }
        //     }, 2000)
        //
        // } else {
        //     if (this.state.alertPopShow) {
        //         $('.alert-overlap').fadeOut('fast');
        //         this.setState({
        //             alertPopShow: false,
        //             // hint:{ //힌트 초기화
        //             //     kor:null,
        //             //     eng:null,
        //             // }
        //         });
        //         clearTimeout(this.mTimeout);
        //         // this.mTimeout = null;
        //     }
        // }


    }

    _handleCorrectPop = (isShow) => {

        if (isShow) {
            this.setState({
                correctPopShow: isShow,
                pass: true, //통과
            });
            $('.alert-correct').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
                if (this.state.correctPopShow) {
                    $('.alert-correct').fadeOut('fast');
                    this.setState({
                        correctPopShow: false,

                    })
                }
            }, 2000)


        } else {
            if (this.state.correctPopShow) {
                $('.alert-correct').fadeOut('fast');
                this.setState({
                    correctPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
               // this.mTimeout = null;
            }
        }

    };

    _handleAlertPop = (isShow, msg) => {

        if (isShow) {
            this.setState({
                alertPopShow: isShow,
                alertMsg: msg,
            });
            $('.alert-overlap').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //2초후에 자동으로 팝업 종료
                if (this.state.alertPopShow) {
                    $('.alert-overlap').fadeOut('fast');
                    this.setState({
                        alertPopShow: false,

                    })
                }
            }, 2000)

        } else {
            if (this.state.alertPopShow) {
                $('.alert-overlap').fadeOut('fast');
                this.setState({
                    alertPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
               // this.mTimeout = null;
            }
        }
    };


    _renderStars = () => {
        let stars = [];
        if (this.state.phase === 1) {
            stars.push(
                <li className="star-info">나의 별 : <i className="icon-star"/>
                    <strong>{this.state.totalStar}</strong>개
                    {/*나중에 db에서 별 개수를 가져와야함*/}
                </li>
            )
        } else {
            for (let i = 0; i < 5; i++) {
                stars.push(
                    <li
                        className={this.state.userAnswer.length > i ? "star star-score" : "star star-empty"}
                        key={i}
                    >
                    </li>
                )
            }
        }


        return (
            <ul className="top-score">
                {stars}
            </ul>
        )

    }

    _renderWords = () => {
        let words = [];

        for (let i = 0; i < this.state.wordsContents.length; i++) {
            words.push(
                <li
                    className={this.state.userInputsIdx.indexOf(i) === -1 ? "s-card" : "s-card empty"}
                    key={i}
                    onClick={() => {
                        if(this.state.userInputsIdx.indexOf(i) !== -1) {
                            return;
                        }
                        this._selectCards(i);
                    }}

                >
                    {this.state.wordsContents[i].eng_content}
                </li>
            )
        }

        return (
            <ul className="sorting-card">
                {words}
            </ul>
        )
    }

    _renderUserResult = () => {
        let answers = [];

        for (let i = 0; i < this.state.userAnswer.length; i++) {
            answers.push(
                <li key={i}>
                    <strong>
                        {this.state.answerContents[this.state.userAnswer[i]].eng_content}
                    </strong>
                    ({this.state.answerContents[this.state.userAnswer[i]].kor_content})
                </li>
            )
        }

        return (
            <ul className="my-answer-list">
                {answers}
            </ul>

        )
    }


    _renderUserInput = () => {
        let userInputs = [];
        for (let i = 0; i < this.state.userInputsIdx.length; i++) {
            if (i === this.state.userInputsIdx.length - 1) { //마지막단어에는 Remove버튼 추가
                userInputs.push(
                    <li className="s-card correct" key={i}>
                        <button
                            type="button"
                            className="btn-remove-card"
                            onClick={() => this._cancelSelect(this.state.userInputsIdx[i])}
                        >
                            제거
                        </button>
                        {this.state.wordsContents[this.state.userInputsIdx[i]].eng_content}
                    </li>
                )
            } else {
                userInputs.push(
                    <li className="s-card correct" key={i}>
                        {this.state.wordsContents[this.state.userInputsIdx[i]].eng_content}
                    </li>
                )
            }
        }

        return (
            <ul className="sorting-card">
                {userInputs}
            </ul>
        )

    }

    _selectCards = (idx) => {
        this.props.closeTip();

        if(this.state.userInputsIdx.indexOf(idx) !== -1){ //idx값이 이미 존재하면 return
            return;
        }

        let userInputsIdx = this.state.userInputsIdx;
        userInputsIdx.push(idx);

        this.setState({
            userInputsIdx
        })
    }

    _cancelSelect = (idx) => {
        let userInputsIdx = this.state.userInputsIdx;
        userInputsIdx.splice(userInputsIdx.indexOf(idx), 1);

        this.setState({
            userInputsIdx
        })
    }

    _checkCorrect = () => {
        if(this.state.userInputsIdx.length < 3){
            //alert("단어를 3개 이상 사용하여 문장을 만들어 주세요.");
            this._handleAlertPop(true, "단어를 3개 이상 사용하여 문장을 만들어 주세요.");
            return;
        }

        let userString = "";
        for (let i = 0; i < this.state.userInputsIdx.length; i++) {
            userString += this.state.wordsContents[this.state.userInputsIdx[i]].eng_content;
        }

        //console.log("User Answer :", userString);
        let trimmedAnswer = userString.replace(/[^(a-zA-Z|0-9)]/gi, "");
        //console.log("trimmed Answer :", trimmedAnswer);
        for (let i = 0; i < this.state.answerContents.length; i++) {
            if (trimmedAnswer.toUpperCase() === this.state.answerContents[i].eng_content.replace(/[^(a-zA-Z|0-9)]/gi, "").toUpperCase()) { //정답이 확인되면 break;
                let userAnswer = this.state.userAnswer;
                if (userAnswer.indexOf(i) === -1) { //중복정답이 아니면 정답 처리
                    this._handleCorrectPop(true);
                    userAnswer.push(i);
                    this.setState({
                        userAnswer,
                        userInputsIdx: [], // 유저가 선택한 카드 IDX arr 초기화
                        userInputs: [], //선택한 유저 인풋 출력을 위한 변수 초기화
                        hint:{
                            eng:this.state.answerContents[i].eng_content,
                            kor:this.state.answerContents[i].kor_content
                        }
                    });

                    break;
                } else {
                    //console.log("이미 입력한 정답입니다.")
                    this._handleAlertPop(true, "이미 입력한 정답입니다.");
                    this.setState({
                        userInputsIdx: [], // 유저가 선택한 카드 IDX arr 초기화
                        userInputs: [], //선택한 유저 인풋 출력을 위한 변수 초기화
                    })

                    break;
                }

            } else { //정답이 아닌경우


                if(i === this.state.answerContents.length - 1) {
                    //마지막 까지 정답이 없으면

                    for(let j = 0; j < this.state.wrongContents.length; j ++){
                        if(trimmedAnswer.toUpperCase() === this.state.wrongContents[j].eng_content.replace(/[^(a-zA-Z|0-9)]/gi, "").toUpperCase()) { //오답 설명 콘텐츠가 있다면 wrongIdx 입력
                            this.setState({
                                wrongIdx: j
                            });
                            break;

                        }

                        if(j === this.state.wrongContents.length - 1){ //오답콘텐츠에 없는거라면 wrongIdx 초기화
                            this.setState({
                                wrongIdx: null,
                            })
                        }
                    }

                    //console.log("오답입니다.");
                    this._handleWrongPop(true);
                    this.setState({
                        userInputsIdx: [], // 유저가 선택한 카드 IDX arr 초기화
                        userInputs: [], //선택한 유저 인풋 출력을 위한 변수 초기화,
                        errCount:this.state.errCount + 1,
                    })
                }

            }
        }
    }

    _handlePhase = (idx) => {
        this.setState({
            phase:idx,
        })

        if(idx === 1){
            this.props.openTip();
        }
    }

    _renderAnswers = () => {
        let answers = [];

        for (let i = 0; i < this.state.answerContents.length; i++) {
            answers.push(
                <li
                    className={this.state.userAnswer.indexOf(i) === -1 ? "" : "line-through"}
                    key={i}
                >
                    <strong>
                        {this.state.answerContents[i].eng_content}
                    </strong>
                    ({this.state.answerContents[i].kor_content})
                </li>
            )
        }
        return (
            <ul className="my-answer-list">
                {answers}
            </ul>
        )
    }
}


export default withContents()(TakeBreak);
    
    