import React from 'react';

const Footer_ISM_A = (props) => {
    return (

        <section className="view-footer">
            {
                props.isPageOnePass ?
                    <button
                        type="button"
                        className="f-btn btn-test"
                        onClick={() =>{
                            props.closeTip();
                            props.handleError(null);
                            props.handlePhase(2);
                        }

                        }>다음메뉴</button>
                    :
                    <button
                        type="button"
                        className="f-btn"
                        >다음메뉴</button>
            }


        </section>


    )
}

export default Footer_ISM_A;
    
    