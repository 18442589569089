import React from 'react';



const Footer_CPH = (props) => {
    return (

        <section className="view-footer">
            <button type="button" className="f-btn">Empty</button>
            {props.isPlaying ?
                <button
                    type="button"
                    className="f-btn f-center btn-listen"
                    >듣는 중</button>
                :
                <button
                    type="button"
                    className="f-btn f-center btn-listen-all"
                    onClick={() => {
                        props.playAll(0);
                        props.closeTip();
                    }}>전체 듣기</button>
            }


            {
                props.isAllPlayingComplete ?    //전체 듣기 완료 후, 다음 버튼 출력
                    <button
                        type="button"
                        className="f-btn btn-next-page"
                        onClick={() => props.goNextMenu()}>다음메뉴</button>
                    :
                    <button type="button" className="f-btn">Empty</button>
            }

        </section>


    )
}



export default Footer_CPH;
    
    