import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_CVP_L3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,

        };

    }

    _testContinue = () => {

        if(isIOS){
            this.props.fakeRecognitionStart();
            setTimeout(() => {this.props.fakeRecognitionEnd()},5000)
        } else {
            this.props.startRecognition();
        }

        this.props.closeTip();
    };

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.isTesting && nextProps.letStartRecognition && !nextProps.listening){
            //console.log("음성인식 시작");
            if(isIOS){
                nextProps.fakeRecognitionStart();
                setTimeout(() => {nextProps.fakeRecognitionEnd()},5000)
            } else {
                nextProps.startRecognition();
            }
            nextProps.handleLetStartRecognition(false);
        }

        return null;
    }


    // componentDidMount(){
    //     this._test3Words();
    // }
    componentDidUpdate() {

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);
        if (!this.props.isTesting) {
            return (
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn"
                    >
                        Empty
                    </button>
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.startTest()
                        }}
                    >
                        TEST
                    </button>
                    {
                        this.props.pass ?
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {
                                    this.props.goNextMenu()
                                }}
                            >
                                Next
                            </button>
                            :
                            <button
                                type="button"
                                className="f-btn"
                            >
                                Empty
                            </button>
                    }
                </section>
            )
        }

        return (

            <section className="view-footer">


                {
                    this.props.isPlaying ?
                        <button type="button" className="f-btn f-center btn-listen"/>
                        :

                        this.props.listening ? (
                                <MicVisualizer/>
                            )
                            :
                            (
                                this.props.waiting ?
                                    <button type="button" className="f-btn">Empty</button>
                                    :
                                    this.props.err === null ?

                                        <button type="button" className="f-btn">Empty</button>
                                        :
                                        <>

                                            <button type="button" className="f-btn">
                                                Empty
                                            </button>
                                            <button
                                                type="button"
                                                className="f-btn f-center btn-test"
                                                onClick={() => {
                                                    this._testContinue()
                                                }}>
                                            </button>
                                            <button type="button" className="f-btn">Empty</button>
                                        </>
                            )
                }

            </section>

        );
    }

}

export default withRecognizer()(Footer_CVP_L3);
    
    