import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_RDT_A from '../../Footer_RDT_A';
import Footer_RDT_B from '../../Footer_RDT_B';
import {
    brTagActivator,
    calculateScore,
    FailScore,
    getCurrentMenuInfo, isIOS,
    uploadScore
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import { TweenLite } from 'gsap/TweenLite';
import $ from 'jquery';
import {scroller, Element} from "react-scroll/modules/index";



class ReadingTestInL6 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            thisMenuNumber: 79,
            isStart: false,
            isTesting: false,
            listening: false, //음성인식 중인지 확인하는 state. 에러 메시지 출력을 위해
            hasResult: false,
            result:{
            },
            uploadComplete: false,
            selectedParagraph: 0,
            count: 0,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            isEndTest: false,
            pass: false,
            totalScore: 60,
            tryCount:0,
            minDuration:0,
            err:null,
            errCount:0,
            waiting:false,
        };

    }

    componentDidUpdate(prevProps, prevState){

        if(this.state.hasResult && !prevState.hasResult) {
            TweenLite.to("#score-poplayer", 1, {
                y:0,
                delay:0,
                yPercent:0,
                opacity:1
            });
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.currentMenu !== prevState.thisMenuNumber){
            return({
                index: 0,
                thisMenuNumber: nextProps.currentMenu,
                isStart: false,
                isTesting: false,
                hasResult: false,
                result:{
                },
                uploadComplete: false,
                selectedParagraph: 0,
                count: 0,
                wordInfo: {
                    num: 0,
                    getResult: false,
                },
                isEndTest: false,
                pass: false,
                totalScore: 60,
                minDuration:0,
            })
        }

        if(nextProps.contents !== null && prevState.minDuration === 0) {
            return({
                minDuration:nextProps.contents[0].eng_content.length*80,
            })
        }
        return null;



    }

    render() {
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        if(this.state.isEndTest && !this.state.isTesting && this.state.hasResult) { //테스트 종료 후 결과가 있으면 결과 출력함.
            return (
                this._renderResult()
            )
        }

        return (
            this._renderMain()
        );

    }
    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                {/*{JSON.stringify(this.props.contents)}*/}
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>

                    <Element className="content-wrap align-start HideScrollBar" id={"paragraphContainer"}>

                        <ul className="recording-list">
                            {this._renderReadingList(this.props.contents)}
                        </ul>
                    </Element>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    <>
                                        리딩테스트를 시작합니다. 준비가 되셨다면<br />
                                        TEST버튼을 누른 후 "삐" 소리와 함께<br />
                                        <strong>선택된 문장</strong>을 영어로 읽으세요.
                                        <hr className="divider" />
                                        <div className="tip">
                                            녹음이 도중에 멈춘다면<br />
                                            조금 더 빠르게 읽어주세요.
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>



                </section>
                {
                    this.state.isTesting ?
                        <Footer_RDT_B
                            {...this.props}
                            c_code={this.props.c_code}
                            result={this.state.result}
                            setResult={this._setResult}
                            isTesting={this.state.isTesting}
                            //index={this.state.index}
                            wordInfo={this.state.wordInfo}
                            minDuration={this.state.minDuration}
                            handleError={this._handleError}
                            isListening={this._isListening}
                            waiting={this.state.waiting}
                            error={this.state.err}
                        />
                        :
                        <Footer_RDT_A
                            {...this.props}
                            c_code={this.props.c_code}
                            result={this.state.result}
                            startTest = {this._startTest}
                            setResult={this._setResult}
                            isTesting={this.state.isTesting}
                            //index={this.state.index}
                            wordInfo={this.state.wordInfo}
                            pass={this.state.pass}
                            minDuration={this.state.minDuration}
                            handleError={this._handleError}
                        />
                }

            </>

        );
    };

    _renderReadingList = (paragraphs) => {
        return(
            paragraphs.map((paragraph, index) => {
                ////console.log(paragraph);
                return (
                    <li key={paragraph.contents_idx} className={this.state.selectedParagraph === paragraph.contents_idx ? "active" : ""}>
                        <Element name={`paragraph${index}`}>
                            <p>{paragraph.eng_content}</p>
                        </Element>
                    </li>
                )
            })
        );

    };

    _renderResult = () => {
        return(
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="score-poplayer" id="score-poplayer">
                            <div className="content">
                                <p>
                                    <span className="sr-only">SCORE</span>
                                    <strong>
                                        {isIOS ? "Pass" : this.state.totalScore}
                                    </strong>
                                </p>
                                <button type={"button"} className="btn-answer CursorPointer" onClick={() => {
                                    $('.speak-poplayer').wPopLayer();
                                }}>해석보기
                                </button>
                            </div>
                        </div>

                        <p className="score-help-block">

                        </p>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">

                            {
                                this.state.err === null ?
                                    <>
                                        참 잘하셨어요. 더 좋은 점수를 원하신다면<br/>
                                        다시 <strong>테스트</strong> 해보세요.
                                        <hr className="divider" />
                                        <div className="tip">
                                            녹음이 도중에 멈춘다면<br/>
                                            조금 더 빠르게 읽어보세요. <br />
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>


                    <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                        <h4>Reading Test 해석</h4>
                        <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                        <ul className="speak-list">
                            {this._renderAnswer()}
                        </ul>
                    </div>


                </section>

                <Footer_RDT_A
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    hasResult={this.state.hasResult}
                    startTest = {this._startTest}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    index={this.state.index}
                    wordInfo={this.state.wordInfo}
                    pass={this.state.pass}
                    minDuration={this.state.minDuration}
                    handleError={this._handleError}
                />

            </>
        )


    };

    _renderAnswer = () => {
        let answers = [];
        for(let i = 0; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p>
                        {this.props.contents[i].kor_content} <br />
                        <span>{this.props.contents[i].eng_content}</span>
                    </p>
                    <button type={"button"} onClick={() => this.audioPlay(i)} className={"btn-play-sound CursorPointer"}>발음듣기</button>
                </li>
            )
        }
        return (answers);

    };


    _start = () => {
        this.setState({
            isStart: true,
            err:null,
        })
        //this.props.setControlPanel(true);
    };

    // _audioPlay = (i) => {
    //     if(this.props.isPlaying){
    //         return;
    //     }
    //     this.props.audioPlay("eng", i)
    //
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _startTest = () => {
        this.props.closeTip();
        this.setState({
            isTesting: true,
            isEndTest: false, //테스트 할 때 변수 초기화
            result:{
            },
            err:null,
            waiting:false,
            errCount:0,
            index: 0,
            hasResult: false,
            selectedParagraph: this.props.contents[0].contents_idx,
            count: 0,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            totalScore: 0,
        });
    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        ////console.log("Reading Test Component did received result. index is: "+index+ " and result is " + result);

        if(this.state.result[index] !== undefined){ //결과값이 이미 있으면 리턴
            //console.log("결과값이 이미 있어 리턴");
            return;
        }

        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
        });

        //결과를 받았으니, paragraphInfo 업데이트함.
        this._updateWordInfo(index, true);

        //하지만 결과를 N개 받지 못했으면 paragraphInfo 1초후에 다시 갱신함
        if(this.state.count < this.props.contents.length){
            ////console.log("1초 후에 paragraphInfo 업데이트 됨.");
            ////console.log("현재 count 는: "+ this.state.count);
            setTimeout(() => {
                ////console.log("paragraphInfo 업데이트!", this.state.wordInfo.num + 1);
                this._updateWordInfo(this.state.wordInfo.num + 1, false);
                this.setState({
                    selectedParagraph: this.props.contents[this.state.wordInfo.num].contents_idx,
                    err:null,
                    waiting:false,
                })
            }, 1000);
        } else {
            let totalScore = isIOS ? 0 : this._calculateTotalScore();
            uploadScore(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);
            let pass = totalScore  >= FailScore || isIOS;
            this._decidePass(pass);

            this._showResult();
        }

    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            //console.log("Decide Pass: Fail but Pass");
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });

            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            //console.log("Decide Pass: Fail. Retry");
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: [`${FailScore}점 이상이어야 통과할 수 있습니다.`,<br />,"다시 ", <strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip"> <strong>정답 보기</strong>를 클릭하여<br /> 충분히 연습 후 테스트 해보세요.</div>],
            })
        } else {
            //console.log("Decide Pass: Pass");
            this.props.openTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };


    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },
            minDuration:this.props.contents[this.state.wordInfo.num].eng_content.length*80,
            errCount:0,
        });
        if(num >= 5){
            scroller.scrollTo(`paragraph${num-2}`,{
                duration:800,
                delay:0,
                smooth:true,
                containerId: 'paragraphContainer',
                offset:0,
            });
        }
    };

    _showResult = () => {

        this.props.openTip();

        this.setState({
            ...this.state,
            isTesting:false,
            hasResult: true,
            isEndTest: true,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            waiting:false,
        });
    };


    _calculateTotalScore(){
        let sum = 0;
        for(let i = 0 ; i < this.props.contents.length; i ++){
            ////console.log(`${i} 번째 결과는: ${this.state.result[i]}. Original은 : ${this.props.contents[i].eng_content}`);
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);
        }

        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / this.props.contents.length);
        }

        this.setState({
            totalScore:totalScore
        });

        return totalScore;
    }

    _handleError = (err) => {
        ////console.log(`Handle Error : ${err}`);
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }

        if(this.state.errCount >= 1){
            this._setResult('##ERROR', this.state.wordInfo.num); //에러코드를 setResult를 통해 결과로 입력시킴

            return;
        }


        this.props.openTip();

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
        })

    };


    _isListening = (bool) => {
        this.setState({
            listening:bool,
        })
    }



}


export default withContents()(ReadingTestInL6);

