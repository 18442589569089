import React, {Component} from 'react';
import withContents from "../../withContents";
import LoadingBar from '../../../other/LoadingBar';
import {brTagActivator, getCurrentMenuInfo, isMobile} from "../../../../asset/js/myFunctions";
import Footer_CPH from "../../Footer_CPH";
import {scroller, Element} from "react-scroll/modules/index";


class Comprehension extends Component {

    constructor(props){
        super(props);
        this.state = {
            index: 0,
            thisMenuNumber: 102,
            isStart: false,
            playingIndex:0,
            isAllPlayingComplete:false,
        }

    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     // if(nextProps.playingIndex !== prevState.playingIndex){
    //     //     scroller.scrollTo(`paragraph${nextProps.playingIndex}`,{
    //     //         duration:800,
    //     //         delay:0,
    //     //         smooth:true,
    //     //         containerId: 'answerContainer',
    //     //         offset:-300,
    //     //
    //     //     });
    //     //     return {playingIndex: nextProps.playingIndex};
    //     // }
    //     return null;
    // }


    render(){
        if(!this.props.contents){
            return(<LoadingBar />);
        }

        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }

        return(
            this._renderMain()
        )
    }

    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({
            isStart: true,
        })
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <Element className="content-wrap align-start HideScrollBar" id={"answerContainer"}>

                        <ul className="listening-list">
                            {this._renderComprehension()}
                        </ul>
                    </Element>
                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle" onClick={this.props.initTipToggle}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            전체 듣기를 눌러 <strong>원어민 소리</strong>와 <strong>뜻</strong>을 확인해보세요.<br/>
                            <div className="divider" />
                            <div className="tip">
                                전체 듣기를 통해 모두 들어야<br/>
                                다음 메뉴로 넘어갈 수 있습니다.
                            </div>
                        </div>
                    </div>
                </section>
                <Footer_CPH
                    {...this.props}
                    c_code={this.props.c_code}
                    playAll={this._playAll}
                    isAllPlayingComplete={this.state.isAllPlayingComplete}


                />

            </>
        )

    }

    _renderComprehension = () => {
        let list = [];
        for(let i = 0 ; i < this.props.contents.length; i++){
            list.push(

                <li className={this.state.playingIndex === i ? "active" : ""} key={this.props.contents[i].contents_idx}>
                    <Element name={`paragraph${i}`}>
                        <h2>{this.props.contents[i].eng_content}</h2>
                        <p>{this.props.contents[i].kor_content}</p>
                        <button type="button" className="btn-play-sound" onClick={() => this.audioPlay(i)}/>
                    </Element>

                </li>

            )
        }

        return (
            list
        )
    }

    _playAll = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        //Recursive
        if(index === 0){
            this.props.closeTip();

            this.setState({
                //isAllPlayingComplete: false,
                playingIndex:0,
                isPlaying:true,
            });


        } else if(index > this.props.contents.length - 1) {
            this.setState({
                isAllPlayingComplete: true,
                isPlaying:false,
            })
            return;
        } else {
            this.setState({
                playingIndex:index
            });
        }

        scroller.scrollTo(`paragraph${index}`,{
            duration:800,
            delay:0,
            smooth:true,
            containerId: 'answerContainer',
            offset:isMobile ? -150 : -300,

        });

        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;
        // this.props.setHowler(
        //     src,
        //     () => {
        //         this._playAll(index + 1);
        //     },
        // );

        this.props.setHowlerError(
            src,
            () => {
                this._playAll(index + 1);
            },
            () => {
                console.log("on load error");
                this.props.setSrc(null);
                setTimeout(() => {
                    this._playAll(index + 1);
                }, 1000)
            },

        )
    }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
        this.setState({
            playingIndex:index,
        })
    }


}

export default withContents()(Comprehension);