import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo,
    isMobile,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_RES_L3 from "../../Footer_RES_L3";
import axios from "axios/index";
import {apiKey} from "../../../../secret/keys";
axios.defaults.headers.common['Authorization'] = apiKey;
class TakeBreak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            result: {},
            err: null,
            errCount: 0,
            pass: false,
            noStar:false,
            wrongPopShow: false,
            // correctPopShow:false,
            hint: {
                eng: null,
                kor: null,
            },
            selectedIdx: null,
            selectedAnswerIdx: null,
            numOfUsedHints: 0,
            heardList: [],
            usedHintList: [],
            userAnswer: null,
            isAlreadyGetStar: false,
            totalStar:0,
        };

        this.mTimeout = null;

    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevState.pass && this.state.pass && !this.state.noStar){
            Promise.all([
                axios({
                    method: 'put',
                    url: `${this.props.apiUrl}/users/star/I/${5-this.state.numOfUsedHints}/${this.props.serverData.mem_idx}/S/M/${this.props.lessonInfo.lesson_idx}/${this.props.currentMenu}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ]).then(([resContents]) => {
                //기록 완료 후 별 개수 조회
                Promise.all([
                    axios({
                        method: 'get',
                        url: `${this.props.apiUrl}/users/star/${this.props.serverData.mem_idx}`.replace(/\s/g, ''),
                        data: {
                            apiKey: apiKey,
                        }
                    })
                ]).then(([resContents]) => {
                    //별개수 기록
                    const star = resContents.data.star;
                    //console.log("API에서 받아온 star:", star);
                    this.setState({
                        totalStar:star,
                    });
                })
                    .catch(function (err) {
                        //console.log(err);
                    })
                    .then(function () {

                    });

            })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });
        } else if(!prevState.pass && this.state.pass && this.state.noStar) {
            Promise.all([
                axios({
                    method: 'get',
                    url: `${this.props.apiUrl}/users/star/${this.props.serverData.mem_idx}`.replace(/\s/g, ''),
                    data: {
                        apiKey: apiKey,
                    }
                })
            ]).then(([resContents]) => {
                //별개수 기록
                const star = resContents.data.star;
                //console.log("API에서 받아온 star:", star);
                this.setState({
                    totalStar:star,
                });
            })
                .catch(function (err) {
                    //console.log(err);
                })
                .then(function () {

                });
        }

    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height: this.props.bodyHeight - this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={
                        this.state.pass ?
                            isMobile ?
                                "content-wrap justify-start" :
                                "content-wrap" :
                            "content-wrap align-start"
                    }>
                        {
                            this.state.pass ?
                                <>
                                    {this._renderStars()}

                                    < div className="five-game-result">
                                        < h2> 정답입니다.</h2>
                                        <div className="H10"/>
                                        <p>{this.props.contents[0].t_answer}, {this.props.contents[0].t_question}</p>
                                    </div>
                                    {this._renderAnswer()}

                                    <button type="button" className="star-achieve MT25">
                                        {
                                            this.state.isAlreadyGetStar ?
                                                <p className="achieve-message MT5">이미 학습한 레슨입니다.</p>
                                                :
                                                !this.state.noStar &&
                                                <>
                                                    <div className="stars">
                                                        {this._renderGetStars()}
                                                    </div>
                                                    <p className="achieve-message MT5">별 {5 - this.state.numOfUsedHints}개를
                                                    획득 했습니다.</p>
                                                </>
                                        }
                                    </button>
                                </>
                                :
                                <>
                                    <div className="top-tip">다음 다섯가지 소리를 듣고, 정답을 맞춰보세요.</div>

                                    {this._renderStars()}
                                    <div className="five-game">
                                        <h2 className="tit">{this.props.contents[0].eng_content}</h2>
                                        {this._renderHints()}
                                        <input
                                            className="input-answer"
                                            placeholder="한글 또는 영어로 정답 입력"
                                            onChange={this._handleInputChange}
                                        />
                                    </div>

                                    <div className={this.state.wrongPopShow ? "alert-wrong display" : "alert-wrong"}>
                                        <div className="mask" onClick={() => {
                                            this._handleWrongPop(false)
                                        }}/>
                                        <div className="alert-wrap">
                                            <h2 className="tit">다시 한번 생각해 보세요</h2>
                                            <h4 className="tit1">{this.state.hint.eng}</h4>
                                            <p className="tit2">{this.state.hint.kor}</p>
                                        </div>
                                    </div>
                                </>

                        }


                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.pass ?
                                    <>
                                        획득한 <strong>별</strong>을 모아서 캐릭터를 구매할 수 있습니다.<br/>
                                        <strong>마이스터디 > 캐릭터</strong>을 확인해 보세요.
                                        <div className="tip">
                                            한번 별을 획득한 과에서는 또 획득할 수 없습니다.
                                        </div>
                                    </>
                                    :
                                    this.state.err === null ?
                                        <>
                                            <div className="tip">
                                                <strong>힌트</strong>를 사용하면 별 개수가 줄어드니 <br/>
                                                신중히 사용하세요.
                                            </div>
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }

                        </div>
                    </div>
                </section>
                <Footer_RES_L3
                    {...this.props}
                    pass={this.state.pass}
                    wrong={this.state.errCount >= 4}
                    checkCorrect={this._checkCorrect}
                    passWithoutStar={this._passWithoutStar}
                />

            </>

        );
    };


    _handleWrongPop = (isShow) => {
        if (isShow) {
            this.setState({
                wrongPopShow: isShow
            });
            $('.alert-wrong').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.wrongPopShow) {
                    $('.alert-wrong').fadeOut('fast');
                    this.setState({
                        wrongPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 4000)
        } else {
            if (this.state.wrongPopShow) {
                $('.alert-wrong').fadeOut('fast');
                this.setState({
                    wrongPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    }

    _handleCorrectPop = (isShow) => {
        if (isShow) {
            this.setState({
                correctPopShow: isShow
            });
            $('.alert-correct').fadeIn('fast');

            this.mTimeout = setTimeout(() => { //4초후에 자동으로 팝업 종료
                if (this.state.correctPopShow) {
                    $('.alert-correct').fadeOut('fast');
                    this.setState({
                        correctPopShow: false,
                        // hint:{ //힌트 초기화
                        //     kor:null,
                        //     eng:null,
                        // }
                    })
                }
            }, 4000)
        } else {
            if (this.state.correctPopShow) {
                $('.alert-correct').fadeOut('fast');
                this.setState({
                    correctPopShow: false,
                    // hint:{ //힌트 초기화
                    //     kor:null,
                    //     eng:null,
                    // }
                });
                clearTimeout(this.mTimeout);
            }
        }

    };

    _checkCorrect = () => {
        if (this.state.userAnswer == null) {
            //유저 입력값이 없으면 리턴
            return;
        }

        if (this.state.userAnswer.toLowerCase().replace(/\s/g, '') === this.props.contents[0].t_question.toLowerCase().replace(/\s/g, '') || this.state.userAnswer.toLowerCase().replace(/\s/g, '') === this.props.contents[0].t_answer.toLowerCase().replace(/\s/g, '')) {
            this.setState({
                pass: true,
            });
            this.props.closeTip();
            //this._handleCorrectPop(true);
        } else {
            if (this.state.errCount >= 3) { //여러번 틀리면 정답 알려주기
                this.props.openTip();
                this.setState({
                    err: ["정답을 맞추지 않고 넘어가시면 별을 획득하실 수 없습니다."],
                })

            }
            //console.log("오답");
            this._handleWrongPop(true);
            this.setState({
                errCount: this.state.errCount + 1,
            })
        }

    };

    _renderStars = () => {
        let stars = [];
        if (this.state.pass) {
            stars.push(
                <li className="star-info">나의 별 : <i className="icon-star"/>
                    <strong>{this.state.totalStar}</strong>개
                    {/*나중에 db에서 별 개수를 가져와야함*/}
                </li>
            )
        } else {
            for (let i = 0; i < 5; i++) {
                stars.push(
                    <li className={this.state.numOfUsedHints > i ? "star star-empty" : "star star-score"}>
                    </li>
                )
            }
        }


        return (
            <ul className="top-score">
                {stars}
            </ul>
        )

    }

    _renderHints = () => {
        let hints = [];
        for (let i = 1; i < this.props.contents.length; i++) {  //i=0일때는 QUESTION 임.
            hints.push(
                <li className={this.state.usedHintList.includes(i) ? "tip-opened" : ""}>
                    <span className="num">{i}.</span>

                    <button
                        type="button"
                        className="btn btn-play-this MR5"
                        onClick={() => {
                            this._audioClick(i)
                        }}
                    >음원듣기
                    </button>
                    <p className="hint">{this.props.contents[i].eng_content}<span>&nbsp;&nbsp;</span>
                        <small>{this.props.contents[i].kor_content}</small>
                    </p>
                    {

                        this.state.heardList.includes(i) ?
                            <button
                                type="button"
                                className="btn btn-use-hint"
                                onClick={() => {
                                    this._useHint(i)
                                }}
                            >
                                힌트 사용 (점수 -1)
                            </button>
                            :
                            ""
                    }
                </li>
            )
        }

        return (
            <ul>
                {hints}
            </ul>
        )
    }

    _audioClick = (idx) => { //idx 는 1부터 시작해야함.
        // //console.log(this.state.heardList.includes(idx));
        this.props.closeTip();
        if (this.props.isPlaying) {
            //console.log("이미 음원이 플레이 중입니다.");
            return;
        }

        if (!this.state.heardList.includes(idx)) { //없으면
            let newHeardList = this.state.heardList;
            newHeardList.push(idx);
            ////console.log(typeof newHeardList);
            this.setState({
                heardList: newHeardList,
            });
        }

        // this.props.audioPlay("eng", idx);
        let src = `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].eng_url}`;

        this.props.setHowler(
            src
        );

    };

    _useHint = (idx) => {
        if (!this.state.usedHintList.includes(idx)) {
            let newUsedHintList = this.state.usedHintList;
            newUsedHintList.push(idx);
            this.setState({
                usedHintList: newUsedHintList,
                numOfUsedHints: newUsedHintList.length,
            })
        }
    }

    _handleInputChange = (e) => {
        this.setState({
            userAnswer: e.target.value.toString().toLowerCase(),
        });

    }

    _renderAnswer = () => {
        let answers = [];

        for (let i = 1; i < this.props.contents.length; i++) {
            answers.push(
                <li key={i}
                    className={this.state.selectedAnswerIdx === i ? "selected" : "CursorPointer"}
                    onClick={() => {
                        this._clickAnswer(i);
                    }}>
                    <div className="word">{this.props.contents[i].eng_content}</div>
                    <div className="txt">{this.props.contents[i].kor_content}</div>
                </li>
            )
        }

        return (
            <ul className="result-list2">
                {answers}
            </ul>
        )
    }

    _clickAnswer = (idx) => {
        this.setState({
            selectedAnswerIdx: idx,
        });

        //this._audioPlay("eng", idx);
        this.audioPlay(idx);
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.playingIndex === idx) {
    //         //this.props.audioStop();
    //         return;
    //     } else {
    //         if (this.props.isPlaying) {
    //             this.props.audioStop();
    //         }
    //         this.props.audioPlay(lang, idx);
    //     }
    // };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }


    _renderGetStars = () => {
        let getStars = [];

        for (let i = 0; i < 5 - this.state.numOfUsedHints; i++) {
            getStars.push(<i className="icon-star"/>)
        }

        return (
            <>
                {getStars}
            </>
        )
    }

    _passWithoutStar = () => {
        this.setState({
            pass:true,
            noStar:true,
        })
    }
}


export default withContents()(TakeBreak);
    
    