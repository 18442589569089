import React, {Component} from 'react';
import {
    brTagActivator, calculateScore, FailScore,
    getCurrentMenuInfo, isIOS,
    uploadScoreAl,
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import $ from "jquery";
import Footer_Accumulate from "../../Footer_Accumulate";
import {TweenLite} from "gsap/TweenLite";

class Accumulate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            phase: 0,
            currentIdx: 0,
            isPlayingAudio: false, //렌더링에 사용하는 변수
            result: {},
            totalScore: 0,
            err: null,
            errCount: 0,
            pass: false,
            isInitialized: false,
            isTesting: false,
            index: 0,
            wordInfo: {
                num: null,
                getResult: false,
            },
            contents: null,
            waiting: null,
            count: 0,
            letStartRecognition: false,
            showResult: false,
            listening:false,

        };

        this.mTimeout = null;
    }



    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }


        return (
            this._renderMain()
        )


    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {

        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        {
                            this.state.showResult ?
                                <>
                                    <div className="score-poplayer" id="score-poplayer">
                                        <div className="content">
                                            <p>
                                                <span className="sr-only">SCORE</span>
                                                {/*<strong> {isIOS ? "Pass" : this.state.totalScore} </strong>*/}
                                                <strong> {this.state.totalScore} </strong>
                                            </p>
                                            <button type={"button"} className="btn-answer"
                                               onClick={() => {
                                                   $('#speak-poplayer').wPopLayer();
                                               }}>정답보기</button>
                                        </div>
                                    </div>

                                    {/*<p className = "score-help-block" > {this.state.pass ? "":`${FailScore}점 이상이어야 통과할 수 있습니다. 다시 테스트 하세요.`}</p>*/}
                                </>
                                :
                                <>
                                    <div className="accumulate-game">
                                        <div
                                            onClick={() => {if(this.state.err != null){this.audioPlayInError(0)}}}
                                            className={
                                            this.state.isPlayingAudio ?
                                                this.state.currentIdx === 0 ?
                                                    "game-circle blue"
                                                    :
                                                    "game-circle"
                                                :
                                                this.state.wordInfo.num === 0 ?
                                                    "game-circle orange"
                                                    :
                                                    "game-circle"
                                        }>
                                            1
                                        </div>
                                        <div
                                            onClick={() => {if(this.state.err != null){this.audioPlayInError(1)}}}
                                            className={
                                            this.state.isPlayingAudio ?
                                                this.state.currentIdx === 1 ?
                                                    "game-circle blue"
                                                    :
                                                    "game-circle"
                                                :
                                                this.state.wordInfo.num === 1 ?
                                                    "game-circle orange"
                                                    :
                                                    "game-circle"
                                        }>
                                            2
                                        </div>
                                        <div
                                            onClick={() => {if(this.state.err != null){this.audioPlayInError(2)}}}
                                            className={

                                            this.state.isPlayingAudio ?
                                                this.state.currentIdx === 2 ?
                                                    "game-circle blue"
                                                    :
                                                    "game-circle"
                                                :
                                                this.state.wordInfo.num === 2 ?
                                                    "game-circle orange"
                                                    :
                                                    "game-circle"
                                        }>
                                            3
                                        </div>
                                        <div
                                            onClick={() => {if(this.state.err != null){this.audioPlayInError(3)}}}
                                            className={

                                            this.state.isPlayingAudio ?
                                                this.state.currentIdx === 3 ?
                                                    "game-circle blue"
                                                    :
                                                    "game-circle"
                                                :
                                                this.state.wordInfo.num === 3 ?
                                                    "game-circle orange"
                                                    :
                                                    "game-circle"
                                        }>
                                            4
                                        </div>
                                        <div
                                            onClick={() => {if(this.state.err != null){this.audioPlayInError(4)}}}
                                            className={

                                            this.state.isPlayingAudio ?
                                                this.state.currentIdx === 4 ?
                                                    "game-circle blue"
                                                    :
                                                    "game-circle"
                                                :
                                                this.state.wordInfo.num === 4 ?
                                                    "game-circle orange"
                                                    :
                                                    "game-circle"
                                        }>
                                            5
                                        </div>


                                    </div>
                                    {this._renderHelpText()}
                                </>
                        }


                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.showResult ?
                                    <>
                                        참 잘하셨어요. 더 좋은 점수를 원하신다면<br/>
                                        다시 <strong>테스트</strong> 해보세요.
                                        <hr className="divider" />
                                        <div className="tip">
                                            녹음이 도중에 멈춘다면<br/>
                                            조금 더 빠르게 말해보세요. <br />
                                        </div>
                                    </>
                                    :
                                this.state.err === null ?
                                    <>
                                        들려주는 <strong>영어 문장</strong>을 잘 듣고 <strong>기억</strong>하여<br/>
                                        <strong>삐</strong> 소리와 함께 <strong>영어로</strong> 말하세요. <br/>
                                        테스트 버튼을 눌러 시작합니다.
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                    {this._renderAnswer()}
                </section>
                <Footer_Accumulate
                    {...this.props}
                    c_code={this.props.c_code}
                    result={this.state.result}
                    setResult={this._setResult}
                    isTesting={this.state.isTesting}
                    showResult={this.state.showResult}
                    index={this.state.index}
                    pass={this.state.pass}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    waiting={this.state.waiting}
                    err={this.state.err}
                    startTest={this._startTest}
                    handleLetStartRecognition={this._handleLetStartRecognition}
                    isListening ={this._isListening}
                    letStartRecognition={this.state.letStartRecognition}
                    isPlaying={this.props.isPlaying}
                    isPlayingAudio={this.state.isPlayingAudio}
                    currentIdx = {this.state.currentIdx}
                />

            </>

        );
    };

    _handleIsComplete = (bool) => {
        this.setState({
            isComplete: bool,
        })
    }

    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };
    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        this.setState({
            result: {
                ...this.state.result,
                [index]: result
            },
            count: this.state.count + 1,
            err: null,
            waiting: true,
            errCount: 0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);

        if (index === 4) {   ///!!!누적암기는 무조건 5문장으로 구성되어 있음.
            //console.log("마지막 문장까지 녹음 완료");
            //누적암기 완료.
            setTimeout(() => {
                // let totalScore = isIOS ? 0 : this._calculateTotalScore();
                let totalScore = this._calculateTotalScore();
                // let pass = totalScore > FailScore;

                //점수 서버 업로드
                uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1, this.props.serverData.auth_type);

                this._decidePass(true); //일단 무조건 패스시킴
                this._showResult();
                this.setState({
                    totalScore,
                    wordInfo: { //wordInfo 초기화
                        num: 0,
                        getResult: false,
                    },
                });

            }, 1000);

            return;
        }


        if (index === this.state.phase) { //페이즈 마지막 문장 녹음 완료 하면
            //console.log("현재 페이즈 마지막 문장 녹음 완료");
            this.setState({
                phase: this.state.phase + 1, //페이즈 증가.
                currentIdx: 0, //currentIdx 초기화

            });
            setTimeout(() => {
                this.setState({
                    wordInfo: { //wordInfo 초기화
                        num: 0,
                        getResult: false,
                    },
                });
                this._audioPlayWithCb(0);
            }, 1000); //1초후 음원 재생 시작

        } else { //아직 마지막 문장까지 녹음완료를 한게 아니라면
            setTimeout(() => {
                this.setState({
                    waiting: false,
                });
                if (this.state.isTesting) {
                    //console.log("wordInfo 업데이트!");
                    this._updateWordInfo(this.state.wordInfo.num + 1, false);
                    this._handleLetStartRecognition(true);
                }
            }, 400); //0.4초후 녹음 다시 시작

        }
    };

    _audioPlayWithCb = (currentIdx) => {
        // //console.log("playIdx is :", this.state.currentIdx);
        if(currentIdx < this.state.phase){
            this.setState({
                isPlayingAudio: true,
            });
            // this.props.audioPlayWithCb("eng", currentIdx, () => {
            //     this.setState({
            //         currentIdx: this.state.currentIdx + 1,
            //     });
            //     this._audioPlayWithCb(currentIdx + 1);
            // })

            let src = `${this.props.contents[currentIdx].step_code}/${this.props.contents[currentIdx].lesson_no}/${this.props.contents[currentIdx].eng_url}`;
            this.props.setHowler(
                src,
                () => {
                    this.setState({
                        currentIdx: this.state.currentIdx + 1,
                    });
                    this._audioPlayWithCb(currentIdx + 1);
                },
            );

        } else {
            // this.props.audioPlayWithCb("eng", currentIdx, () => {
            //     this.setState({
            //         isPlayingAudio:false,
            //     });
            //     this._handleLetStartRecognition(true);
            // })

            let src = `${this.props.contents[currentIdx].step_code}/${this.props.contents[currentIdx].lesson_no}/${this.props.contents[currentIdx].eng_url}`;
            this.props.setHowler(
                src,
                () => {
                    this.setState({
                        isPlayingAudio:false,
                    });
                    this._handleLetStartRecognition(true);
                },
            );
        }

    }

    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            },

        });
    };


    _startTest = () => {
        // //console.log("컨티뉴리코그나이져");
        this.props.closeTip();
        this.setState({
            isTesting: true,
            result: {},
            wordInfo: {
                num: 0,
                getResult: false,
            },
            showResult: false,
            err: null,
            errCount: 0,
            waiting: false,
            count: 0,
            isPlayingAudio: true, //렌더링에 사용할 변수
            currentIdx:0,
        });

        // this.props.audioPlayWithCb("eng", 0, () => {
        //     this._handleLetStartRecognition(true);
        // })

        this._audioPlayWithCb(0);

    };

    _handleLetStartRecognition = (bool) => {
        this.setState({
            letStartRecognition: bool,
        })
    };

    _showResult = () => {
        this.setState({
            showResult: true,
            isTesting: false,
        });

        $(function () {
            TweenLite.to("#score-poplayer", 1, {
                y: 0,
                delay: 0,
                yPercent: 0,
                opacity: 1
            })

        });

    };


    _decidePass = (isPass) => {
        if (this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if (!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass: this.state.pass ? true : isPass,
                tryCount: this.state.tryCount + 1,
                err: ["다시 한번 ", <strong>테스트</strong>, "하세요.",<br/>,`${FailScore}점 이상이어야 통과합니다.`,
                    <div className="divider"/>,
                    <div className="tip">점수가 잘 나오지 않는다면 <br/>더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass: isPass,
                err: null,
            })
        }


    };

    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) {
            this.setState({
                err: err,
            });

            return;
        }


        if (this.state.errCount >= 1) {


            //에러가 한번 이상 발생하면 그냥 에러 결과를 입력하고, 다음 문제로 넘어감

            this._setResult("잘 인식되지 않았습니다.", this.state.wordInfo.num);

            return;
        }

        this.props.openTip();
        this.setState({
            err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br/>, "발음 문제가 아니니 너무 상심하지 마세요.", <div className="divider"/>,
                <div className="tip">문제가 계속된다면 마이크를 점검하세요.</div>],
            errCount: this.state.errCount + 1,
            waiting: false,
        })
    }

    _renderAnswer = () => {
        let answers = [];
        for (let i = 0; i < this.props.contents.length; i++) {
            answers.push(
                <li key={i}>
                    <p>
                        {this.props.contents[i].kor_content}<br/>
                        <span>{this.props.contents[i].eng_content}</span>
                    </p>
                    <button
                        type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() =>
                            this.audioPlay(i)
                        }
                    >발음듣기</button>
                </li>
            )
        }

        return (
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close" onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    }

    _calculateTotalScore = () => {  //합산  점수 출력
        let sum = 0;
        for (let i = 0; i < this.props.contents.length; i++) {
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);
        }

        if(sum === 0){
            return FailScore;
        } else {
            return Math.ceil(sum / this.props.contents.length);
        }



    };

    _renderHelpText = () => {
       if (this.state.listening) {
            return (
                <div className="help-text">{`${this.state.wordInfo.num + 1}번을 말하세요.`}</div>
            )
        } else if (this.props.isPlaying) {
            return (
                <div className="help-text">{`잘 듣고 암기하세요.`}</div>
            )
        } else {
            return (
                <div className="help-text">&nbsp;</div>
            );
        }
    }

    // _audioPlayInError = (idx) => {
    //     if(idx > this.state.currentIdx) {
    //         return;
    //     }
    //
    //     this._audioPlay("eng", idx);
    // }
    audioPlayInError = (idx) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }

        if(idx > this.state.currentIdx) {
            return;
        }

        let src = `${this.props.contents[idx].step_code}/${this.props.contents[idx].lesson_no}/${this.props.contents[idx].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _isListening = (bool) => {
        this.setState({
            listening: bool,
        })
    }


}


export default withContents()(Accumulate);
    
    