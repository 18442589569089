import React, {Component} from 'react';

class Footer_Practice_C extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,
        };
    }

    componentDidUpdate() {

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);

        return (

            <section className="view-footer">
                {
                    this.props.phase === 2 ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                this.props.goNextMenu()
                            }}>
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                className="f-btn btn-prev"
                                onClick={() => {
                                    this.props.handlePhase(0)
                                }}>
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center btn-check-correct"
                                onClick={() => {
                                    //this.props.handlePhase(2);
                                    this.props.checkCorrect();
                                }}>
                            </button>
                            <button type="button" className="f-btn">Empty</button>
                        </>

                }

            </section>

        );
    }

}

export default Footer_Practice_C;
    
    