import React, {Component} from 'react';

import withRecognizer from "./withRecognizer";
import MicVisualizer from "../other/MicVisualizer";
import {isIOS} from "../../asset/js/myFunctions";


class Footer_Blinking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 999,

        };
        // this._testStart = this._testStart.bind(this);

    }
    //
    // _testStart = () => {
    //     this.props.startRecognition();
    //     this.props.closeTip();
    // };

    _continueTest = () => {
        // if(isIOS){
        //     this.props.fakeRecognitionStart();
        //     // this.props.audioPlayMute("eng",this.props.wordInfo.num,
        //     //     () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
        //     // );
        //
        //     let src = `${this.props.contents[this.props.wordInfo.num].step_code}/${this.props.contents[this.props.wordInfo.num].lesson_no}/${this.props.contents[this.props.wordInfo.num].eng_url}`;
        //     this.props.setHowlerMute(
        //         src,
        //             () => setTimeout(() => {this.props.fakeRecognitionEnd()},1000)
        //     );
        // } else {
        //     this.props.startRecognition();
        // }
        this.props.startRecognition();
        this.props.closeTip();
    }

    //현재 페이지를 state.index에 저장함.
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.isTesting && nextProps.wordInfo.getResult === false && nextProps.recognizerReady === true && nextProps.wordInfo.num !== prevState.index) {
            //만약 아직 결과를 못받았고, 리코그나이저가 준비되어 있으며 wordInfo의 num 가 새로운 것일 경우 테스트를 실행하고 index를 num에 맞춤.
            //console.log("received wordInfo is: " + nextProps.wordInfo.num + " " + nextProps.wordInfo.getResult);
            //this._test3Words();
            // if(isIOS){
            //     nextProps.fakeRecognitionStart();
            //     // nextProps.audioPlayMute("eng",nextProps.wordInfo.num,
            //     //     () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
            //     // );
            //
            //     let src = `${nextProps.contents[nextProps.wordInfo.num].step_code}/${nextProps.contents[nextProps.wordInfo.num].lesson_no}/${nextProps.contents[nextProps.wordInfo.num].eng_url}`;
            //     nextProps.setHowlerMute(
            //         src,
            //         () => setTimeout(() => {nextProps.fakeRecognitionEnd()},1000)
            //     );
            // } else {
            //     nextProps.startRecognition();
            // }
            nextProps.startRecognition();
            return {
                index: nextProps.wordInfo.num,
            }
        }
        return null;
    }


    // componentDidMount(){
    //     this._test3Words();
    // }
    componentDidUpdate() {

    }

    render() {
        ////console.log("is Listening?:", this.props.listening);

        return (

            <section className="view-footer">
                {
                    <button type="button" className="f-btn">Empty</button>
                }

                {
                    this.props.isTesting ?
                        this.props.listening ?
                            <MicVisualizer/>
                            :
                            this.props.waiting ?
                                <button type="button" className="f-btn">Empty</button>
                                :
                                this.props.err == null ?

                                    <button type="button" className="f-btn">Empty</button>
                                    :
                                    <button
                                        type="button"
                                        className="f-btn f-center btn-test"
                                        onClick={() => {
                                            this._continueTest()
                                        }}>
                                    </button>


                        :
                        <button
                            type="button"
                            className="f-btn f-center btn-test"
                            onClick={() => {
                                this.props.startTest();
                            }}
                        >테스트</button>
                }

                {
                    this.props.isTesting ?

                        <button type="button" className="f-btn">Empty</button>
                        :
                        this.props.pass ?
                            <button
                                type="button"
                                className="f-btn btn-next-page"
                                onClick={() => {
                                    this.props.goNextMenu()
                                }}>
                            </button>
                            :
                            <button type="button" className="f-btn">Empty</button>
                }


            </section>

        );
    }

}

export default withRecognizer()(Footer_Blinking);
    
    