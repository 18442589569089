import React, {Component} from 'react';
import {
    brTagActivator, calculateScore,
    getCurrentMenuInfo,
    FailScore, strongTagChanger, uploadScoreAl, capitalize, isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import Footer_PTP from "../../Footer_PTP";
import Footer_PTP_B from "../../Footer_PTP_B";
import $ from "jquery";
import ReactHtmlParser from 'react-html-parser';
import {TweenLite} from "gsap/TweenLite";

class PatternPractice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            isStart: false,
            isTest: false,
            isTesting:false,
            showResult:false,
            currentPage: 0,
            totalPage: 0,
            result: {},
            count:0,
            wordInfo: {
                num: 0,
                getResult: false,
            },
            err: null,
            errCount: 0,
            tryCount: 0,
            pass: false,
            totalScore: 0,
            waiting:false,
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentPage !== prevState.currentPage) { //페이지 변경시
            nextProps.closeTip();
            return {
                currentPage: nextProps.currentPage,
                hasResult: false,
                wordInfo: {
                    num: nextProps.currentPage,
                    getResult: false,
                },
                errCount: 0,
                err: null,
                tryCount: 0,
                totalScore: 0,
            }
        }
        // //console.log(this.props);
        if (nextProps.contents && prevState.totalPage === 0) {
            return {
                totalPage: nextProps.contents.length - 1,  //0번 콘텐츠(패턴)는 빼고 체크
            }
        }


        return null;

    }

    // componentDidUpdate() {
    //     //this.props.myJS();
    //
    //     $(function(){
    //         TweenLite.to( "#score-poplayer", 1, {
    //             y:0,
    //             delay:0,
    //             yPercent:0,
    //             opacity:1
    //         })
    //     });
    //
    // }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }
        // if (this.props.currentPage > this.props.contents.length) {
        //         //     return (<div>Error. Exceed contents.</div>);
        //         // }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.showResult){
            return(
                this._renderResult()
            );
        }


        return (
            this._renderMain()
        )

    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }



    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className={this.state.isTest ? "content-wrap align-start" : "content-wrap"}>
                        {
                            this.state.isTest ?

                                <div className="sound-box">
                                    <img
                                        src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[this.state.wordInfo.num].tpk_url}`}
                                        // src={require(`../../../../asset/images/samples/lecture-child.jpg`)}
                                        className="ptp-img"
                                        alt=""/>
                                        <div className="sound-box-text type2">
                                            <p className="word-kor">{ReactHtmlParser(strongTagChanger(this.props.contents[this.state.wordInfo.num].kor_content))}</p>
                                            <p className="word-eng">({this.props.contents[this.state.wordInfo.num].tpk_content})</p>
                                        </div>
                                        <div className="voice-input-box">
                                            {/*<p className="voice-before">I'm going to</p>*/}
                                            <div className="voice-input">
                                                {
                                                    isIOS ?
                                                        this.state.result[this.state.wordInfo.num] ?
                                                            this.props.contents[this.state.wordInfo.num].eng_content
                                                            :
                                                            ""
                                                        :
                                                        capitalize(this.state.result[this.state.wordInfo.num])
                                                }
                                            </div>
                                        </div>
                                </div>

                                :
                                <>
                                    <div className="top-tip">오늘의 표현</div>
                                    <ul className="word-list voice-box big">
                                        <li>
                                            <div className="word">{this.props.contents[0].eng_content}</div>
                                            <div className="txt">{this.props.contents[0].kor_content}</div>
                                            <span className="btn-wrap">
                                                {/*<a href="#" className="btn-play-sound">발음듣기</a>*/}
                                                <button className="btn-play-sound CursorPointer"
                                                        onClick={
                                                            () => this.audioPlay(0)
                                                        }
                                                >
                                                    발음듣기
                                                </button>
                                            </span>
                                        </li>
                                    </ul>
                                </>
                        }

                    </div>
                    {
                        this.state.isTest ?
                            <div className="lecture-step">
                                <span className="current">{this.state.wordInfo.num}</span>/<span className="total">{this.state.totalPage}</span>
                            </div>
                            :
                            ""
                    }


                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.err === null ?
                                    this.state.isTest ?
                                        <>
                                            테스트 버튼을 눌러 <strong>패턴 연습</strong>합니다.<br/>
                                            <div className="divider"/>
                                            <div className="tip">
                                                오늘 배운 표현을 활용해<br/>
                                                대답해 보세요.
                                            </div>
                                        </>
                                        :
                                        <>
                                            오늘의 표현입니다.<br/>
                                            다음 표현을 <strong>외우시고</strong> 다음으로 넘어가세요.<br/>
                                            이 표현을 활용해 <strong>패턴 연습</strong>합니다.
                                        </>

                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                            {/*<hr className="divider"/>*/}
                            {/*<div className="tip">*/}
                            {/*{*/}
                            {/*this.state.err === null ?*/}
                            {/*""*/}
                            {/*:*/}
                            {/*this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)*/}
                            {/*}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*<IOPanel_1 original={this.props.contents[this.state.index].eng_content}*/}
                    {/*back={this._prev}*/}
                    {/*next={this._next} />*/}
                </section>
                {
                    this.state.isTest ?
                        <Footer_PTP
                            ref={this.child}
                            {...this.props}
                            c_code={this.props.c_code}
                            setResult={this._setResult}
                            wordInfo={this.state.wordInfo}
                            handleError={this._handleError}
                            handleIsTest = {this._handleIsTest}
                            handleIsTesting = {this._handleIsTesting}
                            handleShowResult={this._handleShowResult}
                            result={this.state.result}
                            pass={this.state.pass}
                            waiting={this.state.waiting}
                            isTest={this.state.isTest}
                            isTesting={this.state.isTesting}
                        />
                        :
                        <Footer_PTP_B
                            {...this.props}
                            handleIsTest = {this._handleIsTest}
                            handleError={this._handleError}

                        />
                }

            </>

        );
    };

    _renderResult = () => {
        return(
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap">
                        <div className="score-poplayer" id="score-poplayer">
                            <div className="content">
                                <p>
                                    <span className="sr-only">SCORE</span>
                                    <strong>
                                        {isIOS ? "Pass" : this.state.totalScore}
                                    </strong>
                                </p>
                                <button type={"button"} className="btn-answer CursorPointer" onClick={() => {
                                    $('.speak-poplayer').wPopLayer();
                                }}>정답보기
                                </button>
                            </div>
                        </div>
                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">

                            {
                                this.state.err === null ?
                                    <>
                                        참 잘하셨어요. 더 좋은 점수를 원하신다면<br/>다시 <strong>테스트</strong> 해보세요.
                                        <hr className="divider" />
                                        <div className="tip">녹음이 도중에 멈춘다면<br/> 더 빠르게 말해보세요. <br />
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>

                    {this._renderAnswer()}
                </section>

                <Footer_PTP
                    ref={this.child}
                    {...this.props}
                    c_code={this.props.c_code}
                    setResult={this._setResult}
                    wordInfo={this.state.wordInfo}
                    handleError={this._handleError}
                    handleIsTest = {this._handleIsTest}
                    handleIsTesting = {this._handleIsTesting}
                    handleShowResult={this._handleShowResult}
                    result={this.state.result}
                    pass={this.state.pass}
                    waiting={this.state.waiting}
                    isTest={this.state.isTest}
                    isTesting={this.state.isTesting}
                />

            </>
        )


    };

    _setResult = (result, index) => {
        //결과를 받으면 결과를 state에 저장함.
        // //console.log("M3w Component did received result. index is: "+index+ " and result is " + result);
        // if(calculateScore(result, this.props.contents[index].eng_content) <= FailScore) {
        //     this._decidePass(false);
        // }

        this.setState({
            result: {
                ...this.state.result,
                [index]:result
            },
            count: this.state.count + 1,
            err:null,
            waiting:true,
            errCount:0,
        });

        //결과를 받았으니, wordInfo를 업데이트함.
        this._updateWordInfo(index, true);


        //하지만 결과를 다 받지 못했으면 wordInfo를 0.1초후에 다시 갱신함
        if(this.state.count < this.props.contents.length - 1){
            // //console.log("1초 후에 wordInfo 업데이트 됨.");
            // //console.log("현재 count 는: "+this.state.count);
            // //console.log(this.child.current);
            setTimeout(() => {
                // this.setState({
                //     waiting:false,
                // });
                //console.log("wordInfo 업데이트!");
                this._updateWordInfo(this.state.wordInfo.num + 1, false);


            }, 1000);
        }

        if(this.state.count >= this.props.contents.length - 1) {
            setTimeout( () => {
                this._handleShowResult(true); //결과화면 출력

                let totalScore = isIOS ? 0 : this._calculateTotalScore();

                uploadScoreAl(this.props.apiUrl, totalScore, this.props.attend_idx, this.props.currentMenu, this.state.tryCount+1,this.props.serverData.auth_type);//점수 서버에 업로드

                let pass = totalScore >= FailScore || isIOS;
                this._decidePass(pass);

            },500);

        }
    };


    _updateWordInfo = (num, bool) => {
        this.setState({
            wordInfo: {
                num: num,
                getResult: bool,
            }
        })
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });

            return;
        }

        this.props.openTip();

        if(this.state.errCount >= 1){
            this.setState({
                result: '##ERROR',
                err: ["특정 단어는 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                pass:true,
                waiting:false,
            });

            return;
        }

        this.setState({
            err:err,
            errCount:this.state.errCount + 1,
            waiting:false,
        })
    }


    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],

            });
            return;
        }

        if(!isPass) { // fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다시 한번 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">점수가 잘 나오지 않는다면 <br />더 <strong>큰 목소리</strong>로 따라해보세요.</div>],
            })
        } else {
            this.props.openTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }


    };

    // _playAudio = (idx) => {
    //     if(this.props.isPlaying) { //중복 음원 실행 방지
    //         return;
    //     }
    //     this.props.audioPlay("eng", idx);
    // }

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _handleIsTesting = (bool) => {
        this.setState({
            isTesting:bool,
            showResult:false,
            result: {},
            count:0,
            wordInfo: {
                num: 1,
                getResult: false,
            },
            err: null,
            errCount: 0,
            totalScore: 0,
            waiting:false,
        })
    }

    _handleIsTest = (bool) => {

        if(!bool){ //다시 패턴 페이지로 돌아오면 변수값 초기화
            this.setState({
                isTest: bool,
                isTesting:false,
                showResult:false,
                currentPage: 0,
                totalPage: 0,
                result: {},
                count:0,
                wordInfo: {
                    num: 0,
                    getResult: false,
                },
                err: null,
                errCount: 0,
                tryCount: 0,
                totalScore: 0,
                waiting:false,
            })
        } else {
            this.setState({
                isTest:bool,
            })
        }


    }

    _handleShowResult = (bool) => {
        if(bool){
            $(function(){
                TweenLite.to( "#score-poplayer", 1, {
                    y:0,
                    delay:0,
                    yPercent:0,
                    opacity:1
                })
            });

            this.setState({
                showResult:bool,
                waiting:false,
                isTesting:false, //결과값 뿌려줄때는 isTesting off
            });
        } else {
            this.setState({
                showResult:bool,
                waiting:false,
            });
        }


    };

    _renderAnswer = () => {
        // //console.log(this.props.lessonInfo);
        let answers = [];
        for(let i = 1 ; i < this.props.contents.length; i ++){
            answers.push(
                <li key = {i}>
                    <p>
                        {this.props.contents[i].kor_content.replace(/[<>]/g,"")}<br />
                        <span>{this.props.contents[i].eng_content.replace(/[<>]/g,"")}</span>
                    </p>
                    <button
                        type={"button"}
                        className={"btn-play-sound CursorPointer"}
                        onClick={() =>
                            //this._playAudio(i)
                            this.audioPlay(i)
                        }
                    >발음듣기</button>
                </li>
            )
        }

        return(
            <div className="popup speak-poplayer" id="speak-poplayer" data-toggle="pop-layer">
                <h4>정답 확인</h4>
                <button type="button" data-button="pop-close" className="btn-pop-close"  onClick={() => {this.props.audioStop()}}>닫기</button>

                <ul className={"speak-list"}>
                    {answers}
                </ul>
            </div>
        )


    };

    _calculateTotalScore(){
        let sum = 0;

        for(let i = 1 ; i < this.props.contents.length ; i ++){
            sum += calculateScore(this.state.result[i], this.props.contents[i].eng_content);
        }

        let totalScore;

        if(sum === 0){
            totalScore = FailScore;
        } else {
            totalScore = Math.ceil(sum / (this.props.contents.length - 1));
        }

        this.setState({
            totalScore:totalScore
        });

        return totalScore;


    }

}


export default withContents()(PatternPractice);
    
    