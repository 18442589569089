import React, {Component} from 'react';
import StartSound from '../../asset/sound/start_sound.mp3';
import EndSound from '../../asset/sound/score_sound.mp3';
import axios from 'axios';
import {apiKey} from '../../secret/keys';

// import MicRecorder from 'mic-recorder-to-mp3';

axios.defaults.headers.common['Authorization'] = apiKey;

const withRecorder = () => (WrappedComponent)  => {
    //let mediaRecorder;
    //let chunks = [];
    let audio = null;
    //const recorder = new MicRecorder({bitRate:128}); //minimum bitRate is 128

    let mediaRecorder = null;
    let chunks = [];



    return class extends Component {

        state = {
            // maxRecordingTime:maxRecordingTime, //60초
            duration: 300000, //300초 5분. 맥스
            chunks: [],
            blob:null,
            audioURL:null,
            recording: false,
            nowPlaying: false,
            isPlaying:false,
            isUpload:false,
            uploading:false,
            file:null,
            fileName:null,
        };

        componentDidMount() {
           this.initialize();
        }

        componentWillUnmount(){
            //recorder.stop();
        }

        //
        initialize(){

            if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                //console.log('getUserMedia supported!');
                navigator.mediaDevices.getUserMedia(
                    { audio: true }
                ).then((stream) => {
                    //console.log("mediarecorder create");
                    //success callback
                    //console.log("getUserMedia success. callback function start");
                    let options = {
                        mimeType: 'audio/webm',
                    };
                    mediaRecorder = new MediaRecorder(stream, options);
                    // mediaRecorder.
                    mediaRecorder.ondataavailable = (e) => {
                        chunks.push(e.data);
                        // console.log(chunks);
                    };
                    mediaRecorder.onstop = (e) => {
                        //console.log("녹음 종료");
                        let endSound = new Audio(EndSound);
                        endSound.play();

                        if(this.props.startTest){
                            this.props.startTest(false);
                        }

                        let fileName = `${this.props.serverData.mem_idx}_${this.props.lessonInfo.step_code}${this.props.lessonInfo.lesson_no}_1_${Date.now()}`.replace(/\s+/g, '');
                        // let outputName = `${this.props.serverData.mem_idx}_${this.props.lessonInfo.step_code}${this.props.lessonInfo.lesson_no}_1_${Date.now()}.mp3`.replace(/\s+/g, '');

                        // let blob = new Blob(chunks, {'type':'audio/mpeg-3'});
                        let blob = new Blob(chunks, {'type':'audio/webm'});
                        //const blob = new Blob(chunks, {'type':'audio/aac; codecs=aac' });
                        // blob.lastModifiedDate = Date.now();
                        // blob.name = fileName;
                        let file = new File([blob], fileName, {
                            type:'audio/webm',
                            lastModified: Date.now(),
                        });


                        this.props.checkResult(true);

                        this.setState({
                            audioURL:window.URL.createObjectURL(blob),
                            file,
                            fileName,
                            recording:false,
                        });
                        chunks = [];
                    };
                    //console.log(mediaRecorder);

                }).catch((err) => {
                    //console.log('The Following getUserMedia error occured: ', err);
                    alert("마이크를 확인해 주세요.");
                })
            } else {
                //console.log("getUserMedia not supported on your browser!");
            }

        }

        // initialize(){
        //
        // }


        _recordingStart(){
            if(!mediaRecorder) {
                alert("마이크를 찾을 수 없습니다.");
            }
            mediaRecorder.start();
            //console.log(mediaRecorder);
            if(mediaRecorder.state === "recording"){
                let startAudio = new Audio(StartSound);
                startAudio.play();
                this.props.checkResult(false);
                this.setState({
                    recording:true,
                });
            }

        }


        //  결과값없이 녹음종료
        _recordingStopWithoutResult = () => {

            console.log("without result");

        };


        _recordingStop() {
            if(mediaRecorder.state !== "recording") {
                return;
            }
            mediaRecorder.stop();
        };

        // _recordingStop = () => {
        //     if(mediaRecorder.state === "inactive") {
        //         //console.log("Recording is already stopped!");
        //         return;
        //     }
        //     let endSound = new Audio(EndSound);
        //     endSound.play();
        //     mediaRecorder.stop();
        //     if(this.props.startTest){
        //         this.props.startTest(false);
        //     }
        //     this.setState({recording: false});
        //     ////console.log(mediaRecorder.state);
        //     //console.log("current Chunks is :", chunks);
        //     //console.log("recorder stopped!");
        //
        //
        //     mediaRecorder.onstop = (e) => {
        //         let blob = new Blob(chunks, { 'type' : 'audio/ogg' });
        //         chunks = [];
        //
        //         if(blob !== null ) {
        //             //console.log("has result is true");
        //             this.props.checkResult(true);
        //         }
        //         this.setState({
        //             blob: blob,
        //             audioURL: window.URL.createObjectURL(blob),
        //             isUpload:false, //새로 녹음하면 isUpload false 로 변경
        //         });
        //
        //         // //console.log(this.state.audioURL);
        //         //new Audio(audioURL).play();
        //
        //     }
        //
        // };

        _playRecordingAudio(){
            if(this.state.audioURL === null){
                //console.log("녹음 된 파일이 없습니다.")

            } else if(this.state.nowPlaying === true) {

                //console.log("이미 플레이 중입니다.")
            } else {
                this.setState({nowPlaying: true} , () => {
                    //console.log("1 now Playing is changed to: ", this.state.nowPlaying);
                    audio = new Audio(this.state.audioURL);
                    //console.log(this.state.audioURL);
                    audio.volume = 1.0;
                    audio.play();
                    audio.addEventListener("ended", () => {
                        //this.setState({nowPlaying: false});
                        this.props.stopRecordedFile(); //WFT패널에서 state.isPlaying을 false로 바꾸는 함수 호출
                        this.setState({nowPlaying:false}, () => {
                            //console.log("2 now Playing is changed to: ", this.state.nowPlaying);
                        })

                    });
                });

            }
        }

        _stopRecordingAudio(){

            if(audio !== null){
                //console.log("record file Stop Playing")
                audio.pause();
                audio.currentTime = 0;
                this.setState({
                    nowPlaying:false,
                });
                this.props.stopRecordedFile(); //WFT패널에서 state.isPlaying을 false로 바꾸는 함수 호출
            } else {
                //console.log("audio is empty");
            }

        }




        _uploadAudioFile(attend_idx, menu_no, device){
            // //console.log("파일 업로드 완료!",this.state.audioURL);
            if(this.state.isUpload){
                //console.log("이미 업로드한 파일입니다.");
                return;
            }

            if(this.state.uploading){ //업로드 중.
                //잠시만 기다려주세요

                return;
            }

            if(this.props.serverData.auth_type === "F"){
                alert("무료체험은 녹음파일을 제출할 수 없습니다.\n확인을 누르시면 다음 메뉴로 이동합니다.");
                this.props.goNextMenu();
                // return;
            } else if(this.props.serverData.auth_type === "A") {
                alert("관리자는 녹음파일을 제출할 수 없습니다.\n확인을 누르시면 다음 메뉴로 이동합니다.");
                this.props.goNextMenu();
                //  return;
            } else {
                this.setState({
                    uploading:true,
                });

                const data = new FormData();
                // //let file = new File(this.state.blob, "test.ogg", {type:'audio/ogg', lastModified:Date.now()});
                // //data.append('file', this.state.blob);
                data.append('file', this.state.file);


                const ptSteps = ['L9','L10','L11','L12'];
                //추가학습의 경우 업로드
                if(!ptSteps.includes(this.props.lessonInfo.step_code.replace(/\s/g,'')) && this.props.lessonInfo.group_no === 3){
                    //console.log("추가학습 녹음파일 업로드");
                    Promise.all([axios({
                            method: 'post',
                            url: `${this.props.apiUrl}/class/upload/al/${attend_idx}/${menu_no}/${device}/${this.state.fileName}`,
                            //url: `${this.props.apiUrl}/class/upload/test`,
                            data
                        }
                    )]).then(([res]) => {
                        //데이터 업로드 성공!
                        //console.log("res:",res);
                        // console.log(res);
                        if(res.status === 500) {
                            alert("녹음 파일 업로드를 실패하였습니다. 잠시 후에 다시 시도해 주세요.");
                            return;
                        }

                        this.setState({
                            isUpload:true,
                            uploading:false,
                        });
                        alert("녹음파일을 업로드하였습니다.");
                        this.props.goNextMenu();

                    }).catch(err => {
                        //console.log("에러 발견", err);
                        alert("ERROR: 녹음 파일 제출을 실패하였습니다. 다시 시도해 주세요.");
                        //메뉴 넘버 업데이트 실패
                        this.setState({
                            uploading:false,
                        });
                    }).then(() => {
                        //에러 발견시, 콜백
                        this.setState({
                            uploading:false,
                        });
                    });
                } else { //일반학습
                    Promise.all([axios({
                            method: 'post',
                            url: `${this.props.apiUrl}/class/upload/${attend_idx}/${menu_no}/${device}/${this.state.fileName}`,
                            data
                        }
                    )]).then(([res]) => {
                        //데이터 업로드 성공!
                       // console.log(res);
                        if(res.status === 500) {
                            alert("녹음 파일 업로드를 실패하였습니다. 잠시 후에 다시 시도해 주세요.");
                            return;
                        }
                        this.setState({
                            // isUpload:true,
                            uploading:false,
                        });
                        alert("녹음파일을 업로드하였습니다.");
                        this.props.goNextMenu();
                    }).catch(err => {
                        console.log(err);
                        //console.log("에러 발견", err);
                        //메뉴 넘버 업데이트 실패
                        this.setState({
                            uploading:false,
                        });
                    }).then(() => {
                        //에러 발견시, 콜백
                        this.setState({
                            uploading:false,
                        });
                    });
                }
            }
        }

        _downloadAudioFile(url){
            //console.log("파일 다운로드 완료!",this.state.audioURL);
        }



        render(){
            return (
                <WrappedComponent
                    {...this.props}
                    start = {this._recordingStart.bind(this)}
                    stop = {this._recordingStop.bind(this)}
                    stopWithoutResult = {this._recordingStopWithoutResult.bind(this)}
                    play = {this._playRecordingAudio.bind(this)}
                    stopPlaying = {this._stopRecordingAudio.bind(this)}
                    download = {this._downloadAudioFile.bind(this)}
                    upload = {this._uploadAudioFile.bind(this)}
                    uploading={this.state.uploading}
                    setDuration = {this._setDuration.bind(this)}
                    nowPlaying = {this.state.nowPlaying}
                    audioURL = {this.state.audioURL}
                    recording = {this.state.recording}
                    isUpload = {this.state.isUpload}
                />
            )
        }

        _setDuration = (duration) => {
            //console.log("set duration :", duration);
            this.setState({
                duration
            });
        }
    }
};

export default withRecorder;
