import React, {Component} from 'react';
import {
    brTagActivator,
    getCurrentMenuInfo, isMobile,isIOS
} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";
import withContents from "../../withContents";
import Footer_ReadingStoryL4 from "../../Footer_ReadingStoryL4";

class ReadingStory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            pass: false,
            isInitialize: false,
            hasResult:false,
            isTesting:false,
            maxDuration:60, //최대 녹음시간 60초 (단위 s)
            err:null,
            errCount:0,
            waiting:false,
            recordTime:0,
            audioURL: null,
            uploadComplete: false,
            letUpload: false,
        };
    }

    render() {

        if (!this.props.contents) {
            return (<LoadingBar/>);
        }

        if (!this.state.isStart) {
            return (
                this._renderIntro()
            );
        }

        if(this.state.isComplete){

            return(
                this._renderComplete()
            )
        }

        return (
            this._renderMain()
        )
    }

    //Intro > main
    _start = () => {
        this.setState({
            isStart: true,
        })
    }


    _renderIntro = () => {

        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    {/*<div>{JSON.stringify(this._getCurrentMenuInfo(this.props.menuInfo).menu_title_en)}</div>*/}

                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _renderMain = () => {


        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap HideScrollBar">
                        {this.state.hasResult ?
                            <>
                            {/*<div className="score-wrapper">*/}
                                {/*<div className="record-score"><strong>{this.state.recordTime}</strong>초</div>*/}
                            {/*</div>*/}

                            <ul className="btn-record-wrap">
                                <li>
                                    <button type="button" className="btn-record-confirm" onClick={() => {
                                        this._playRecordedFile()
                                    }}>녹음듣기
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="btn-record-submit" onClick={() => {
                                        this._uploadRecordedFile()
                                    }}>녹음제출
                                    </button>
                                </li>
                            </ul>
                                </>

                            :
                            <>
                                <div className="top-tip">다음 스토리의 내용을 파악하며 빠르게 읽어보세요.</div>
                                <div className="StoryContainer">
                                    <p className="larger">
                                        {/*{this._renderReading()}*/}
                                        {this.props.contents[0].eng_content}
                                    </p>
                                </div>
                            </>
                        }

                    </div>

                    <button type="button" className="btn-tip-toggle" data-toggle="tip-toggle"
                            onClick={this.props.initTipToggle}>팁 토글
                    </button>

                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">
                            {
                                this.state.hasResult ?
                                    <>
                                        녹음파일을 들어보신 후 제출하세요.<br/>
                                        <div className="divider"/>
                                        <div className={"tip"}>
                                            결과가 만족스럽지 않다면 <br/>
                                            <strong>테스트 버튼</strong>을 눌러 다시 녹음하세요.
                                        </div>

                                    </>
                                    :

                                    this.state.err === null ?
                                        <>
                                            다음 스토리를 <strong>빠르게</strong> 읽어주세요.<br/>
                                            <strong>테스트 버튼</strong>을 눌러 시작합니다.
                                            <div className="divider"/>
                                            <div className="tip">
                                                모두 다 읽고 난 후, 정지 버튼을 누르세요.
                                            </div>
                                        </>
                                        :
                                        this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_ReadingStoryL4
                    {...this.props}
                    maxDuration={this.state.maxDuration}
                    setListening = {this._setListening}
                    isListening={this.state.listening}
                    isTesting={this.state.isTesting}
                    hasResult={this.state.hasResult}
                    pass={this.state.pass}
                    handleIsTesting={this._handleIsTesting}
                    checkResult={this._checkResult}
                    handleError={this._handleError}
                    liftCounterTime={this._setRecord}
                    isPlaying={this.state.isPlaying}
                    playRecordedFile={this._playRecordedFile}
                    stopRecordedFile={this._stopRecordedFile}
                    getAudioURL={this._getAudioURL}
                    letUpload={this.state.letUpload}
                    device={isMobile ? isIOS ? "I" : "W" : "C"}
                    recordTime={this.state.recordTime}
                />

            </>

        );
    };

    _handleIsTesting = (bool) => {
        this.setState({
            isTesting:bool,
        })
    };

    _checkResult = (bool) => {
        if(bool) {
            this.props.openTip();
        }
        this.setState({
            hasResult:bool
        })
    }


    // _audioPlay = (lang, idx) => {
    //     if (this.props.isPlaying) {
    //         //this.props.audioStop();
    //         return;
    //     }
    //     this.props.audioPlay(lang, idx);
    //
    // };
    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }



    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if (err === null) { //err===null 이면 return
            this.setState({
                err: err,
            });
            return;
        }


        this.props.openTip();
        this.setState({
            err: err,
            errCount: this.state.errCount + 1,
        })
    };

    //시간 기록 저장
    _setRecord = (time) => {
        this.setState({
            recordTime:time
        })
    }

    _uploadRecordedFile = () => {
        //console.log("upload recorded file! ", this.state.audioURL);
        //this.props.upload();
        this.setState({
            letUpload: true,
            uploadComplete: true,
        });

    };

    _playRecordedFile = () => {
        //console.log("play recorded file!");
        this.setState({
            isPlaying: true,
        });

    };
    
    _stopRecordedFile = () => {
        //console.log("stop playing recorded file!");
        this.setState({
            isPlaying: false,
        })
    };






}


export default withContents()(ReadingStory);
    
    