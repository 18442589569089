import React from "react";

function DiffTextNoSound(props) {
    return (
        <h3 className={"english sentence"}>
            {
                props.diffOriginal.map((textOrHtml, index) =>
                    <React.Fragment key={index}>
                        {textOrHtml}
                    </React.Fragment>
                )
            }
            <br/>
        </h3>
    );
}

export default DiffTextNoSound