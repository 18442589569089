import React, {Component} from 'react';

import withRecognizerSP2 from "./withRecognizerSP2";
import MicVisualizer from "../other/MicVisualizer";
import {isMobile} from "../../asset/js/myFunctions";


class Footer_SP2 extends Component {

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isPlayingComplete && this.props.isPlayingComplete) { //음원이 다 종료되면,
            if (this.props.listening) {
                setTimeout(() => {
                    this.props.endRecognition();
                }, 1000);
            }
        }
    }

    render() {
        ////console.log("In ControlPanel, currentMenuNumber is:"+ this.props.currentMenu);

        return (

            <section className="view-footer">
                {
                    this.props.isPlaying && !this.props.listening ?
                        <button type="button" className="f-btn f-center btn-listen"/>
                        :
                        <>

                            {
                                this.props.currentPage > 1 ?
                                    this.props.listening ?
                                        <button type="button" className="f-btn">Empty</button>
                                        :
                                        <button type="button" className="f-btn btn-prev"
                                                onClick={this.props.prev}>이전</button>
                                    :
                                    <button type="button" className="f-btn">Empty</button>
                            }
                            {this.props.listening ?
                                <MicVisualizer/>
                                :
                                <button
                                    type="button"
                                    className="f-btn f-center btn-test"
                                    onClick={() => {
                                        if (this.props.listening) {
                                            //console.log("already Listening!");
                                            return null;
                                        }
                                        this._testClick();

                                        ////console.log(this.props.audioPlay);
                                        // this.props.audioPlayWithCb("eng", this.props.index, () => {
                                        //     setTimeout(() => {
                                        //         this.props.endRecognition()
                                        //     }, 1000);
                                        // });
                                        // this.props.startRecognition();
                                        // this.props.handleError(null);
                                        // this.props.closeTip();
                                    }}>
                                    테스트
                                </button>

                            }

                            {
                                this.props.pass && this.props.recognizerReady ?

                                    this.props.maxPage === this.props.currentPage ?
                                        <button type="button" className="f-btn btn-next-page" onClick={() => {
                                            this.props.goNextMenu()
                                        }}>다음메뉴</button>
                                        :
                                        <button type="button" className="f-btn btn-next" onClick={() => {
                                            this.props.next()
                                        }}>다음</button>
                                    :
                                    <button type="button" className="f-btn">Empty</button>
                            }
                        </>
                }


            </section>

        );
    }

    _testClick = () => {
        ////console.log(this.props.audioPlay);
        this.props.setResult(null); //결과값 초기화 후 테스트 시작
        // this.props.audioPlay("eng", this.props.index);

        //모바일의 경우, 소리 재생 + 음성인식X
        if(isMobile) {
            // this.props.audioPlayWithCb("eng",this.props.index, () => {
            //     //음원 종료 후 녹음 종료
            //     //this.props.endRecognition();
            //     setTimeout(() => {
            //         this.props.fakeEndRecognition();
            //     }, 1000);
            //
            // });
            let src = `${this.props.contents[this.props.index].step_code}/${this.props.contents[this.props.index].lesson_no}/${this.props.contents[this.props.index].eng_url}`;
            this.props.setHowler(
                src,
                () => {
                    setTimeout(() => {
                        //this.props.endRecognition()
                        this.props.fakeEndRecognition();
                    }, 1000)
                }
            );
            this.props.fakeStartRecognition();
            this.props.handleError(null);
            this.props.closeTip();
           // this.props.startTest(); //set isTest True
        } else {
            // this.props.audioPlayWithCb("eng", this.props.index, () => {
            //     setTimeout(() => {
            //         this.props.endRecognition()
            //     }, 1000);
            // });
            //
            let src = `${this.props.contents[this.props.index].step_code}/${this.props.contents[this.props.index].lesson_no}/${this.props.contents[this.props.index].eng_url}`;
            this.props.setHowler(
                src,
                () => {
                    setTimeout(() => {
                        this.props.endRecognition()
                    }, 1000)
                }
            );
            this.props.startRecognition();
            this.props.handleError(null);
            this.props.closeTip();
        }


    }

}

export default withRecognizerSP2()(Footer_SP2);
    
    